export enum AlertType {
  Success = "success",
  Warning = "warning",
  Info = "info",
  Danger = "danger"
}

export enum AlertContentType {
  ContractAddError = "CONTRACT_ADD_EXCEPTION",
  ContractDeleteError = "CONTRACT_DELETE_EXCEPTION",
  ContractPublishError = "CONTRACT_PUBLISH_EXCEPTION",
  ContractDeleteSuccess = "CONTRACT_DELETE_SUCCESS",
  RowHasErrors = "ROW_EXCEPTION",
  ContractDetailsErrors = "CONTRACT_EXCEPTION",
  MainLeaseErrors = "MAIN_LEASE_EXCEPTION",
  ContractHasNoActiveRows = "CONTRACT_HAS_NO_ACTIVE_ROWS",
  PropertyHasActiveRows = "PROPERTY_HAS_ACTIVE_ROWS",
  ProjectHasActiveRows = "PROJECT_HAS_ACTIVE_ROWS",
  Success = "SUCCESS",
  Published = "PUBLISHED",
  ContractHasNoRows = "CONTRACT_HAS_NO_ROWS",
  RowsSavedSuccess = "ROWS_SAVED_SUCCESS",
  RowsSavedFail = "ROWS_SAVED_FAIL",
  DetailSavedFail = "DETAIL_SAVED_FAIL",
  DefaultPricesSaveFailed = "DEFAULT_PRICES_SAVED_FAIL",
  ThiMultiplicationFailed = "THI_MULTIPLICATION_FAIL",
  ClipBoardHasTooManyRows = "CLIPBOARD_HAS_TOO_MANY_ROWS",
  ClipBoardHasTooManyColumns = "CLIPBOARD_HAS_TOO_MANY_COLUMNS",
  ClipBoardDataDoesntMatchField = "CLIPBOARD_DATA_DOESNT_MATCH_FIELD",
  FileUploadSuccess = "FILE_UPLOAD_SUCCESS",
  FileUploadFail = "FILE_UPLOAD_FAIL",
  FileCommentFail = "FILE_COMMENT_FAIL",
  FileDeleteFail = "FILE_DELETE_FAIL",
  PropertyCleanFailed = "PROPERTY_CLEAN_FAILED",
  PropertySyncStarted = "PROPERTY_REGISTRY_SYNC_STARTED",
  UserSyncStarted = "USER_REGISTRY_SYNC_STARTED",
  PropertySyncFailed = "PROPERTY_REGISTRY_SYNC_FAILED",
  UserSyncFailed = "USER_REGISTRY_SYNC_FAILED",
  ConstructionContractSyncStarted = "CONSTRUCTION_CONTRACT_SYNC_STARTED",
  ConstructionContractSyncFailed = "CONSTRUCTION_CONTRACT_SYNC_FAILED",
  EDHSContractAddFailed = "EDHS_CONTRACT_ADD_FAILED",
  EDHSContractPublishFailed = "EDHS_CONTRACT_PUBLISH_FAILED",
  EDHSContractDeleteFailed = "EDHS_CONTRACT_DELETE_FAILED",
  EDHSContractUpdateSuccess = "EDHS_CONTRACT_UPDATE_SUCCESS",
  EDHSContractUpdateFailed = "EDHS_CONTRACT_UPDATE_FAILED",
  EDHSFileAddFailed = "EDHS_FILE_ADD_FAILED",
  EDHSFileDownloadFailed = "EDHS_FILE_DOWNLOAD_FAILED",
  EDHSFileListFailed = "EDHS_FILE_LIST_FAILED",
  EDHSFileDeleteFailed = "EDHS_FILE_DELETE_FAILED",
  ContractRowImportSuccess = "CONTRACT_ROW_IMPORT_SUCCESS",
  ContractRowImportFail = "CONTRACT_ROW_IMPORT_FAIL",
  ContractorNotificationSuccess = "CONTRACTOR_NOTIFICATION_SUCCESS",
  ContractorNotificationFailed = "CONTRACTOR_NOTIFICATION_FAILED",
  NotificationPreviewFailed = "NOTIFICATION_PREVIEW_FAILED",
  WarrantyEndDateSaveFailed = "WARRANTY_END_DATES_UPDATE_FAIL",
}

export class Alert {
  id: number;
  type: AlertType;
  content: AlertContentType;
  additionalContent?: string;

  static translateAlertContent(alertContent: AlertContentType): string {
    switch (alertContent) {
      case AlertContentType.ConstructionContractSyncStarted:
        return "Ehituslepingute uuendamine on käivitatud!";
      case AlertContentType.ConstructionContractSyncFailed:
        return "Ehituslepingute uuendamine ebaõnnestus!";
      case AlertContentType.NotificationPreviewFailed:
        return "Teavituskirja eelvaate loomine ebaõnnestus.";
      case AlertContentType.ContractorNotificationSuccess:
        return "Teavituskiri projektijuhile saadetud.";
      case AlertContentType.ContractorNotificationFailed:
        return "Teavituskirja saatmine ebaõnnestus.";
      case AlertContentType.EDHSContractUpdateSuccess:
        return "Lepingu uuendamine EDHS's õnnestus.";
      case AlertContentType.EDHSContractUpdateFailed:
        return "Lepingu uuendamine EDHS's ebaõnnestus.";
      case AlertContentType.ContractAddError:
        return "Lepingu lisamine ebaõnnestus.";
      case AlertContentType.ContractDeleteError:
        return "Lepingu kustutamine ebaõnnestus.";
      case AlertContentType.ContractPublishError:
        return "Lepingu avaldamine ebaõnnestus.";
      case AlertContentType.ContractDeleteSuccess:
        return "Lepingu kustutamine õnnestus.";
      case AlertContentType.RowHasErrors:
        return "Lepingul on vigaseid ridu, palun parandage need!";
      case AlertContentType.ContractHasNoActiveRows:
        return "Lepingul puuduvad aktiivsed read.";
      case AlertContentType.PropertyHasActiveRows:
        return "Kustutamine pole võimalik. Kinnistuga on seotud read.";
      case AlertContentType.ProjectHasActiveRows:
        return "Kustutamine pole võimalik. Projektiga on seotud read.";
      case AlertContentType.ContractDetailsErrors:
        return "Lepingu päiseandmed on vigased.";
      case AlertContentType.Success:
        return "Lepingu päiseandmed ja read on korrektsed.";
      case AlertContentType.Published:
        return "Leping on edukalt avaldatud.";
      case AlertContentType.ContractHasNoRows:
        return "Lepingul puuduvad read, lisage vähemalt üks rida!";
      case AlertContentType.RowsSavedSuccess:
        return "Ridade salvestamine õnnestus";
      case AlertContentType.RowsSavedFail:
        return "Ridade salvestamine ebaõnnestus";
      case AlertContentType.DetailSavedFail:
        return "Salvestamine ebaõnnestus";
      case AlertContentType.DefaultPricesSaveFailed:
        return "Tunnihindade salvestamine ebaõnnestus";
      case AlertContentType.ThiMultiplicationFailed:
        return "THI'ga korrutamine ebaõnnestus";
      case AlertContentType.ClipBoardHasTooManyRows:
        return "Kopeeritud ridu on liiga palju!";
      case AlertContentType.ClipBoardHasTooManyColumns:
        return "Kopeeritud veerge on liiga palju!";
      case AlertContentType.ClipBoardDataDoesntMatchField:
        return "Kopeeritud andmed ei sobitu välja tüübiga!";
      case AlertContentType.FileUploadSuccess:
        return "Faili üleslaadimine õnnestus!";
      case AlertContentType.FileUploadFail:
        return "Faili üleslaadimine ebaõnnestus!";
      case AlertContentType.FileCommentFail:
        return "Faili kommentaari salvestamine ebaõnnestus!";
      case AlertContentType.FileDeleteFail:
        return "Faili kustutamine ebaõnnestus!";
      case AlertContentType.MainLeaseErrors:
        return "Peaüüri andmed on vigased";
      case AlertContentType.PropertyCleanFailed:
        return "Kinnistute puhastamine ebaõnnestus!";
      case AlertContentType.PropertySyncStarted:
        return "Kinnistute uuendamine on käivitatud!";
      case AlertContentType.UserSyncStarted:
        return "Kasutajate uuendamine on käivitatud!";
      case AlertContentType.PropertySyncFailed:
        return "Kinnistute sünk ebaõnnestus!";
      case AlertContentType.UserSyncFailed:
        return "Kasutajate sünk ebaõnnestus!";
      case AlertContentType.EDHSContractAddFailed:
        return "EDHS vea tõttu ei saa lepingut lisada!";
      case AlertContentType.EDHSContractPublishFailed:
        return "EDHS vea tõttu ei saa lepingut avaldada!";
      case AlertContentType.EDHSContractDeleteFailed:
        return "EDHS vea tõttu ei saa lepingut kustutada!";
      case AlertContentType.EDHSFileAddFailed:
        return "EDHS vea tõttu ei saa faili lisada!";
      case AlertContentType.EDHSFileDownloadFailed:
        return "EDHS vea tõttu ei saa faili alla laadida!";
      case AlertContentType.EDHSFileListFailed:
        return "EDHS vea tõttu ei saa lepingu faile kuvada!";
      case AlertContentType.EDHSFileDeleteFailed:
        return "EDHS vea tõttu ei saa faili kustutada!";
      case AlertContentType.ContractRowImportSuccess:
        return "Ridade import õnnestus!";
      case AlertContentType.ContractRowImportFail:
        return "Ridade import ebaõnnestus!";
      case AlertContentType.WarrantyEndDateSaveFailed:
        return "Garantii lõpukuupäevade uuendamine ebaõnnestus!";
    }
  }

  static fromException(exception: string): AlertContentType {
    return exception ? this.enumFromStringValue(AlertContentType, exception.toUpperCase()) : undefined;
  }

  private static enumFromStringValue<T> (enm: { [s: string]: T}, value: string): T | undefined {
    return (Object.values(enm) as unknown as string[]).includes(value)
        ? value as unknown as T
        : undefined;
  }
}

import React, {ChangeEvent} from "react";
import { ContractRowField, ContractRowFieldProps } from "./ContractRowField";
import { ContractRow } from "../../ContractRow";

interface Props<T extends ContractRow> extends ContractRowFieldProps<T> {
    placeholder?: string;
}

export default class TextRowField<T extends ContractRow> extends ContractRowField<T, Props<T>> {

    private onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.row, this.props.fieldKey, event.target.value);
        }
    };

    protected renderEditableField(): JSX.Element {
        return (
            <input className="form-control"
                   placeholder={this.props.placeholder}
                   defaultValue={this.getFieldValue()}
                   onChange={this.onChange}
                   disabled={!this.props.editable}
            />
        );
    }
}
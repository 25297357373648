import React from "react";
import { ContractInfoField, ContractInfoFieldProps } from "./ContractInfoField";
import { Company, FullContract } from "../../../Contract";
import axios from "axios";
import { AsyncPaginate} from "react-select-async-paginate";
import { PagingMetadata } from "../../../../Paging/Paging";
import { PagingUtils } from "../../../../Paging/PagingUtils";
import {MainLease} from "../../../../MainLease/MainLease";

type CompanyFieldKey<T extends FullContract | MainLease> = { [C in keyof T]: T[C] extends Company ? C : never }[keyof T];

interface Props<T extends FullContract | MainLease> extends ContractInfoFieldProps<T> {
  baseURL: string;
  fieldKey: CompanyFieldKey<T>;
  isClearable?: boolean;
}

interface State {
  paging: PagingMetadata;
}

type CompanyOption = { label: string, value: number, company: Company };

export default class CompanyField<T extends FullContract | MainLease> extends ContractInfoField<T, Props<T>, State> {

  constructor(props: Props<T>) {
    super(props);

    this.state = {
      paging: new PagingMetadata({
        page: 0,
        limit: 20,
        sort: "name"
      })
    };
  }

  private formatLabel = (company: Company) => `${company.name} - ${company.regNo}`;

  private getOption = (company?: Company): CompanyOption => {
    if (company) {
      return {
        label: this.formatLabel(company),
        value: company.id,
        company
      };
    }
    return undefined;
  };

  private getDefaultOptions(): CompanyOption[]|boolean {
    let company: Company = this.getFieldValue();
    if (company) {
      return [this.getOption(company)];
    }
    return true;
  }

  private loadOptions = (search, options, { page }) => {
    return axios.get(`${this.props.baseURL}/companies`, {
      params: PagingUtils.toHttpParams(new PagingMetadata({
        ...this.state.paging,
        page,
        search
      }))
    }).then(res => {
      const paging: PagingMetadata = new PagingMetadata(res.data.metadata);

      this.setState({ paging });

      return {
        options: res.data.data.map(this.getOption),
        hasMore: ((paging.page + 1) * paging.limit) < paging.count,
        additional: {
          page: page + 1
        }
      }
    });
  };

  private onChange = (option: CompanyOption) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.fieldKey, option ? option.company : undefined);
    }
  };

  protected renderReadOnlyField(): JSX.Element {
    return (
        <span className="font-weight-bold">
          {(this.getFieldValue() || {})["name"] || '-'}
        </span>
    );
  }

  protected renderEditableField(): JSX.Element {
    return (
        <AsyncPaginate className="react-select"
                       classNamePrefix="react-select"
                       isClearable={this.props.isClearable}
                       placeholder="Vali"
                       menuPortalTarget={document.querySelector('body')}
                       isDisabled={!this.props.editable}
                       defaultOptions={this.getDefaultOptions()}
                       loadOptions={this.loadOptions}
                       additional={{
                         page: 0
                       }}
                       onChange={this.onChange}
                       value={this.getOption(this.getFieldValue())}
        />
    );
  }
}
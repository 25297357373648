import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../rootReducer";
import { sideNavActions } from "./sideNavActions";

export class SideNavState {
    sideNavToggled: boolean;

    constructor(state?: SideNavState) {
        if (state) {
            this.sideNavToggled = state.sideNavToggled;
        } else {
            this.sideNavToggled = false;
        }
    }
}

export const sideNavReducer = createReducer(new SideNavState(), {
    [sideNavActions.toggleSideNav.type]: (state, action: PayloadAction<boolean>) => {
        let newState = new SideNavState();
        newState.sideNavToggled = action.payload;
        return newState;
    }
});

export function getSideNavToggle(state: RootState): boolean {
    return state.sideNav.sideNavToggled;
}
import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import EnergyContractList from "./EnergyContractList";
import ContractDetails2 from "../ContractDetails/ContractDetails";

export class EnergyContracts extends React.Component<RouteComponentProps<{}>> {

  render() {
    return (
        <>
          <Route path={this.props.match!.url} exact={true} component={EnergyContractList} />
          <Route path={this.props.match!.url + "/:id"} exact={true} component={ContractDetails2} />
        </>
    );
  }
}
import {combineReducers} from '@reduxjs/toolkit';
import {configReducer, ConfigState} from "./Config/configReducer";
import {ConfigActionReturnTypes} from "./Config/configActions";
import {costItemsReducer, CostItemState} from "./Contracts/costItemReducer";
import {CostItemActionReturnTypes} from "./Contracts/costItemActions";
import {alertReducer, AlertState} from "./Alerts/alertReducer";
import {classifierReducer, ClassifierState} from "./Classifier/classifierReducer";
import {ClassifierActionReturnTypes} from "./Classifier/classifierActions";
import {AuthActionReturnTypes} from "./Auth/authActions";
import {authReducer, AuthState} from "./Auth/authReducer";
import {sideNavReducer, SideNavState} from "./SideNav/sideNavReducer";
import {SideNavActionReturnTypes} from "./SideNav/sideNavActions";
import {ContractActionReturnTypes} from "./Contracts/contractActions";
import {contractReducer, ContractState} from "./Contracts/contractReducer";
import {AlertActionReturnTypes} from "./Alerts/alertActions";
import {contractDetailsReducer, ContractDetailsState} from "./Contracts/ContractDetails/contractDetailsReducer";
import {ContractDetailsActionReturnTypes} from "./Contracts/ContractDetails/contractDetailsActions";
import {modalReducer, ModalState} from "./Modal/modalReducer";
import {ModalActionReturnTypes} from "./Modal/modalActions";

export type RootAction = ConfigActionReturnTypes
    | AuthActionReturnTypes
    | CostItemActionReturnTypes
    | ClassifierActionReturnTypes
    | AlertActionReturnTypes
    | SideNavActionReturnTypes
    | ContractActionReturnTypes
    | ContractDetailsActionReturnTypes
    | ModalActionReturnTypes;

export interface RootState {
    config: ConfigState;
    auth: AuthState;
    costItem: CostItemState;
    classifier: ClassifierState;
    alert: AlertState;
    sideNav: SideNavState;
    contract: ContractState;
    contractDetails: ContractDetailsState;
    modal: ModalState;
}

export const rootReducer = combineReducers<RootState, RootAction>({
    config: configReducer,
    auth: authReducer,
    costItem: costItemsReducer,
    classifier: classifierReducer,
    alert: alertReducer,
    sideNav: sideNavReducer,
    contract: contractReducer,
    contractDetails: contractDetailsReducer,
    modal: modalReducer
});
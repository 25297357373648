import "./globals";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./startui/css/startui.css";
import App from "./App/App";
import "map.prototype.tojson";
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/et';

ReactDOM.render(
    <BrowserRouter>
      <App/>
    </BrowserRouter>,
    document.getElementById("root") as HTMLElement
);

import {
  ContractRow,
  CostItem, EnergyContractRow,
  LeaseContractRow, ServiceContractRow
} from "./ContractDetails/ContractRow";
import { Permission, User } from "../Auth/User";

export enum ContractDomain {
  Services = "SERVICES",
}

export type contractKeys =
    keyof ServiceContract
    | keyof LeaseContract
    | keyof ConstructionContract
    | keyof SupportServiceContract
    | keyof EnergyContract
    | keyof ConsumptionContract;

export enum ContractType {
  ServiceContract = "SERVICE_CONTRACT",
  ConstructionContract = "CONSTRUCTION_CONTRACT",
  SupportServiceContract = "SUPPORT_SERVICE_CONTRACT",
  LeaseAgreement = "LEASE_AGREEMENT",
  EnergySaleAgreement = "ENERGY_SALE_AGREEMENT",
  ConsumptionContract = "CONSUMPTION_CONTRACT"
}

export class Property {
  id: number;
  code: string;
  name: string;
  address: string;
  regionType: string;
  portfolioType: PortfolioType;
  wbsCode: string;
  reserveValue?: number;
  countryCode: string;
  rentalCodeCount: number;
  saleDate: number;
  manager: Person;
  accountant: Person;
  portfolioManager: Person;
  projectManager: Person;
  hasPublished: boolean;
}

export class MainProperty {
  id: number;
  name: string;
  costProperty: Property;
  properties: Property[];
}

export class Project {
  id: number;
  projectNumber: string;
  name: string;
  reserveValue?: number;
  propertyCodes?: string[];
  warrantyStartDate?: string;
  warrantyEndDate?: string;

  constructor(externalProject: ExternalProject, contractExternalId: string | undefined) {
    this.id = externalProject.id;
    this.projectNumber = externalProject.projectNumber;
    this.name = externalProject.name;
    this.reserveValue = externalProject.reserveValue;
    this.propertyCodes = externalProject.propertyCodes;

    if(contractExternalId && externalProject.projectWarranties && externalProject.projectWarranties.length > 0) {
      const contractWarranty = externalProject.projectWarranties.find(w => w.edhsId === parseInt(contractExternalId));
      if(contractWarranty) {
        this.warrantyStartDate = contractWarranty.startDate;
        this.warrantyEndDate = contractWarranty.endDate;
      }
    }
  }
}

export class ExternalProject {
  id: number;
  projectNumber: string;
  name: string;
  reserveValue?: number;
  propertyCodes?: string[];
  projectWarranties?: ProjectWarranty[];
}


export class ProjectWarranty {
  id?: number;
  startDate?: string;
  lengthMonths: number;
  endDate?: string;
  edhsId?: number;
}

export enum PortfolioType {
    InterimLease = "INTERIM_LEASE",
    Expired = "EXPIRED",
    Fm = "FM",
    Dev = "DEV",
    Sale = "SALE",
    Contract = "CONTRACT",
    Takeover = "TAKEOVER",
    Energysale = "ENERGYSALE",
    Unknown = "UNKNOWN"
}

export enum GovernanceAreaType {
  MinistryEdu = "MINISTRY_EDU",
  MinistryJustice = "MINISTRY_JUSTICE",
  MinistryDefence = "MINISTRY_DEFENCE",
  MinistryCulture = "MINISTRY_CULTURE",
  MinistryAgri = "MINISTRY_AGRI",
  MinistryFin = "MINISTRY_FIN",
  MinistryInterior = "MINISTRY_INTERIOR",
  MinistryForeign = "MINISTRY_FOREIGN",
  GovOffice = "GOV_OFFICE",
  GovOther = "GOV_OTHER",
  GovLocal = "GOV_LOCAL",
  Other = "OTHER",
  MinistrySocial = "MINISTRY_SOCIAL",
  MinistryEconomy = "MINISTRY_ECONOMY",
  MinistryEnv = "MINISTRY_ENV"
}

export class Company {
  id: number;
  code: string;
  name: string;
  zip: string;
  countryCode: string;
  governanceArea: GovernanceAreaType;
  regNo: string;
  email: string;
  phone: string;
}

export class Person {
  countryCode: string;
  forename: string;
  surname: string;
  email: string;
  personalCode: string;
  phoneNumber: string;

  constructor() {
    this.countryCode = "";
    this.forename = "";
    this.surname = "";
    this.email = "";
    this.personalCode = "";
    this.phoneNumber = "";
  }
}

export class Eic {
  eic: string;
  contractNumber?: string;
}

export class Event {
  id: number;
  type: EventType;
  executedBy: string;
  executedOn: number;
}

export enum EventType {
  Entered = "ENTERED",
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED"
}

export class ContractValidationError {
  id: number;
  type: ContractValidationErrorType;
  errorCode: string;
  errorMessage?: string;
}

export enum ContractValidationErrorType {
  RowException = "ROW_EXCEPTION",
  ContractException = "CONTRACT_EXCEPTION"
}

export class DefaultPrice {
  id: number;
  costItems: CostItem[];
  price: number;
  groupType: string;

  searchValue?: number;
}

export class ThiMultiplication {
  properties: Property[];
  percentage: number;
  startDate: number;
}

export class CostItemWarranty {
  contractId: number;
  costItem: CostItem;
  warrantyEndDate: Date;

  constructor(contractId: number, costItem: CostItem, warrantyEndDate: Date | null) {
    this.contractId = contractId;
    this.costItem = costItem;
    this.warrantyEndDate = warrantyEndDate;
  }
}

export class Equipment {
  id: number;
  code: string;
  propertyCode: string;
  buildingCode: string;
  eic: string;
  equipmentType: EquipmentType;
  comment: string;
  consumptionContract?: ConsumptionContract;
}

export enum ReserveCalculationBasedOn {
  Percent = "PERCENT",
  Value = "VALUE"
}

export enum LeaseType {
  Unknown = "UNKNOWN",
  CapitalMarketBased = "CAPITAL_MARKET_BASED",
  CapitalExpenseBased = "CAPITAL_EXPENSE_BASED",
  UsageMarketBased = "USAGE_MARKET_BASED",
  UsageExpenseBased = "USAGE_EXPENSE_BASED"
}

export enum HeatingType {
  Gas = "GAS",
  RemoteHeating = "REMOTE_HEATING",
  LightOil = "LIGHT_OIL",
  HeavyOil = "HEAVY_OIL",
  ShaleOil = "SHALE_OIL",
  Coal = "COAL",
  Timber = "TIMBER",
  Pellet = "PELLET",
  Chipwood = "CHIPWOOD",
  Combined = "COMBINED",
  Electricity = "ELECTRICITY",
  None = "NONE"
}

export enum NoticeDate {
  "02_15" = "15.02",
  "04_01" = "1.04",
  "06_01" = "1.06",
  "09_01" = "1.09",
  "10_01" = "1.10",
  "12_01" = "1.12",
  "12_05" = "5.12",
  "12_15" = "15.12",
  "31_15" = "31.12"
}

export enum ConsumptionService {
  Fixed = "FIXED",
  Forecast = "FORECAST",
  None = "NONE",
  Leaser = "LEASER"
}

export enum ActFrequency {
  Yearly = "YEARLY",
  Quarterly = "QUARTERLY",
  Monthly = "MONTHLY",
  Unknown = "UNKNOWN"
}

export enum ServiceContractType {
  ServTh = "SERV_TH",
  ServHk = "SERV_HK",
  ServTT = "SERV_TT"
}

export enum LeaseContractType {
  Lease = "LEASE",
  FmLease = "FMLEASE",
  Servitude = "SERVITUDE",
  Invoicing = "INVOICING"
}

export enum SupportServiceContractType {
  RKAS = "RKAS_SUPPORT_SERVICE_CONTRACT",
  Property = "PROPERTY_SUPPORT_SERVICE_CONTRACT"
}

export enum EndingPredication {
  DeadlineExpiration = "DEADLINE_EXPIRATION",
  EndingByLeaser = "ENDING_BY_LEASER"
}

export enum InvoiceChannelType {
  Post = "POST",
  Portal = "PORTAL",
  Email = "EMAIL"
}

export enum MeterForwardType {
  DistanceReader = "DISTANCE_READER",
  Email = "EMAIL",
  SelfService = "SELF_SERVICE",
  NotForwarder = "NOT_FORWARDED"
}

export enum ConsumptionContractType {
  Gas = "GAS",
  Electricity = "ELECTRICITY",
  Heat = "HEAT",
  Water = "WATER",
  Other = "OTHER"
}

export enum EquipmentType {
  Vahearvesti = "VAHEARVESTI",
  Peaarvesti = "PEAARVESTI",
  VkKrtPeaJaAlaarvestid = "VK KRT PEA JA ALAARVESTID",
  Elektriarvesti = "ELEKTRIARVESTI",
  Soojusarvesti = "SOOJUSARVESTI",
  Veearvesti = "VEEARVESTI",
  Gaasiarvesti = "GAASIARVESTI"
}

export class Contract {
  id: number;
  generalDocumentTitle: string;
  sapCode: string;
  number: string;
  domain: ContractDomain;
  externalId: string;
  type: ContractType;
  events: Event[];
  errors?: ContractValidationError[];
  startTime: number;
  endTime: number;
  createdOn: number;
  createdBy: string;
  changedOn: number;
  changedBy: string;
  publishedOn?: number;
  publishedBy?: string;
  hasPublished: boolean;
  isAvailableUnpublished: boolean;
  rhrReferenceNumber : string;

  static translateContractType(contractType: ContractType): string {
    switch (contractType) {
      case ContractType.ServiceContract:
        return "Korrashoiuteenuste leping";
      case ContractType.ConstructionContract:
        return "Ehitusleping";
      case ContractType.SupportServiceContract:
        return "Tugiteenusleping";
      case ContractType.EnergySaleAgreement:
        return "Elektrileping";
      case ContractType.LeaseAgreement:
        return "Kasutusleping";
      case ContractType.ConsumptionContract:
        return "Tarbimisleping";
    }
  }

  static translateEventType(eventType: EventType): string {
    switch (eventType) {
      case EventType.Entered:
        return "Sisestamine";
      case EventType.Published:
        return "Avaldamine";
      case EventType.Unpublished:
        return "Muutmisesse võtmine";
    }
  }
}

export class ServiceContract extends Contract {
  mainProperty: MainProperty;
  customer: Company;
  customerRepresentative: Person;
  contractor: Company;
  contractorRepresentative: Person;
  enterTime: number;
  sum: number;
  reservePercentage: number;
  reserveValue: number;
  reserveCalculationBasedOn: ReserveCalculationBasedOn;
  paymentTerm: string;
  content: string;
  note: string;
  processingNote: string;
  autoActsEnabled: boolean;
  isActable: boolean;
  jobApprovalDays: number;
  frameContractReference: string;
  procurementReference: string;
  serviceContractType: ServiceContractType;
  defaultPrices: DefaultPrice[];
  minServiceStartTime: number;
  maxServiceEndTime: number;
  contractorNotificationTimestamp?: number;
}

export class ConstructionContract extends ServiceContract {
  authorizer: Company;
  authorizerRepresentative: Person;
  workConfirmationDeadline: number;
  customerWorkConfirmationDeadline: number;
  owner: Company;
  ownerRepresentative: Person;
  actFrequency: ActFrequency;
  warrantyEndDate: number;
  isIndexable: boolean;

  static translateActFrequency(actFrequency: ActFrequency): string {
    switch (actFrequency) {
      case ActFrequency.Yearly :
        return "Aastane";
      case ActFrequency.Quarterly :
        return "Kvartaalne";
      case ActFrequency.Monthly :
        return "Igakuine";
      case ActFrequency.Unknown:
        return "Määramata";
    }
  }
}

export class SupportServiceContract extends ServiceContract {
  edhsCode: string;
  supportServiceContractType: SupportServiceContractType;
}

export class EnergyContract extends Contract {
  rentalCode: string;
  eic: string[];
  mainLease: string;
  comment: string;
  leaser: Company;
  invoiceDueDays: number;
  contactPhone: string;
  contactEmail: string;
}

export class ConsumptionContract extends Contract {
  customer: Company;
  customerRepresentative: Person;
  contractor: Company;
  contractorRepresentative: Person;
  meterForwardType: MeterForwardType;
  comment: string;
  consumptionContractType: ConsumptionContractType;
  emailAddress1: string;
  emailAddress2: string;
  isFrameAgreement: boolean;
  frameContractReference: string;
  dateSigned: number;
  sum: number;
}

export class LeaseContract extends Contract {
  rentalCode: string;
  manager: Person;
  specialist: Person;
  leaser: Company;
  area: number;
  leaseContractType: LeaseContractType;
  leaseType: LeaseType;
  isSettleable: boolean;
  sendNotice: boolean;
  noticeDate: NoticeDate;
  heatingType: HeatingType;
  transitionPeriodEnd: number;
  indexingStart: number;
  cpiDate: number;
  coefficient: number;
  consumptionService: ConsumptionService;
  comment: string;
  mainLease: string;
  endingPredication: EndingPredication;
  isBondCollateral: boolean;
  isInternalBilling: boolean;
  invoiceDueDays: number;
  invoiceChannelType: InvoiceChannelType;
  terminationTerm: string;
  isOrdinaryCancellation: boolean;
  cancellationPeriod: number;
  cancellationContractReference: string;
  cancellationCompensation: string;
  subsequentMonthInvoicing: boolean;
  frameContractReference: string;
  limit: number;

  static translateLeaseType(leaseType: LeaseType): string {
    switch (leaseType) {
      case LeaseType.UsageMarketBased:
        return "Turupõhine kasutusrent";
      case      LeaseType.UsageExpenseBased:
        return "Kulupõhine kasutusrent";
      case      LeaseType.CapitalMarketBased:
        return "Turupõhine kapitalirent";
      case      LeaseType.CapitalExpenseBased:
        return "Kulupõhine kapitalirent";
      case LeaseType.Unknown:
        return "Määramata";
    }
  }

  static translateHeatingType(heatingType: HeatingType): string {
    switch (heatingType) {
      case HeatingType.Gas :
        return "Maagaas";
      case HeatingType.RemoteHeating :
        return "Kaugküte";
      case HeatingType.LightOil :
        return "Kergekütteõli";
      case HeatingType.HeavyOil :
        return "Raskekütteõli";
      case HeatingType.ShaleOil :
        return "Põlevkiviõli";
      case HeatingType.Coal :
        return "Kivisüsi";
      case HeatingType.Timber :
        return "Halupuu";
      case HeatingType.Pellet :
        return "Pellet";
      case HeatingType.Chipwood :
        return "Hakkepuit";
      case HeatingType.Combined :
        return "Kombineeritud küte";
      case HeatingType.Electricity :
        return "Elektriküte";
      case HeatingType.None :
        return "Ei köeta";
    }
  }

  static translateConsumptionService(consumptionService: ConsumptionService): string {
    switch (consumptionService) {
      case ConsumptionService.Fixed :
        return "Tarbimisteenused fikseeritud";
      case ConsumptionService.Forecast :
        return "Tasutakse tegeliku kulu alusel, esitatud kulu prognoos";
      case ConsumptionService.None :
        return "Tarbimisteenuseid ei osutata";
      case ConsumptionService.Leaser:
        return "Tarbimisteenused üürniku nimel";
    }
  }

}

export class FullServiceContract extends ServiceContract {
  rows?: ServiceContractRow[];
  properties?: Property[];
}

export class FullConstructionContract extends ConstructionContract {
  rows?: ServiceContractRow[];
  properties?: Property[];
  projects?: Project[];
}

export class FullSupportServiceContract extends SupportServiceContract {
  properties?: Property[];
}

export class FullLeaseContract extends LeaseContract {
  rows?: LeaseContractRow[];
  properties?: Property[];
}

export class FullEnergyContract extends EnergyContract {
  rows?: EnergyContractRow[];
  properties?: Property[];
}

export class FullConsumptionContract extends ConsumptionContract {
  equipment?: Equipment[];
  properties?: Property[];
}

export type FullContract = FullServiceContract | FullConstructionContract | FullSupportServiceContract
    | FullLeaseContract | FullEnergyContract | FullConsumptionContract;

export function getContractRows(contract?: Contract): ContractRow[] {
  let rows: ContractRow[] = [];
  if (contract) {
    rows = (contract as FullServiceContract | FullConstructionContract | FullLeaseContract).rows || [];
  }
  return rows;
}

export function getContractProperties(contract?: FullContract & { properties?: Property[]}) {
  let properties: Property[] = [];
  if (contract) {
    properties = contract.properties || [];
  }
  return properties;
}

export function getContractProjects(contract?: FullConstructionContract) {
  let projects: Project[] = [];
  if (contract) {
    projects = contract.projects || [];
  }
  return projects;
}

export function getManagePermission(contractType: ContractType): Permission {
  switch (contractType) {
    case ContractType.ServiceContract:
      return Permission.ManageServiceContract;
    case ContractType.ConstructionContract:
      return Permission.ManageConstructionContract;
    case ContractType.SupportServiceContract:
      return Permission.ManageSupportServiceContract;
    case ContractType.LeaseAgreement:
      return Permission.ManageLeaseAgreement;
    case ContractType.EnergySaleAgreement:
      return Permission.ManageEnergySaleAgreement;
    case ContractType.ConsumptionContract:
      return Permission.ManageConstructionContract;
  }
}

export class FileContainer {
  auditComment: string;
  containerFileContent: string;
  containerType: string;
  dateCreated: number;
  dateModified: number;
  documentParenttype: string;
  documentSubtype: string;
  documentType: string;
  format: string;
  id: number;
  name: string;
  signingStatusType: boolean;
  size: number;
  stream: string;
  templateContainerName: string;
  userCreated: string;
  userModified: string;
  version: string;

  // kihl comment
  comment: string;
}

export class ContractFile {
  id: number;
  fileId: number;
  size: number;
  mimeType: string;
  name: string;
  path: string;

  // kihl comment
  comment: string;
}

export class ContractFileMeta {
  id: number;
  externalId: number;
  fileType: ContractFileType;
  fileNumber: string;
  startDate: number;
  description: string;
  area: number;
  fileName: string;

  createdAt: number;
  createdBy: User;
  updatedAt: number;
  updatedBy: User;
}

export enum ContractFileType {
  Undefined = "UNDEFINED",
  Addition = "ADDITION",
  Act = "ACT",
  Contract = "CONTRACT",
  Ending = "ENDING",
  Notice = "NOTICE",
  Change = "CHANGE"
}

import React from "react";
import axios from "axios";
import {AsyncPaginate} from "react-select-async-paginate";
import {PagingMetadata} from "../Paging/Paging";
import {Company} from "../Contracts/Contract";
import {ContractFilters} from "../Contracts/ContractFilters";
import {PagingUtils} from "../Paging/PagingUtils";
import {MainLeaseFilters} from "../MainLease/MainLeaseList/MainLeaseFilters";
import {ReportFilters} from "../Reports/ReportFilters";

interface Props {
    baseURL: string;
    fieldKey: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters;
    backingFieldKey: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters;
    freeTextFieldKey: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters;
    isClearable?: boolean;
    portalTarget?: string;
    fieldValue: any;
    backingFieldValue: any;
    freeTextFieldValue: any;
    valueKey: keyof Company;
    onChange?: (fieldKeys: (keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters)[], values: any[]) => void;
}

interface State {
    paging: PagingMetadata;
    options: CompanyOption[];
}

type CompanyOption = { label: string, value: string, company: Company, isFreeText: boolean };

export default class CompanyListFilter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            paging: new PagingMetadata({
                page: 0,
                limit: 20,
                sort: "name"
            }),
            options: []
        };
    }

    private formatLabel = (company: Company) => `${company.name} - ${company.regNo}`;

    private getOption = (company?: Company): CompanyOption => {
        if (company) {
            return {
                label: this.formatLabel(company),
                value: company.code,
                company,
                isFreeText: false
            };
        }
        return undefined;
    };

    private findOption = (companyRegNo?: string): CompanyOption => {
        if (companyRegNo) {
            const storedOption = this.state.options.find(option => option?.company?.regNo == companyRegNo);
            if(storedOption) return storedOption;

            axios.get(`${this.props.baseURL}/companies/${companyRegNo}`).then(res => {
                const paging = new PagingMetadata({
                    page: 0,
                    limit: 20,
                    sort: "name"
                });
                const option = this.getOption(res.data);
                this.setState({ paging, options: [option] });
                return option;
            });
        }
        return undefined;
    };

    private getValue() {
        const value = this.findOption(this.props.backingFieldValue) ?? this.getFreeTextOption(this.props.freeTextFieldValue);
        return value ?? null;
    }

    private getDefaultOptions(): CompanyOption[]|boolean {
        let company: Company = this.props.backingFieldValue;
        if (company) {
            return [this.getOption(company)];
        }
        return true;
    }

    private getFreeTextOption(freeText : string) : CompanyOption {
        if(freeText && freeText.length > 0)
            return {label: `Vabatekst: ${freeText}`, value: freeText, company: null, isFreeText: true}
        else
            return undefined;
    }

    private loadOptions = (search, options, { page }) => {
        return axios.get(`${this.props.baseURL}/companies`, {
            params: PagingUtils.toHttpParams(new PagingMetadata({
                ...this.state.paging,
                page,
                search
            }))
        }).then(res => {
            const paging: PagingMetadata = new PagingMetadata(res.data.metadata);

            let options = res.data.data.map(this.getOption);
            if(search && search.length > 0)
                options.unshift(this.getFreeTextOption(search));

            this.setState({ paging, options });
            return {
                options: options,
                hasMore: ((paging.page + 1) * paging.limit) < paging.count,
                additional: {
                    page: page + 1
                }
            }
        });
    };

    private onChange = (option: CompanyOption) => {
        if (this.props.onChange) {
            let keys : (keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters)[] = [this.props.fieldKey,this.props.backingFieldKey,this.props.freeTextFieldKey];
            let values : any[];
            if(!option) {
                values = [undefined, undefined];
                this.props.onChange(keys, values);
                return;
            }

            if(!option.isFreeText) {
                values = [option ? option.company[this.props.valueKey] : undefined,option ? option.company.regNo : undefined, undefined];
                this.props.onChange(keys, values);
            }
            else {
                values = [undefined, undefined, option.value];
                this.props.onChange(keys, values);
            }

        }
    };

    public render(): JSX.Element {
        return (
            <AsyncPaginate className="react-select"
                           menuPlacement={"auto"}
                           classNamePrefix="react-select"
                           isClearable={true}
                           placeholder="Vali"
                           menuPortalTarget={document.querySelector(this.props.portalTarget ? this.props.portalTarget : 'body')}
                           loadOptions={this.loadOptions}
                           additional={{
                               page: 0
                           }}
                           defaultOptions={this.getDefaultOptions()}
                           onChange={this.onChange}
                           value={this.getValue()}
            />
        );
    }
}
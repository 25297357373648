import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ThDefaultPricesModalData} from "../Modal";
import {DefaultPrice} from "../../Contracts/Contract";
import {NumberInput} from "../../Common/NumberInput";
import {
    CostItem,
    ThCostItemGroup
} from "../../Contracts/ContractDetails/ContractRow";
import {RootState} from "../../rootReducer";
import {getCostItems} from "../../Contracts/costItemReducer";
import {connect} from "react-redux";
import {getClassifiers} from "../../Classifier/classifierReducer";
import {Classifier, ClassifierCode, getClassifierValues} from "../../Classifier/Classifier";
import axios from "axios";
import {getBaseURL} from "../../Config/configReducer";

interface Props {
    baseURL: string;
    allCostItems: CostItem[];
    classifiers: Classifier[];

    modalOpen: boolean;
    modalData: ThDefaultPricesModalData;
    onToggleModal: () => void;
}

interface State {
    prices: DefaultPrice[],
    thCostItemGroups: ThCostItemGroup[]
}

class ThDefaultPricesModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            prices: [],
            thCostItemGroups: []
        }
    }

    componentWillMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps:Props) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            this.resetState();
        }
    }

    render() {
        return (
            <Modal centered={true} isOpen={this.props.modalOpen} toggle={this.props.onToggleModal}>
                <ModalHeader toggle={this.props.onToggleModal}>Muuda tehnohoolduslepingu tunnihindu</ModalHeader>
                <ModalBody className="p-4">
                    <>
                        <div className="d-flex flex-column">
                            {this.renderThPriceGroups()}
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary">
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        color="primary">
                        Salvesta tunnihinnad
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    private renderThPriceGroups() {
        return this.state.prices.filter(price => price.groupType != null).map(defaultPrice => (
            <React.Fragment key={defaultPrice.groupType}>
                <div className="form-row custom-form-row pt-2 pl-3 pr-2 pb-3" style={{backgroundColor: "#fff",boxShadow: "0 0 14px 0 #e7e7e7"}}>
                    <div className="d-flex flex-column justify-content-center col-8">
                        <label>{this.state.thCostItemGroups.find(thCostItemGroup => thCostItemGroup.code == defaultPrice.groupType)?.label}</label>
                        <span className="small text-gray">Kulukohad: {defaultPrice.costItems.flatMap(costItem => costItem.code).join(", ")}</span>
                    </div>
                    <div className="col-4 flex-column">
                        <label className="w-200 mb-2">Tunnihind:</label>
                        <NumberInput value={defaultPrice.price}
                                     onNumberChange={value => this.changeGroupPrice(defaultPrice,value)} />
                    </div>

                </div>
            </React.Fragment>
        ))
    }

    private cancel = () => {
        this.resetState();
        this.props.onToggleModal();
    };

    private onSave = () => {
        this.props.modalData.onSubmit(this.state.prices);
        this.resetState();
        this.props.onToggleModal();
    };

    private resetState() {
        let prices = this.props.modalData.contract.defaultPrices ? this.props.modalData.contract.defaultPrices : [new DefaultPrice()];
        this.setState({
            prices: prices
        });
        this.getParameters();
    }

    private changeGroupPrice(item: DefaultPrice, value: number) {
        const prices = this.state.prices;
        prices[prices.indexOf(item)].price = value;
        this.setState({prices: prices});
    }

    private getParameters() {
        const thGroups = getClassifierValues(this.props.classifiers,ClassifierCode.ThCostItemGroupType);
        let thItemGroups :ThCostItemGroup[] = Array(thGroups.length).fill(null);
        let promises = [];
        let thCostItems : string[] = [];

        thGroups.forEach((thGroup,index) => {
            promises.push(axios.get(this.props.baseURL + "/parameters/" + thGroup.code).then(res => {
                let itemGroup: ThCostItemGroup = new ThCostItemGroup();
                itemGroup.code = res.data.name;
                itemGroup.label = thGroup.name;
                itemGroup.costItems = [];
                res.data.value.split(",").forEach(costItemCode => {
                    const costItem = new CostItem({id: parseInt(costItemCode), code: costItemCode});
                    itemGroup.costItems.push(costItem);
                    thCostItems.push(costItem.code);
                })
                thItemGroups[index] = itemGroup;
            }));
        });

        Promise.all(promises).then(() => {
            let prices = this.state.prices;

            prices.forEach(price => {
                // legacy non-TH group, remove items
                if(thItemGroups.some(thItemGroup => thItemGroup.code == price.groupType) == false) {
                    // keep only those items that are not used for TH groups
                    price.costItems = price.costItems.filter(costItem => thCostItems.includes(costItem.code) == false);
                }
            })
            // add missing groups
            thItemGroups.filter(thItemGroup => prices.some(price => price.groupType == thItemGroup.code) == false).forEach(thItemGroup => {
                const defaultPrice = new DefaultPrice();
                defaultPrice.groupType = thItemGroup.code;
                defaultPrice.price = 0;
                defaultPrice.costItems = thItemGroup.costItems;

                prices.push(defaultPrice);
            });
            this.setState({thCostItemGroups : thItemGroups, prices: prices});
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    allCostItems: getCostItems(state),
    classifiers: getClassifiers(state)
});

export default connect(mapStateToProps, null)(ThDefaultPricesModal);
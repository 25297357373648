import React from "react";
import {Modal, ModalHeader, ModalBody, Button, ModalFooter} from "reactstrap";
import {ThiMultiplicationModalData} from "../Modal";
import {Property, ThiMultiplication} from "../../Contracts/Contract";
import axios from "axios";
import ClassifierField from "../../Contracts/ContractDetails/ContractInfo/Fields/ClassifierField";
import {ClassifierCode} from "../../Classifier/Classifier";
import DatePicker from "react-datepicker";
import {NumberInput} from "../../Common/NumberInput";
import {ServiceContractRow} from "../../Contracts/ContractDetails/ContractRow";
import {isNullOrUndefined} from "util";

interface Props {
    modalOpen: boolean;
    modalData: ThiMultiplicationModalData;
    onToggleModal: () => void;
}

interface State {
    selectedProperties: Property[];
    percentage: number;
    startDate: Date;
}

export default class ThiMultiplicationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedProperties: [],
            percentage: 0,
            startDate: new Date()
        }
    }

    componentWillMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps:Props) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            this.resetState();
        }
    }

    render() {
        const properties = this.props.modalData.contract.properties;
        return (
            <Modal isOpen={this.props.modalOpen} toggle={this.props.onToggleModal}>
                <ModalHeader toggle={this.props.onToggleModal}>Korruta THI'ga</ModalHeader>
                <ModalBody className="p-4">
                    <>
                        <div className="d-flex flex-column">
                            {this.renderPropertySelection()}
                        </div>
                        <div className="d-flex flex-column mt-4" style={{width:"max-content"}}>
                            {this.renderMetadata()}
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary">
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        disabled={!this.isValid()}
                        color="primary">
                        Korruta
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    private renderPropertySelection() {
        return this.props.modalData.contract.properties.map(
            item => (
                <React.Fragment key={item.id}>
                    <div className={"" + (!!this.state.selectedProperties.includes(item) && "selected")}
                         onClick={(e) => {e.preventDefault(); this.togglePropertySelection(item)}}
                         style={{width: "max-content", cursor: "pointer"}}>
                        <div className="selectable-baserow-number d-inline-block" style={{ minWidth: "25px", maxWidth: "25px"}}>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                       className="custom-control-input"
                                       id={"select-property-"+item.code}
                                       readOnly={true}
                                       checked={!!this.state.selectedProperties.includes(item)}
                                />
                                <label className="custom-control-label no-wrap" htmlFor={"select-property-"+item.code}/>
                            </div>

                        </div>
                        <div className="selectable-baserow-text d-inline-block">
                            <h6 className="pl-2">{`${item.name} (${item.code}), ${item.address}`}</h6>
                        </div>
                    </div>
                </React.Fragment>
            )
        )
    }

    private renderMetadata() {
        return (
            <>
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Protsent:</label>
                        <NumberInput value={this.state.percentage}
                                     onNumberChange={value => this.setState({percentage: value})} />
                    </div>
                </div>
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Kuupäev alates:</label>
                        <DatePicker
                            className="form-control calendar-input"
                            selected={this.state.startDate}
                            dateFormat="dd.MM.yyyy"
                            onChange={(value: Date) => this.setState({startDate: value })}
                        />
                    </div>
                </div>
            </>
        );
    }

    private cancel = () => {

        this.resetState();
        this.props.onToggleModal();
    };

    private onSave = () => {

        const data = new ThiMultiplication();
        data.percentage = this.state.percentage;
        data.startDate = this.state.startDate.valueOf();
        data.properties = this.state.selectedProperties;

        this.props.modalData.onSubmit(data);
        this.resetState();
        this.props.onToggleModal();
    };

    private togglePropertySelection(item: Property) {
        let selectedProperties = this.state.selectedProperties;
        if(selectedProperties.includes(item))
            selectedProperties = selectedProperties.filter(property => property !== item)
        else
            selectedProperties.push(item);

        this.setState({ selectedProperties: selectedProperties})
    }

    private resetState() {
        this.setState({
            selectedProperties: this.props.modalData.contract.properties,
            percentage: 0,
            startDate: new Date()
        });
    }

    private isValid() {
        return this.state.selectedProperties && this.state.selectedProperties.length
            && !isNullOrUndefined(this.state.percentage)
            && !isNullOrUndefined(this.state.startDate);
    }
}
import * as React from "react";
import { Route, RouteComponentProps } from "react-router";
import ConstructionContractList from "./ConstructionContractList";
import ContractDetails2 from "../ContractDetails/ContractDetails";

export class ConstructionContracts extends React.Component<RouteComponentProps> {

  render() {
    return (
        <>
          <Route path={this.props.match!.url} exact={true} component={ConstructionContractList} />
          <Route path={this.props.match!.url + "/:id"} exact={true} component={ContractDetails2} />
        </>
    );
  }
}
import { configActions } from "./configActions";
import { RootState } from "../rootReducer";
import { Config, ExceptionRowDefaults, TimeLimits } from "./Config";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export class ConfigState {
  readonly baseURL: string;
  readonly regularRowsDefaultValues: TimeLimits;
  readonly exceptionRowsDefaultValues: ExceptionRowDefaults[];
  readonly logo: string;
  readonly rkasCompanyRegNo: string;
  readonly tuumikUrl: string;
  readonly projectsUrl: string;
  readonly workorder: string;
  readonly propertyregistry: string;
  readonly userregistry: string;
  readonly yyripakkumised: string;
  readonly lepingud: string;
  readonly edhsEnabled: boolean;
  readonly sapEnabled: boolean;
}

export const configReducer = createReducer(new ConfigState(), {
    [configActions.setConfig.type]: (state, action: PayloadAction<Config>) => ({
        ...state,
        baseURL: action.payload.baseURL,
        regularRowsDefaultValues: action.payload.regularRowsDefaultValues,
        exceptionRowsDefaultValues: action.payload.exceptionRowsDefaultValues,
        logo: action.payload.logo,
        rkasCompanyRegNo: action.payload.rkasCompanyRegNo,
        tuumikUrl: action.payload.tuumikUrl,
        projectsUrl: action.payload.projectsUrl,
        edhsEnabled: action.payload.edhsEnabled,
        sapEnabled: action.payload.sapEnabled,
        workorder: action.payload.workorder,
        propertyregistry: action.payload.propertyregistry,
        userregistry: action.payload.userregistry,
        yyripakkumised: action.payload.yyripakkumised,
        lepingud: action.payload.lepingud
    })
});

export function getBaseURL(state: RootState): string {
  return state.config.baseURL || "http://fix-the-base-url-in-the-config";
}

export function getExceptionRowsDefaultValues(state: RootState): ExceptionRowDefaults[] {
  return state.config.exceptionRowsDefaultValues || [];
}

export function getRegularRowsDefaultValues(state: RootState): TimeLimits {
  return state.config.regularRowsDefaultValues || {} as TimeLimits;
}

export function getLogoPath(state: RootState): string {
    return state.config.logo || null;
}

export function getRkasCompanyRegNo(state: RootState): string {
  return state.config.rkasCompanyRegNo || "set-rkas-company-reg-no";
}

export function getTuumikUrl(state: RootState): string {
  return state.config.tuumikUrl || "set-tuumik-url";
}

export function getEdhsEnabled(state: RootState): boolean {
  return state.config.edhsEnabled;
}

export function getSapEnabled(state: RootState): boolean {
    return state.config.sapEnabled;
}

export function getProjectsUrl(state: RootState): string {
  return state.config.projectsUrl || "set-projectsUrl-url";
}

export function getLepingudUrl(state: RootState): string {
  return state.config.lepingud || "set-lepingud-url";
}

export function getUserRegistryUrl(state: RootState): string {
  return state.config.userregistry || "set-userregistry-url";
}

export function getWorksUrl(state: RootState): string {
  return state.config.workorder || "set-workorder-url";
}

export function getPropregUrl(state: RootState): string {
  return state.config.propertyregistry || "set-propertyregistry-url";
}

export function getYyripakkumisedUrl(state: RootState): string {
  return state.config.yyripakkumised || "set-yyripakkumised-url";
}


import React from "react";
import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import {Company, FullContract, Person, Property} from "../../../Contract";
import axios from "axios";
import Select from "react-select";
import {MainLease} from "../../../../MainLease/MainLease";

type PersonFieldKey<T extends FullContract | MainLease | Property> = { [C in keyof T]: T[C] extends Person ? C : never }[keyof T];

type PersonOption = { label: string, value: string, person: Person };

interface Props<T extends FullContract | MainLease | Property> extends ContractInfoFieldProps<T> {
    baseURL: string;
    fieldKey: PersonFieldKey<T>;
    isClearable?: boolean;
    company?: Company;
    companyRegNo?: string;
    roleCode?: string;
    placeholder?: string;
    onLinkClick?: (person: Person) => void;
}

interface State {
    options: PersonOption[];
}

export default class PersonField<T extends FullContract | MainLease | Property> extends ContractInfoField<T, Props<T>, State> {

    selectRef = null;
    _isMounted = false;

    constructor(props: Props<T>) {
        super(props);

        this.state = {
            options: this.getDefaultOptions()
        };
    }

    componentDidMount(): void {
        this._isMounted = true;
        this.loadOptions();
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }


    componentDidUpdate(prevProps: Readonly<Props<T>>): void {
        if (prevProps.company?.id !== this.props.company?.id) {
            this.loadOptions();

            if(this._isMounted && this.selectRef) {
                this.selectRef.select.clearValue();
            }

        }
    }

    private formatLabel = (person: Person) => `${person.forename} ${person.surname}`;

    private getOption = (person: Person): PersonOption => {
        if (person) {
            return {
                label: this.formatLabel(person),
                value: person.personalCode,
                person
            };
        }
        return undefined;
    };

    private getDefaultOptions(): PersonOption[] {
        let person: Person = this.getFieldValue();
        if (person) {
            return [this.getOption(person)];
        }
        return;
    }

    private loadOptions = () => {
        const {baseURL, company, companyRegNo} = this.props;

        if (company || companyRegNo && this._isMounted) {
            return axios
                .get(`${baseURL}/companies/${company ? company.regNo : companyRegNo}/representatives`, { params: { roleCode: this.props.roleCode } })
                .then(res => {
                    this.setState({
                        options: res.data.map(this.getOption)
                    });
                });
        } else {
            this.setState({
                options: []
            });
        }
    };

    private onChange = (option: PersonOption) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.fieldKey, option ? option.person : undefined);
        }
    };

    protected renderReadOnlyField(): JSX.Element {
        const person: Person = this.getFieldValue();

        return (
            <>
                {(this.props.onLinkClick != null && person != null) &&
                <>
                    <button id={"show-contact-info-" + person.personalCode}
                            className="btn btn-link p-0"
                            onClick={() => this.props.onLinkClick(person)}>
                        {person && this.formatLabel(person)}
                        {!person && "-"}
                    </button>
{/*                    <UncontrolledTooltip target={"show-contact-info-" + person.personalCode}   fade={false}>
                        Kuva kontaktandmed
                    </UncontrolledTooltip>*/}
                </>
                }
                {(!this.props.onLinkClick || !person) &&
                <span className="font-weight-bold">
                    {person && this.formatLabel(person)}
                    {(!person && !this.props.placeholder) && "-"}
                    {(!person && this.props.placeholder) && this.props.placeholder}
                </span>
                }
            </>
        );
    }

    protected renderEditableField(): JSX.Element {
        return (
            <Select ref={ref => {
                        this.selectRef = ref;
                    }}
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={this.props.isClearable}
                    placeholder={this.props.placeholder || "Vali"}
                    menuPortalTarget={document.querySelector('body')}
                    options={this.state.options}
                    value={this.getOption(this.getFieldValue())}
                    isDisabled={!this.props.editable}
                    onChange={this.onChange}
            />
        );
    }
}

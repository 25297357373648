import * as React from "react";
import {CustomModal} from "../Modal/CustomModal";
import store from "../store";
import moment from "moment";
import axios, {AxiosResponse} from "axios";

interface State {
    showModal: boolean,
    sessionExpirationTime: Date,
    minutesRemaining: number
}

export class SessionExpirationModal extends React.Component<{}, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            showModal: false,
            sessionExpirationTime: null,
            minutesRemaining: 999
        };
    };

    componentDidMount() {
        axios.interceptors.response.use((response) => {
                const expiration = SessionExpirationModal.getExpiration(response);
                if (expiration != null) {
                    this.setState({
                        sessionExpirationTime: expiration
                    });
                }
                return response;
            }
        );
        this.startCountDown();
    }

    render() {
        return (
            <CustomModal visible={this.state.showModal}
                         dismissable={false}
                         onDismiss={null}
                         header={this.state.minutesRemaining >= 0 ? "Sessioon aegub" : "Sessioon on aegunud"}
                         style={{zIndex: 10000}}>
                <div className="modal-body">
                    {
                        this.state.minutesRemaining >= 0
                            ? "Teie sessioon on aegumas ja teid logitakse välja. Kas soovite sessiooni jätkata?"
                            : "Teie sessioon on lõppenud, palun logige keskkonda uuesti sisse."
                    }

                </div>
                <div className="modal-footer">
                    {this.state.minutesRemaining >= 0 &&
                        <>
                            <button className="btn btn-outline-primary" type="button" onClick={this.logOut}>Logi välja</button>
                            <button className="btn btn-primary" type="button" onClick={this.extendSession}>
                                {"Jätka sessiooni"}
                            </button>
                        </>
                    }
                    {this.state.minutesRemaining < 0 &&
                    <>
                        <button className="btn btn-primary" type="button" onClick={this.logIn}>Logi sisse</button>
                    </>
                    }

                </div>
            </CustomModal>
        );
    }

    private logOut = () => {
        window.location.href = `${store.getState().config.baseURL}/logout/cas`;
    };

    private extendSession = () => {
        axios.get(`${store.getState().config.baseURL}/auth/refresh-session`, {})
            .then(() => this.setState({showModal: false}));
    };

    private logIn = () => {
        window.location.reload();
    };

    private startCountDown() {
        setInterval(this.timer, 10000);
    }

    timer = () => {
        if (this.state.sessionExpirationTime) {
            const diff = -moment().diff(this.state.sessionExpirationTime, 'minutes');
            this.setState({minutesRemaining: diff});
            if (diff <= 3) {
                this.setState({showModal: true});
            } else {
                if (this.state.showModal) {
                    this.setState({showModal: false});
                }
            }
        }
    };

    private static getExpiration(response: AxiosResponse): Date {
        if (!response?.headers || !response.headers['x-session-expires']) {
            return null;
        }

        const expirationString = response.headers['x-session-expires'];
        if (!expirationString) {
            return null;
        }

        const expirationMoment = moment(expirationString);
        if (!expirationMoment.isValid()) {
            return null;
        }

        return expirationMoment.toDate();
    }
}
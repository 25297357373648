import {modalActions} from "./modalActions";
import {createReducer, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../rootReducer";
import {ModalData} from "./Modal";

export class ModalState {
    open: boolean;
    data: ModalData;

    constructor(state?: ModalState) {
        if (state) {
            this.open = state.open;
        } else {
            this.open = false;
        }
    }
}

export const modalReducer = createReducer(new ModalState(), {
    [modalActions.toggleModal.type]: (state, action: PayloadAction<ModalData>) => {
        let newState = new ModalState();

        if(action.payload == state.data)
            newState.open = !state.open;
        else{
            newState.data = action.payload;
            newState.open = true;
        }

        return newState;
    }
});

export function getModalToggle(state: RootState): boolean {
    return state.modal.open;
}

export function getModalData(state: RootState): ModalData {
    return state.modal.data;
}
import { Project, Property } from "../Contract";

export enum RowType {
  Sum = "SUM",
  Monthly = "MONTHLY",
  Unit = "UNIT",
  Quantity = "QUANTITY",
  Lease = "LEASE",
  MonthlyReserve = "MONTHLY_RESERVE",
  Energy = "ENERGY",
  IndexableSum = "INDEXABLE_SUM"
}

export enum ColumnType {
  ServiceName = "SERVICENAME",
  Quantity = "QUANTITY",
  UnitName = "UNITNAME",
  UnitPrice = "UNITPRICE",
  ServiceStartDate = "SERVICESTARTDATE",
  RegularityNote = "REGULARITYNOTE",
  ResourceNote = "RESOURCENOTE",
  ServiceEndDate = "SERVICEENDDATE",
  SlaReactionTimeLimit = "SLAREACTIONTIMELIMIT",
  SlaCompletionTimeLimit = "SLACOMPLETIONTIMELIMIT",
  ProcessingNote = "PROCESSINGNOTE",
  RowPrice = "ROWPRICE"
}

export enum RowEditState {
  Normal,
  Deleted,
  JustAdded,
  JustDeleted,
  JustMoved,
  JustChanged
}

export enum RowValidityState {
  Valid,
  Invalid
}

export enum RowGroupState {
  Expired,
  Current,
  Future
}

export class CostItem {
  id: number;
  label: string;
  code: string;
  disabled: boolean;
  children: CostItem[];

  public constructor(init?:Partial<CostItem>) {
    Object.assign(this, init);
  }
}

export class ThCostItemGroup {
  code: string;
  label: string;
  costItems: CostItem[];

  public constructor(init?:Partial<ThCostItemGroup>) {
    Object.assign(this, init);
  }
}

export class ContractRowState{
  editState: RowEditState;
  groupState: RowGroupState;
  validityState: RowValidityState;
  message?: string;

  public constructor(init?:Partial<ContractRowState>) {
    Object.assign(this, init);
  }
}

export class ContractRowStateMap extends Map<number, ContractRowState> {
}

export class ContractRow {
  id?: number;
  tempId?: number;
  type: RowType;
  propertyId: number;
  costItem: CostItem;
  lastUpdatedOn?: number;
  lastUpdatedBy?: string;
  publishedOn?: number;
  publishedBy?: string;
  deletedOn?: number;
  deletedBy?: string;
  serviceName: string;
  invalid?: boolean;
}

export class EnergyContractRow extends ContractRow {
  incomeType: EnergyRowIncomeType;
  incomeSourceType: IncomeSource;
  startDate: number | null;
  endDate: number | null;

  static translateIncomeType(type: EnergyRowIncomeType): string {
    switch (type) {
      case EnergyRowIncomeType.SideService:
        return "Kõrvalteenus";
    }
  }

  static translateIncomeSource(type: IncomeSource): string {
    switch (type) {
      case IncomeSource.Budget:
        return "Eelarve";
      case IncomeSource.Factual:
        return "Tegelik";
    }
  }
}

export class ServiceContractRow extends ContractRow {
  unitPrice?: number;
  quantity?: number;
  unitName?: string | null;
  serviceStartDate: number | null;
  serviceEndDate: number | null;
  hasAlteredServiceDates?: boolean;
  rowPrice?: number;
  regularityNote?: string;
  resourceNote?: string;
  warrantyEndDate: number | null;

  slaReactionTimeLimit?: number;
  slaCompletionTimeLimit?: number;
  processingNote?: string;

  project?: Project;
}

export enum IncomeSource {
  Budget = "BUDGET",
  Factual = "FACTUAL"
}

export enum LeaseRowIncomeType {
  LeaseService = "LEASE_SERVICE",
  SideService = "SIDE_SERVICE"
}

export enum EnergyRowIncomeType {
  SideService = "SIDE_SERVICE"
}

export class LeaseContractRow extends ContractRow {
  incomeType: LeaseRowIncomeType;
  isSettleable: boolean;
  incomeSource: IncomeSource;
  changedIncomeBasedOnExpense: boolean;
  startDate: number | null;
  endDate: number | null;
  minVendorInvoices: number = 0;

  static translateIncomeType(type: LeaseRowIncomeType): string {
    switch (type) {
      case LeaseRowIncomeType.LeaseService:
        return "Üüriteenus";
      case LeaseRowIncomeType.SideService:
        return "Kõrvalteenus";
    }
  }

  static translateIncomeSource(type: IncomeSource): string {
    switch (type) {
      case IncomeSource.Budget:
        return "Eelarve";
      case IncomeSource.Factual:
        return "Tegelik";
    }
  }
}

export const translatedRowType = (rowType?: RowType): string => {
  if (rowType === RowType.Monthly) {
    return "Kuurida";
  } else if (rowType === RowType.Sum) {
    return "Summarida";
  } else if (rowType === RowType.Quantity) {
    return "Koguserida";
  } else if (rowType === RowType.Unit) {
    return "Ühikurida";
  }
  return "";
};

export class FocusedField {
  tableIndex: number;
  rowIndex: number;
  fieldKey: string;
}

export const fixStringIfItHasMoreThanFourDecimals = (numberAsString: string): string => {
  numberAsString = numberAsString.replace(",", ".");
  const splittedNumber = numberAsString.split(".");
  if (splittedNumber.length > 1 && splittedNumber[1].length > 4) {
    return splittedNumber[0] + "." + splittedNumber[1].substring(0, 4);
  } else {
    return numberAsString;
  }
};

export const isFieldDisabled = (rowType: RowType, fieldKey: string) => {
  return (
      (rowType === RowType.Monthly && fieldKey === "quantity")
      || (rowType === RowType.Monthly && fieldKey === "unitName")
      || (rowType === RowType.Monthly && fieldKey === "rowPrice")
      || (rowType === RowType.Quantity && fieldKey === "rowPrice")
      || (rowType === RowType.Sum && fieldKey === "quantity")
      || (rowType === RowType.Sum && fieldKey === "unitName")
      || (rowType === RowType.Sum && fieldKey === "unitPrice")
      || (rowType === RowType.Unit && fieldKey === "quantity")
      || (rowType === RowType.Unit && fieldKey === "rowPrice")
  );
};

export const fakeProperty = () => {
  const property = new Property();
  property.id = -1;
  property.name = "KINNISTU ON LISAMATA";
  property.code = "KINNISTU ON LISAMATA";
  return property;
};

export function itemOrChildCodeMatches(item: CostItem, code: string): boolean {

  return code === item.code
      || (item.children && item.children.some(child => itemOrChildCodeMatches(child, code)));
}

export type RowFieldKey = keyof ServiceContractRow | keyof LeaseContractRow;

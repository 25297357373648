import React from "react";
import {FullContract, Property} from "../../../Contract";
import {MainLease} from "../../../../MainLease/MainLease";

export interface ContractInfoFieldProps<T extends FullContract | MainLease | Property> {
  contract: T;
  fieldValue?: any;
  label: string;
  labelClass? : string;
  fieldKey: keyof T;
  editMode: boolean;
  editable?: boolean;
  required?: boolean;
  skipLabel?: boolean;
  skipFormRowClasses?: boolean;

  onChange?: (fieldKey: keyof T, value: any) => void;
}

export abstract class ContractInfoField<T extends FullContract | MainLease | Property,
      P extends ContractInfoFieldProps<T> = ContractInfoFieldProps<T>, S = {}>
    extends React.Component<P, S> {

  constructor(props: P) {
    super(props);
  }

  protected abstract renderEditableField(): JSX.Element;

  protected getFieldValue(): any {
    return this.props.contract ? this.props.contract[this.props.fieldKey] : this.props.fieldValue;
  }

  protected renderReadOnlyField(): JSX.Element {
    return (
        <span className="font-weight-bold">
          {this.getFieldValue() || "-"}
        </span>
    )
  }

  render() {
    return (
        <div className={(this.props.skipFormRowClasses ? "" : "form-row custom-form-row") + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
          <div className={this.props.skipFormRowClasses ? "" : "col-12 align-items-start"}>
            {!this.props.skipLabel &&
              <label className={this.props.labelClass ? this.props.labelClass : "w-200"}>
                {this.props.label}
                {this.props.editMode && this.props.required && <span className="input-required" />}
              </label>
            }
            {this.props.editMode && this.renderEditableField()}
            {!this.props.editMode && this.renderReadOnlyField()}
          </div>
        </div>
    )
  }
}

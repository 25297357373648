import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ConstructionContractEditWarrantyModalData} from "../Modal";
import {ServiceContractRow} from "../../Contracts/ContractDetails/ContractRow";
import {CostItemWarranty} from "../../Contracts/Contract";
import {connect} from "react-redux";
import {alertActions} from "../../Alerts/alertActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import {BodyPortal} from "../../Common/BodyPortal";


interface Props {
    modalOpen: boolean;
    modalData: ConstructionContractEditWarrantyModalData;
    onToggleModal: () => void;
    addAlert: typeof alertActions.addAlert;
}

interface State {
    costItemWarrantyEndDates: CostItemWarranty[];
}

class ConstructionContractEditWarrantyModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            costItemWarrantyEndDates : []
        }
    }


    componentWillMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            this.resetState();
        }
    }

    private cancel = () => {
        this.resetState();
        this.props.onToggleModal();
    };

    private resetState() {
        const contractWarrantyEndDate = this.props.modalData.contract.warrantyEndDate;
        const contractRows = this.props.modalData.contract?.rows as ServiceContractRow[];
        const costItemWarrantyEndDates = contractRows
            .filter((row) => row.deletedOn == null)
            .filter((row, i, arr) => arr.findIndex(t => t.costItem.id === row.costItem.id) === i)
            .map(t => new CostItemWarranty(this.props.modalData.contract.id, t.costItem, this.setWarrantyEndDate(contractWarrantyEndDate, t.warrantyEndDate)))
            .sort((a, b) => a.costItem.id > b.costItem.id ? 1 : -1);

        this.setState({
            costItemWarrantyEndDates: costItemWarrantyEndDates
        });
    }

    private onSave = () => {
        this.props.modalData.onSubmit(this.state.costItemWarrantyEndDates);
        this.resetState();
        this.props.onToggleModal();
    };

    render() {

        return (
            <Modal centered={true} isOpen={this.props.modalOpen} toggle={this.props.onToggleModal}>
                <ModalHeader toggle={this.props.onToggleModal}>Määra garantii lõppkuupäevad</ModalHeader>
                <ModalBody className="p-4" style={{maxHeight: '75vh', overflowY: 'auto'}}>
                    <>
                        <div className="d-flex flex-column h-100">
                            {this.renderRowCostItemGroups()}
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary">
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        color="primary">
                        Salvesta
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private renderRowCostItemGroups() {
        const contractWarrantyEndDate = this.props.modalData.contract.warrantyEndDate;
        return this.state.costItemWarrantyEndDates.map(
            costItemWithEndDate => (
                <React.Fragment key={costItemWithEndDate.costItem.id}>
                    <div className="form-row custom-form-row pt-2 pl-3 pr-2 pb-2" style={{backgroundColor: "#fff",boxShadow: "0 0 14px 0 #e7e7e7"}}>
                        <div className="d-flex flex-column justify-content-center col-8 h-100">
                            <span className="small text-gray pb-1">{costItemWithEndDate.costItem.label}</span>
                            <h5 className="font-weight-bold mb-0">{costItemWithEndDate.costItem.code}</h5>
                        </div>
                        <div className="col-4 flex-column">
                            <span className="small text-gray">Garantii lõpp</span>
                            <DatePicker
                                className="form-control calendar-input"
                                selected={costItemWithEndDate.warrantyEndDate}
                                placeholderText={ contractWarrantyEndDate ? moment(contractWarrantyEndDate).format("DD.MM.YYYY") : 'Vali kuupäev' }
                                dateFormat="dd.MM.yyyy"
                                popperClassName="react-datepicker-popper-modal"
                                popperContainer={BodyPortal}
                                onChange={(value: Date) => this.changeCostItemWarrantyEndDate(costItemWithEndDate, moment(value).format("YYYY-MM-DD"))}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        )
    }

    private changeCostItemWarrantyEndDate(item: CostItemWarranty, value: string) {
        const costItemWarrantyEndDates = this.state.costItemWarrantyEndDates;
        costItemWarrantyEndDates[costItemWarrantyEndDates.indexOf(item)].warrantyEndDate =  moment.utc(value, 'YYYY-MM-DD').toDate();
        this.setState({costItemWarrantyEndDates: costItemWarrantyEndDates});
    }

    private setWarrantyEndDate(contractWarrantyEndDate: number, warrantyEndDate: number) : Date {
        if(warrantyEndDate) {
            if(warrantyEndDate !== contractWarrantyEndDate)
                return new Date(warrantyEndDate);
            else
                return null;
        } else {
            return contractWarrantyEndDate ? new Date(contractWarrantyEndDate) : null;
        }
    }
}

const mapDispatchToProps = {
    addAlert: alertActions.addAlert
};

export default connect(null, mapDispatchToProps)(ConstructionContractEditWarrantyModal);

import React from "react";
import {FullSupportServiceContract} from "../../Contract";
import {connect} from "react-redux";
import {contractDetailsActions} from "../contractDetailsActions";
import SupportServiceContractTable from "./SupportServiceContractTable";

interface Props {
    contract: FullSupportServiceContract;
    infoEditMode: boolean;
    editingContract?: FullSupportServiceContract;
    updateEditingContract: (contract: FullSupportServiceContract, callback?: () => void) => void;
    onSaveContract: () => void;
}



export class SupportServiceContractDetails extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!this.props.infoEditMode) {
            return (
                <>
                    <SupportServiceContractTable  contract={this.props.contract}
                                                  editingContract={this.props.editingContract}
                    />
                </>
            );
        }
        return <></>;
    }
}

const mapDispatchToProps = {
    toggleAddRowModal: contractDetailsActions.toggleAddRowModal
};

export default connect(null,mapDispatchToProps)(SupportServiceContractDetails);
import React from "react";
import { ConstructionContract, ContractType, Project, Property } from "../Contract";
import { RootState } from "../../rootReducer";
import { getBaseURL } from "../../Config/configReducer";
import { connect } from "react-redux";
import axios from "axios";
import { Permission } from "../../Auth/User";
import { Alert, AlertContentType, AlertType } from '../../Alerts/Alert';
import { alertActions } from '../../Alerts/alertActions';
import { AbstractContractList } from "../AbstractContractList";
import { ColumnDescription } from "react-bootstrap-table-next";
import { NavLink } from "react-router-dom";
import {
  dateFormatter,
  dateTimeFormatter,
  decimalFormatter,
  percentFormatter
} from "../../BootstrapTable/customFormatters";

class ConstructionContractList extends AbstractContractList<ConstructionContract> {

  static readonly TYPE = ContractType.ConstructionContract;

  protected getType(): ContractType {
    return ConstructionContractList.TYPE;
  }

  protected getManagePermission(): Permission | Permission[] {
    return Permission.ManageConstructionContract;
  }

  protected getColumns(): ColumnDescription<ConstructionContract>[] {
    return [
      {
        dataField: "number",
        text: "Lepingu number",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/construction/" + row.id}>{cell}</NavLink>
      },
      {
        dataField: "generalDocumentTitle",
        text: "Pealkiri",
        sort: true
      },
      {
        dataField: "rhrReferenceNumber",
        text: "RHR number",
        sort: true
      },
      {
        dataField: "customer.name",
        text: "Tellija",
        sort: true
      },
      {
        dataField: "contractor.name",
        text: "Peatöövõtja",
        sort: true
      },
      {
        dataField: "authorizer.name",
        text: "Volitaja",
        sort: true
      },
      {
        dataField: "owner.name",
        text: "Omanik",
        sort: true
      },
      {
        dataField: "frameContractReference",
        text: "Raamleping",
        sort: true
      },
      {
        dataField: "procurementReference",
        text: "Hange",
        sort: true
      },
      {
        dataField: "enterTime",
        text: "Sõlmitud",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "publishedOn",
        text: "Avaldatud",
        sort: true,
        formatter: dateTimeFormatter
      },
      {
        dataField: "startTime",
        text: "Algus",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "endTime",
        text: "Lõpp",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "warrantyEndDate",
        text: "Garantii lõpp",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "sum",
        text: "Väärtus",
        formatter: (cell, row) => {
          let sum = (row.sum || 0) + (row.reserveValue || 0);
          if (sum === 0) {
            return null;
          }
          return decimalFormatter(sum);
        }
      },
      {
        dataField: "reservePercentage",
        text: "Lepingu reserv",
        sort: true,
        formatter: percentFormatter
      },
      {
        dataField: "properties",
        text: "Kinnistud",
        formatter: (value) => value && value.length ? value.map((p: Property) => p.code).join(", ") : null
      },
      {
        dataField: "projects",
        text: "Projektid",
        formatter: (value) => value && value.length ? value.map((p: Project) => p.name).join(", ") : null
      }
    ];
  }

  protected renderAddButton() {
    return (
        <button type="button"
                className="btn btn-primary ml-4"
                onClick={this.handleAddButton}
                disabled={this.state.addButtonLoading}>
          + Lisa ehitusleping
        </button>
    );
  }

  protected handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState({
      addButtonLoading: true
    }, () => {
      axios.post(this.props.baseURL + "/contracts/construction")
          .then(res => {
            this.props.history.push(this.props.match!.url + "/" + res.data.id, { action: "new" });
          })
          .catch(error => {
            let alertType = AlertContentType.ContractAddError;
            if (error.response) {
              const apiErrorType = Alert.fromException(error.response.data.error);
              if(apiErrorType)
                alertType = apiErrorType;
            }

            const alert: Alert = new Alert();
            alert.type = AlertType.Danger;
            alert.content = alertType;
            this.props.addAlert(alert);

            this.setState({
              addButtonLoading: false
            });
          });
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionContractList);
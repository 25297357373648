import React from "react";
import { FullServiceContract } from "../../Contract";
import ServiceContractTable from "./ServiceContractTable";

interface Props {
    contract: FullServiceContract;
    infoEditMode: boolean;
    editingContract?: FullServiceContract;
    updateEditingContract: (contract: FullServiceContract, callback?: () => void) => void;
    onSaveContract: () => void;
}


export default class ServiceContractDetails extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!this.props.infoEditMode) {
            return (
                <>
                    <ServiceContractTable contract={this.props.contract}
                                          editingContract={this.props.editingContract}
                                          updateEditingContract={this.props.updateEditingContract}
                                          onSaveContract={this.props.onSaveContract}
                    />
                </>
            );
        }
        return <></>;
    }
}
import React from "react";
import axios from "axios";
import {Person} from "../Contracts/Contract";
import {ContractFilters} from "../Contracts/ContractFilters";

import Select from "react-select";
import {MainLeaseFilters} from "../MainLease/MainLeaseList/MainLeaseFilters";
import {ReportFilters} from "../Reports/ReportFilters";

interface Props {
    baseURL: string;
    companyRegNo: string;
    fieldKey: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters;
    freeTextFieldKey: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters;
    isClearable?: boolean;
    portalTarget?: string;
    fieldValue: any;
    freeTextFieldValue: any;
    onChange?: (fieldKeys: (keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters)[], values: any[]) => void;
}

interface State {
    options: PersonOption[];
}

type PersonOption = { label: string, value: string, person: Person , isFreeText: boolean };

export default class PersonListFilter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            options: []
        };
    }

    componentDidMount(): void {
        axios.get(`${this.props.baseURL}/companies/${this.props.companyRegNo}/representatives`, ).then(res => {
            let options = res.data.map(person => {
                if (person) {
                    return {
                        label: `${person.forename} ${person.surname}`,
                        value: person.personalCode,
                        person,
                        isFreeText: false
                    };
                }
                return undefined;
            });
            this.setState({options});
        });
    }

    private findOption = (personalCode?: string): PersonOption => {
        if (personalCode) {
            const storedOption = this.state.options.find(option => option?.person?.personalCode == personalCode);
            if(storedOption) return storedOption;
        }
        return undefined;
    };

    private getValue() {
        const value = this.findOption(this.props.fieldValue) ?? this.getFreeTextOption(this.props.freeTextFieldValue);
        return value ?? null;
    }

    private getFreeTextOption(freeText : string) : PersonOption {
        if(freeText && freeText.length > 0)
            return {label: `Vabatekst: ${freeText}`, value: freeText, person: null, isFreeText: true}
        else
            return undefined;
    }

    private getDefaultOptions(): PersonOption[]|boolean {
        return this.state.options;
    }

    private onInputChange = (search: string) => {
        let options = this.state.options;
        options = options.filter(option => !option.isFreeText);
        if(search && search.length > 0) {
            options.unshift(this.getFreeTextOption(search));
        }
        this.setState({options});

        return search;
    };

    private onChange = (option: PersonOption) => {
        if (this.props.onChange) {
            let keys : (keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters)[] = [this.props.fieldKey, this.props.freeTextFieldKey];
            let values : any[];
            if(!option) {
                values = [undefined, undefined];
                this.props.onChange(keys, values);
                return;
            }

            if(!option.isFreeText){
                values = [option ? option.person.personalCode : undefined,undefined];
                this.props.onChange(keys, values);
            }
            else {
                values = [undefined, option.value];
                this.props.onChange(keys, values);
            }

        }
    };

    public render(): JSX.Element {
        return (
            <Select className="react-select"
                           menuPlacement={"auto"}
                           classNamePrefix="react-select"
                           isClearable={true}
                           placeholder="Vali"
                           menuPortalTarget={document.querySelector(this.props.portalTarget ? this.props.portalTarget : 'body')}
                           options={this.state.options}
                           defaultOptions={this.getDefaultOptions()}
                           onChange={this.onChange}
                           onInputChange={this.onInputChange}
                           value={this.getValue()}
            />
        );
    }
}
import * as React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import SupportServiceContractList from "./SupportServiceContractList";
import ContractDetails2 from "../ContractDetails/ContractDetails";

export class SupportServiceContracts extends React.Component<RouteComponentProps<{}>> {

  render() {
    return (
        <div>
          <Route path={this.props.match!.url} exact={true} component={SupportServiceContractList} />
          <Route path={this.props.match!.url + "/:id"} exact={true} component={ContractDetails2} />
        </div>
    );
  }
}
import * as React from "react";

import "./Header.css";
import {UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {RootState} from "../rootReducer";
import {getBaseURL, getLogoPath} from "../Config/configReducer";
import {connect} from "react-redux";
import {CustomModal} from "../Modal/CustomModal";
import {getCurrentUser} from "../Auth/authReducer";
import {User} from "../Auth/User";
import RepresentativeModal from "../Auth/RepresentativeModal";
import SideNav from "../SideNav/SideNav";
import { NavLink } from "react-router-dom";
import {RouteComponentProps, withRouter} from "react-router";

const profileIcon = require("../../assets/gfx/icons/person-24px-white.svg") as string;
const switchRolesIcon = require("../../assets/gfx/icons/swap_horiz-24px-white.svg") as string;
const logOutIcon = require("../../assets/gfx/icons/logout-24px-white.svg") as string;

interface State {
    dropDownOpen: boolean;
    sideNavOpen: boolean;
    representativeModalOpen: boolean;
}

interface Props extends RouteComponentProps {
    baseUrl: string;
    currentUser: User;
    logoPath: string;
    sideNavToggled: () => void;
}

class Header extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            representativeModalOpen: false,
            dropDownOpen: false,
            sideNavOpen: false
        };
    }

    pushRightClass = 'sidenav-open';

    public getLogo() {
        if (!this.props.logoPath) {
            return null;
        }
        return require('../../assets/gfx/' + this.props.logoPath);
    }

    isToggled(): boolean {
        const dom: Element|null = document.querySelector('body');
        return dom? dom.classList.contains(this.pushRightClass) : false;
    }

    private toggleSidebar = () => {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
        this.setState({sideNavOpen: !this.state.sideNavOpen});
    };

    private logOut = () =>  {
        window.location.href = this.props.baseUrl + "/logout/cas";
    };

    private toggleUserCompaniesModal = () => {
        this.setState({
            representativeModalOpen: !this.state.representativeModalOpen
        });
    };

    private renderOptions(): JSX.Element {
        return (
            <div>
                <DropdownItem toggle={true} tag="li" role="menuitem" style={{padding: "1px"}}>
                    <a className="dropdown-item" href="javascript:;">
                        <img className="users-img" src={profileIcon} width="auto" height="auto" alt="" />
                        Minu profiil
                    </a>
                </DropdownItem>
                <DropdownItem toggle={true} tag="li" role="menuitem" style={{padding: "1px"}}>
                    <a className="dropdown-item" onClick={() => this.toggleUserCompaniesModal()}>
                        <img className="users-img" src={switchRolesIcon} width="auto" height="auto" alt="" />
                        Vaheta rolli
                    </a>
                </DropdownItem>
                <DropdownItem toggle={true} tag="li" role="menuitem" style={{padding: "1px"}}>
                    <a href="javascript:;" className="dropdown-item" onClick={this.logOut}>
                        <img className="users-img" src={logOutIcon} width="auto" height="auto" alt="" />
                        Logi välja
                    </a>
                </DropdownItem>
            </div>
            );
    }

    render() {
        const user = this.props.currentUser;

        const currentPath = window.location.pathname;
        let activeSection = 'contracts';
        if(currentPath.includes('workspace'))
            activeSection = 'workspace';
        else if(currentPath.includes('reports'))
            activeSection = 'reports';

        return (
            <div>
                <SideNav sideNavOpen={this.state.sideNavOpen} toggleSidebar={this.toggleSidebar} />
                <nav className="header sticky">
                    <div className={this.isToggled() ? 'header-left md-move-right' : 'header-left'} >
                        <div className="header-menu-button" onClick={() => this.toggleSidebar()}>
                          <div className="hoverable-button" />
                          <div className="bar1" />
                          <div className="bar2" />
                          <div className="bar3" />
                        </div>
                        <NavLink className="header-logo" to="/">
                            <img src={this.getLogo()} width="auto" height="auto" alt="" />
                        </NavLink>
                    </div>

                    <div className={'header-middle'} >
                        <ul>
                            <li className={activeSection == 'workspace' ? "nav-item active" : "nav-item"}>
                                <NavLink className="nav-link" to='/workspace'>Töölaud</NavLink>
                            </li>
                            <li className={activeSection == 'contracts' ? "nav-item active" : "nav-item"}>
                                <NavLink className="nav-link" to='/service'>Lepingud</NavLink>
                            </li>
                            <li className={activeSection == 'reports' ? "nav-item active" : "nav-item"}>
                                <NavLink className="nav-link" to='/reports/service-contracts'>Aruandlus</NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className="header-right">
                        <UncontrolledDropdown className="btn-group header-dropdown d-none d-md-block">
                            <DropdownToggle className="btn dropdown-toggle btn-custom" caret>
                                <img className="users-img" src={profileIcon} width="auto" height="auto" alt="" />
                                <div className="d-inline" style={{ paddingRight: "48px"}}>
                                    {user && <span className="users-name">{user.userCompany ? user.userCompany.name : ""}</span>}
                                    {user && <span className="users-role">{user.forename} {user.surname}</span>}
                                </div>
                                <span className="caret">
                                    <span></span>
                                    <span></span>
                                </span>
                            </DropdownToggle>
                            <DropdownMenu tag="ul" className="dropdown-menu" style={{width: "295px"}}>
                                {this.renderOptions()}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </nav>
                <CustomModal visible={this.state.representativeModalOpen}
                             dismissable={true}
                             onDismiss={this.toggleUserCompaniesModal}
                             header={"Esindusõiguse valimine"}>
                    {this.state.representativeModalOpen &&
                    <RepresentativeModal onDismiss={this.toggleUserCompaniesModal} />
                    }
                </CustomModal>
            </div>
        );
      }
}

const mapStateToProps = (state: RootState) => ({
    baseUrl: getBaseURL(state),
    currentUser: getCurrentUser(state),
    logoPath: getLogoPath(state)
});

export default withRouter(connect(mapStateToProps)(Header));
import * as React from "react";
import "./WorkSpace.css";
import {RootState} from "../rootReducer";
import {connect} from "react-redux";
import ToolkitProvider, {ToolkitContextType} from "react-bootstrap-table2-toolkit";
import BootstrapTable, {
    ColumnDescription,
    SortOrder,
    TableChangeState,
    TableChangeType
} from "react-bootstrap-table-next";
import {Classifier, ClassifierCode, getClassifierValueName} from "../Classifier/Classifier";
import {dateFormatter, headerFormatter} from "../BootstrapTable/customFormatters";
import {getClassifiers} from "../Classifier/classifierReducer";
import axios from "axios";
import {PagingUtils} from "../Paging/PagingUtils";
import querystring from "querystring";
import {User} from "../Auth/User";
import {getBaseURL} from "../Config/configReducer";
import {getCurrentUser} from "../Auth/authReducer";
import {PagingMetadata} from "../Paging/Paging";
import {LeaseContract} from "../Contracts/Contract";
import {pageListRenderer, sizePerPageRenderer, sortCaretRenderer} from "../BootstrapTable/customRenderers";
import {NavLink} from "react-router-dom";
import paginationFactory, {PaginationProvider, SizePerPageDropdownStandalone} from "react-bootstrap-table2-paginator";
import PaginationListStandalone from "../BootstrapTable/PaginationListStandalone";


interface Props {
    classifiers: Classifier[],
    baseURL: string,
    currentUser: User
}

interface State {
    openedBlocks : string[],
    saleBeforeContractEndData : LeaseContract[],
    saleBeforeContractEndColumns : ColumnDescription<LeaseContract>[],
    saleBeforeContractEndPaging : PagingMetadata,
    saleBeforeContractEndQueryLoading: boolean;
}

class WorkSpace extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const saleColumns = this.getSaleBeforeContractEndColumns();
        saleColumns.forEach(column => {
            column.sortCaret = sortCaretRenderer;
            column.headerFormatter = headerFormatter;
        });

        this.state = {
            openedBlocks: ["firstBlock"],
            saleBeforeContractEndData: [],
            saleBeforeContractEndColumns: saleColumns,
            saleBeforeContractEndPaging: new PagingMetadata({
                page: 0,
                limit: 20,
                count: 0,
                sort: "endTime"
            }),
            saleBeforeContractEndQueryLoading: true,
        };
    }

    componentDidMount(): void {
        this.getSaleBeforeContractEndContracts();
    }

    private isBlockOpened(blockId: string) {
        return this.state.openedBlocks.includes(blockId);
    }

    private toggleBlock(blockId: string) {
        const index = this.state.openedBlocks.indexOf(blockId);
        const newState = this.state.openedBlocks;
        if (index > -1)
            newState.splice(index, 1);
        else
            newState.push(blockId);

        this.setState({
            openedBlocks: newState
        });
    }

    private getSaleBeforeContractEndColumns(): ColumnDescription[] {
        const columns = [
            {
                dataField: "properties[0].code",
                text: "Kinnistu kood",
                formatter: (cell, row, index, field) => {
                    if (row && row.properties.length) {
                        return row.properties[0].code;
                    }
                    return null;
                },
                sort: false
            },
            {
                dataField: "properties[0].regionType",
                text: "Piirkond",
                sort: false,
                formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.RegionType, cell),
            },
            {
                dataField: "number",
                text: "Üürilepingu kood",
                sort: false,
                formatter: (cell, row) => <NavLink to={"/lease/" + row.id}>{cell}</NavLink>
            },
            {
                dataField: "properties.saleDate",
                text: "Kinnistu müügi kp",
                formatter: (cell, row, index, field) => {
                    if (row && row.properties.length) {
                        return <span style={{fontSize: "14px"}} className={"badge badge-extended"}>{dateFormatter(row.properties[0].saleDate)}</span>;
                    }
                    return null;
                },
                sort: false
            },
            {
                dataField: "endTime",
                text: "Lepingu lõpu kp",
                formatter: dateFormatter,
                sort: false
            },
        ];

        return columns.map((column: ColumnDescription) => {
            column.headerFormatter = headerFormatter;
            return column;
        })
    }

    public getSaleBeforeContractEndContracts() {

        let paging: PagingMetadata = this.state.saleBeforeContractEndPaging;
        paging.params = {
            //... this.state.params as any,
            type: 'LEASE_AGREEMENT',
            isCurrent: true,
            endTimeLessThanSaleDate: true
        };

        this.setState({saleBeforeContractEndQueryLoading : true});
        axios.get(this.props.baseURL + "/contracts", {
            params: PagingUtils.toHttpParams(paging),
            paramsSerializer: querystring.stringify
        })
            .then(res => {
                this.setState({
                    saleBeforeContractEndPaging: res.data.metadata,
                    saleBeforeContractEndData: res.data.data,
                    saleBeforeContractEndQueryLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    saleBeforeContractEndQueryLoading: false
                });
            })
    }

    protected onSaleBeforeContractEndTableChange = (type: TableChangeType, state: TableChangeState<LeaseContract>) => {
        const paging = Object.assign(this.state.saleBeforeContractEndPaging, {
            page: (state.page || 1) - 1,
            limit: state.sizePerPage
        })

        this.setState({
            saleBeforeContractEndPaging: paging
        }, () => {
            this.getSaleBeforeContractEndContracts();
        });
    };

    render() {
        return (
            <div className="container-workspace p-3 p-sm-5">
                <div className="workspace-notification-area">
                    <div className="workspace-info-cards">
                        <a href="workspace#firstBlock" className="card bg-blue text-white">
                            <span className="d-flex align-items-center">
                                <span className="amount mr-2">{this.state.saleBeforeContractEndPaging.count}</span><span>Lepingut mille kinnistu müügikuupäev on varasem kui lepingu lõppkuupäev</span>
                            </span>
                        </a>
                    </div>
                </div>
                <div id="firstBlock" className="workspace-table">
                    <div className="block block-white width-100 block-collapsible mb-0">
                        <div className="d-flex flex-1 justify-content-between align-items-center">
                            <div className="d-flex align-items-center px-2 px-md-4 py-2 py-md-3 pointer"
                                 onClick={() => this.toggleBlock("firstBlock")}><span
                                className="amount bg-blue">{this.state.saleBeforeContractEndPaging.count}</span><h5 className="font-xs-14 text-blue">Lepingud mille kinnistu müügikuupäev on varasem kui lepingu lõppkuupäev</h5>
                                <button
                                    className={"w-auto btn btn-link block-title block-title-btn position-relative pl-1 pl-md-3 " +
                                    (this.isBlockOpened("firstBlock") ? "arrow-up" : "arrow-down")}>
                                    <span className="icon icon_arrow_dark icon-blue"></span></button>
                            </div>
                        </div>
                        <div className={"block-collapsible-content " + (this.isBlockOpened("firstBlock") && "open")}>
                            <div className="container-fluid">
                                <ToolkitProvider
                                    keyField="id"
                                    data={this.state.saleBeforeContractEndData}
                                    columns={this.state.saleBeforeContractEndColumns}
                                    columnToggle>
                                    {(context: ToolkitContextType) => (
                                        <>
                                            <div className="white-container mt-0">
                                                {this.renderSaleBeforeContractEndTable(context)}
                                            </div>
                                        </>
                                    )}
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSaleBeforeContractEndTable(context: ToolkitContextType) {
        return (
            <>
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        sizePerPageRenderer: sizePerPageRenderer,
                        pageListRenderer: pageListRenderer(this.state.saleBeforeContractEndPaging),
                        page: (this.state.saleBeforeContractEndPaging.page || 0) + 1,
                        sizePerPage: this.state.saleBeforeContractEndPaging.limit || 20,
                        totalSize: this.state.saleBeforeContractEndPaging.count || 0,
                        sizePerPageList: [20, 50, 100]
                    })}>
                    {({ paginationProps, paginationTableProps }) => (
                        <div className="datatable" style={{position: "relative"}}>
                            <div className="table-responsive" >
                                <BootstrapTable
                                    {...context.baseProps}
                                    {...paginationTableProps}
                                    remote={true}
                                    headerWrapperClasses="datatable-header"
                                    noDataIndication={this.state.saleBeforeContractEndQueryLoading ? "Päring käib, palun oota..." : "Kirjeid ei leitud"}
                                    onTableChange={this.onSaleBeforeContractEndTableChange}
                                    sort={{
                                        dataField: this.state.saleBeforeContractEndPaging.sort,
                                        order: (this.state.saleBeforeContractEndPaging.sortDirection ?
                                            this.state.saleBeforeContractEndPaging.sortDirection.toLowerCase() : undefined) as SortOrder
                                    }}
                                />
                            </div>
                            <div className="datatable-footer">
                                <div className="datatable-footer-inner">
                                    <div className="d-flex align-items-center" style={{padding: "5px 10px"}}>
                                        <SizePerPageDropdownStandalone {...paginationProps} />
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                </div>
                            </div>
                            {this.state.saleBeforeContractEndQueryLoading &&
                                <div style={{backgroundColor: "rgba(255,255,255,0.4)", position: "absolute", width: "100%", height: "100%", top: 0, left: 0, pointerEvents: "none"}}>
                                    <span style={{position: "fixed", top: "46.5%", left: "50%"}}><i className="details-spinner fa fa-spinner fa-spin fa-2x"/></span>
                                </div>
                            }
                        </div>
                    )}
                </PaginationProvider>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    currentUser: getCurrentUser(state)
});

export default connect(mapStateToProps)(WorkSpace);
import { isNullOrUndefined } from "util";

export class PagingMetadata {
  page: number = 0;
  limit: number = 20;
  sort?: string = "id";
  sortDirection?: "ASC"|"DESC" = "ASC";
  search?: string;
  params?: Object;
  count?: number;
  active?: number;
  future?: number;
  expired?: number;

  constructor(options?: { page: number, limit: number, sort?: string, sortDirection?: "ASC"|"DESC", search?: string,
    params?: Object, count?: number }) {

    if (options) {
      if (!isNullOrUndefined(options.page)) {
        this.page = options.page;
      }
      if (!isNullOrUndefined(options.limit)) {
        this.limit = options.limit;
      }
      if (!isNullOrUndefined(options.sort)) {
        this.sort = options.sort;
      }
      if (!isNullOrUndefined(options.sortDirection)) {
        this.sortDirection = options.sortDirection;
      }
      if (!isNullOrUndefined(options.search)) {
        this.search = options.search;
      }
      if (!isNullOrUndefined(options.params)) {
        this.params = options.params;
      }
      if (!isNullOrUndefined(options.count)) {
        this.count = options.count;
      }
    }
  }
}

export class PagedData<T> {
  metadata: PagingMetadata;
  data: T[];
}
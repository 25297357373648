import React from "react";
import { FullContract } from "../../../Contract";
import { ContractInfoField, ContractInfoFieldProps } from "./ContractInfoField";
import {
  Classifier,
  ClassifierCode, ClassifierValue,
  getClassifierValueName,
  getClassifierValues
} from "../../../../Classifier/Classifier";
import Select from "react-select";
import {MainLease} from "../../../../MainLease/MainLease";

interface Props<T extends FullContract | MainLease> extends ContractInfoFieldProps<T> {
  classifiers: Classifier[];
  classifierCode: ClassifierCode;
  excludedValueCodes?: string[];
  isClearable?: boolean;
  portalTarget?: string;
  customClass?: string;
}

export default class ClassifierField<T extends FullContract | MainLease> extends ContractInfoField<T, Props<T>> {

  private getOption(classifierValue: ClassifierValue) {
    return {
      label: classifierValue.name,
      value: classifierValue.code
    }
  }

  private getValue() {
    let value: string = this.getFieldValue();

    if (value) {
      let classifierValue: ClassifierValue = getClassifierValues(this.props.classifiers, this.props.classifierCode)
          .find(classifierValue => classifierValue.code === value);

      if (classifierValue) {
        return this.getOption(classifierValue);
      }
    }
    return undefined;
  }

  private getOptions(): any[] {
    let values : ClassifierValue[] = getClassifierValues(this.props.classifiers, this.props.classifierCode);
    if(this.props.excludedValueCodes) {
      values = values.filter(classifierValue => !this.props.excludedValueCodes.some(code => classifierValue.code == code))
    }
    return values.map(this.getOption);
  }

  private onChange = (option: any) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.fieldKey, option ? option.value : undefined);
    }
  };

  protected renderReadOnlyField(): JSX.Element {
    return (
        <span className="font-weight-bold">
          {getClassifierValueName(this.props.classifiers, this.props.classifierCode, this.getFieldValue()) || '-'}
        </span>
    );
  }

  protected renderEditableField(): JSX.Element {
    return (
        <Select className={"react-select " + this.props.customClass || ""}
                classNamePrefix="react-select"
                isClearable={this.props.isClearable}
                placeholder="Vali"
                menuPortalTarget={document.querySelector(this.props.portalTarget ? this.props.portalTarget : 'body')}
                options={this.getOptions()}
                value={this.getValue()}
                isDisabled={!this.props.editable}
                onChange={this.onChange}
        />
    );
  }
}

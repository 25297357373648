import React from "react";
import { FullConsumptionContract } from "../../Contract";
import ConsumptionEquipmentModal from "./ConsumptionEquipmentModal";
import ConsumptionEquipment from "./ConsumptionEquipment";

interface Props {
  contract: FullConsumptionContract;
  infoEditMode: boolean;
  editingContract?: FullConsumptionContract;
  updateEditingContract: (contract: FullConsumptionContract, callback?: () => void) => void;
  onSaveContract: () => void;
}

interface State {
  equipmentModalOpen: boolean;
}

export class ConsumptionContractDetails extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      equipmentModalOpen: false
    };
  }

  private toggleEquipmentModal = () => {
    this.setState({
      equipmentModalOpen: !this.state.equipmentModalOpen
    })
  };

  render() {
    if (!this.props.infoEditMode) {
      return (
          <>
            {!this.props.contract.publishedOn &&
            <ConsumptionEquipmentModal editingContract={this.props.editingContract}
                                       isOpen={this.state.equipmentModalOpen}
                                       toggle={this.toggleEquipmentModal}
                                       updateEditingContract={this.props.updateEditingContract}
                                       onSaveContract={this.props.onSaveContract}
            />
            }

            <ConsumptionEquipment contract={this.props.contract}
                                  editingContract={this.props.editingContract}
                                  toggleEquipmentModal={this.toggleEquipmentModal}
                                  updateEditingContract={this.props.updateEditingContract}
                                  onSaveContract={this.props.onSaveContract}
            />
          </>
      );
    }
    return <></>;
  }
}
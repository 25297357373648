import { Classifier } from "./Classifier";
import { RootState } from "../rootReducer";
import { classifierActions } from "./classifierActions";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export class ClassifierState {
  classifiers: Classifier[];

  constructor(state?: ClassifierState) {
    if (state) {
      this.classifiers = state.classifiers;
    } else {
      this.classifiers = [];
    }
  }
}

export const classifierReducer = createReducer(new ClassifierState(), {
    [classifierActions.addClassifiers.type]: (state, action: PayloadAction<Classifier[]>) => {
        let newState = new ClassifierState();
        newState.classifiers = action.payload;
        return newState;
    }
});

export function getClassifiers(state: RootState): Classifier[] {
  return state.classifier.classifiers || [];
}
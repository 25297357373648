import React from "react";
import { ContractType, FullEnergyContract } from "../Contract";
import { RootState } from "../../rootReducer";
import { getBaseURL } from "../../Config/configReducer";
import { connect } from "react-redux";
import axios from "axios";
import { Permission } from "../../Auth/User";
import { alertActions } from '../../Alerts/alertActions';
import { Alert, AlertContentType, AlertType } from '../../Alerts/Alert';
import { AbstractContractList } from "../AbstractContractList";
import { ColumnDescription } from "react-bootstrap-table-next";
import { dateFormatter, dateTimeFormatter } from "../../BootstrapTable/customFormatters";
import { NavLink } from "react-router-dom";

class EnergyContractList extends AbstractContractList<FullEnergyContract> {

  static readonly TYPE = ContractType.EnergySaleAgreement;

  protected getType(): ContractType {
    return EnergyContractList.TYPE;
  }

  protected getManagePermission(): Permission | Permission[] {
    return Permission.ManageEnergySaleAgreement;
  }

  protected getColumns(): ColumnDescription<FullEnergyContract>[] {
    return [
      {
        dataField: "number",
        text: "Lepingu number",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/energy/" + row.id}>{cell}</NavLink>
      },
      {
        dataField: "generalDocumentTitle",
        text: "Pealkiri",
        sort: true
      },
      {
        dataField: "leaser.name",
        text: "Üürnik",
        sort: true
      },
      {
        dataField: "leaser.regNo",
        text: "Üürniku reg. number",
        sort: true
      },
      {
        dataField: "rentalCode",
        text: "Üürilepingu kood",
        sort: true
      },
      {
        dataField: "mainLease",
        text: "Peaüür",
        sort: true
      },
      {
        dataField: "properties.code",
        text: "Kinnistu kood",
        formatter: (cell, row, index, field) => {
          if (row && row.properties.length) {
            return row.properties[0].code;
          }
          return null;
        }
      },
      {
        dataField: "properties.name",
        text: "Kinnistu nimi",
        formatter: (cell, row, index, field) => {
          if (row && row.properties.length) {
            return row.properties[0].name;
          }
          return null;
        }
      },
      {
        dataField: "properties.address",
        text: "Kinnistu aadress",
        formatter: (value, row, index, field) => {
          if (row && row.properties.length) {
            return row.properties[0].address;
          }
          return null;
        }
      },
      {
        dataField: "eic",
        text: "EIC",
        sort: true,
        formatter: (value, row, index, field) => {
          if (row && row.eic) {
            return <>{row.eic.map(eic => <p style={{whiteSpace: "nowrap"}} key={eic}>{eic}</p>)}</>
          }
          return null;
        }
      },
      {
        dataField: "startTime",
        text: "Algus",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "endTime",
        text: "Lõpp",
        sort: true,
        formatter: (value) => {
          if (value) {
            if(value == new Date(253370757600000).valueOf())
              return "Tähtajatu";
            else return dateFormatter(value);
          }
        }
      },
      {
        dataField: "publishedOn",
        text: "Avaldamise kuupäev",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "comment",
        text: "Kommentaar",
        sort: true
      }
    ];
  }
  
  protected renderAddButton() {
    return (
        <button type="button"
                className="btn btn-primary ml-4"
                onClick={this.handleAddButton}
                disabled={this.state.addButtonLoading}>
          + Lisa energiavahendusleping
        </button>
    );
  }

  protected handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState({
      addButtonLoading: true
    }, () => {
      axios.post(this.props.baseURL + "/contracts/energy")
          .then(res => {
            this.props.history.push(this.props.match!.url + "/" + res.data.id, { action: "new" });
          })
          .catch(error => {
            let alertType = AlertContentType.ContractAddError;
            if (error.response) {
              const apiErrorType = Alert.fromException(error.response.data.error);
              if(apiErrorType)
                alertType = apiErrorType;
            }

            const alert: Alert = new Alert();
            alert.type = AlertType.Danger;
            alert.content = alertType;
            this.props.addAlert(alert);

            this.setState({
              addButtonLoading: false
            });
          });
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(EnergyContractList);
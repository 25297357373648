import React from "react";
import { RouteComponentProps } from "react-router";
import { Classifier } from "../../Classifier/Classifier";
import { alertActions } from "../../Alerts/alertActions";
import { PagingMetadata } from "../../Paging/Paging";
import * as qs from "qs";
import ListFilters from "../../ListFilters/ListFilters";
import { Permission } from "../../Auth/User";
import Secured from "../../Auth/Secured";
import { PagingUtils } from "../../Paging/PagingUtils";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import axios from "axios";
import BootstrapTable, {
  ColumnDescription, SortOrder,
  TableChangeState,
  TableChangeType
} from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import PaginationListStandalone from "../../BootstrapTable/PaginationListStandalone";
import {
  pageListRenderer,
  sizePerPageRenderer,
  sortCaretRenderer
} from "../../BootstrapTable/customRenderers";
import { headerFormatter, personNameFormatter } from "../../BootstrapTable/customFormatters";
import ToolkitProvider, { ToolkitContextType } from "react-bootstrap-table2-toolkit";
import { MainLease } from "../MainLease";
import { MainLeaseFilters } from "./MainLeaseFilters";
import { RootState } from "../../rootReducer";
import { getBaseURL } from "../../Config/configReducer";
import { getClassifiers } from "../../Classifier/classifierReducer";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

interface Props extends RouteComponentProps<{}> {
  baseURL: string;
  classifiers: Classifier[];
  addAlert: typeof alertActions.addAlert;
  displayNavHeaderCallback: (val: boolean) => void;
}

interface State {
  storedPagingLoaded?: boolean;
  storedFiltersLoaded?: boolean;
  storedHiddenColumnsLoaded?: boolean;
  filters: MainLeaseFilters;
  params: any;
  paging: PagingMetadata;
  columns: ColumnDescription[];
  data: MainLease[];
  filtersToggled: boolean;
  searchTimeout?: number;
  addButtonLoading: boolean;
  editingMainLease?: MainLease;
  searchInput: string;
}

class MainLeaseList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    let params: any = {};
    if (props.location.search) {
      params = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    }

    const columns = this.getColumns();
    columns.forEach(column => {
      column.sortCaret = sortCaretRenderer;
      column.headerFormatter = headerFormatter;
    });

    this.state = {
      filters: {} as MainLeaseFilters,
      params: params,
      paging: new PagingMetadata({
        page: 0,
        limit: 20,
        count: 0,
        sort: "code"
      }),
      columns: columns,
      data: [],
      filtersToggled: false,
      addButtonLoading: false,
      searchInput: ""
    };
  }

  componentDidMount(): void {
    this.loadPaging();
    this.loadFilters();
    this.loadHiddenColumns();
    this.getMainLeases();
  }

  private getColumns(): ColumnDescription<MainLease>[] {
    return [
      {
        text: "Peaüür",
        dataField: "code",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/main-leases/" + row.id}>{cell}</NavLink>
      },
      {
        text: "Lepingu number",
        dataField: "contractNumber",
        sort: true
      },
      {
        text: "Ettevõte",
        dataField: "company.name",
        sort: true
      },
      {
        dataField: "manager",
        text: "Haldur",
        sort: true,
        formatter: personNameFormatter
      },
      {
        dataField: "manager.phoneNumber",
        text: "Halduri telefon",
        sort: true
      },
      {
        dataField: "manager.email",
        text: "Halduri e-mail",
        sort: true
      }
    ];
  };

  private handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.props.history.push(this.props.match!.url + "/new" , { action: "new" });
  };

  private renderAddButton() {
    return (
        <button type="button"
                className="btn btn-primary ml-4"
                onClick={this.handleAddButton}
                disabled={this.state.addButtonLoading}>
          + Lisa peaüür
        </button>
    );
  }

  private closeModal = () => {
    this.setState({
      editingMainLease: undefined
    });
  };

  private getPagingLocalStorageKey = () => {
    return "main_lease_list" + ".paging";
  };

  private getFiltersLocalStorageKey = () => {
    return "main_lease_list" + ".filters";
  };

  private getHiddenColumnsLocalStorageKey = () => {
    return "main_lease_list" + ".hiddenColumns";
  };

  private toggleFilters = () => {
    const dom: any = document.querySelector('body');
    dom.classList.toggle("filters-open");

    this.setState({
      filtersToggled: !this.state.filtersToggled
    });
  };

  private onPagingChange = (paging: PagingMetadata) => {
    const { page, limit, sort, sortDirection, search} = paging;

    localStorage.setItem(this.getPagingLocalStorageKey(), JSON.stringify({
      page,
      limit,
      sort,
      sortDirection,
      search
    }));

    this.setState({
      paging: paging
    }, () => {
      this.getMainLeases();
    });
  };

  protected onFiltersChange = (filters: MainLeaseFilters) => {
    localStorage.setItem(this.getFiltersLocalStorageKey(), JSON.stringify(filters));

    const params = {...this.state.params as any};

    // remove filters that are no longer applied
    Object.keys(this.state.filters).forEach(key => {
      const filterKey = key as keyof MainLeaseFilters;
      if (filters[filterKey] == undefined || filters[filterKey] === '') {
        delete params[filterKey];
      }
    });

    // set currently applied filters
    Object.keys(filters).forEach(key => {
      const filterKey = key as keyof MainLeaseFilters;
      if (filters[filterKey] != undefined && filters[filterKey] !== '') {
        params[filterKey] = filters[filterKey];
      }
    });

    this.setState({
      filters: filters,
      params: params
    }, () => {
      this.getMainLeases();
    });
  };

  private toggleColumn = (column: ColumnDescription) => () => {
    this.setState({
      columns: this.state.columns.map(col => {
        if (col.dataField == column.dataField) {
          col.hidden = !col.hidden;
        }
        return col;
      })
    }, () => {
      const hiddenColumns = this.state.columns.filter(col => col.hidden).map(col => col.dataField);
      localStorage.setItem(this.getHiddenColumnsLocalStorageKey(), JSON.stringify(hiddenColumns));
    });
  };

  private onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout!);
    }

    let timeout = window.setTimeout(() => {
      this.onPagingChange(Object.assign(this.state.paging, {
        search,
        page: 0
      }));
    }, 500);

    this.setState({
      searchTimeout: timeout,
      searchInput: search
    });
  };

  private getMainLeases() {
    const { paging } = this.state;
    paging.params = this.state.params;

    axios.get(this.props.baseURL + "/main-leases", {
           params: PagingUtils.toHttpParams(paging)
         })
         .then(res => {
           this.setState({
             paging: res.data.metadata,
             data: res.data.data
           });
         })
         .catch(error => {
           //params.error(error); todo
         })
  }

  protected renderToolbar(context: ToolkitContextType) {
    return (
        <div className="title-container justify-content-between d-none d-md-flex">
          {!Object.values(this.state.filters as any).length &&
          <div className="works-link align-items-center" onClick={this.toggleFilters}>
            <button className="btn btn-link works-link-label text-primary d-flex p-0"
                    style={{textDecoration: "none"}}>
              <span className="icon icon_filter mr-1"/> Filtreeri
            </button>
          </div>
          }
          {!!Object.values(this.state.filters as any).length &&
          <div className="works-link selected" onClick={this.toggleFilters}>
            <h6 className="works-link-label d-flex align-items-center">
              Filtreeritud <i className="fas fa-pencil-alt" />
            </h6>
            <h4 className="works-filtered-number text-primary">
              {this.state.paging ? this.state.paging.count || 0 : 0}
            </h4>
          </div>
          }

          <div className="d-flex" style={{flex: "1"}}>
            <input type="text"
                   className="form-control"
                   placeholder="Otsi koodi, numbri, ettevõtte järgi"
                   value={this.state.searchInput || ""}
                   onChange={this.onSearch}
            />
          </div>

          <div className="d-flex align-items-center">
            <Secured permission={Permission.ManageMainLease}>
              {this.renderAddButton()}
            </Secured>

            <UncontrolledDropdown className="submenu ml-3" style={{right: 0, position: "relative"}}>
              <div className="submenu-hover" />
              <DropdownToggle color="link" className="btn d-flex">
                <span className="icon icon_settings d-flex" />
              </DropdownToggle>
              <DropdownMenu tag="ul" className="dropdown-menu dropdown-menu-right" style={{marginRight: "20px"}}>
                {this.state.columns.map((column, index, columns) => (
                    <React.Fragment key={"column-toggle-" + index}>
                      <li role="menuitem">
                        <DropdownItem tag="div" className="custom-control custom-checkbox"
                                      style={{paddingLeft: "3rem", paddingBottom: "0.5rem", paddingTop: "0.5rem"}}
                                      toggle={false}
                        >
                          <input type="checkbox"
                                 className="custom-control-input"
                                 id={"toggle-column-" + index}
                                 checked={!column.hidden}
                                 onChange={this.toggleColumn(column)}
                          />
                          <label className="custom-control-label" htmlFor={"toggle-column-" + index}>
                            {column.text}
                          </label>
                        </DropdownItem>
                      </li>
                      {index < (columns.length -1) &&
                      <DropdownItem divider style={{ margin: 0 }} />
                      }
                    </React.Fragment>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
    );
  }

  protected onTableChange = (type: TableChangeType, state: TableChangeState<MainLease>) => {
    this.onPagingChange(Object.assign(this.state.paging, {
      page: (state.page || 1) - 1,
      limit: state.sizePerPage,
      sort: state.sortField,
      sortDirection: (state.sortOrder || "asc").toUpperCase()
    }));
  };

  protected renderTable(context: ToolkitContextType) {
    if (!(this.state.storedFiltersLoaded && this.state.storedPagingLoaded && this.state.storedHiddenColumnsLoaded)) {
      return <></>
    }

    return (
        <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              sizePerPageRenderer: sizePerPageRenderer,
              pageListRenderer: pageListRenderer(this.state.paging),
              page: (this.state.paging.page || 0) + 1,
              sizePerPage: this.state.paging.limit || 20,
              totalSize: this.state.paging.count || 0,
              sizePerPageList: [20, 50, 100]
            })}>
          {({ paginationProps, paginationTableProps }) => (
            <div className="datatable">
              <div className="table-responsive">
                <BootstrapTable
                    {...context.baseProps}
                    {...paginationTableProps}
                    remote={true}
                    headerWrapperClasses="datatable-header"
                    onTableChange={this.onTableChange}
                    noDataIndication="Kirjeid ei leitud"
                    sort={{
                      dataField: this.state.paging.sort,
                      order: (this.state.paging.sortDirection ?
                          this.state.paging.sortDirection.toLowerCase() : undefined) as SortOrder
                    }}
                />
              </div>
              <div className="datatable-footer">
                <div className="datatable-footer-inner">
                  <div className="d-flex align-items-center" style={{padding: "5px 10px"}}>
                    <SizePerPageDropdownStandalone {...paginationProps} />
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>
    );
  }

  render() {
    return (
        <>
          <ListFilters type="mainLease"
                       filters={this.state.filters}
                       show={this.state.filtersToggled}
                       toggleFilters={this.toggleFilters}
                       onFiltersChange={this.onFiltersChange}
          />
          <div className="filters-background" />
          <div className="container-fluid">
            <ToolkitProvider
                keyField="id"
                data={this.state.data}
                columns={this.state.columns}
                columnToggle
            >
              {(context: ToolkitContextType) => (
                <>
                  {this.renderToolbar(context)}

                  <div className="white-container mt-0">
                    {this.renderTable(context)}
                  </div>
                </>
              )}
            </ToolkitProvider>
          </div>
        </>
    );
  }

  private loadPaging(): void {
    const localStorageKey = this.getPagingLocalStorageKey();
    let paging: PagingMetadata = this.state.paging;

    if (localStorage.getItem(localStorageKey)) {
      let pagingString: string = localStorage.getItem(localStorageKey);
      let pagingJson: any = JSON.parse(pagingString) as PagingMetadata;

      paging = Object.assign(paging, pagingJson);
    }

    this.setState({
      paging,
      storedPagingLoaded: true,
      searchInput: paging.search
    });
  }

  private loadFilters(): void {
    const localStorageKey = this.getFiltersLocalStorageKey();
    if (localStorage.getItem(localStorageKey)) {
      let filterString: string = localStorage.getItem(localStorageKey);
      let filters: MainLeaseFilters = JSON.parse(filterString) as MainLeaseFilters;

      this.onFiltersChange(filters);
    }

    this.setState({
      storedFiltersLoaded: true
    });
  }

  private loadHiddenColumns(): void {
    const localStorageKey = this.getHiddenColumnsLocalStorageKey();
    let { columns } = this.state;

    if (localStorage.getItem(localStorageKey)) {
      let columnsString: string = localStorage.getItem(localStorageKey);
      let hiddenColumns: string[] = JSON.parse(columnsString) as string[];

      columns.forEach(column => {
        column.hidden = hiddenColumns.some(hiddenField => hiddenField === column.dataField);
      });
    }

    this.setState({
      columns,
      storedHiddenColumnsLoaded: true
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLeaseList);
import * as React from "react";
import {CSSProperties} from "react";

export interface Props {
    visible: boolean;
    dismissable: boolean;
    onDismiss: (event?: React.MouseEvent) => void;
    header: string;
    style?: CSSProperties;
}

export abstract class CustomModal extends React.Component<Props, {}> {

    render() {
        const { visible, dismissable, onDismiss, header, children, style } = this.props;
        return (
            <>
                {visible && (
                    <div className="custom-modal" style={this.props.style}>
                        <div className="modal-container">
                            <div className="modal-header">
                                <h4 id="dialog-sizes-name1" className="modal-title pull-left">{header}</h4>
                                {dismissable &&
                                <button type="button"
                                        className="close pull-right"
                                        onClick={onDismiss}
                                        aria-label="Close">
                                    <div className="submenu-hover" />
                                    <i className="icon icon_close" />
                                </button>
                                }
                            </div>
                            {children}
                        </div>
                        <div className="modal-bg" onClick={dismissable ? onDismiss : void(0)} />
                    </div>
                )}
            </>
        );
    }
}
import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import { FullContract } from "../../../Contract";
import React, { ChangeEvent } from "react";
import {MainLease} from "../../../../MainLease/MainLease";

interface Props<T extends FullContract | MainLease> extends ContractInfoFieldProps<T> {
  placeholder?: string;
  maxLength?: number;
  pattern?: string;
}

export default class TextField<T extends FullContract | MainLease> extends ContractInfoField<T, Props<T>> {

  private onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.fieldKey, event.target.value);
    }
  };

  protected renderEditableField(): JSX.Element {
    return (
        <input className="form-control"
               placeholder={this.props.placeholder}
               defaultValue={this.getFieldValue()}
               onChange={this.onChange}
               maxLength={this.props.maxLength ?? 524288}
               disabled={!this.props.editable}
        />
    );
  }
}
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface Props {
  isOpen: boolean;
  canConfirm: boolean;
  confirmButtonColor?: string;
  confirmButtonContent?: string|JSX.Element;
  onCancel: () => void;
  onConfirm: () => void;
}

export default class ConfirmationModal extends React.Component<Props, any> {

  render() {
    return (
        <Modal isOpen={this.props.isOpen}>
          <ModalBody className="text-center pb-5">
            {this.props.children}

            <div className="d-flex align-items-center justify-content-center pt-3">
                <Button onClick={this.props.onCancel}
                        color="outline-dark"
                        className="mr-4"
                >
                  Katkesta
                </Button>
                <Button
                    disabled={!this.props.canConfirm}
                    onClick={this.props.onConfirm}
                    color={this.props.confirmButtonColor || "primary"}
                >
                  {this.props.confirmButtonContent || "Kinnita"}
                </Button>
            </div>
          </ModalBody>
        </Modal>
    )
  }
}
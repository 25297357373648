import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {contractDetailsActions} from "./contractDetailsActions";
import {RootState} from "../../rootReducer";

export class ContractDetailsState {
    addRowModalToggled: boolean;

    constructor(state?: ContractDetailsState) {
        if (state) {
            this.addRowModalToggled = state.addRowModalToggled;
        } else {
            this.addRowModalToggled = false;
        }
    }
}

export const contractDetailsReducer = createReducer(new ContractDetailsState(), {
    [contractDetailsActions.toggleAddRowModal.type]: (state, action: PayloadAction<boolean>) => {
        let newState = new ContractDetailsState();
        newState.addRowModalToggled = action.payload;
        return newState;
    }
});

export function getAddRowModalToggle(state: RootState): boolean {
    return state.contractDetails.addRowModalToggled
}
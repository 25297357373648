import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import { FullContract } from "../../../Contract";
import React, { ChangeEvent } from "react";
import {MainLease} from "../../../../MainLease/MainLease";

interface Props<T extends FullContract | MainLease> extends ContractInfoFieldProps<T> {
  placeholder?: string;
  maxLength?: number;
  replacementPattern?: RegExp;
  validationPattern?: RegExp;
}

export default class PatternedTextField<T extends FullContract | MainLease> extends ContractInfoField<T, Props<T>> {

  private onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if(this.props.replacementPattern) {
      value = value.replace(this.props.replacementPattern, "");
    }

    if(this.props.validationPattern) {
      if(!this.props.validationPattern.test(value)) {
         value = this.getFieldValue();
       }
    }

    // '^\\s*(?:[\\w\\.]\\s*){0,15}$'
    // const regex = new RegExp(this.props.pattern);
    // if(!regex.test(value)) {
    //   value = this.getFieldValue();
    // }
    //value = value.replace(new RegExp("/\\s/g"), "");

    if (this.props.onChange) {
      this.props.onChange(this.props.fieldKey, value);
    }
  };

  protected renderEditableField(): JSX.Element {
    return (
        <input className="form-control"
               placeholder={this.props.placeholder}
               value={this.getFieldValue()}
               maxLength={this.props.maxLength}
               onChange={this.onChange}
               disabled={!this.props.editable}
        />
    );
  }
}
import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { PagingMetadata } from "../Paging/Paging";

type PageListRendererOptions = {
  pages: {
    active: boolean;
    disabled: boolean;
    page: string|number;
    title: string
  }[];
  onPageChange: (page: number, sizePerPage?: number) => void;
}

export function sortCaretRenderer(order: "asc"|"desc", column: any) {
  const orderClass = order ? (order === "asc" ? "sort-asc datatable-icon-up" : "sort-desc datatable-icon-down") : "";
  return <span className={"sort-btn " + orderClass} />;
}

export function sizePerPageRenderer(rendererOptions: any) {
  const { options, currSizePerPage, onSizePerPageChange } = rendererOptions;
  return (
      <UncontrolledDropdown
          group
          direction="up"
          className="pr-4">
        <DropdownToggle caret color="link" className="pl-0">
          Kuva {currSizePerPage}
        </DropdownToggle>
        <DropdownMenu tag="ul" positionFixed={true}>
          {options.map((option, index, array) => (
              <React.Fragment key={"sizePerPage-" + option.page}>
                <li>
                  <DropdownItem tag="a" onClick={() => onSizePerPageChange(option.page)}>
                    {option.text}
                  </DropdownItem>
                </li>
                {index !== (array.length - 1) && <DropdownItem tag="li" divider />}
              </React.Fragment>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
  );
}

export function pageListRenderer(paging: PagingMetadata) {
  return ({pages, onPageChange}: PageListRendererOptions) => {
    pages = pages.filter(page => !isNaN(page.page as number));

    if (pages.length > 1) {
      const activePage = pages.find(page => page.active) as {page: number};
      const maxPage = paging.count && paging.limit
          ? Math.ceil(paging.count / paging.limit)
          : Math.max(...pages.map(page => page.page as number));
      const isFirstPage = activePage && activePage.page === 1;
      const isLastPage = activePage && activePage.page === maxPage;

      return (
          <div className="datatable-pager">
            <ul className="pager">
              <li className={isFirstPage ? "disabled" : ""}
                  onClick={() => !isFirstPage && onPageChange(1)}
              >
                <a href="javascript:void(0);">
                  <i className="datatable-icon-prev"/>
                </a>
              </li>
              <li className={isFirstPage ? "disabled" : ""}
                  onClick={() => !isFirstPage && onPageChange(activePage.page - 1)}
              >
                <a href="javascript:void(0);">
                  <i className="datatable-icon-left"/>
                </a>
              </li>
              {pages.map(page => (
                  <li key={"page-" + page.page}
                      className={"pages " + (page.active ? "active" : "")}
                      onClick={() => {
                        if (!(activePage && activePage.page === page.page)) {
                          onPageChange(page.page as number);
                        }
                      }}
                  >
                    <a href="javascript:void(0);">
                      {page.page} {page.active}
                    </a>
                  </li>
              ))}
              <li className={isLastPage ? "disabled" : ""}
                  onClick={() => !isLastPage && onPageChange(activePage.page + 1)}
              >
                <a href="javascript:void(0);">
                  <i className="datatable-icon-right"/>
                </a>
              </li>
              <li className={isLastPage ? "disabled" : ""}
                  onClick={() => !isLastPage && onPageChange(maxPage)}
              >
                <a href="javascript:void(0);">
                  <i className="datatable-icon-skip"/>
                </a>
              </li>
            </ul>
          </div>
      );
    }
    return (<></>);
  }
}

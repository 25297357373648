import React from "react";
import { ContractInfoField, ContractInfoFieldProps } from "./ContractInfoField";
import { FullContract } from "../../../Contract";
import {MainLease} from "../../../../MainLease/MainLease";

interface Props<T extends FullContract | MainLease> extends ContractInfoFieldProps<T> {
  checkboxLabel?: string;
}

export default class CheckboxField<T extends FullContract | MainLease> extends ContractInfoField<T, Props<T>> {

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.fieldKey, event.target.checked);
  };

  protected renderReadOnlyField(): JSX.Element {
    return (
        <span className="font-weight-bold">
          {this.getFieldValue() ? "Jah" : "Ei"}
        </span>
    );
  }

  protected renderEditableField(): JSX.Element {
    return (
        <div className="custom-control custom-checkbox form-control">
          <input type="checkbox"
                 className="custom-control-input"
                 id={"check-" + this.props.fieldKey}
                 checked={!!this.getFieldValue()}
                 disabled={!this.props.editable}
                 onChange={this.onChange}
          />
          <label className="custom-control-label no-wrap" htmlFor={"check-" + this.props.fieldKey}>
            {this.props.checkboxLabel}
          </label>
        </div>
    );
  }
}
import { PagingMetadata } from "./Paging";

export interface BootstrapTablePagingParams {
    limit: number;
    offset: number;
    order: "asc"|"desc";
    search?: string;
    sort?: string;
    filter?: string;
}

export class PagingUtils {

  static fromBootstrapTablePagingParams(params: BootstrapTablePagingParams): PagingMetadata {

    return new PagingMetadata({
      page: params.offset / params.limit,
      limit: params.limit,
      sort: params.sort,
      sortDirection: params.order === "asc" ? "ASC" : "DESC",
      search: params.search
    });
  }

  static toHttpParams(paging: PagingMetadata): Object {

    return Object.assign(
        {
          page: paging.page,
          limit: paging.limit,
          sort: paging.sort,
          sortDirection: paging.sortDirection,
          search: paging.search
        },
        paging.params
    );
  }
}
import * as React from 'react';
import { Button } from "reactstrap";
import {connect} from "react-redux";
import {getBaseURL, getRkasCompanyRegNo} from "../../Config/configReducer";
import {RootState} from "../../rootReducer";
import {MainLease} from "../MainLease";
import {LeaseContract} from "../../Contracts/Contract";
import TextField from "../../Contracts/ContractDetails/ContractInfo/Fields/TextField";
import CompanyField from "../../Contracts/ContractDetails/ContractInfo/Fields/CompanyField";
import PersonField from "../../Contracts/ContractDetails/ContractInfo/Fields/PersonField";
import {ContactInfoModalData, ModalType} from "../../Modal/Modal";
import {modalActions} from "../../Modal/modalActions";
import ClassifierField from "../../Contracts/ContractDetails/ContractInfo/Fields/ClassifierField";
import {Classifier, ClassifierCode} from "../../Classifier/Classifier";
import {getClassifiers} from "../../Classifier/classifierReducer";
import NumberField from "../../Contracts/ContractDetails/ContractInfo/Fields/NumberField";
import CheckboxField from "../../Contracts/ContractDetails/ContractInfo/Fields/CheckboxField";


interface Props {
    classifiers: Classifier[];
    baseURL: string;
    mainLease?: MainLease;
    //contract: FullContract;
    editingContract?: LeaseContract;
    infoEditMode: boolean;

    toggleInfoEditMode?: () => void;
    updateMainLease: (mainLease: MainLease) => void;
    onShowUnsavedRowsConfirmationModal?: () => void;

    toggleModal: typeof modalActions.toggleModal;
    rkasCompanyRegNo: string;
}

interface State {
    open: boolean;
    tabs: TabConfiguration[];
    openedTab: TabConfiguration;
}

interface TabConfiguration {
    name: string;
    label: string;
    showEdit: boolean;
}

class MainLeaseInfo extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const tabs = [{ name:"overview", label: 'Peaüüri põhiandmed', showEdit: true },]
        this.state = {
            open: true,
            tabs: tabs,
            openedTab: tabs[0],
        };
    }

    private renderTabs() {
        return (
            this.state.tabs.map((tab) => (
                <React.Fragment key={tab.name}>
                    <button
                        onClick={() => {this.openTab(tab)}}
                        className={`btn btn-link complex-block-title block-title-btn position-relative no-wrap
                        ${this.state.open && this.state.openedTab === tab && 'tab-open'}
                        ${this.state.openedTab === tab && 'block-active'}`}
                    >
                        {tab.label}
                    </button>
                    <span className="complex-block-title-separator"/>
                </React.Fragment>
            ))
        );
    }

    private onChange = (fieldKey: keyof MainLease, value: any) => {
        if (this.props.mainLease) {
            this.props.updateMainLease({
                ...this.props.mainLease,
                [fieldKey]: value
            });
        }
    };

    private renderContent() {
        const mainLease = this.props.mainLease;
        return(
            <div className="row">
                <div className={this.props.infoEditMode
                    ? "col-12 col-lg-5 col-xl-5"
                    : "col-12 col-md-6"}>
                    <>
                        <TextField contract={mainLease}
                                   label={"Lepingu number"}
                                   fieldKey={"contractNumber"}
                                   editMode={this.props.infoEditMode}
                                   editable={this.props.infoEditMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <TextField contract={mainLease}
                                   label={"Peaüür"}
                                   fieldKey={"code"}
                                   editMode={this.props.infoEditMode}
                                   editable={this.props.infoEditMode && !mainLease.id}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <CompanyField contract={mainLease}
                                      label={"Ettevõte"}
                                      fieldKey={"company"}
                                      baseURL={this.props.baseURL}
                                      required={true}
                                      editMode={this.props.infoEditMode}
                                      editable={this.props.infoEditMode}
                                      onChange={this.onChange}
                        />
                        <PersonField contract={mainLease}
                                     label={"Ettevõtte kontaktisik"}
                                     fieldKey={"contact"}
                                     company={mainLease.company}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.infoEditMode}
                                     editable={this.props.infoEditMode}
                                     isClearable={true}
                                     required={false}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <PersonField contract={mainLease}
                                     label={"Peaüüri arve kinnitaja"}
                                     fieldKey={"manager"}
                                     companyRegNo={this.props.rkasCompanyRegNo}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.infoEditMode}
                                     editable={this.props.infoEditMode}
                                     isClearable={true}
                                     required={true}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                    </>
                </div>
                <div className={this.props.infoEditMode
                    ? "col-12 col-lg-6 col-xl-5 offset-md-0 offset-lg-1 offset-xl-2 mt-3 mt-lg-0"
                    : "col-12 col-md-6"}>
                    <>
                        <ClassifierField contract={mainLease}
                                         label={"Arve saatmise kanal"}
                                         fieldKey={"invoiceChannelType"}
                                         classifiers={this.props.classifiers}
                                         classifierCode={ClassifierCode.InvoiceChannelType}
                                         isClearable={true}
                                         required={true}
                                         editMode={this.props.infoEditMode}
                                         editable={this.props.infoEditMode}
                                         onChange={this.onChange}
                        />
                        {this.renderInvoiceDueDays()}
                    </>
                </div>
            </div>
        );
    }

    private renderInvoiceDueDays(): JSX.Element {
        const mainLease = this.props.mainLease;
        return (
            <>
                {this.props.infoEditMode &&
                <NumberField contract={mainLease}
                             label={"Maksetähtaeg (päevades)"}
                             fieldKey={"invoiceDueDays"}
                             editMode={this.props.infoEditMode}
                             editable={this.props.infoEditMode}
                             required={true}
                             onChange={this.onChange}
                />
                }

                {!this.props.infoEditMode &&
                <div className={"form-row custom-form-row" + (!this.props.infoEditMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Maksetähtaeg"}</label>
                        {mainLease.invoiceDueDays &&
                        <>
                            <span className="font-weight-bold">{mainLease.invoiceDueDays}</span>
                            <span className="font-weight-bold">&nbsp;{mainLease.invoiceDueDays == 1 ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!mainLease.invoiceDueDays &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private toggleOpen = () => {
        this.setState({
            open: !this.state.open
        });
    };

    private openTab = (tab: TabConfiguration) => {
        this.setState({
            openedTab : tab
        });
    }

    private editInfo = () => {
        this.setState({
            open: true,
        }, () => {
            this.props.toggleInfoEditMode();
        })
    };

    render(): React.ReactNode {
        return (
            <div className="block block-white width-100 block-collapsible contract-info">
                <div className="d-flex title-border-bottom align-items-center justify-content-between">
                    {!this.props.infoEditMode &&
                    <div className="d-flex align-items-center">
                        {this.renderTabs()}
                    </div>
                    }

                    {this.props.infoEditMode &&
                    <span className="block-title w-auto no-wrap">Peaüüri põhiandmed</span>
                    }
                    <button
                        onClick={this.toggleOpen}
                        className={`btn btn-link block-title block-title-btn position-relative
                                                ${this.state.open ? 'arrow-up' : 'arrow-down'}`}
                    >
                        <span className="icon icon_arrow_dark"/>
                    </button>


                    {this.state.openedTab.showEdit
                    && !this.props.infoEditMode &&
                    <Button color="outline-dark"
                            className="no-wrap d-flex align-items-center justify-content-center mr-3 border-0"
                            onClick={this.editInfo}
                    >
                        Muuda
                    </Button>
                    }
                </div>
                <div className={`block-collapsible-content ${this.state.open && 'open'}`}>
                    <div className="p-4 p-md-4 p-lg-5">
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    rkasCompanyRegNo: getRkasCompanyRegNo(state),
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};


export default connect(mapStateToProps, mapDispatchToProps)(MainLeaseInfo);
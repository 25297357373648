import React from "react";
import { FullLeaseContract } from "../../Contract";
import LeaseContractTable from "./LeaseContractTable";
import "./LeaseContract.css";
import LeaseContractAddRowsModal from "./LeaseContractAddRowsModal";
import {connect} from "react-redux";
import {contractDetailsActions} from "../contractDetailsActions";

interface Props {
    contract: FullLeaseContract;
    infoEditMode: boolean;
    editingContract?: FullLeaseContract;
    updateEditingContract: (contract: FullLeaseContract, callback?: () => void) => void;
    onSaveContract: () => void;

    toggleAddRowModal: typeof contractDetailsActions.toggleAddRowModal;
}


export class LeaseContractDetails extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!this.props.infoEditMode) {
            return (
                <>
                    {!this.props.contract.publishedOn &&
                    <LeaseContractAddRowsModal editingContract={this.props.editingContract}
                                               updateEditingContract={this.props.updateEditingContract}
                                               onSaveContract={this.props.onSaveContract}
                    />
                    }

                    <LeaseContractTable   contract={this.props.contract}
                                          editingContract={this.props.editingContract}
                                          toggleLeaseModal={this.props.toggleAddRowModal}
                                          updateEditingContract={this.props.updateEditingContract}
                                          onSaveContract={this.props.onSaveContract}
                    />
                </>
            );
        }
        return <></>;
    }
}

const mapDispatchToProps = {
    toggleAddRowModal: contractDetailsActions.toggleAddRowModal
};

export default connect(null,mapDispatchToProps)(LeaseContractDetails);
import * as React from "react";
import {Provider} from "react-redux";
import store from "./store";
import {configActions} from "./Config/configActions";
import axios from "axios";
import AlertContainer from "./Alerts/AlertContainer";
import {HotKeys} from "react-hotkeys";
import {costItemActions} from "./Contracts/costItemActions";
import {Redirect, Route, RouteComponentProps, withRouter} from "react-router";
import {LeaseContracts} from "./Contracts/LeaseContract/LeaseContracts";
import {ServiceContracts} from "./Contracts/ServiceContract/ServiceContracts";

import "bootstrap";
import "./App.css";
import "../assets/scss/styles.css";
import {ConstructionContracts} from "./Contracts/ConstructionContract/ConstructionContracts";
import {EnergyContracts} from "./Contracts/EnergyContract/EnergyContracts";
import {classifierActions} from "./Classifier/classifierActions";
import {MainLeases} from "./MainLease/MainLeases";
import {SupportServiceContracts} from "./Contracts/SupportServiceContract/SupportServiceContracts";
import * as History from 'history';
import {authActions} from "./Auth/authActions";
import Secured from "./Auth/Secured";
import {Permission, User, UserCompany} from "./Auth/User";
import ReactNotifications from 'react-notifications-component';
import {CustomModal} from "./Modal/CustomModal";
import RepresentativeModal from "./Auth/RepresentativeModal";
import Header from "./Header/Header";
import NavigationHeader from "./Header/NavigationHeader";
import {getCurrentUser} from './Auth/authReducer';
import {ConsumptionContracts} from "./Contracts/ConsumptionContract/ConsumptionContracts";
import ModalContainer from "./Modal/ModalContainer";
import WorkSpace from "./WorkSpace/WorkSpace";
import ServiceContractsReportList from "./Reports/ServiceContractsReport/ServiceContractsReportList";
import {SessionExpirationModal} from "./Session/SessionExpirationModal";

interface State {
  userFetched: boolean;
  configFetched: boolean;
  classifiersFetched: boolean;
  triedImplicitLogin: boolean;
  sideNavToggled: boolean;
  displayRepresentativeModal: boolean;
  displayNavHeader: boolean;
}

class App extends React.Component<RouteComponentProps, State> {

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      userFetched: false,
      configFetched: false,
      classifiersFetched: false,
      triedImplicitLogin: false,
      sideNavToggled: false,
      displayRepresentativeModal: false,
      displayNavHeader: false
    };

    this.sideNavToggled = this.sideNavToggled.bind(this);
  }

  componentDidMount() {
      axios.get("/config.json")
          .then((res) => {
              store.dispatch(configActions.setConfig(res.data));
          })
          .then(() => {
              const userCompanySelection = sessionStorage.getItem('UserCompanySelection');
              if (!userCompanySelection) {
                  this.setState({
                      displayRepresentativeModal: true
                  });
              }
              axios.post(store.getState().config.baseURL + "/auth/login", {userCompanyId: Number(sessionStorage.getItem('UserCompanySelection'))})
                  .then(
                      res => {
                          if (res.headers["content-type"] !== "application/json") {
                              this.redirectToAuth();
                          }
                          store.dispatch(authActions.setCurrentUser(res.data));
                      },
                      err => {
                          if (err.response === undefined) {
                              this.redirectToAuth();
                          }
                      }
                  )
                  .then(() => {
                      this.setState({
                          userFetched: true
                      }, () => this.tryImplicitLogin());
                  });
          })
          .then(() => {
              axios.get(store.getState().config.baseURL + "/cost-items")
                  .then(res => {
                      store.dispatch(costItemActions.addCostItems(res.data));
                  })
                  .then(() => {
                      this.setState({
                          configFetched: true
                      });
                  });
          })
          .then(() => {
              axios.get(store.getState().config.baseURL + "/classifiers")
                  .then(
                      res => {
                          store.dispatch(classifierActions.addClassifiers(res.data));
                      },
                      () => {
                          store.dispatch(classifierActions.addClassifiers([]));
                      })
                  .then(() => {
                      this.setState({
                          classifiersFetched: true
                      });
                  });
          })
          .then(() => this.redirectToPreviousLocation());
  }

  render() {
    const map = {
      "enter": "enter",
      "shiftAndEnter": "shift+enter"
    };

    return (
        <div>
            <Provider store={store}>
                <div>
                  <CustomModal
                      visible={this.state.displayRepresentativeModal && this.state.triedImplicitLogin}
                      dismissable={false}
                      onDismiss={this.dismissUserCompaniesModal}
                      header={"Esindusõiguse valimine"}
                  >
                      {this.state.displayRepresentativeModal && this.state.userFetched &&
                      <RepresentativeModal onDismiss={this.dismissUserCompaniesModal} />
                      }
                  </CustomModal>

                  <HotKeys keyMap={map}>
                    <div className="main-container">
                      <div className="sticky">
                          <Header sideNavToggled={this.sideNavToggled} />
                          <Route path={["/service", "/construction", "/support-service", "/lease", "/energy", "/consumption", "/main-leases", "/reports/service-contracts"]} exact={true} component={NavigationHeader}/>
                      </div>
                      {this.canRenderRouter() &&
                      <div>
                        <Route path="" exact={true} render={() => (<Redirect to="/service" />)} />
                        <Route path="/contracts" exact={true} render={() => (<Redirect to="/service" />)} />
                        <Route path="/reports" exact={true} render={() => (<Redirect to="/reports/service-contracts" />)} />
                        <Route path="/workspace" component={WorkSpace} />

                        <Secured permission={Permission.AccessServiceContract}>
                            <Route path="/service" component={ServiceContracts} />
                        </Secured>
                        <Secured permission={Permission.AccessConstructionContract}>
                          <Route path="/construction" component={ConstructionContracts} />
                        </Secured>
                        <Secured permission={Permission.AccessSupportServiceContract}>
                          <Route path="/support-service" component={SupportServiceContracts} />
                        </Secured>
                        <Secured permission={Permission.AccessLeaseAgreement}>
                          <Route path="/lease" component={LeaseContracts} />
                        </Secured>
                        <Secured permission={Permission.AccessEnergySaleAgreement}>
                          <Route path="/energy" component={EnergyContracts} />
                        </Secured>
                        <Secured permission={Permission.AccessConsumptionContract}>
                          <Route path="/consumption" component={ConsumptionContracts} />
                        </Secured>
                        <Secured permission={Permission.AccessMainLease}>
                          <Route path="/main-leases" component={MainLeases} />
                        </Secured>

                        <Secured permission={Permission.AccessServiceContract}>
                          <Route path="/reports/service-contracts" component={ServiceContractsReportList} />
                        </Secured>
                      </div>
                      }
                      <ReactNotifications />
                      <AlertContainer/>
                      <ModalContainer />
                      <SessionExpirationModal/>
                    </div>
                    <footer>
                      <div className="footer-container">
                        <h6 className="text-gray">Riigi Kinnisvara AS</h6>
                      </div>
                    </footer>
                  </HotKeys>
                </div>
            </Provider>
        </div>
    );
  }

  public displayNavHeader(isDisplayed: boolean) {
      this.setState({
          displayNavHeader: true
      });
  }
  private dismissUserCompaniesModal = () => {
      this.setState({
          displayRepresentativeModal: false
      });
  };

  private canRenderRouter(): boolean {
      const currentUser: User = getCurrentUser(store.getState());

      return this.state.userFetched && this.state.configFetched && this.state.classifiersFetched && !!currentUser
          && !!currentUser.userCompany && !!currentUser.userCompany.id;
  }

  private sideNavToggled() {
      this.setState({
          sideNavToggled: this.state.sideNavToggled
      })
  }

  private redirectToAuth(): void {
    sessionStorage.setItem("kihl.location", JSON.stringify(this.props.location));

    window.location.replace(`${store.getState().config.baseURL}/auth`);
  }

  private redirectToPreviousLocation(): void {
    let previousLocation: History.Location|string|null = sessionStorage.getItem("kihl.location");

    if (previousLocation) {
      previousLocation = JSON.parse(previousLocation) as History.Location;
      this.props.history.push(previousLocation);

      sessionStorage.removeItem("kihl.location");
    }
  }


    private tryImplicitLogin() {
        const currentUser: User = getCurrentUser(store.getState());

        if(currentUser) {
            const userCompanySelection = sessionStorage.getItem('UserCompanySelection');
            if(!userCompanySelection) {
               if(currentUser.companies.length == 1 && currentUser.substitutions.length < 1) {
                    this.selectDefaultUserCompany(currentUser.companies[0]);
                    return;
                }
            }
        }

        this.setState({triedImplicitLogin : true});
    }

    private selectDefaultUserCompany = (company: UserCompany) => {
        sessionStorage.setItem('UserCompanySelection', company.id.toString());

        axios.post(store.getState().config.baseURL + "/auth/login", {userCompanyId: company.id})
            .then(
                res => {
                    if (res.headers["content-type"] !== "application/json") {
                        this.redirectToAuth();
                    }
                    store.dispatch(authActions.setCurrentUser(res.data));
                    this.setState({
                        displayRepresentativeModal: false,
                        triedImplicitLogin: true
                    });
                },
                err => {
                    if (err.response === undefined) {
                        this.redirectToAuth();
                    }
                }
            )
    };
}

export default withRouter(App);

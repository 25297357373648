import * as React from "react";
import DatePicker from "react-datepicker";

interface Props {
    type: string;
    update: (date: Date) => void;
    lastUpdate: string | null;
}

interface State {
    startDate: Date
}

export class SyncData extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            startDate: new Date()
        };

    };

   handleDateChange = (date: Date) => {
       this.setState({
           startDate: date
       });
   };

   updateClicked = () => {
     this.props.update(this.state.startDate);
   };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <h5 className="mb-0">{this.props.type} uuendamine</h5>
                        <span className="text-gray text-sm">Viimati uuendatud: {this.props.lastUpdate}</span>
                    </div>
                </div>
                <div className="work-details-full-width pb-3">
                    <div className="work-details-1-row">
                        <div className="work-details-1-col mt-2 align-items-center">
                            <label className="work-details-1-label">Alguskuupäev</label>
                            <div className="w-100">
                                <DatePicker className="form-control w-100"
                                    selected={this.state.startDate}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={this.handleDateChange}
                                />
                            </div>
                            <button type="button" className="btn btn-primary ml-2" onClick={this.updateClicked}>
                                Uuenda
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
import React from "react";
import axios from "axios";
import {Eic, Project} from "../Contracts/Contract";
import {ContractFilters} from "../Contracts/ContractFilters";
import {MainLeaseFilters} from "../MainLease/MainLeaseList/MainLeaseFilters";
import Select from "react-select";

interface Props {
    baseURL: string;
    fieldKey: keyof ContractFilters|keyof MainLeaseFilters;
    isClearable?: boolean;
    portalTarget?: string;
    fieldValue: any;
    onChange?: (fieldKey: keyof ContractFilters|keyof MainLeaseFilters, value: any) => void;
}

interface State {
    options: EICOption[];
}

type EICOption = { label: string, value: string, eic: Eic };

export default class EICListFilter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            options: this.getDefaultOptions()
        };
    }

    componentDidMount(): void {
        this.loadOptions();
    }

    private formatLabel = (eic: Eic) => `${eic.eic} - ${eic.contractNumber}`;

    private getOption = (eic?: Eic): EICOption => {
        if (eic) {
            return {
                label: this.formatLabel(eic),
                value: eic.eic,
                eic
            };
        }
        return undefined;
    };

    private findOption = (eic?: string): EICOption => {
        if (eic && this.state.options) {
            return this.state.options.find(option => option?.eic?.eic == eic)
        }
        return undefined;
    };

    private getValue(): EICOption[] {
        let eics: string = this.props.fieldValue;
        if (eics) {
            return eics.split(",").map(this.findOption);
        }
        return;
    }

    private getDefaultOptions(): EICOption[]{
        let eic: Eic = this.props.fieldValue;
        if (eic) {
            return [this.getOption(eic)];
        }
    }

    private loadOptions = () => {
        return axios.get(`${this.props.baseURL}/contract-properties/eics`).then(res => {
            let options = res.data.map(this.getOption);
            this.setState({ options });
        });
    };

    private onChange = (options: EICOption[]) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.fieldKey, options ? options.map(option => option.eic.eic).join(",") : undefined);
        }
    };

    public render(): JSX.Element {
        return (
            <Select className="react-select"
                    menuPlacement={"auto"}
                    classNamePrefix="react-select"
                    isClearable={true}
                    isMulti={true}
                    placeholder="Vali"
                    menuPortalTarget={document.querySelector(this.props.portalTarget ? this.props.portalTarget : 'body')}
                    options={this.state.options}
                    onChange={this.onChange}
                    value={this.getValue()}
            />
        );
    }
}
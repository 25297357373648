import * as React from "react";
import "./SideNav.css";
import {RootState} from "../rootReducer";
import {
  getLepingudUrl,
  getPropregUrl, getTuumikUrl,
  getUserRegistryUrl,
  getWorksUrl,
  getYyripakkumisedUrl
} from "../Config/configReducer";
import {connect} from "react-redux";


interface Props {
  workorder: string;
  userregistry: string;
  propertyregistry: string;
  yyripakkumised: string;
  lepingud: string;
  tuumikUrl: string;
  sideNavOpen: boolean;
  toggleSidebar: () => void;
}

interface State {
  sideNavVisible: boolean;
}

class SideNav extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      sideNavVisible: false
    };
  }

  isToggled(): boolean {
    const dom: Element | null = document.querySelector('body');

    return dom != null && dom.classList.contains('sidenav-open');
  }

  render() {
    const dom: Element | null = document.querySelector('body');

    return (
        dom != null && dom.classList.contains('sidenav-open') &&
        <aside className={this.isToggled() ? 'sidenav visible' : 'sidenav'}>

          <div className="background" onClick={() => this.props.toggleSidebar()}></div>

          <nav className="sidenav d-none d-md-block">

            <ul>
              <li><a href={this.props.workorder} className="sidenav-link icon-works">Kliendiportaal</a></li>
              <li ><a href={this.props.userregistry} className="sidenav-link icon-users">Kasutajad</a></li>
              <li><a href={this.props.tuumikUrl} className="sidenav-link icon-euro">Kulud</a></li>
              <li><a href={this.props.propertyregistry} className="sidenav-link icon-objects">Kinnistud</a></li>
              <li><a href={this.props.lepingud} className="sidenav-link icon-files">Lepingud</a></li>
              <li><a href={this.props.yyripakkumised} className="sidenav-link icon-rentals">Üüripakkumised</a></li>
           </ul>
          </nav>

        </aside>
  );
  }
}

const mapStateToProps = (state: RootState) => ({
  workorder: getWorksUrl(state),
  userregistry: getUserRegistryUrl(state),
  propertyregistry: getPropregUrl(state),
  yyripakkumised: getYyripakkumisedUrl(state),
  lepingud: getLepingudUrl(state),
  tuumikUrl: getTuumikUrl(state)
});

export default connect(mapStateToProps)(SideNav);
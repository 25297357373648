import * as React from "react";
import axios, {AxiosPromise} from "axios";
import { MainLease } from "../MainLease";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "../../rootReducer";
import { getBaseURL } from "../../Config/configReducer";
import { PagingMetadata } from "../../Paging/Paging";
import { LeaseContract } from "../../Contracts/Contract";
import { getCurrentUser } from "../../Auth/authReducer";
import MainLeaseInfo from "./MainLeaseInfo";
import DetailsHeader from "../../Contracts/ContractDetails/DetailsHeader";
import MainLeaseTable from "./MainLeaseTable";
import {Alert, AlertContentType, AlertType} from "../../Alerts/Alert";
import {alertActions} from "../../Alerts/alertActions";

interface Params {
  id: string;
}

interface Props extends RouteComponentProps<Params> {
  baseURL: string;
  addAlert: typeof alertActions.addAlert;
}

interface State {
  loading: boolean;
  mainLease?: MainLease;
  paging?: PagingMetadata;
  editingContract?: LeaseContract;
  infoEditMode: boolean;
}

class MainLeaseDetails extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      infoEditMode: false,
      paging: new PagingMetadata({
        page: 0,
        limit: 20
      })
    };
  }

  componentDidMount() {
    switch(this.props.location.state?.action) {
      case "new":
        this.props.history.replace({...this.props.location, state: {...this.props.location.state, action: undefined}});
        let mainLease = new MainLease();
        mainLease.isBondCollateral = false; // null not accepted
        this.setState({ loading: false, mainLease : mainLease }, () => this.toggleInfoEditMode())
        break;
      default:
        axios.get(this.props.baseURL + "/main-leases/" + this.props.match!.params.id)
            .then(res => {
              this.updateMainLease(res.data);
            })
            .catch(() => {
              this.setState({
                loading: false
              });
            });
        break;
    }
  }

  updateMainLease = (mainLease: MainLease) => {
    this.setState({
      loading: false,
      mainLease: mainLease
    });
  };

  private toggleInfoEditMode = () => {
    this.setState({
      infoEditMode: !this.state.infoEditMode
    });
  };

  private onSave = () => {
    let promise: AxiosPromise;
    if (this.state.mainLease!.id) {
      promise = axios.put(
          `${this.props.baseURL}/main-leases/${this.state.mainLease!.id}`,
          this.state.mainLease
      );
    } else {
      promise = axios.post(`${this.props.baseURL}/main-leases`, this.state.mainLease);
    }

    promise.then((res) => {
      if(this.state.infoEditMode) {
        this.toggleInfoEditMode();
        this.props.history.push(`${res.data.id}`);
      }

    }).catch(() => {
      let alert: Alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.MainLeaseErrors;
      this.props.addAlert(alert);
    });
  };

  render() {
    return (
        <div>
          <div className="main-lease-details">
            {this.contentHandler()}
          </div>
        </div>
    );
  }

  private contentHandler() {
    if (this.state.loading) {
      return <span><i className="details-spinner fa fa-spinner fa-spin fa-2x"/></span>;
    } else {
      if (this.state.mainLease) {
        return(
            <>
              <div className="sticky">
                <DetailsHeader
                    {...this.props as RouteComponentProps<Params>}
                    contract={this.state.mainLease}
                    isContract={false}
                    editingContract={this.state.editingContract}
                    infoEditMode={this.state.infoEditMode}
                    onValidate={null}
                    onSaveContract={this.onSave}
                    onUpdateEditingContract={null}
                    onSaveContractRows={null}
                    onShowPublishConfirmationModal={null}
                    onShowDeleteConfirmationModal={null}
                    onShowUnsavedRowsConfirmationModal={null}
                    onShowContractHistory={null}
                    onSaveThiMultiplication={null}
                    onCleanProperties={null}
                    onSaveDefaultPrices={null}
                    onSaveWarrantyEndDates={null}
                    onExportToEDHS={null}
                    onShowContractorNotificationPreviewModal={null}
                />
              </div>
              {this.state.infoEditMode &&
              <div className="container-fluid d-flex align-items-center flex-column mt-4 container-xl-max-width">
                {this.renderContent()}
              </div>
              }
              {!this.state.infoEditMode &&
              <div className="container-fluid pt-4">
                {this.renderContent()}
              </div>
              }
            </>
        );
      } else {
        return <span className="not-found">Paha lugu, seda peaüüri meie andmebaasist ei leitud!</span>;
      }
    }
  }

  private renderContent() {
    return (
        <>
          <MainLeaseInfo
              mainLease={this.state.mainLease}
              infoEditMode={this.state.infoEditMode}
              toggleInfoEditMode={this.toggleInfoEditMode}
              updateMainLease={this.updateMainLease}
            />
          {!this.state.infoEditMode &&
            <MainLeaseTable
                mainLease={this.state.mainLease}
                editingMainLease={this.state.mainLease}
                updateMainLease={this.updateMainLease}
                onSaveMainLease={null}
            />
          }
        </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLeaseDetails);

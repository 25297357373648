import React from "react";
import {FullLeaseContract, Property} from "../../Contract";
import {Classifier, ClassifierCode} from "../../../Classifier/Classifier";
import {connect} from "react-redux";
import {RootState} from "../../../rootReducer";
import {getClassifiers} from "../../../Classifier/classifierReducer";
import {getPropregUrl} from "../../../Config/configReducer";
import {
    ContractRowState, ContractRowStateMap,
    LeaseContractRow,
    RowEditState,
    RowGroupState
} from "../ContractRow";
import BooleanSelectRowField from "./Fields/BooleanSelectRowField";
import ClassifierRowField from "./Fields/ClassifierRowField";
import NumberRowField from "./Fields/NumberRowField";
import DateRowField from "./Fields/DateRowField";
import DatePicker from "react-datepicker";
import moment from "moment";
import {ContractTableUtils} from "../../../Common/ContractTableUtils";
import {UncontrolledTooltip} from "reactstrap";


interface Props {
    classifiers: Classifier[]
    propregUrl: string;

    contract: FullLeaseContract;
    editingContract?: FullLeaseContract;

    toggleLeaseModal: (boolean) => void;
    updateEditingContract: (contract: FullLeaseContract, callback: () => void) => void;
    onSaveContract: () => void;
}

interface State {
    quickFilter : string;
    showDeletedRows : boolean;
    multiSelectDateModalOpen : boolean;
    multiSelectDateStart : Date;
    multiSelectDateEnd : Date;
    openedPropertyTabs : number[];
    selectedRows: LeaseContractRow[];
}



class LeaseContractTable extends React.Component<Props, State> {
    private rowStates: ContractRowStateMap;

    constructor(props: Props) {
        super(props);

        this.state = {
            quickFilter : "current",
            showDeletedRows : false,
            multiSelectDateModalOpen : false,
            multiSelectDateStart: null,
            multiSelectDateEnd: null,
            openedPropertyTabs :[],
            selectedRows: []
        };
    }

    componentDidMount() {

        const contract = this.props.contract || this.props.editingContract;
        if(contract != null && contract.properties.length > 0)
            this.togglePropertyTable(contract.properties[0]);
    }

    private onChange = (row: LeaseContractRow, fieldKey: keyof LeaseContractRow, value: any) => {
        if (this.props.editingContract) {
            row[fieldKey.valueOf()] = value;
            this.props.updateEditingContract({
                ...this.props.editingContract
            }, () => {
                //this.props.onSaveContract();
            });
        }
    };

    private costItemCodeFormatter(cell) {
        const count = 3 - (String(cell).match(/0/g) || []).length;
        return (
            <div style={{paddingLeft:24*count}}>{cell}</div>
        );
    }

    render() {
        const editMode = this.props.contract.publishedOn === null;
        let contract = editMode ? this.props.editingContract : this.props.contract;
        let contractRows = contract.rows;
        this.rowStates = ContractTableUtils.ProcessRows(this.props.editingContract,this.props.contract);

        if(!this.state.showDeletedRows)
            contractRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.editState != RowEditState.Deleted);

        const expiredRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.groupState == RowGroupState.Expired);
        const futureRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.groupState == RowGroupState.Future);
        const currentRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.groupState == RowGroupState.Current);

        let filteredRows = [];
        switch(this.state.quickFilter){
            case "current":
                filteredRows = currentRows;
                break;
            case "expired":
                filteredRows = expiredRows;
                break;
            case "future":
                filteredRows = futureRows;
                break;
            default:
                filteredRows = currentRows;
        }
        const multiSelectDelete = this.state.selectedRows.every(row => row.deletedOn == null);

        return (
            <>
                <div className="d-lg-flex mb-4" style={{justifyContent:"space-between"}}>
                    <div className="btn-group">
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "current" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("current")}>
                            Kehtivad ({currentRows.length})
                        </button>
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "expired" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("expired")}>
                            Kehtetud ({expiredRows.length})
                        </button>
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "future" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("future")}>
                            Tulevikus kehtivad ({futureRows.length})
                        </button>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                               className="custom-control-input"
                               id="show-deleted-rows-checkbox"
                               checked={!!this.state.showDeletedRows}
                               onChange={() => this.setState({ showDeletedRows: !this.state.showDeletedRows})}
                        />
                        <label className="custom-control-label no-wrap" htmlFor={"show-deleted-rows-checkbox"}>
                            Näita kustutatud ridu
                        </label>
                    </div>
                </div>
                {this.props.editingContract.properties.length > 0 &&
                    this.renderPropertyTable(this.props.editingContract.properties[0], filteredRows)
                }

                {(editMode && this.state.selectedRows.length > 0) &&
                <div className="contract-footer"><h6 className="mr-3">Valitud: {this.state.selectedRows.length}</h6>
                    {!this.state.multiSelectDateModalOpen &&
                        <>
                            <button type="button"
                                    className="btn btn-outline-primary mr-3 btn-sm"
                                    onClick={() => this.setState({selectedRows: []})}>
                                Tühista
                            </button>
                            <button type="button"
                                    className="btn btn-primary mr-3 btn-sm"
                                    onClick={() => this.setState({multiSelectDateModalOpen: true})}>
                                Muuda valitud kuupäevi
                            </button>

                            {multiSelectDelete &&
                                <button type="button"
                                        className="btn btn-danger mr-3 btn-sm"
                                        onClick={() => this.deleteMultiSelection()}>
                                    Kustuta valitud read
                                </button>
                            }
                            {!multiSelectDelete &&
                            <button type="button"
                                    className="btn btn-success mr-3 btn-sm"
                                    onClick={() => this.unDeleteMultiSelection()}>
                                Taasta valitud read
                            </button>
                            }

                        </>
                    }
                    {this.state.multiSelectDateModalOpen &&
                        <>
                            <div className="form-row custom-form-row ml-3 mr-4">
                                <div className="col-12 align-items-center">
                                    <h6 style={{width: "50px"}}>Algus:</h6>
                                    <DatePicker
                                        className="form-control calendar-input"
                                        selected={this.state.multiSelectDateStart}
                                        placeholderText={"Vali"}
                                        dateFormat="dd.MM.yyyy"
                                        onChange={(date: Date) => this.setState({multiSelectDateStart : date})}
                                    />
                                </div>
                            </div>
                            <div className="form-row custom-form-row mr-5">
                                <div className="col-12 align-items-center">
                                    <h6 style={{width: "50px"}}>Lõpp:</h6>
                                    <DatePicker
                                        className="form-control calendar-input"
                                        selected={this.state.multiSelectDateEnd}
                                        placeholderText={"Vali"}
                                        dateFormat="dd.MM.yyyy"
                                        onChange={(date: Date) => this.setState({multiSelectDateEnd : date})}
                                    />
                                </div>
                            </div>
                            <button type="button"
                                    className="btn btn-outline-primary mr-3 btn-sm"
                                    onClick={() => this.setState({multiSelectDateModalOpen: false})}>
                                Tühista
                            </button>
                            <button type="button"
                                    className="btn btn-primary mr-3 btn-sm"
                                    onClick={() => this.changeDatesMultiSelection()}>
                                Muuda valitud kuupäevi
                            </button>
                        </>
                    }
                </div>
                }
            </>
        );
    }

    private renderPropertyTableHeaderRow(label: string, data: LeaseContractRow[]) {
        const editMode = this.props.contract.publishedOn === null;
        return (
            <tr>
                {editMode &&
                    <th style={{width: "45px"}}>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                   className="custom-control-input"
                                   id={"select-row-type-"+label}
                                   checked={!!data.every(row => this.state.selectedRows.includes(row))}
                                   onChange={() => this.toggleMultiSelection(data) }
                            />
                            <label className="custom-control-label no-wrap" htmlFor={"select-row-type-"+label}/>
                        </div>
                    </th>
                }
                <th colSpan={2}>{label}</th>
                <th style={{width: "100px"}}>Tasaarveldatav</th>
                <th className="w-140">Tulu liik</th>
                <th className="w-140">Tulu allikas</th>
                <th style={{width: "100px"}}>Muuda tulud kulu alusel</th>
                <th style={{width: "100px"}}>Oodatavad ostuarved</th>
                <th className="w-140">Algus (pp.kk.aasta)</th>
                <th className ="w-140">Lõpp (pp.kk.aasta)</th>

                {editMode &&
                    <th style={{width: "50px"}} />
                }
            </tr>
        );
    }

    private renderPropertyTableBodyRows(rows : LeaseContractRow[]){
        const editMode = this.props.contract.publishedOn === null;
        const editable = this.props.contract.publishedOn === null;
        return (
            rows.map(row =>
                <tr key={row.id || row.tempId} id={"row-"+(row.id || row.tempId)} className={ContractTableUtils.renderRowStatus(row, this.rowStates)}>
                    {!this.props.contract.publishedOn && editMode &&
                    <td>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                   className="custom-control-input"
                                   id={"select-row-"+(row.id || row.tempId)}
                                   checked={!!this.state.selectedRows.includes(row)}
                                   onChange={() => this.toggleRowSelection(row) }
                            />
                            <label className="custom-control-label no-wrap" htmlFor={"select-row-"+(row.id || row.tempId)}/>
                        </div>
                    </td>
                    }
                    <td className="w-100px">{this.costItemCodeFormatter(row.costItem.id)}</td>
                    <td>{row.serviceName}</td>
                    <td>
                        <BooleanSelectRowField
                            row={row}
                            field={row["isSettleable"]}
                            fieldKey={"isSettleable"}
                            editMode={editMode && !row.deletedOn}
                            editable={editable}
                            onChange={this.onChange}/>
                    </td>
                    <td>
                        <ClassifierRowField
                             row={row}
                             field={row["incomeType"]}
                             fieldKey={"incomeType"}
                             classifiers={this.props.classifiers}
                             classifierCode={ClassifierCode.IncomeType}
                             isClearable={false}
                             editMode={editMode && !row.deletedOn}
                             editable={editable}
                             onChange={this.onChange}
                        />
                    </td>
                    <td>
                        <ClassifierRowField
                            row={row}
                            field={row["incomeSource"]}
                            fieldKey={"incomeSource"}
                            classifiers={this.props.classifiers}
                            classifierCode={ClassifierCode.LeaseContractRowSource}
                            isClearable={false}
                            editMode={editMode && !row.deletedOn}
                            editable={editable}
                            onChange={this.onChange}
                        />
                    </td>
                    <td>
                        <BooleanSelectRowField
                            row={row}
                            field={row["changedIncomeBasedOnExpense"]}
                            fieldKey={"changedIncomeBasedOnExpense"}
                            editMode={editMode && !row.deletedOn}
                            editable={editable}
                            onChange={this.onChange}/>
                    </td>
                    <td>
                        <NumberRowField
                            row={row}
                            field={row["minVendorInvoices"]}
                            fieldKey={"minVendorInvoices"}
                            editMode={editMode && !row.deletedOn}
                            editable={editable}
                            onChange={this.onChange}/>
                    </td>
                    <td>
                        <DateRowField
                            row={row}
                            field={row["startDate"]}
                            fieldKey={"startDate"}
                            editMode={editMode && !row.deletedOn}
                            editable={editable}
                            fallbackText={ this.props.contract.startTime && moment(this.props.contract.startTime).format("DD.MM.YYYY")}
                            onChange={this.onChange}/>
                    </td>
                    <td>
                        <DateRowField
                            row={row}
                            field={row["endDate"]}
                            fieldKey={"endDate"}
                            editMode={editMode && !row.deletedOn}
                            editable={editable}
                            fallbackText={ this.props.contract.endTime && moment(this.props.contract.endTime).format("DD.MM.YYYY")}
                            onChange={this.onChange}/>
                    </td>

                    {editMode &&
                    <td>
                        <button onClick={() => this.toggleDeleted(row)} className="close mr-2 mt-2 mb-2" type="button">
                            <span className="submenu-hover submenu-hover-position" />
                            <span className={row.deletedOn == null ? "icon trash icon-bg-danger" : "icon trash icon-bg-success"} />
                        </button>
                    </td>
                    }
                    {ContractTableUtils.renderRowMessage(row, this.rowStates) &&
                        <UncontrolledTooltip target={"row-"+(row.id || row.tempId)} fade={false} innerClassName="tooltip-row-error"
                                             placement={"top"} hideArrow={true}>
                            {ContractTableUtils.renderRowMessage(row, this.rowStates)}
                        </UncontrolledTooltip>
                    }
                </tr>
            )
        );
    }

    private renderPropertyTable(property: Property, filteredRows : LeaseContractRow[]) {
        const editMode = this.props.contract.publishedOn === null;
        const propertyRows = filteredRows.filter(row => row.propertyId == property.id);
        const leaseRows = propertyRows.filter(row => row.incomeType == "LEASE_SERVICE" || (row.incomeType == null && row.type == "LEASE"))
                                      .sort((a, b) => a.costItem.id > b.costItem.id ? 1 : -1);
        const sideRows = propertyRows.filter(row => row.incomeType == "SIDE_SERVICE")
                                      .sort((a, b) => a.costItem.id > b.costItem.id ? 1 : -1);

        return (
            <>
                <div className="block block-white block-collapsible width-100">
                    <div className="d-flex align-items-center justify-content-between title-border-bottom">
                        <button className={"btn btn-link block-title title-border-bottom block-title-btn position-relative " +
                        (this.isPropertyTableOpened(property) ? "arrow-up" : "arrow-down")}
                                onClick={() => this.togglePropertyTable(property)}
                        >
                            {property.name}, {property.address}, {property.code}
                           <span className="icon icon_arrow_dark ml-2" />
                        </button>
                        <div className="d-flex align-items-center">
                            {editMode &&
                                <>
                                    <button className="btn btn-outline-dark no-wrap mr-3 ml-3" style={{"border": "none"}}
                                        onClick={() => this.props.toggleLeaseModal(true)}>
                                        Lisa ridu
                                    </button>
                                </>
                             }
{/*                            <a id={"redirect-to-expenses"}
                               href="https://proto2.rkas.bitweb.ee/expenses" target="_blank"
                               className="btn btn-link btn-sm no-wrap d-flex align-items-center hover-icon-secondary">
                                Ava arved <span className="icon external-link ml-1"/>
                            </a>
                            <UncontrolledTooltip target={"redirect-to-expenses"} fade={false}>
                                Suunab Tuumikusse
                            </UncontrolledTooltip>*/}
                        </div>
                    </div>

                    <div className={"block-collapsible-content " + (this.isPropertyTableOpened(property) && "open")}>
                        {(propertyRows && propertyRows.length > 0) &&
                        <div className="datatable">
                            <div className="table-responsive-lg">
                                <div >
                                    <table className="table table-bordered datatable-style table-lease-contract-details table-hover w-100 overflow-hidden">
                                        {leaseRows.length > 0 &&
                                        <>
                                            <thead>
                                            {this.renderPropertyTableHeaderRow("Üüriteenused", leaseRows)}
                                            </thead>
                                            <tbody>
                                            {this.renderPropertyTableBodyRows(leaseRows)}
                                            </tbody>
                                        </>
                                        }
                                        {sideRows.length > 0 &&
                                        <>
                                            <thead>
                                            {this.renderPropertyTableHeaderRow("Kõrvalteenused", sideRows)}
                                            </thead>
                                            <tbody>
                                            {this.renderPropertyTableBodyRows(sideRows)}
                                            </tbody>
                                        </>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                        }
                        {(!propertyRows || propertyRows.length <= 0) &&
                        <span className={"block-title"} style={{verticalAlign:"middle"}}>Kirjeid ei leitud</span>
                        }
                    </div>
                </div>
            </>
        );
    }

    private isPropertyTableOpened(property: Property) {
        return this.state.openedPropertyTabs.includes(property.id);
    }

    private togglePropertyTable(property: Property) {
        const index = this.state.openedPropertyTabs.indexOf(property.id);
        const newTabsState = this.state.openedPropertyTabs;
        if (index > -1)
            newTabsState.splice(index, 1);
        else
            newTabsState.push(property.id);

        this.setState({
            openedPropertyTabs: newTabsState
        });
    }

    private switchQuickFilter(filterType: string) {
        this.setState({
                quickFilter: filterType
        });
    }

    private toggleDeleted(row: LeaseContractRow) {
        this.onChange(row,"deletedOn", row.deletedOn ? null : new Date().getTime());
    }

    private toggleRowSelection(row: LeaseContractRow) {
        let selectedRows = this.state.selectedRows;
        if(selectedRows.includes(row))
            selectedRows = selectedRows.filter(selectedRow => selectedRow !== row)
        else
            selectedRows.push(row);

        this.setState({ selectedRows: selectedRows})
    }

    private toggleMultiSelection(data: LeaseContractRow[]) {
        if(data.every(row => this.state.selectedRows.includes(row)))
            this.setState({ selectedRows: []})
        else
            this.setState({ selectedRows: data})
    }

    private deleteMultiSelection() {
        this.state.selectedRows.map(row => {
            this.onChange(row,"deletedOn", new Date().getTime());
        })
        this.setState({selectedRows: []})
    }
    private unDeleteMultiSelection() {
        this.state.selectedRows.map(row => {
            this.onChange(row,"deletedOn", null);
        })
        this.setState({selectedRows: []})
    }


    private changeDatesMultiSelection() {
        this.state.selectedRows.map(row => {
            if(this.state.multiSelectDateStart){
                this.onChange(row,"startDate", this.state.multiSelectDateStart.getTime());
            }
            if(this.state.multiSelectDateEnd){
                this.onChange(row,"endDate", this.state.multiSelectDateEnd.getTime());
            }
        })
        this.setState({
            selectedRows: [],
            multiSelectDateModalOpen: false
        })
    }
}

const mapStateToProps = (state: RootState) => ({
    classifiers: getClassifiers(state),
    propregUrl: getPropregUrl(state)
});

export default connect(mapStateToProps)(LeaseContractTable);
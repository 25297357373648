import React from "react";
import {Classifier, ClassifierCode} from "../../Classifier/Classifier";
import {MainLease} from "../MainLease";
import axios from "axios";
import {FullEnergyContract, LeaseContract} from "../../Contracts/Contract";
import {getBaseURL} from "../../Config/configReducer";
import {getClassifiers} from "../../Classifier/classifierReducer";
import {RootState} from "../../rootReducer";
import {connect} from "react-redux";
import BootstrapTable, {ColumnDescription} from "react-bootstrap-table-next";
import {headerFormatter} from "../../BootstrapTable/customFormatters";
import ToolkitProvider, {ToolkitContextType} from "react-bootstrap-table2-toolkit";
import ClassifierField from "../../Contracts/ContractDetails/ContractInfo/Fields/ClassifierField";
import Select from "react-select";
import MainLeaseField from "../../Contracts/ContractDetails/ContractInfo/Fields/MainLeaseField";
import {Alert, AlertContentType, AlertType} from "../../Alerts/Alert";
import {klona} from "klona";
import {Button, ModalFooter} from "reactstrap";


interface Props {
    classifiers: Classifier[];
    baseURL: string;

    mainLease: MainLease;
    editingMainLease?: MainLease;

    updateMainLease: (mainLease: MainLease, callback: () => void) => void;
    onSaveMainLease: () => void;
}

interface State {
    rows :  LeaseContract[];
    mainLeases : MainLease[];
}

class MainLeaseTable extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            rows: [],
            mainLeases: [],
        };
    }

    componentDidMount() {
        this.getContracts();
        this.getMainLeases();
    }

    private getContracts(): void {
        axios.get(this.props.baseURL + "/main-leases/" + this.props.mainLease.id + "/contracts")
            .then(res => {
                this.setState({
                    rows: res.data.data.map((contract: LeaseContract) => {
                        contract.mainLease = this.props.mainLease!.code;
                        return contract;
                    })
                });
            })
    }

    private getMainLeases(): void {
        axios.get(this.props.baseURL + "/main-leases")
            .then(res => {
                this.setState({
                    mainLeases: res.data.data.map((mainLease: MainLease) => {
                        return mainLease.code;
                    })
                });
                console.log(this.state.mainLeases);
            })
    }

    private onMainLeaseChange = (leaseContract: LeaseContract, value: any) => {
        let rows = klona(this.state.rows);
        rows.find(row => row.id == leaseContract.id).mainLease = value;
        this.setState({rows: rows});
    };

    private onMainLeaseSave = (leaseContract: LeaseContract) => {
        axios
            .patch(this.props.baseURL + "/contracts/" + leaseContract.id + "/main-lease", {
                mainLease: leaseContract.mainLease
            })
            .then(res => {
                this.getContracts();
            })
    }

    private getColumns(): ColumnDescription[] {
        const columns = [
            {
                dataField: "number",
                text: "Lepingu number",
            },
            {
                dataField: "rentalCode",
                text: "Üürikood",
            },
            {
                dataField: "leaser.name",
                text: "Üürnik",
            },
            {
                dataField: "",
                text: "Peaüür",
                formatter: (cell, row) => (
                    <>
                        <div className={"d-flex"}>
                            <div style={{flexGrow:1}}>
                                <MainLeaseField
                                    baseURL={this.props.baseURL}
                                    contract={row}
                                    fieldKey={"mainLease"}
                                    fieldValue={row.mainLease}
                                    label={""}
                                    skipLabel={true}
                                    skipFormRowClasses={true}
                                    editMode={true}
                                    editable={true}
                                    onChange={((fieldKey: string, value) => this.onMainLeaseChange(row, value) )}/>
                            </div>
                            {row.mainLease !== this.props.mainLease.code &&
                                <Button color="primary"
                                        onClick={() => this.onMainLeaseSave(row)}
                                        style={{padding: "8px",maxWidth: "70px"}}>
                                    Salvesta
                                </Button>
                            }
                        </div>

                        </>
                ),
                style: {
                    width: "350px"
                } as React.CSSProperties
            }
        ];

        return columns.map((column: ColumnDescription) => {
            column.headerFormatter = headerFormatter;
            return column;
        })
    }

    render() {
        return (
            <>
                <div className="block block-white width-100">
                    <div className="d-flex align-items-center justify-content-between title-border-bottom">
                        <div className="d-flex align-items-center">
                          <span className="block-title block-title-btn position-relative w-auto">
                            Kasutuslepingud
                          </span>
                        </div>
                    </div>

                    <div className="block-collapsible-content open">
                        <ToolkitProvider
                            keyField="id"
                            columns={this.getColumns()}
                            data={this.state.rows}
                            search={true}
                        >
                            { (props: ToolkitContextType) => (
                                <>
                                    <div className="datatable">
                                        <div className="table-responsive-lg">
                                            <BootstrapTable
                                                {...props.baseProps}
                                                noDataIndication="Kirjeid ei leitud"
                                                classes="table-condensed-medium"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
});

export default connect(mapStateToProps)(MainLeaseTable);
import React from "react";
import { ContractRow } from "../../ContractRow";

export interface ContractRowFieldProps<T extends ContractRow> {
  row: T; // pure component's shallow comparison cannot detect changes here
  field: any; // provide additional direct value to force change detection
  fieldKey: keyof T;
  editMode: boolean;
  editable?: boolean;

  onChange?: (row: T, fieldKey: keyof T, value: any) => void;
}

export abstract class ContractRowField<T extends ContractRow,
      P extends ContractRowFieldProps<T> = ContractRowFieldProps<T>, S = {}>
    extends React.PureComponent<P, S> {

  constructor(props: P) {
    super(props);
  }

  protected abstract renderEditableField(): JSX.Element;

  protected getFieldValue(): any {
    return this.props.field ? this.props.field : this.props.row[this.props.fieldKey];
  }

  protected renderReadOnlyField(): JSX.Element {
    return (
        <span className="font-weight-bold">
          {this.getFieldValue()}
        </span>
    )
  }

  render() {
    return (
        <>
          {this.props.editMode && this.renderEditableField()}
          {!this.props.editMode && this.renderReadOnlyField()}
        </>
    )
  }
}
import * as React from "react";
import {ContractFileMeta, ContractFileType, ContractType, FullContract, getManagePermission,} from "../../../Contract";
import Secured from "../../../../Auth/Secured";
import {RootState} from "../../../../rootReducer";
import {getBaseURL} from "../../../../Config/configReducer";
import {connect} from "react-redux";
import {Classifier} from "../../../../Classifier/Classifier";
import {getClassifiers} from "../../../../Classifier/classifierReducer";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Button, UncontrolledTooltip} from 'reactstrap';
import {BodyPortal} from "../../../../Common/BodyPortal";
import {getBadgeColor, translateType} from "./ContractFiles";
import {NumberInput} from "../../../../Common/NumberInput";


interface Props {
    baseURL: string;
    classifiers: Classifier[];
    contract: FullContract;
    file: ContractFileMeta;
    files: ContractFileMeta[];
    editingFile?: ContractFileMeta;
    onToggleEdit: (editingFile?: ContractFileMeta) => void;
    onSaveFile: (file: ContractFileMeta) => void;
    onDeleteFile: (file: ContractFileMeta) => void;
    showDeleteButton: boolean;
}

interface State {
    editingFile?: ContractFileMeta;
}

class ContractFile extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if ((!prevProps.editingFile && this.props.editingFile) || (prevProps.editingFile && !this.props.editingFile)
            || (prevProps.editingFile && this.props.editingFile && prevProps.editingFile.id !== this.props.editingFile.id)) {
            this.setState({
                editingFile: this.props.editingFile
            });
        }
    }

    render() {
        return (
            <>
                {this.renderFileViewTable()}
            </>
        );
    }

    private renderFileViewTable() {
        const editMode = !!this.state.editingFile;
        const cellClass = editMode ? "p-2 align-top" : "p-2 align-middle";
        const containsContract = this.props.files.filter(file => file.fileType == ContractFileType.Contract).length > 0;
        return (
            <tr className={editMode ? "bg-light-gray-2" : ""}>
                <td className={cellClass}>
                    {editMode &&
                    <div className={"d-flex flex-wrap"}>
                        {this.state.editingFile!.fileType == null &&
                            <>{this.renderTypeButton(null)}</>
                        }
                        {this.state.editingFile!.fileType != null &&
                            <>{this.renderTypeButton(ContractFileType.Undefined)}</>
                        }
                        {this.renderTypeButton(ContractFileType.Contract, this.state.editingFile!.fileType == ContractFileType.Contract ? false : containsContract)}
                        {this.renderTypeButton(ContractFileType.Change)}
                        {this.renderTypeButton(ContractFileType.Act)}
                        {this.renderTypeButton(ContractFileType.Addition)}
                        {this.props.contract.type == ContractType.LeaseAgreement &&
                        this.renderTypeButton(ContractFileType.Notice)}
                        {this.props.contract.type == ContractType.LeaseAgreement &&
                        this.renderTypeButton(ContractFileType.Ending)}
                    </div>
                    }
                    {!editMode &&
                    <span className={`badge badge-${getBadgeColor(this.props.file.fileType)}`}>
                            {translateType(this.props.file.fileType, this.props.classifiers)}
                        </span>
                    }
                </td>
                <td className={cellClass}>
                    {editMode &&
                    <DatePicker
                        className="form-control calendar-input"
                        selected={new Date(this.state.editingFile!.startDate)}
                        placeholderText="Vali kuupäev"
                        dateFormat="dd.MM.yyyy"
                        onChange={this.handleDateChange("startDate")}
                        popperContainer={BodyPortal}
                        popperPlacement={"top"}
                        popperModifiers={{
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                                padding: 25
                            }
                        }}/>
                    }
                    {(!editMode && this.props.file.startDate) &&
                    <span className="d-block">
                            {moment(this.props.file.startDate).format("DD.MM.YYYY")}
                        </span>
                    }
                </td>
                <td className={cellClass}>
                    {editMode &&
                    <input className="form-control"
                           value={this.state.editingFile!.fileNumber}
                           onChange={this.handleInputChange("fileNumber")}/>
                    }
                    {!editMode &&
                    <span className="d-block">{this.props.file.fileNumber || "-"}</span>
                    }
                </td>
                <td className={cellClass}>
                    {editMode &&
                    <input className="form-control"
                           value={this.state.editingFile!.description}
                           onChange={this.handleInputChange("description")}/>
                    }
                    {!editMode &&
                    <span className="d-block font-16">{this.props.file.description || "-"}</span>
                    }
                    <button id={"download-" + this.props.file.id.toString()}
                            className="btn btn-link p-0 text-left"
                            onClick={this.download}>
                        {this.props.file.fileName}
                    </button>
                    <UncontrolledTooltip target={"download-" + this.props.file.id.toString()} fade={false}>
                        Laadi alla
                    </UncontrolledTooltip>
                </td>
                {this.props.contract.type == ContractType.LeaseAgreement && (
                    <td className={cellClass}>
                        {editMode &&
                        <NumberInput value={this.state.editingFile!.area}
                                     onNumberChange={value => this.handleChange("area", value)}/>
                        }
                        {!editMode &&
                        <span
                            className="d-block">{this.props.file.area != undefined ? [this.props.file.area, <>m<sup>2</sup></>] : '-'}</span>
                        }
                    </td>
                )}
                <td className={cellClass}>
                    <Secured permission={getManagePermission(this.props.contract.type)}>
                        {!editMode &&
                        <button type="button"
                                onClick={() => this.props.onToggleEdit(this.props.file)}
                                className="close mr-2 mt-2 mb-2">
                            <span className="submenu-hover submenu-hover-position"/>
                            <span className="icon pencil"/>
                        </button>
                        }
                        {editMode &&
                        <div>
                            <Button className="btn btn-secondary"
                                    onClick={() => this.props.onSaveFile(this.state.editingFile!)}>
                                Salvesta
                            </Button>
                            <Button className="btn btn-light border-light-gray-1px mt-3"
                                    onClick={() => this.props.onToggleEdit(this.props.file)}>
                                Katkesta
                            </Button>
                        </div>
                        }
                    </Secured>
                </td>
                {this.props.showDeleteButton &&
                <td className={cellClass}>
                    <Secured permission={getManagePermission(this.props.contract.type)}>
                        <button type="button" id={"delete-file-" + this.props.file.id}
                                onClick={() => this.props.onDeleteFile(this.props.file)}
                                className="close mr-2 mt-2 mb-2">
                            <span className="submenu-hover submenu-hover-position"/>
                            <span className="icon trash icon-bg-danger"/>
                        </button>
                        <UncontrolledTooltip target={"delete-file-" + this.props.file.id} fade={false}>
                            Kustuta fail
                        </UncontrolledTooltip>
                    </Secured>
                </td>
                }
            </tr>
        );
    }

    private renderTypeButton(type: ContractFileType | null, disabled: boolean = false): JSX.Element {
        return (
            <Button color="badge"
                    className="p-0 mb-2"
                    disabled={ disabled }
                    onClick={() => this.handleChange('fileType', type)}
            >
              <span className={`badge cursor-pointer
                    badge-${type != this.state.editingFile!.fileType ? 'outline-' : ''}${getBadgeColor(type)}`}
                    style={{opacity: disabled ? 0.25 : 1.0}}>
                  {translateType(type, this.props.classifiers)}
              </span>
            </Button>
        );
    }

    private handleInputChange = (field: keyof ContractFileMeta) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const target = event.target as HTMLInputElement;
            let value: string | number | {}[] = target.value;

            this.handleChange(field, value);
        };

    private handleDateChange = (field: keyof ContractFileMeta) => (value: Date) => {
        this.handleChange(field, value ? value.getTime() : new Date().getTime());
    };

    private handleChange = (field: keyof ContractFileMeta, value: any) => {
        if (this.state.editingFile) {
            this.setState({
                editingFile: {
                    ...this.state.editingFile,
                    [field]: value
                }
            });
        }
    };

    private download = () => {
        window.open(`${this.props.baseURL}/contracts/${this.props.contract.id}/files/${this.props.file.id}`);
    };
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state)
});

export default connect(mapStateToProps)(ContractFile);
import * as React from "react";
import {NavLink} from "react-router-dom";
import {Permission} from "../Auth/User";
import Secured from "../Auth/Secured";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {CustomModal} from "../Modal/CustomModal";
import {SyncData} from "../Sync/SyncData";
import axios from "axios";
import store from "../store";
import { store as notificationStore } from 'react-notifications-component';
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import {connect} from "react-redux";
import {alertActions} from "../Alerts/alertActions";
import {Alert, AlertContentType} from "../Alerts/Alert";
import moment from "moment";

interface State {
    syncOpen: boolean;
    modalToggled: boolean;
    lastUserUpdate: string | null;
    lastPropertyUpdate: string | null;
    lastConstructionContractUpdate: string | null;
}

interface Props {
    addAlert: typeof alertActions.addAlert;
}

enum SyncType {
    propertySync = "PROPERTY_REGISTRY_SYNC",
    userSync = "USER_REGISTRY_SYNC",
    constructionContractSync = "CONSTRUCTION_CONTRACT_SYNC",
}

class NavigationHeader extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            syncOpen: false,
            modalToggled: false,
            lastUserUpdate: null,
            lastPropertyUpdate: null,
            lastConstructionContractUpdate: null
        };
        this.updateProperties = this.updateProperties.bind(this);
        this.updateUsers = this.updateUsers.bind(this);
        this.updateConstructionContracts = this.updateConstructionContracts.bind(this);
    };

    private toggleOptions = () => {
        this.setState({
            syncOpen: !this.state.syncOpen
        });
    };

    private toggleModal = (event: React.MouseEvent) => {
        event.preventDefault();

        if (!this.state.modalToggled) {
            axios.get(store.getState().config.baseURL + "/activities/" + SyncType.propertySync.valueOf() + "/last")
                .then(res => {
                    const lastPropertySync = moment(res.data.finishedAt).format("DD.MM.YYYY HH:mm:ss");
                    axios.get(store.getState().config.baseURL + "/activities/" + SyncType.userSync.valueOf() + "/last")
                        .then(res => {
                            const lastUserSync = moment(res.data.finishedAt).format("DD.MM.YYYY HH:mm:ss");
                            axios.get(store.getState().config.baseURL + "/activities/" + SyncType.constructionContractSync.valueOf() + "/last")
                                .then(res => {
                                    this.setState({
                                        modalToggled: !this.state.modalToggled,
                                        syncOpen: false,
                                        lastPropertyUpdate: lastPropertySync,
                                        lastUserUpdate: lastUserSync,
                                        lastConstructionContractUpdate: moment(res.data.finishedAt).format("DD.MM.YYYY HH:mm:ss")
                                    })
                                })
                        });
                });
        }
        else {
            this.setState({
                modalToggled: false,
                syncOpen: false
            })
        }
    };

    updateProperties(date: any) {
        const formattedDate = date ? moment(date, 'yyyy-MM-DD').format('yyyy-MM-DDTHH:mm:ss') : null;
        let that = this;
        axios.post(store.getState().config.baseURL + "/propertyregistry-sync" + (formattedDate ? "?updatedAfter=" + formattedDate : ""))
            .then(res => {
                const errors = res.data.errors;

                if (!errors || errors && errors.length === 0) {
                    this.renderSyncStartedAlert(SyncType.propertySync);
                } else {
                   this.renderSyncFailedAlert(SyncType.propertySync);
                }
            });
    }

    updateUsers(date: any) {
        const formattedDate = date ? moment(date, 'yyyy-MM-DD').format('yyyy-MM-DDTHH:mm:ss') : null;
        let that = this;
        axios.post(store.getState().config.baseURL + "/user-registry-sync" + (formattedDate ? "?updatedAfter=" + formattedDate : ""))
            .then(res => {
                const errors = res.data.errors;

                if (!errors || errors && errors.length === 0) {
                    this.renderSyncStartedAlert(SyncType.userSync);
                } else {
                    this.renderSyncFailedAlert(SyncType.userSync);
                }
            });
    }

    updateConstructionContracts(date: any) {
        const formattedDate = date ? moment(date, 'yyyy-MM-DD').format('yyyy-MM-DDTHH:mm:ss') : null;
        let that = this;
        axios.post(store.getState().config.baseURL + "/contracts/sync-contracts-with-unwarranted-projects" + (formattedDate ? "?updatedAfter=" + formattedDate : ""))
            .then(res => {
                const errors = res.data.errors;

                if (!errors || errors && errors.length === 0) {
                    this.renderSyncStartedAlert(SyncType.constructionContractSync);
                } else {
                    this.renderSyncFailedAlert(SyncType.constructionContractSync);
                }
            });
    }

   private renderSyncStartedAlert = (type: SyncType) => {
       let message;
       switch(type) {
           case SyncType.propertySync:
               message = AlertContentType.PropertySyncStarted;
               break;
           case SyncType.userSync:
               message = AlertContentType.UserSyncStarted;
               break;
           case SyncType.constructionContractSync:
               message = AlertContentType.ConstructionContractSyncStarted;
               break;
       }
       notificationStore.addNotification({
           message: Alert.translateAlertContent(message),
           type: 'default',                         // 'default', 'success', 'info', 'warning'
           container: 'bottom-left',                // where to position the notifications
           animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
           animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
           dismiss: {
               duration: 3000
           }
       });
    };

    private renderSyncFailedAlert = (type: SyncType) => {
        let message;
        switch(type) {
            case SyncType.propertySync:
                message = AlertContentType.PropertySyncFailed;
                break;
            case SyncType.userSync:
                message = AlertContentType.UserSyncFailed;
                break;
            case SyncType.constructionContractSync:
                message = AlertContentType.ConstructionContractSyncStarted;
                break;
        }

        notificationStore.addNotification({
            message: Alert.translateAlertContent(message),
            type: 'warning',                         // 'default', 'success', 'info', 'warning'
            container: 'bottom-left',                // where to position the notifications
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
            dismiss: {
                duration: 3000
            }
        });
    };

    render() {

        const currentPath = window.location.pathname;
        let activeSection = 'contracts';
        if(currentPath.includes('reports'))
            activeSection = 'reports';

        return (
            <div>
                <CustomModal visible={this.state.modalToggled}
                             dismissable={true}
                             onDismiss={this.toggleModal}
                             header={"Sünkimine"}>
                    <div className="modal-body">

                        <SyncData type={"Kinnistute"} lastUpdate={this.state.lastPropertyUpdate} update={this.updateProperties}/>
                        <hr />
                        <SyncData type={"Kasutajate"} lastUpdate={this.state.lastUserUpdate} update={this.updateUsers}/>
                        <hr />
                        <SyncData type={"Ehituslepingute"} lastUpdate={this.state.lastConstructionContractUpdate} update={this.updateConstructionContracts}/>
                    </div>
                </CustomModal>
                <nav className="navbar navbar-general-page">
                    {activeSection == 'contracts' && this.renderContractsNavItems()}
                    {activeSection == 'reports' && this.renderReportsNavItems()}

                    <Dropdown className="submenu submenu-position-in-nav" isOpen={this.state.syncOpen} toggle={this.toggleOptions}>
                        <div className="submenu-hover" />
                        <DropdownToggle color="link">
                            <span className="icon icon_more-info-primary" />
                        </DropdownToggle>
                        <DropdownMenu id="dropdown-alignment" tag="ul" className="dropdown-menu dropdown-menu-right" style={{marginRight: "20px"}}>
                            <li role="menuitem" onClick={this.toggleModal}>
                                <a className="dropdown-item">
                                    <i className="fas fa-sync-alt" />
                                    Sünkimine
                                </a>
                            </li>
                        </DropdownMenu>
                    </Dropdown>
                </nav>
            </div>
        );
    }

    renderContractsNavItems() {
        return (
            <ul>
                <Secured permission={Permission.AccessServiceContract}>
                    <li className={window.location.pathname == '/service' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/service'>Korrashoiuteenuste lepingud</NavLink>
                    </li>
                </Secured>
                <Secured permission={Permission.AccessConstructionContract}>
                    <li className={window.location.pathname == '/construction' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/construction'>Ehituslepingud</NavLink>
                    </li>
                </Secured>
                <Secured permission={Permission.AccessSupportServiceContract}>
                    <li className={window.location.pathname == '/support-service' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/support-service'>Tugiteenuslepingud</NavLink>
                    </li>
                </Secured>
                <Secured permission={Permission.AccessLeaseAgreement}>
                    <li className={window.location.pathname == '/lease' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/lease'>Kasutuslepingud</NavLink>
                    </li>
                </Secured>
                <Secured permission={Permission.AccessEnergySaleAgreement}>
                    <li className={window.location.pathname == '/energy' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/energy'>Energiavahenduslepingud</NavLink>
                    </li>
                </Secured>
                <Secured permission={Permission.AccessConsumptionContract}>
                    <li className={window.location.pathname == '/consumption' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/consumption'>Tarbimislepingud</NavLink>
                    </li>
                </Secured>
                <Secured permission={Permission.AccessMainLease}>
                    <li className={window.location.pathname == '/main-leases' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/main-leases'>Peaüürid</NavLink>
                    </li>
                </Secured>
            </ul>
        );
    }

    renderReportsNavItems() {
        return (
            <ul>
                <Secured permission={Permission.AccessServiceContract}>
                    <li className={window.location.pathname == '/reports/service-contracts' ? "nav-item active" : "nav-item"}>
                        <NavLink className="nav-link" to='/reports/service-contracts'>Korrashoiuteenuste XLSX väljavõte</NavLink>
                    </li>
                </Secured>
            </ul>
        );
    }
}

const mapDispatchToProps = {
    addAlert: alertActions.addAlert
};

export default connect(undefined, mapDispatchToProps)(NavigationHeader);

import React from "react";
import {FullConstructionContract, getContractRows, ReserveCalculationBasedOn} from "../../Contract";
import {Classifier, ClassifierCode} from "../../../Classifier/Classifier";
import {RootState} from "../../../rootReducer";
import {getClassifiers} from "../../../Classifier/classifierReducer";
import {connect} from "react-redux";
import TextField from "../ContractInfo/Fields/TextField";
import ClassifierField from "../ContractInfo/Fields/ClassifierField";
import TextAreaField from "../ContractInfo/Fields/TextAreaField";
import PersonField from "../ContractInfo/Fields/PersonField";
import DateField from "../ContractInfo/Fields/DateField";
import {getBaseURL, getRkasCompanyRegNo, getSapEnabled} from "../../../Config/configReducer";
import CompanyField from "../ContractInfo/Fields/CompanyField";
import CheckboxField from "../ContractInfo/Fields/CheckboxField";
import NumberField from "../ContractInfo/Fields/NumberField";
import ReserveField from "../ContractInfo/Fields/ReserveField";
import {ContractRow, RowType, ServiceContractRow} from "../ContractRow";
import {calculateSumOfObjectReserves, calculateValueOfContract} from "../ContractDetails";
import {modalActions} from "../../../Modal/modalActions";
import {ContactInfoModalData, ModalType} from "../../../Modal/Modal";
import ProjectField from "../ContractInfo/Fields/ProjectField";
import {NumberUtils} from "../../../Common/NumberUtils";
import PatternedTextField from "../ContractInfo/Fields/PatternedTextField";

interface Props {
    classifiers: Classifier[]
    baseURL: string;
    contract: FullConstructionContract;
    editingContract?: FullConstructionContract;
    editMode: boolean;
    updateEditingContract: (contract: FullConstructionContract) => void;
    filterType: string;
    rkasCompanyRegNo: string;
    sapEnabled: boolean;

    toggleModal: typeof modalActions.toggleModal;
}

class ConstructionInfo extends React.Component<Props, any> {

    private onChange = (fieldKey: keyof FullConstructionContract, value: any) => {
        if (this.props.editingContract) {
            this.props.updateEditingContract({
                ...this.props.editingContract,
                [fieldKey]: value
            });
        }
    };

    render() {
        const contract = this.props.editingContract || this.props.contract;

        return (
            <div className="row">
                <div className={this.props.editMode
                    ? "col-12 col-lg-5 col-xl-5"
                    : "col-12 col-md-6"}>
                    <>
                        <TextField contract={contract}
                                   label={"Lepingu number"}
                                   fieldKey={"number"}
                                   editMode={this.props.editMode}
                                   editable={false}
                                   onChange={this.onChange}
                        />
                        <TextField contract={contract}
                                   label={"Pealkiri"}
                                   fieldKey={"generalDocumentTitle"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <PatternedTextField contract={contract}
                                            label={"RHR number"}
                                            fieldKey={"rhrReferenceNumber"}
                                            replacementPattern={/\s/g}
                                            validationPattern={/^\s*(?:[\w\.]\s*){0,15}$/}
                                            editMode={this.props.editMode}
                                            editable={this.props.editMode}
                                            onChange={this.onChange}
                        />
                        <ProjectField contract={contract}
                                   label={"Projektid"}
                                   baseURL={this.props.baseURL}
                                   fieldKey={"projects"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <CompanyField contract={contract}
                                     label={"Tellija"}
                                     fieldKey={"customer"}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     onChange={this.onChange}
                        />
                        <PersonField contract={contract}
                                     label={"Tellija kontaktisik"}
                                     fieldKey={"customerRepresentative"}
                                     company={contract.customer}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     isClearable={true}
                                     required={true}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <CompanyField contract={contract}
                                      label={"Peatöövõtja"}
                                      fieldKey={"contractor"}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      onChange={this.onChange}
                        />
                        <PersonField contract={contract}
                                     label={"Peatöövõtja kontaktisik"}
                                     fieldKey={"contractorRepresentative"}
                                     company={contract.contractor}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     isClearable={true}
                                     required={true}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <CompanyField contract={contract}
                                      label={"Volitaja"}
                                      fieldKey={"authorizer"}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      isClearable={true}
                                      onChange={this.onChange}
                        />
                        <PersonField contract={contract}
                                     label={"Volitaja kontaktisik"}
                                     fieldKey={"authorizerRepresentative"}
                                     company={contract.authorizer}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     isClearable={true}
                                     required={true}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <CompanyField contract={contract}
                                      label={"Omaniku järelvalve"}
                                      fieldKey={"owner"}
                                      isClearable={true}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      onChange={this.onChange}
                        />
                        <PersonField contract={contract}
                                     label={"Omaniku järelvalve kontaktisik"}
                                     fieldKey={"ownerRepresentative"}
                                     company={contract.owner}
                                     roleCode={"portal-contractor-owner-supervisor"}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     isClearable={true}
                                     required={!!contract.owner}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <TextField contract={contract}
                                    label={"Raamleping"}
                                    fieldKey={"frameContractReference"}
                                    editMode={this.props.editMode}
                                    editable={this.props.editMode}
                                    onChange={this.onChange}
                        />
                        <TextField contract={contract}
                                   label={"Hange"}
                                   fieldKey={"procurementReference"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Algus"}
                                   fieldKey={"startTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Lõpp"}
                                   fieldKey={"endTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        {(!this.props.editMode || !this.props.editingContract.projects || this.props.editingContract.projects.length == 0) &&
                            <DateField contract={contract}
                                       label={"Garantii lõpp"}
                                       fieldKey={"warrantyEndDate"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                            />
                        }
                    </>
                </div>
                <div className={this.props.editMode
                    ? "col-12 col-lg-6 col-xl-5 offset-md-0 offset-lg-1 offset-xl-2 mt-3 mt-lg-0"
                    : "col-12 col-md-6"}>
                    <>
                        {this.renderInvoiceDueDays()}
                        <DateField contract={contract}
                                   label={"Sõlmitud"}
                                   fieldKey={"enterTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Muudetud"}
                                   fieldKey={"changedOn"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        <CheckboxField contract={contract}
                                       label={"Akteeritav leping"}
                                       checkboxLabel={"on akteeritav"}
                                       fieldKey={"isActable"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                        <ClassifierField contract={contract}
                                         label={"Akteerimise sagedus"}
                                         fieldKey={"actFrequency"}
                                         classifiers={this.props.classifiers}
                                         classifierCode={ClassifierCode.ActFrequency}
                                         editMode={this.props.editMode}
                                         editable={this.props.editMode}
                                         onChange={this.onChange}
                        />
                        <CheckboxField contract={contract}
                                       label={"Indekseeritav leping"}
                                       checkboxLabel={"on indekseeritav"}
                                       fieldKey={"isIndexable"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                        {this.renderWorkConfirmationDeadline()}
                        {this.renderCustomerWorkConfirmationDeadline()}
                        <NumberField contract={contract}
                                     label={"Lepingu summa"}
                                     fieldKey={"sum"}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     onChange={this.onChange}
                                     minimumFractionDigits={2}
                                     suffix={" €"}
                        />
                        {!this.props.editMode &&
                        <div className={"form-row custom-form-row mt-md-0 mb-md-2 mb-lg-0"}>
                            <div className={"col-12 align-items-start"}>
                                <label className="w-200">Ridade summa</label>
                                <span className="font-weight-bold">{this.calculateSumOfRows(contract)}</span>
                            </div>
                        </div>
                        }
                        {this.renderReserveField(contract)}
                        {!this.props.editMode &&
                            <>
                                <div className={"form-row custom-form-row mt-md-0 mb-md-2 mb-lg-0"}>
                                    <div className={"col-12 align-items-start"}>
                                        <label className="w-200">Kinnistute ja projektide reserv</label>
                                        <span className="font-weight-bold">{calculateSumOfObjectReserves(contract)}</span>
                                    </div>
                                </div>
                                <div className={"form-row custom-form-row mt-md-0 mb-md-2 mb-lg-0"}>
                                    <div className={"col-12 align-items-start"}>
                                        <label className="w-200">Lepingu väärtus</label>
                                        <span className="font-weight-bold">{calculateValueOfContract(contract)}</span>
                                    </div>
                                </div>
                            </>
                        }
                        {this.props.sapEnabled &&
                            <TextField contract={contract}
                                       label={"SAP kood"}
                                       fieldKey={"sapCode"}
                                       editMode={this.props.editMode}
                                       editable={false}
                                       onChange={this.onChange}
                            />
                        }
                        <TextField contract={contract}
                                   label={"Sisu"}
                                   fieldKey={"content"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        <TextAreaField contract={contract}
                                       label={"Märkused"}
                                       fieldKey={"note"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                    </>
                </div>
            </div>
        );
    }

    private renderInvoiceDueDays(): JSX.Element {
        return (
            <>
                {this.props.editMode &&
                <NumberField contract={this.props.editingContract || this.props.contract}
                             label={"Maksetähtaeg (päevades)"}
                             fieldKey={"paymentTerm"}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
                />
                }

                {!this.props.editMode &&
                <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Maksetähtaeg"}</label>
                        {this.props.contract.paymentTerm &&
                        <>
                            <span className="font-weight-bold">{this.props.contract.paymentTerm}</span>
                            <span className="font-weight-bold">&nbsp;{this.props.contract.paymentTerm == "1" ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!this.props.contract.paymentTerm &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private renderCustomerWorkConfirmationDeadline(): JSX.Element {
        return (
            <>
                {this.props.editMode &&
                <NumberField contract={this.props.editingContract || this.props.contract}
                             label={"Tellija tööde kinnitamise tähtaeg"}
                             fieldKey={"customerWorkConfirmationDeadline"}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
                />
                }

                {!this.props.editMode &&
                <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Tellija tööde kinnitamise tähtaeg"}</label>
                        {this.props.contract.customerWorkConfirmationDeadline != null &&
                        <>
                            <span className="font-weight-bold">{this.props.contract.customerWorkConfirmationDeadline}</span>
                            <span className="font-weight-bold">&nbsp;{this.props.contract.customerWorkConfirmationDeadline == 1 ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!this.props.contract.customerWorkConfirmationDeadline == null &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private renderWorkConfirmationDeadline(): JSX.Element {
        return (
            <>
                {this.props.editMode &&
                <NumberField contract={this.props.editingContract || this.props.contract}
                             label={"Tööde kinnitamise tähtaeg"}
                             fieldKey={"workConfirmationDeadline"}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
                />
                }

                {!this.props.editMode &&
                <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Tööde kinnitamise tähtaeg"}</label>
                        {this.props.contract.workConfirmationDeadline != null &&
                        <>
                            <span className="font-weight-bold">{this.props.contract.workConfirmationDeadline}</span>
                            <span className="font-weight-bold">&nbsp;{this.props.contract.workConfirmationDeadline == 1 ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!this.props.contract.workConfirmationDeadline == null &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private renderReserveField(contract: FullConstructionContract) : JSX.Element {
        if(this.props.editMode){
            return (
                <div className={"form-row custom-form-row"}>
                    <div className={"col-12 align-items-start"}>
                        <label className="w-200">Reserv<span className="input-required" /></label>
                        {contract.reserveCalculationBasedOn == ReserveCalculationBasedOn.Value &&
                            <ReserveField contract={contract}
                                          label={""}
                                          reserveCalculationBasedOn={contract.reserveCalculationBasedOn}
                                          reservePercentage={contract.reservePercentage}
                                          fieldKey={"reserveValue"}
                                          editMode={this.props.editMode}
                                          editable={this.props.editMode}
                                          onChange={this.onChange}
                                          skipLabel={true}
                                          skipFormRowClasses={true}
                            />
                        }
                        {contract.reserveCalculationBasedOn == ReserveCalculationBasedOn.Percent &&
                            <ReserveField contract={contract}
                                          label={""}
                                          reserveCalculationBasedOn={contract.reserveCalculationBasedOn}
                                          reservePercentage={contract.reservePercentage}
                                          fieldKey={"reservePercentage"}
                                          editMode={this.props.editMode}
                                          editable={this.props.editMode}
                                          onChange={this.onChange}
                                          skipLabel={true}
                                          skipFormRowClasses={true}
                            />
                        }
                        <div style={{flexGrow:1}}>
                            <ClassifierField contract={contract}
                                             label={""}
                                             fieldKey={"reserveCalculationBasedOn"}
                                             classifiers={this.props.classifiers}
                                             classifierCode={ClassifierCode.ReserveCalculationBasedOn}
                                             editMode={this.props.editMode}
                                             editable={this.props.editMode}
                                             onChange={this.onChange}
                                             skipLabel={true}
                                             skipFormRowClasses={true}
                            />
                        </div>
                    </div>
                </div>);
        }
        else {
            return (
                <ReserveField contract={contract}
                              label={"Reserv"}
                              reserveCalculationBasedOn={contract.reserveCalculationBasedOn}
                              reservePercentage={contract.reservePercentage}
                              fieldKey={"reserveValue"}
                              editMode={this.props.editMode}
                              editable={this.props.editMode}
                              onChange={this.onChange}
                />
            );
        }
    }

    private calculateSumOfRows = (contract: FullConstructionContract) => {
        let sum = 0;
        let rows: ContractRow[] = getContractRows(contract);
        rows.forEach((row: ServiceContractRow) => {
            if (row.deletedOn === null && row.rowPrice !== null && ![RowType.MonthlyReserve,RowType.IndexableSum].some(t => row.type === t)) {
                sum = sum + row.rowPrice!;
            }
        });

        let formattedSum = NumberUtils.format(sum,2,2).replace(".", ",");

        return sum > 0 ? (formattedSum + " €") : "";
    };
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    rkasCompanyRegNo: getRkasCompanyRegNo(state),
    sapEnabled: getSapEnabled(state)
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps,mapDispatchToProps)(ConstructionInfo);

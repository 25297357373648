import * as React from "react";
import { connect } from "react-redux";
import { hasPermission, Permission, User } from "./User";
import { getCurrentUser } from "./authReducer";
import { RootState } from "../rootReducer";
import { PropsWithChildren } from 'react';

interface Props {
  permission: Permission|Permission[];
  currentUser: User;
}

class Secured extends React.Component<PropsWithChildren<Props>> {

  render() {
    if ((Array.isArray(this.props.permission)
          && this.props.permission.some(p => hasPermission(this.props.currentUser, p)))
        || (typeof this.props.permission === "string"
            && hasPermission(this.props.currentUser, this.props.permission as Permission))) {
      return this.props.children;
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  currentUser: getCurrentUser(state)
});

export default connect(mapStateToProps)(Secured);
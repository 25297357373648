import React from "react";
import 'requestidlecallback-polyfill';

export function DeferredRender({ children, idleTimeout }) {
    const [render, setRender] = React.useState(false);

    React.useEffect(() => {
        if (render) setRender(false);
        // @ts-ignore
        const id = window.requestIdleCallback(() => setRender(true), { timeout: idleTimeout });

        // @ts-ignore
        return () => window.cancelIdleCallback(id);
    }, [idleTimeout]);

    if (!render) return null;

    return children;
}
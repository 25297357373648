import React from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {ModalData} from "../Modal";


interface Props {
    modalOpen: boolean;
    modalData: ModalData;
    onToggleModal: () => void;
}

export default class SimpleTextModal extends React.Component<Props, any> {

    render() {
        return (
            <Modal isOpen={this.props.modalOpen} toggle={this.props.onToggleModal}>
                <ModalHeader toggle={this.props.onToggleModal}>Modal title</ModalHeader>
                <ModalBody className="text-center pb-5">
                    {this.props.modalData &&
                        <span>{this.props.modalData.text}</span>
                    }
                </ModalBody>
            </Modal>
        )
    }
}
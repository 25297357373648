import * as React from 'react';
import {
    ContractFileMeta,
    ContractFileType,
    ContractType,
    FullContract,
    FullLeaseContract,
    getManagePermission
} from "../../../Contract";
import axios from 'axios';
import {RootState} from '../../../../rootReducer';
import {getBaseURL} from '../../../../Config/configReducer';
import {connect} from 'react-redux';
import ContractFile from './ContractFile';
import Secured from "../../../../Auth/Secured";
import Dropzone from 'react-dropzone';
import {alertActions} from '../../../../Alerts/alertActions';
import {Alert, AlertContentType, AlertType} from '../../../../Alerts/Alert';
import {Button} from "reactstrap";
import {Classifier, ClassifierCode, getClassifierValueName} from "../../../../Classifier/Classifier";
import { getClassifiers } from "../../../../Classifier/classifierReducer";
import Bootbox from "../../../../../assets/lib/bootbox-react";

interface Props {
    baseURL: string;
    classifiers: Classifier[];

    addAlert: typeof alertActions.addAlert;
    open: boolean;
    contract: FullContract;
    editingContract: FullContract;
    onFileCountChanged: (newCount) => void;
    updateEditingContract: (contract: FullContract, callback?: () => void) => void;
    onSaveContract: () => void;
    onUnpublishContract: (toggleModal: boolean) => void;
    onPublishContract: (toggleModal: boolean) => void;
}

interface State {
    editingFile?: ContractFileMeta;
    files?: ContractFileMeta[];
    filteredTypes: ContractFileType[];
    searchValue: string;
    showFileDelete: boolean;
    showEmptyAreaModal: boolean;
    showMismatchedAreaModal: boolean;
    pendingFile: ContractFileMeta;
}

class ContractFiles extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            filteredTypes: Object.values(ContractFileType),
            searchValue: "",
            showFileDelete: false,
            showEmptyAreaModal: false,
            showMismatchedAreaModal: false,
            pendingFile: null
        };
    }

    componentDidMount(): void {
        this.getFiles();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.contract.id !== this.props.contract.id) {
            this.getFiles();
        }
    }

    render() {
        return (
            <>
                <Bootbox show={this.state.showEmptyAreaModal}
                         type={"confirm"}
                         message={"Üüripind on määramata. Kas soovid sisestada väärtuse?"}
                         successLabel={"Ei"}
                         cancelLabel={"Jah"}
                         successClassNames={"btn-primary"}
                         cancelClassNames={"btn-secondary"}
                         onSuccess={() => this.confirmFileSave()}
                         onCancel={() => this.cancelFileSave()}
                         onClose={() => this.cancelFileSave()}
                />
                <Bootbox show={this.state.showMismatchedAreaModal}
                         type={"confirm"}
                         message={`Üüripind on erinev lepingu üüripinnast. Jätkamisel kirjutatakse lepingu pinna väärtus üle sisestatud väärtusega! ${this.props.editingContract.publishedOn ? "<br> <span style='color: #FF0048'>NB! Leping on avaldatud, pinna muutmisel leping taasavaldatakse.</span>":""}`}
                         successLabel={"Salvesta"}
                         cancelLabel={"Katkesta"}
                         successClassNames={"btn-danger"}
                         cancelClassNames={"btn-primary"}
                         onSuccess={() => this.confirmFileSave()}
                         onCancel={() => this.cancelFileSave()}
                         onClose={() => this.cancelFileSave()}
                />
                <div className="d-flex justify-content-between bg-light-gray-2 pl-2 pr-2 pt-2 pb-2">
                    <div className="d-none d-lg-flex">
                        <div className="form-control-with-search-icon mr-3" style={{maxWidth: "300px", width: "250px"}}>
                            <input type="text"
                                   placeholder="Otsi numbri/nime/kirjelduse järgi"
                                   className="form-control bg-white"
                                   value={this.state.searchValue}
                                   onChange={(e) => this.setState({searchValue: e.target.value})}/>
                            <span className="icon icon_search icon-sm icon-gray"/>
                        </div>
                        <div className="d-flex flex-wrap">
                            {this.renderFilterButtons()}
                            {this.state.filteredTypes.length != Object.values(ContractFileType).length &&
                            <button className="btn btn-link text-dark"
                                    onClick={() => this.setState({filteredTypes: Object.values(ContractFileType)})}>
                                Tühista filtrid
                            </button>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center mt-2 mb-2 ml-auto">
                        <div className="switch pr-2">
                            <input type="checkbox" id="delete-switch"
                                   className="switch-input"
                                   checked={!!this.state.showFileDelete}
                                   onChange={e => this.setState({showFileDelete: e.target.checked})}/>
                            <label htmlFor="delete-switch" className="switch-label text-gray">Kustuta faile</label>
                        </div>
                    </div>
                </div>
                <table className="datatable-style table-default-styles table-hover">
                    <tr>
                        <th className="bg-white border-0 align-bottom p-0" style={{width: "30px"}}>Tüüp</th>
                        <th className="bg-white border-0 align-bottom p-0" style={{width: "150px"}}>Arvestuse algus</th>
                        <th className="bg-white border-0 align-bottom p-0" style={{width: "150px"}}>Dokumendi nr</th>
                        <th className="bg-white border-0 align-bottom p-0">Dokument</th>
                        {this.props.contract.type == ContractType.LeaseAgreement && (
                            <th className="bg-white border-0 align-bottom p-0" style={{width: "150px"}}>Üüripind
                                lepingus</th>
                        )}
                        <th className="bg-white border-0 align-bottom p-0" style={{width: "50px"}}>Tegevused</th>
                        {this.state.showFileDelete &&
                        <th className="bg-white border-0 align-bottom p-0" style={{width: "55px"}}/>
                        }
                    </tr>
                    <tbody>
                    {this.renderFiles()}
                    </tbody>
                </table>
                <Secured permission={getManagePermission(this.props.contract.type)}>
                    {this.renderUpload()}
                </Secured>
            </>
        );
    }

    private renderFiles(): JSX.Element[] {
        let index: number = 0;
        let filteredFiles = this.state.files || [];

        filteredFiles = filteredFiles.filter(file => this.state.filteredTypes.includes(file.fileType as ContractFileType || ContractFileType.Undefined));
        filteredFiles = filteredFiles.filter(file => {
            const search: string = this.state.searchValue?.toLowerCase() || "";
            const description: string = file.description?.toLowerCase() || "";
            const fileName: string = file.fileName?.toLowerCase() || "";
            const fileNumber: string = file.fileNumber?.toLowerCase() || "";
            if (description.includes(search)) return true;
            if (fileName.includes(search)) return true;
            if (fileNumber.includes(search)) return true;
            return false;
        });
        return filteredFiles.map(file => (
            <ContractFile
                contract={this.props.contract}
                file={file}
                files={this.state.files}
                editingFile={this.state.editingFile && this.state.editingFile.id === file.id ? this.state.editingFile : undefined}
                onToggleEdit={this.toggleEdit}
                onSaveFile={this.onSaveFile}
                onDeleteFile={this.onDeleteFile}
                showDeleteButton={this.state.showFileDelete}
                key={'file-' + index++}
            />
        ));
    }

    private renderUpload(): JSX.Element {
        return (
            <div className="form-row custom-form-row">
                <div className="col-12">
                    <Dropzone onDrop={this.handleFileUpload}>
                        {({getRootProps, getInputProps}) => (
                            <div className="file-input w-100" {...getRootProps()}>
                                <input {...getInputProps()}/>
                                <span className="icon icon_upload icon-lg"/>
                                <h6>Lohista fail siia või</h6>
                                <label htmlFor="upload_file" className="btn btn-link pointer">vali seadmest</label>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </div>
        )
    }

    private getFiles() {
        axios.get(`${this.props.baseURL}/contracts/${this.props.contract.id}/files`)
            .then(res => {
                this.setState({
                    files: res.data as ContractFileMeta[],
                    editingFile: undefined
                })
            });
    }

    private renderFilterButtons() {
        const allTypes: ContractFileType[] = Object.values(ContractFileType);
        return allTypes.map(fileType =>
            <Button key={"type-" + fileType} color="badge"
                    className="p-0 mr-2"
                    onClick={() => this.toggleFilter(fileType)}>
              <span className={`badge cursor-pointer 
              badge-${!this.state.filteredTypes.includes(fileType) ? 'outline-' : ''}${getBadgeColor(fileType)}`}>
                  {translateType(fileType, this.props.classifiers)}
              </span>
            </Button>
        );
    }

    private toggleEdit = (file: ContractFileMeta) => {
        this.setState({
            editingFile: !this.state.editingFile || this.state.editingFile.id !== file.id ? {...file} : undefined
        });
    };

    private onSaveFile = (file: ContractFileMeta) => {
        let validFile = true;
        if (this.props.contract && this.props.contract.type == ContractType.LeaseAgreement) {
            if (file.fileType == ContractFileType.Contract || file.fileType == ContractFileType.Change) {
                if (!file.area) {
                    validFile = false;
                    this.setState({showEmptyAreaModal: true, pendingFile: file});
                } else if (file.area != (this.props.contract as FullLeaseContract).area) {
                    validFile = false;
                    this.setState({showMismatchedAreaModal: true, pendingFile: file});
                }
            }
        }

        if (validFile)
            this.saveFile(file);
    };

    private saveFile = (file: ContractFileMeta) => {
        axios.put(`${this.props.baseURL}/contracts/${this.props.contract.id}/files/${file.id}`, file)
            .then(() => {
                this.getFiles();
            })
    };

    private onDeleteFile = (file: ContractFileMeta) => {
        const confirmDelete: boolean = window.confirm('Oled kindel, et soovid faili kustutada?');

        // TODO confirm modal

        if (confirmDelete) {
            axios.delete(`${this.props.baseURL}/contracts/${this.props.contract.id}/files/${file.id}`)
                .then(() => {
                    this.setState({
                        files: (this.state.files || []).filter(f => f.id !== file.id)
                    }, () => {
                        this.setState({
                            editingFile: undefined
                        });
                        this.props.onFileCountChanged(this.state.files.length);
                    });
                })
                .catch(error => {
                    let alertType = AlertContentType.FileDeleteFail;
                    if (error.response) {
                        const apiErrorType = Alert.fromException(error.response.data.error);
                        if(apiErrorType)
                            alertType = apiErrorType;
                    }
                    const alert: Alert = new Alert();
                    alert.type = AlertType.Danger;
                    alert.content = alertType;

                    this.props.addAlert(alert);
                });
        }
    };

    private handleFileUpload = ([file]: File[]) => {
        const formData = new FormData();
        formData.append("file", file);

        axios.post(`${this.props.baseURL}/contracts/${this.props.contract.id}/files`, formData)
            .then((res) => {
                this.setState({
                    files: [
                        ...(this.state.files || []),
                        res.data
                    ]
                }, () => {
                    this.toggleEdit(res.data);
                    this.props.onFileCountChanged(this.state.files.length);
                });
            }).catch(error => {
            let alertType = AlertContentType.FileUploadFail;
            if (error.response) {
                const apiErrorType = Alert.fromException(error.response.data.error);
                if(apiErrorType)
                    alertType = apiErrorType;
            }

            const alert: Alert = new Alert();
            alert.type = AlertType.Danger;
            alert.content = alertType;
            this.props.addAlert(alert);
        });
    };

    private toggleFilter(fileType: ContractFileType) {
        let newFilter = this.state.filteredTypes;
        if (newFilter.length == Object.values(ContractFileType).length)
            newFilter = [];

        if (newFilter.includes(fileType))
            newFilter = newFilter.filter(filterType => filterType !== fileType);
        else
            newFilter.push(fileType);

        if(newFilter.length == 0)
            newFilter = Object.values(ContractFileType);

        this.setState({filteredTypes: newFilter});
    }

    private cancelFileSave() {
        this.setState({showEmptyAreaModal: false, showMismatchedAreaModal: false, pendingFile: null});
    }

    private confirmFileSave() {
        const file = this.state.pendingFile;
        const overWriteContractArea = this.state.showMismatchedAreaModal;

        this.setState({showEmptyAreaModal: false, showMismatchedAreaModal: false, pendingFile: null}, () => {
            this.saveFile(file);
            if (overWriteContractArea) {

                (this.props.editingContract as FullLeaseContract).area = file.area;
                this.props.updateEditingContract(this.props.editingContract);
                this.props.onSaveContract();

                if(this.props.contract.publishedOn) {
                    window.setTimeout(() => {
                        this.props.onUnpublishContract(false);
                    }, 500);
                    window.setTimeout(() => {
                        this.props.onPublishContract(false);
                    }, 1000);
                }
            }
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state)
});

const mapDispatchToProps = {
    addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractFiles);

export function getBadgeColor(type: ContractFileType | string | null): string {
    switch (type) {
        case ContractFileType.Undefined:
            return 'gray';
        case ContractFileType.Contract:
            return 'rkas-pink';
        case ContractFileType.Change:
            return 'confirmed';
        case ContractFileType.Act:
            return 'extended';
        case ContractFileType.Addition:
            return 'done';
        case ContractFileType.Notice:
            return 'rkas-success';
        case ContractFileType.Ending:
            return 'submitted';
        default:
            return 'gray';
    }
}

export function translateType(type: ContractFileType | string | null, classifiers : Classifier[]): string {
    if (type) {
        return getClassifierValueName(classifiers, ClassifierCode.ContractFileType, type);
    }
    return 'Määramata';
}
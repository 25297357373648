import * as React from "react";
import "./AlertContainer.css";
import {Alert, AlertContentType} from "./Alert";
import {RootState} from "../rootReducer";
import {getAlerts} from "./alertReducer";
import {connect} from "react-redux";
import {alertActions} from "./alertActions";

interface Props {
  alerts: Alert[];
  removeAlert: typeof alertActions.removeAlert;
}

class AlertContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
        <div className="alert-container">
          {this.renderAlerts()}
        </div>
    );
  }

  private renderAlerts = () => {
    return this.props.alerts.reverse().map(al => this.renderAlertItem(al));
  };

  private renderAlertItem = (alert: Alert) => {
      let alertClassName:string = `alert alert-${alert.type} alert-fill alert-close alert-dismissible fade show`;
      let textClassName:string = "";
      if (alert.content == AlertContentType.PropertySyncFailed) {
          alertClassName = "alert-sync alert-danger alert-fill alert-close alert-dismissible fade show";
          textClassName = "alert-text";
      }
    return (
        <div className={alertClassName} key={alert.id}>
          <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Sulge"
              onClick={this.handleCloseAlert(alert.id)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <b className={textClassName}>{Alert.translateAlertContent(alert.content)}</b>
          {this.renderAdditionalContent(alert)}
        </div>
    );
  };

  private renderAdditionalContent(alert: Alert) {
    return alert.additionalContent ? [
        <br/>,
        <div>
          {alert.additionalContent}
        </div>
    ] : null;
  }

  private handleCloseAlert = (id: number) => () => {
    this.props.removeAlert(id);
  };
}

const mapStateToProps = (state: RootState) => ({
  alerts: getAlerts(state)
});

const mapDispatchToProps = {
  removeAlert: alertActions.removeAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
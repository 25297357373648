import React from "react";
import { ContractRowField } from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import { NumberInput } from "../../../../Common/NumberInput";

export default class SlaTimeLimitRowField<T extends ContractRow> extends ContractRowField<T> {

    private getValue(): number {
        return this.getFieldValue() ? Math.round(this.getFieldValue() / 3600) : 0;
    }

    private onChange = (value: number) => {
        this.props.onChange(this.props.row, this.props.fieldKey, value * 3600);
    };

    protected renderReadOnlyField(): JSX.Element {
        return (
            <span>
                {this.getValue()}
            </span>
        );
    }

    protected renderEditableField(): JSX.Element {
        return (
            <NumberInput value={this.getValue()}
                         onNumberChange={this.onChange} />
        );
    }
}
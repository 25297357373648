import {
    CostItemWarranty,
    DefaultPrice, Eic,
    FullConstructionContract, FullEnergyContract,
    FullServiceContract,
    Person, Project,
    Property,
    ThiMultiplication
} from "../Contracts/Contract";

export enum ModalType {
    SimpleText,
    ContactInfo,
    ThiMultiplication,
    DefaultPrices,
    ThDefaultPrices,
    ServiceContractAddRows,
    ConstructionContractImportRows,
    ConstructionContractEditWarranty
}

export class ModalData {
    type: ModalType;
    text: string;

    public constructor(init?:Partial<ModalData>) {
        Object.assign(this, init);
    }
}

export class ContactInfoModalData extends ModalData {
    person : Person;

    public constructor(init?:Partial<ContactInfoModalData>) {
        super();
        Object.assign(this, init);
    }
}

export class ThiMultiplicationModalData extends ModalData {
    contract : FullServiceContract;
    onSubmit : (data:ThiMultiplication) => void;

    public constructor(init?:Partial<ThiMultiplicationModalData>) {
        super();
        Object.assign(this, init);
    }
}

export class DefaultPricesModalData extends ModalData {
    contract : FullServiceContract;
    onSubmit : (data: DefaultPrice[]) => void;

    public constructor(init?:Partial<DefaultPricesModalData>) {
        super();
        Object.assign(this, init);
    }
}

export class ThDefaultPricesModalData extends ModalData {
    contract : FullServiceContract;
    onSubmit : (data: DefaultPrice[]) => void;

    public constructor(init?:Partial<DefaultPricesModalData>) {
        super();
        Object.assign(this, init);
    }
}

export class ServiceContractAddRowsModalData extends ModalData {
    editingContract: FullServiceContract | FullConstructionContract;
    updateEditingContract: (contract: FullServiceContract | FullConstructionContract, callback?: () => void) => void;
    onSaveContract: () => void;
    selectedProperty?: Property;
    selectedProject?: Project;

    public constructor(init?:Partial<ServiceContractAddRowsModalData>) {
        super();
        Object.assign(this, init);
    }
}

export class ConstructionContractImportRowsModalData extends ModalData {
    editingContract: FullConstructionContract;
    updateEditingContract: (contract: FullConstructionContract, callback?: () => void) => void;
    onSaveContract: () => void;

    public constructor(init?:Partial<ConstructionContractImportRowsModalData>) {
        super();
        Object.assign(this, init);
    }
}

export class ConstructionContractEditWarrantyModalData extends ModalData {
    contract: FullConstructionContract;
    onSubmit : (data: CostItemWarranty[]) => void;

    public constructor(init?:Partial<ConstructionContractEditWarrantyModalData>) {
        super();
        Object.assign(this, init);
    }
}


import React from "react";
import { ContractRowField, ContractRowFieldProps } from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import {
    Classifier,
    ClassifierCode, ClassifierValue,
    getClassifierValueName,
    getClassifierValues
} from "../../../../Classifier/Classifier";
import Select from "react-select";

interface Props<T extends ContractRow> extends ContractRowFieldProps<T> {
    classifiers: Classifier[];
    classifierCode: ClassifierCode;
    isClearable?: boolean;
}

export default class ClassifierRowField<T extends ContractRow> extends ContractRowField<T, Props<T>> {

    private getOption(classifierValue: ClassifierValue) {
        return {
            label: classifierValue.name,
            value: classifierValue.code
        }
    }

    private getValue() {
        let value: string = this.getFieldValue();

        if (value) {
            let classifierValue: ClassifierValue = getClassifierValues(this.props.classifiers, this.props.classifierCode)
                .find(classifierValue => classifierValue.code === value);

            if (classifierValue) {
                return this.getOption(classifierValue);
            }
        }
        return undefined;
    }

    private getOptions(): any[] {
        return getClassifierValues(this.props.classifiers, this.props.classifierCode).map(this.getOption);
    }

    private onChange = (option: any) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.row, this.props.fieldKey, option ? option.value: undefined);
        }
    };

    protected renderReadOnlyField(): JSX.Element {
      return (
          <span>
          {getClassifierValueName(this.props.classifiers, this.props.classifierCode, this.getFieldValue()) || ''}
        </span>
      );
    }

    protected renderEditableField(): JSX.Element {
        return (
            <Select className="react-select"
                    classNamePrefix="react-select"
                    isClearable={this.props.isClearable}
                    placeholder={this.props.editable ? "Vali" : "-"}
                    menuPortalTarget={document.querySelector('body')}
                    options={this.getOptions()}
                    value={this.getValue()}
                    isDisabled={!this.props.editable}
                    onChange={this.onChange}
            />
        );
    }
}
import React from "react";
import { Contract, FullContract } from "../../Contract";
import moment from "moment";

interface Props {
  contract: FullContract;
  isOpen: boolean;
  toggle: () => void;
}

export class ContractHistory extends React.Component<Props, any> {

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.isOpen !== this.props.isOpen) {
      const body: any = document.querySelector('body');
      body.classList.toggle("filters-open");
    }
  }

  private formatDate(date: number) {
    return moment(date).format("HH:mm, DD.MM.YYYY");
  }

  render() {
    return (
        <>
          <aside className={"filter" + (this.props.isOpen ? " show" : "")}>
            <nav className="width-500">
              <div className="filter-header-fixed">
                <h4>Logi: {this.props.contract.number}</h4>
              </div>

              {this.props.contract.events.map((event, index) => (
                  <div className="contracts-history-row" key={"event-" + index}>
                    <div>
                      <h6>{event.executedBy}</h6>
                      <h6>{Contract.translateEventType(event.type)}</h6>
                    </div>
                    <span className="ml-auto">{this.formatDate(event.executedOn)}</span>
                  </div>
              ))}

              <div className="filter-buttons">
                <button className="btn btn-secondary w-100" onClick={this.props.toggle}>Sulge</button>
            </div>
            </nav>
          </aside>
          <div className="filters-background" />
        </>
    )
  }
}
import React from "react";
import {ContractRowField, ContractRowFieldProps} from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import moment from "moment";
import DatePicker from "react-datepicker";
import {BodyPortal} from "../../../../Common/BodyPortal";

interface Props<T extends ContractRow> extends ContractRowFieldProps<T> {
    fallbackText?: string;
}

export default class DateRowField<T extends ContractRow> extends ContractRowField<T, Props<T>> {

    private getValue(): Date {
        return this.getFieldValue() ? new Date(this.getFieldValue()) : undefined;
    }

    private onChange = (date: Date) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.row, this.props.fieldKey, date?.getTime());
        }
    };

    protected renderReadOnlyField(): JSX.Element {
        const value: number = this.getFieldValue();

        return (
            <>
                {value && <span>{moment(value).format("DD.MM.YYYY")}</span>}
                {(!value && this.props.fallbackText) && <span className={"cell-placeholder"}>{this.props.fallbackText}</span>}
            </>
        )
    }

    protected renderEditableField(): JSX.Element {

        return (
            <div className="w-100">
                <DatePicker
                    className="form-control calendar-input"
                    selected={this.getValue()}
                    placeholderText={this.props.fallbackText}
                    dateFormat="dd.MM.yyyy"
                    onChange={this.onChange}
                    popperContainer={BodyPortal}
                    popperPlacement={"top"}
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                            padding: 25
                        }
                    }}
                    disabled={!this.props.editable}
                />
            </div>
        );
    }
}
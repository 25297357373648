import React from "react";
import { Equipment, FullConsumptionContract } from "../../Contract";
import { Button, UncontrolledTooltip } from "reactstrap";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { Classifier, ClassifierCode, getClassifierValueName } from "../../../Classifier/Classifier";
import { connect } from "react-redux";
import { RootState } from "../../../rootReducer";
import { getClassifiers } from "../../../Classifier/classifierReducer";
import ToolkitProvider, { InjectedSearchProps, ToolkitContextType } from "react-bootstrap-table2-toolkit";
import { headerFormatter } from "../../../BootstrapTable/customFormatters";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import { getPropregUrl } from "../../../Config/configReducer";

interface Props {
  classifiers: Classifier[]
  propregUrl: string;

  contract: FullConsumptionContract;
  editingContract?: FullConsumptionContract;

  toggleEquipmentModal: () => void;
  updateEditingContract: (contract: FullConsumptionContract, callback: () => void) => void;
  onSaveContract: () => void;
}

interface State {
  searchText: string;
  confirmDeleteEquipment?: Equipment;
}

class ConsumptionEquipment extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: ""
    };
  }

  private toggleConfirmDeleteEquipment = (equipment?: Equipment) => {
    this.setState({
      confirmDeleteEquipment: equipment
    });
  };

  private onDeleteEquipment = () => {
    if (this.state.confirmDeleteEquipment) {
      this.props.updateEditingContract({
        ...this.props.editingContract,
        equipment: this.props.editingContract.equipment.filter(e => e.id !== this.state.confirmDeleteEquipment.id)
      }, () => {
        this.props.onSaveContract();
        this.toggleConfirmDeleteEquipment();
      });
    }
  };

  private onSearchChange = (searchProps: InjectedSearchProps) =>
      (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) => {
        let searchText: string;
        if (event.type === "change") {
          searchText = (event as React.ChangeEvent<HTMLInputElement>).target.value;
        } else {
          searchText = "";
        }
        this.setState({
          searchText
        }, () => {
          if (searchText) {
            searchProps.onSearch(searchText);
          } else {
            searchProps.onClear();
          }
        });
      };

  private getPropertyRegistryUrl = (equipment: Equipment) => {
    return `${this.props.propregUrl}/#/equipment/${equipment.id}`;
  };

  private getColumns(): ColumnDescription[] {
    const columns = [
      {
        dataField: "code",
        text: "Kood",
        formatter: (cell, row) => (
            <span>
            <a className="btn btn-link btn-sm text-nowrap pt-0 pb-0 d-flex align-items-center hover-icon-secondary"
               href={this.getPropertyRegistryUrl(row)}
               target="_blank"
               id={"code-" + row.id}
            >
              {cell}
              <span className="icon external-link ml-1" />
            </a>
            <UncontrolledTooltip target={"code-" + row.id} fade={false}>
              Suunab kinnisturegistrisse
            </UncontrolledTooltip>
          </span>
        )
      },
      {
        dataField: "eic",
        text: "EIC",
        style: {
          whiteSpace: "nowrap"
        } as React.CSSProperties
      },
      {
        dataField: "equipmentType",
        text: "Tüüp",
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.EquipmentType, cell)
      },
      {
        dataField: "propertyCode",
        text: "Kinnistu kood"
      },
      {
        dataField: "buildingCode",
        text: "Hoone kood"
      },
      {
        dataField: "comment",
        text: "Kommentaar"
      }
    ];

    if (!this.props.contract.publishedOn) {
      columns.push({
        dataField: "",
        text: "",
        formatter: (cell, row) => (
            <button onClick={() => this.toggleConfirmDeleteEquipment(row)} className="close mr-2 mt-2 mb-2" type="button">
              <span className="submenu-hover submenu-hover-position" />
              <span className="icon trash icon-bg-danger" />
            </button>
        )
      });
    }

    return columns.map((column: ColumnDescription) => {
      column.headerFormatter = headerFormatter;
      return column;
    })
  }

  render() {
    return (
        <>
        <ConfirmationModal isOpen={!!this.state.confirmDeleteEquipment}
                           canConfirm={true}
                           confirmButtonColor="danger"
                           confirmButtonContent={<><i className="far fa-trash-alt mr-1" />Kustuta</>}
                           onCancel={() => this.toggleConfirmDeleteEquipment()}
                           onConfirm={this.onDeleteEquipment}
        >
          <h4>Oled kindel, et soovid arvesti kustutada?</h4>
        </ConfirmationModal>

        <div className="block block-white width-100">
          <div className="d-flex align-items-center justify-content-between title-border-bottom">
            <div className="d-flex align-items-center">
              <span className="block-title block-title-btn position-relative w-auto">
                Lepingu arvestid
              </span>
            </div>

            {!this.props.contract.publishedOn &&
            <Button
              color="outline-dark"
              className="no-wrap d-flex align-items-center justify-content-center mr-3 border-0"
              onClick={this.props.toggleEquipmentModal}
            >
              + Vali arvesteid
            </Button>
            }
          </div>

          <div className="block-collapsible-content open">
            <ToolkitProvider
                keyField="id"
                columns={this.getColumns()}
                data={(this.props.contract || this.props.editingContract).equipment}
                search={true}
            >
              { (props: ToolkitContextType) => (
                <>
                  <div className="form-control-with-search-icon">
                    <input type="text"
                           className="form-control"
                           placeholder="Otsi arvestit"
                           value={props.searchProps.searchText}
                           onChange={this.onSearchChange(props.searchProps)}
                    />
                    <span className={"icon icon_search icon-sm" + (!this.state.searchText ? " mask-gray" : "")}/>
                    {this.state.searchText &&
                    <button className="btn p-0" onClick={this.onSearchChange(props.searchProps)}>
                      <span className="icon icon_close icon-sm"/>
                    </button>
                    }
                  </div>

                  <div className="datatable">
                    <div className="table-responsive-lg">
                      <BootstrapTable
                          {...props.baseProps}
                          noDataIndication="Kirjeid ei leitud"
                          classes="table-condensed"
                      />
                    </div>
                  </div>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
        </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  classifiers: getClassifiers(state),
  propregUrl: getPropregUrl(state)
});

export default connect(mapStateToProps)(ConsumptionEquipment);
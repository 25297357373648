import { ContractInfoField } from "./ContractInfoField";
import { FullContract } from "../../../Contract";
import React, { ChangeEvent } from "react";

export default class TextAreaField<T extends FullContract> extends ContractInfoField<T> {

  private onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.fieldKey, event.target.value);
    }
  };

  protected renderEditableField(): JSX.Element {
    return (
        <textarea className="form-control"
                  defaultValue={this.getFieldValue()}
                  onChange={this.onChange}
                  disabled={!this.props.editable}
                  style={{ resize: "vertical" }}
        />
    );
  }
}
import * as React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import MainLeaseList from "./MainLeaseList/MainLeaseList";
import MainLeaseDetails from "./MainLeaseDetails/MainLeaseDetails";


export class MainLeases extends React.Component<RouteComponentProps<{}>> {

  render() {
    return (
        <div>
          <Route path={this.props.match!.url} exact={true} component={MainLeaseList} />
          <Route path={this.props.match!.url + "/:id"} exact={true} component={MainLeaseDetails} />
        </div>
    );
  }
}
import * as React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import ServiceContractList from "./ServiceContractList";
import ContractDetails2 from "../ContractDetails/ContractDetails";

export class ServiceContracts extends React.Component<RouteComponentProps<{}>> {

    render() {
        return (
            <>
                <Route path={this.props.match!.url} exact={true} component={ServiceContractList} />
                <Route path={this.props.match!.url + "/:id"} exact={true} component={ContractDetails2} />
            </>
        );
    }
}
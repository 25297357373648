import React from "react";
import {FullConstructionContract, Project, Property} from "../../Contract";
import {Classifier, ClassifierCode} from "../../../Classifier/Classifier";
import {connect} from "react-redux";
import {RootState} from "../../../rootReducer";
import {getClassifiers} from "../../../Classifier/classifierReducer";
import {getPropregUrl} from "../../../Config/configReducer";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
    ContractRowStateMap,
    RowEditState,
    RowGroupState,
    RowType,
    ServiceContractRow
} from "../ContractRow";
import ClassifierRowField from "../LeaseContract/Fields/ClassifierRowField";
import NumberRowField from "../LeaseContract/Fields/NumberRowField";
import DateRowField from "../LeaseContract/Fields/DateRowField";
import SlaTimeLimitRowField from "../LeaseContract/Fields/SlaTimeLimitRowField";
import TextRowField from "../LeaseContract/Fields/TextRowField";
import ServiceTitleRowField from "../LeaseContract/Fields/ServiceTitleRowField";
import {Popover, PopoverBody, PopoverHeader, UncontrolledTooltip} from "reactstrap";
import {NumberInput} from "../../../Common/NumberInput";
import {DeferredRender} from "../../../Common/RenderHelpers";
import {NumberUtils} from "../../../Common/NumberUtils";
import {BodyPortal} from "../../../Common/BodyPortal";
import {modalActions} from "../../../Modal/modalActions";
import {ModalType, ServiceContractAddRowsModalData} from "../../../Modal/Modal";
import {ContractTableUtils} from "../../../Common/ContractTableUtils";
import TextAreaRowField from "../LeaseContract/Fields/TextAreaRowField";


interface Props {
    classifiers: Classifier[]
    propregUrl: string;

    contract: FullConstructionContract;
    editingContract?: FullConstructionContract;

    updateEditingContract: (contract: FullConstructionContract, callback: () => void) => void;
    onSaveContract: () => void;

    toggleModal: typeof modalActions.toggleModal;
}

interface State {
    quickFilter : string;
    showDeletedRows : boolean;
    multiSelectDateModalOpen : boolean;
    multiSelectDateStart : Date;
    multiSelectDateEnd : Date;
    openedObjectTabs : number[];
    selectedRows: ServiceContractRow[];
    reservePopovers : ReservePopOver[];
    sumDate: number;
}

class ConstructionContractTable extends React.Component<Props, State> {
    private rowStates: ContractRowStateMap;

    constructor(props: Props) {
        super(props);

        this.state = {
            quickFilter : "current",
            showDeletedRows : false,
            multiSelectDateModalOpen : false,
            multiSelectDateStart: null,
            multiSelectDateEnd: null,
            openedObjectTabs :[],
            selectedRows: [],
            reservePopovers: [],
            sumDate: new Date().valueOf()
        };
    }

    componentDidMount() {
        const contract = this.props.contract || this.props.editingContract;
        if(contract.properties.length > 0)
            this.toggleObjectTable(contract.properties[0]);
        else if(contract.projects.length > 0)
            this.toggleObjectTable(contract.projects[0]);

        let reservePopovers = [];
        for(const property of contract.properties) {
            let popOver = {} as ReservePopOver;
            popOver.objectIndex = property.id;
            popOver.open = false;
            popOver.value = property.reserveValue;
            reservePopovers.push(popOver);
        }
        for(const project of contract.projects) {
            let popOver = {} as ReservePopOver;
            popOver.objectIndex = project.id;
            popOver.open = false;
            popOver.value = project.reserveValue;
            reservePopovers.push(popOver);
        }
        this.setState({reservePopovers: reservePopovers});
    }

    private onChange = (row: ServiceContractRow, fieldKey: keyof ServiceContractRow, value: any) => {
        if (this.props.editingContract) {
            row[fieldKey.valueOf()] = value;
            this.props.updateEditingContract({
                ...this.props.editingContract
            }, () => {
                //this.props.onSaveContract();
            });
        }
    };

    private onReserveValueChange = (object: Property | Project, reservePopover: ReservePopOver) => {
        if (this.props.editingContract) {
            object.reserveValue = reservePopover.value;
            this.props.updateEditingContract({
                ...this.props.editingContract
            }, () => {
                this.toggleReservePopover(reservePopover);
            });
        }
    }

    render() {
        const editMode = this.props.contract.publishedOn === null;
        let contract = editMode ? this.props.editingContract : this.props.contract;
        let contractRows = contract.rows;

        this.rowStates = ContractTableUtils.ProcessRows(this.props.editingContract,this.props.contract);

        if(!this.state.showDeletedRows)
            contractRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.editState != RowEditState.Deleted);

        const allSumRows = contractRows.filter(row => row.serviceStartDate < this.state.sumDate && row.serviceEndDate > this.state.sumDate);
        const expiredRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.groupState == RowGroupState.Expired);
        const futureRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.groupState == RowGroupState.Future);
        const currentRows = contractRows.filter(row => this.rowStates.get(row.id || row.tempId)?.groupState == RowGroupState.Current);

        let filteredRows = [];
        switch(this.state.quickFilter){
            case "all":
                filteredRows = allSumRows;
                break;
            case "current":
                filteredRows = currentRows;
                break;
            case "expired":
                filteredRows = expiredRows;
                break;
            case "future":
                filteredRows = futureRows;
                break;
            default:
                filteredRows = currentRows;
        }

        const multiSelectDelete = this.state.selectedRows.every(row => row.deletedOn == null);
        return (
            <>
                <div className="d-lg-flex mb-4" style={{justifyContent:"space-between"}}>
                    <div className="btn-group">
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "all" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("all")}>
                            Kõik {moment(this.state.sumDate.valueOf()).format("DD.MM.YYYY")} ({allSumRows.length})
                        </button>
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "current" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("current")}>
                            Kehtivad ({currentRows.length})
                        </button>
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "expired" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("expired")}>
                            Kehtetud ({expiredRows.length})
                        </button>
                        <button className={"btn btn-outline-primary btn-sm " + (this.state.quickFilter == "future" ? "active" : "") }
                                onClick={() => this.switchQuickFilter("future")}>
                            Tulevikus kehtivad ({futureRows.length})
                        </button>
                        <div style={{padding: "5px 5px 5px 15px"}}>
                            <span style={{verticalAlign: "middle"}}>Korraliste teenuste kuupõhine hind: {NumberUtils.format(this.getMonthlySum(filteredRows,"unitPrice"), 2, 2) + " €"}</span>
                        </div>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                               className="custom-control-input"
                               id="show-deleted-rows-checkbox"
                               checked={!!this.state.showDeletedRows}
                               onChange={() => this.setState({ showDeletedRows: !this.state.showDeletedRows})}
                        />
                        <label className="custom-control-label no-wrap" htmlFor={"show-deleted-rows-checkbox"}>
                            Näita kustutatud ridu
                        </label>
                    </div>
                </div>
                {this.props.editingContract.properties.length > 0 &&
                    this.props.editingContract.properties.map(property => this.renderObjectTable(property, "Property", filteredRows))
                }
                {this.props.editingContract.projects.length > 0 &&
                    this.props.editingContract.projects.map(project => this.renderObjectTable(project, "Project", filteredRows))
                }

                {(editMode && this.state.selectedRows.length > 0) &&
                <div className="contract-footer"><h6 className="mr-3">Valitud: {this.state.selectedRows.length}</h6>
                    {!this.state.multiSelectDateModalOpen &&
                    <>
                        <button type="button"
                                className="btn btn-outline-primary mr-3 btn-sm"
                                onClick={() => this.setState({selectedRows: []})}>
                            Tühista
                        </button>
                        <button type="button"
                                className="btn btn-primary mr-3 btn-sm"
                                onClick={() => this.setState({multiSelectDateModalOpen: true})}>
                            Muuda valitud kuupäevi
                        </button>
                        {multiSelectDelete &&
                        <button type="button"
                                className="btn btn-danger mr-3 btn-sm"
                                onClick={() => this.deleteMultiSelection()}>
                            Kustuta valitud read
                        </button>
                        }
                        {!multiSelectDelete &&
                        <button type="button"
                                className="btn btn-success mr-3 btn-sm"
                                onClick={() => this.unDeleteMultiSelection()}>
                            Taasta valitud read
                        </button>
                        }
                    </>
                    }
                    {this.state.multiSelectDateModalOpen &&
                    <>
                        <div className="form-row custom-form-row ml-3 mr-4">
                            <div className="col-12 align-items-center">
                                <h6 style={{width: "50px"}}>Algus:</h6>
                                <DatePicker
                                    className="form-control calendar-input"
                                    selected={this.state.multiSelectDateStart}
                                    placeholderText={"Vali"}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(date: Date) => this.setState({multiSelectDateStart : date})}
                                />
                            </div>
                        </div>
                        <div className="form-row custom-form-row mr-5">
                            <div className="col-12 align-items-center">
                                <h6 style={{width: "50px"}}>Lõpp:</h6>
                                <DatePicker
                                    className="form-control calendar-input"
                                    selected={this.state.multiSelectDateEnd}
                                    placeholderText={"Vali"}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(date: Date) => this.setState({multiSelectDateEnd : date})}
                                />
                            </div>
                        </div>
                        <button type="button"
                                className="btn btn-outline-primary mr-3 btn-sm"
                                onClick={() => this.setState({multiSelectDateModalOpen: false})}>
                            Tühista
                        </button>
                        <button type="button"
                                className="btn btn-primary mr-3 btn-sm"
                                onClick={() => this.changeDatesMultiSelection()}>
                            Muuda valitud kuupäevi
                        </button>
                    </>
                    }
                </div>
                }
            </>
        );
    }

    private renderPropertyTableHeaderRow(label: string, data: ServiceContractRow[]) {
        const editMode = this.props.contract.publishedOn === null;
        return (
            <tr>
                {editMode &&
                <th style={{width: "45px"}}>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox"
                               className="custom-control-input"
                               id={"select-row-type-"+label}
                               checked={(!!data.every(row => this.state.selectedRows.includes(row)) && data.length > 0)}
                               onChange={() => this.toggleMultiSelection(data) }
                        />
                        <label className="custom-control-label no-wrap" htmlFor={"select-row-type-"+label}/>
                    </div>
                </th>
                }
                <th style={{width: "60px"}}>Kulukoht</th>
                <th style={{minWidth: "250px", maxWidth: "450px"}}>Teenus</th>
                <th className="w-140">Tüüp</th>
                <th>Kogus</th>
                <th style={{width: "100px"}}>Ühik</th>
                <th>Hind/tk</th>
                <th className="w-140">Algus</th>
                <th className="w-140">Lõpp</th>
                <th className="w-140">Garantii lõpp</th>
                <th>Märkus</th>
                <th>Summa</th>
                {editMode &&
                <th style={{width: "50px"}} />
                }
            </tr>
        );
    }

    private renderSumRow(rows : ServiceContractRow[]) {
        const editMode = this.props.contract.publishedOn === null;
        return (
            <>
                <tr className="sum-row">
                    {editMode && <td/>}
                    <td colSpan={4}>
                        {this.state.quickFilter != "expired" && this.state.quickFilter != "future" &&
                        <div className="form-row">
                            <div className="col-12 align-items-end">
                                <DatePicker
                                    className="form-control calendar-input sum-date-input"
                                    popperContainer={BodyPortal}
                                    selected={new Date(this.state.sumDate)}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(value: Date) => this.sumRowDateChanged(value)}
                                />
                            </div>
                        </div>
                        }
                    </td>
                    <td>KOKKU</td>
                    <td>{NumberUtils.format(this.getMonthlySum(rows,"unitPrice"), 2, 2)}</td>
                    <td colSpan={7}/>
                    <td>{NumberUtils.format(this.getMonthlySum(rows,"rowPrice"), 4, 4)}</td>
                    {editMode && <td/>}
                </tr>
                <tr className={"table-separator-row"}><td colSpan={18}/></tr>
            </>
        );
    }

    private renderPropertyTableBodyRows(rows : ServiceContractRow[]){
        const editMode = this.props.contract.publishedOn === null;
        const editable = this.props.contract.publishedOn === null;
        return (
            rows.map((row, index) => {
                return <React.Fragment key={row.id || row.tempId}>
                    <DeferredRender idleTimeout={50}>
                        {(index > 0 && row.costItem.id !== rows[index - 1].costItem.id) &&
                            <tr key={(row.id || row.tempId) + "-separator"} className={"table-separator-row"}><td colSpan={16}/></tr>
                        }
                        {ContractTableUtils.renderRowMessage(row, this.rowStates) &&
                            <UncontrolledTooltip target={"row-"+(row.id || row.tempId)} fade={false} innerClassName="tooltip-row-error"
                                                 placement={"top"} hideArrow={true}>
                                {ContractTableUtils.renderRowMessage(row, this.rowStates)}
                            </UncontrolledTooltip>
                        }
                        <tr id={"row-"+(row.id || row.tempId)} className={ContractTableUtils.renderRowStatus(row, this.rowStates)}>
                            {editable &&
                            <td>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           className="custom-control-input"
                                           id={"select-row-"+(row.id || row.tempId)}
                                           checked={!!this.state.selectedRows.includes(row)}
                                           onChange={() => this.toggleRowSelection(row) }
                                    />
                                    <label className="custom-control-label no-wrap" htmlFor={"select-row-"+(row.id || row.tempId)}/>
                                </div>
                            </td>
                            }
                            <td style={{textAlign: "center"}}>
                                <span id={"costitem-"+(row.id || row.tempId)}>{row.costItem.id}</span>
                                <UncontrolledTooltip target={"costitem-"+(row.id || row.tempId)} fade={false}>
                                    {row.costItem.label}
                                </UncontrolledTooltip>
                            </td>
                            <td>
                                <ServiceTitleRowField
                                    row={row}
                                    field={row.serviceName}
                                    fieldKey={"serviceName"}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable}
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <ClassifierRowField
                                    row={row}
                                    field={row.type}
                                    fieldKey={"type"}
                                    classifiers={this.props.classifiers}
                                    classifierCode={ClassifierCode.ContractRowType}
                                    isClearable={false}
                                    editMode={false}
                                    editable={false}
                                    onChange={this.onChange}
                                />
                            </td>
                            <td>
                                <NumberRowField
                                    row={row}
                                    field={row.quantity}
                                    fieldKey={"quantity"}
                                    editMode={editMode && !row.deletedOn && row.type == RowType.Quantity}
                                    editable={editable && row.type == RowType.Quantity}
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <ClassifierRowField
                                    row={row}
                                    field={row.unitName}
                                    fieldKey={"unitName"}
                                    classifiers={this.props.classifiers}
                                    classifierCode={ClassifierCode.UnitType}
                                    isClearable={false}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable && (row.type == RowType.Quantity || row.type == RowType.Unit)}
                                    onChange={this.onChange}
                                />
                            </td>
                            <td>
                                <NumberRowField
                                    row={row}
                                    field={row.unitPrice}
                                    fieldKey={"unitPrice"}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable && (row.type == RowType.Quantity || row.type == RowType.Unit || row.type == RowType.Monthly || row.type == RowType.MonthlyReserve)}
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <DateRowField
                                    row={row}
                                    field={row.serviceStartDate}
                                    fieldKey={"serviceStartDate"}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable}
                                    fallbackText={ this.props.contract.startTime && moment(this.props.contract.startTime).format("DD.MM.YYYY")}
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <DateRowField
                                    row={row}
                                    field={row.serviceEndDate}
                                    fieldKey={"serviceEndDate"}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable}
                                    fallbackText={ this.props.contract.endTime && moment(this.props.contract.endTime).format("DD.MM.YYYY")}
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <DateRowField
                                    row={row}
                                    field={row.warrantyEndDate}
                                    fieldKey={"warrantyEndDate"}
                                    editMode={false}
                                    editable={false}
                                    fallbackText={ '-' }
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <TextAreaRowField
                                    row={row}
                                    field={row.processingNote}
                                    fieldKey={"processingNote"}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable}
                                    onChange={this.onChange}/>
                            </td>
                            <td>
                                <NumberRowField
                                    row={row}
                                    field={row.rowPrice}
                                    fieldKey={"rowPrice"}
                                    editMode={editMode && !row.deletedOn}
                                    editable={editable}
                                    onChange={this.onChange}/>
                            </td>
                            {editable &&
                            <td>
                                <button onClick={() => this.toggleDeleted(row)} className="close mr-2 mt-2 mb-2" type="button">
                                    <span className="submenu-hover submenu-hover-position" />
                                    <span className="icon trash icon-bg-danger" />
                                </button>
                            </td>
                            }
                        </tr>
                    </DeferredRender>
                </React.Fragment>
            })
        );
    }

    private renderObjectTable(object: Property | Project, type: string, filteredRows : ServiceContractRow[]) {

        const editMode = this.props.contract.publishedOn === null;
        let objectRows;
        let name = "";
        let isProperty = false;
        switch (type){
            case "Property":
                objectRows = filteredRows.filter(row => row.propertyId == object.id).sort((a, b) => a.costItem.id > b.costItem.id ? 1 : -1);
                name = `${object.name} ${(object as Property).address} ${(object as Property).code}`;
                isProperty = true;
                break;
            case "Project":
                objectRows = filteredRows.filter(row => row.project?.id == object.id).sort((a, b) => a.costItem.id > b.costItem.id ? 1 : -1);
                name = `${object.name}${(object as Project).projectNumber ? (" - " + (object as Project).projectNumber) : ""}`;
                break;
        }
        const reservePopover = this.state.reservePopovers.find(popover => popover.objectIndex == object.id);
        return (
            <React.Fragment key={object.id} >
                <div className="block block-white block-collapsible width-100">
                    <div className="d-flex align-items-center justify-content-between title-border-bottom">
                        <button className={"btn btn-link block-title title-border-bottom block-title-btn position-relative " +
                        (this.isObjectTableOpened(object) ? "arrow-up" : "arrow-down")}
                                onClick={() => this.toggleObjectTable(object)}
                        >
                            {name}
                            <span className="icon icon_arrow_dark ml-2" />
                        </button>

                        <div className="d-flex align-items-center">
                            {(editMode && reservePopover) &&
                            <>

                                <button id={"change-extra-reserve-" + object.id.toString()} onClick={() => this.toggleReservePopover(reservePopover)} className="btn btn-link no-wrap d-flex align-items-center hover-icon-secondary">
                                    {isProperty &&
                                        <>Kinnistu reserv: {object.reserveValue ?? 0} €</>
                                    }
                                    {!isProperty &&
                                        <>Projekti reserv: {object.reserveValue ?? 0} €</>
                                    }
                                </button>
                                <Popover isOpen={reservePopover.open} placement="bottom" target={"change-extra-reserve-" + object.id.toString()}>
                                    <PopoverHeader>{isProperty ? "Muuda kinnistu reservi" : "Muuda projekti reservi"}</PopoverHeader>
                                    <PopoverBody>
                                        <NumberInput value={reservePopover.value}
                                                     onNumberChange={ value => {reservePopover.value = value}} />
                                        <div className="d-lg-flex mt-2">
                                            <div className="btn-group">
                                                <button onClick={() => this.toggleReservePopover(reservePopover)} className="btn no-wrap btn-outline-primary">
                                                    Katkesta
                                                </button>
                                                <button onClick={() => this.onReserveValueChange(object, reservePopover)} className="btn no-wrap btn-outline-primary ">
                                                    Salvesta
                                                </button>
                                            </div>
                                        </div>
                                    </PopoverBody>
                                </Popover>
                                <span className="complex-block-title-separator"/>
                                <button className="btn btn-outline-dark no-wrap mr-3 ml-3" style={{"border": "none"}}
                                        onClick={() => this.toggleAddRowModal(object, type)}>
                                    Lisa ridu
                                </button>
                            </>
                            }
                            {!editMode &&
                                <>
                                    {isProperty &&
                                        <span style={{whiteSpace: "nowrap", paddingRight: "10px"}}>Kinnistu reserv: <strong>{object.reserveValue ?? 0} €</strong></span>
                                    }
                                    {!isProperty &&
                                        <span style={{whiteSpace: "nowrap", paddingRight: "10px"}}>Projekti reserv: <strong>{object.reserveValue ?? 0} €</strong></span>
                                    }
                                </>
                            }
                        </div>
                    </div>

                    {this.isObjectTableOpened(object) &&
                        <div className={"block-collapsible-content " + (this.isObjectTableOpened(object) && "open")}>
                            {(objectRows) &&
                            <div className="datatable">
                                <div className="table-responsive-lg">
                                    <div >
                                        <table className="table table-bordered datatable-style table-lease-contract-details table-hover w-100 overflow-hidden">
                                            <thead>
                                                {this.renderPropertyTableHeaderRow("object" + object.id, objectRows)}
                                            </thead>
                                            <tbody>
                                                {this.renderSumRow(objectRows)}
                                                {this.renderPropertyTableBodyRows(objectRows)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            }
                            {(!objectRows || objectRows.length <= 0) &&
                                <span className={"block-title d-block text-gray"} style={{verticalAlign:"middle", paddingTop: "0"}}>Kirjeid ei leitud</span>
                            }
                        </div>
                    }

                </div>
            </React.Fragment>
        );
    }

    private isObjectTableOpened(object: Property | Project) {
        return this.state.openedObjectTabs.includes(object.id);
    }

    private toggleObjectTable(object: Property | Project) {
        const index = this.state.openedObjectTabs.indexOf(object.id);
        const newTabsState = this.state.openedObjectTabs;
        if (index > -1)
            newTabsState.splice(index, 1);
        else
            newTabsState.push(object.id);

        this.setState({
            openedObjectTabs: newTabsState
        });
    }

    private toggleReservePopover(toggledPopover : ReservePopOver) {
        let reservePopovers = this.state.reservePopovers;
        let rp = reservePopovers[reservePopovers.findIndex(popover => popover.objectIndex == toggledPopover.objectIndex)];
        rp.open = !rp.open;

        this.setState({
            reservePopovers: reservePopovers
        });
    }

    private switchQuickFilter(filterType: string) {
        let sumDate = this.state.sumDate;
        if(filterType == "current")
            sumDate = new Date().valueOf();

        this.setState({
            quickFilter: filterType,
            sumDate: sumDate
        });
    }

    private toggleDeleted(row: ServiceContractRow) {
        this.onChange(row,"deletedOn", row.deletedOn ? null : new Date().getTime());
    }

    private toggleRowSelection(row: ServiceContractRow) {
        let selectedRows = this.state.selectedRows;
        if(selectedRows.includes(row))
            selectedRows = selectedRows.filter(selectedRow => selectedRow !== row)
        else
            selectedRows.push(row);

        this.setState({ selectedRows: selectedRows})
    }

    private toggleMultiSelection(data: ServiceContractRow[]) {
        if(data.every(row => this.state.selectedRows.includes(row)))
            this.setState({ selectedRows: []})
        else
            this.setState({ selectedRows: data})
    }

    private deleteMultiSelection() {
        this.state.selectedRows.map(row => {
            this.onChange(row,"deletedOn", new Date().getTime());
        })
        this.setState({selectedRows: []})
    }

    private unDeleteMultiSelection() {
        this.state.selectedRows.map(row => {
            this.onChange(row,"deletedOn", null);
        })
        this.setState({selectedRows: []})
    }


    private changeDatesMultiSelection() {
        this.state.selectedRows.map(row => {
            if(this.state.multiSelectDateStart){
                this.onChange(row,"serviceStartDate", this.state.multiSelectDateStart.getTime());
            }
            if(this.state.multiSelectDateEnd){
                this.onChange(row,"serviceEndDate", this.state.multiSelectDateEnd.getTime());
            }
        })
        this.setState({
            selectedRows: [],
            multiSelectDateModalOpen: false
        })
    }

    private getMonthlySum(rows: ServiceContractRow[], field: keyof ServiceContractRow): number {
        return rows.filter(row => {
            let serviceRow: ServiceContractRow = row as ServiceContractRow;
            let startDate: number = serviceRow.serviceStartDate || this.props.contract.startTime;
            let endDate: number = serviceRow.serviceEndDate || this.props.contract.endTime;

            let isSummableRow = row.type === RowType.Monthly && !row.deletedOn;

            if(this.state.quickFilter == "all" || this.state.quickFilter == "current") {
                isSummableRow = isSummableRow && this.state.sumDate > startDate && this.state.sumDate < endDate
            }

            return isSummableRow;
        }).reduce((accumulator, row) => accumulator + (row[field] as number || 0), 0);
    }

    private sumRowDateChanged(value: Date) {
        if(!moment(value).isSame(new Date(), "day"))
            this.switchQuickFilter("all");
        else
            this.switchQuickFilter("current");

        this.setState({sumDate: value.valueOf() });
    }

    private toggleAddRowModal(object: Property | Project, objectType: string) {
        const selectedProperty : Property = objectType == "Property" ? object as Property : null;
        const selectedProject : Project = objectType == "Project" ? object as Project : null;
        const modalData = new ServiceContractAddRowsModalData({
            type: ModalType.ServiceContractAddRows,
            editingContract: this.props.editingContract as FullConstructionContract,
            selectedProperty: selectedProperty,
            selectedProject: selectedProject,
            onSaveContract: this.props.onSaveContract,
            updateEditingContract: this.props.updateEditingContract})
        console.log(modalData);
        this.props.toggleModal(modalData);
    }
}

const mapStateToProps = (state: RootState) => ({
    classifiers: getClassifiers(state),
    propregUrl: getPropregUrl(state)
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps,mapDispatchToProps)(ConstructionContractTable);

interface ReservePopOver {
    objectIndex: number,
    open: boolean,
    value: number,
}

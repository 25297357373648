import * as React from "react";
import {ChangeEvent} from "react";
import "./ListFilters.css";
import {ContractFilters} from "../Contracts/ContractFilters";
import {Classifier, ClassifierCode, ClassifierValue, getClassifierValues} from "../Classifier/Classifier";
import {RootState} from "../rootReducer";
import {connect} from "react-redux";
import {getClassifiers} from "../Classifier/classifierReducer";
import {ContractType} from "../Contracts/Contract";
import moment from "moment";
import DatePicker from "react-datepicker";
import {Button} from "reactstrap";
import Select from "react-select";
import {MainLeaseFilters} from "../MainLease/MainLeaseList/MainLeaseFilters";
import {getBaseURL, getRkasCompanyRegNo} from "../Config/configReducer";
import CompanyListFilter from "./CompanyListFilter";
import PersonListFilter from "./PersonListFilter";
import EICListFilter from "./EICListFilter";
import {ReportFilters} from "../Reports/ReportFilters";
import {ReportType} from "../Reports/Report";

interface Props {
    classifiers: Classifier[];
    baseURL: string;
    rkasCompanyRegNo: string;

    type: "contract"|"mainLease"|"report";
    contractType?: ContractType;
    reportType?: ReportType;
    filters: ContractFilters|MainLeaseFilters|ReportFilters;
    show: boolean;
    toggleFilters: () => void;
    onFiltersChange: (filters: ContractFilters|MainLeaseFilters|ReportFilters) => void;
}

interface State {
  filters: ContractFilters|MainLeaseFilters|ReportFilters;
}

class ListFilters extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
          filters: this.props.filters
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
      if (this.props.filters != prevProps.filters && this.props.filters != this.state.filters) {
        this.setState({
          filters: this.props.filters
        });
      }
    }

    private renderContractFilters() {
      const { filters } = this.state as { filters: ContractFilters };

      return (
          <>
            <h6 className="text-gray mb-2">
               <i className="fas fa-asterisk mr-2" /> Filtreeri lepinguid avaldatuse järgi:
            </h6>
              <div className="mb-4 bg-light-gray-2 p-3">
                  <>
                      {this.renderClassifierMultiRadioFilter("", "publishedStatusType", ClassifierCode.ContractPublishedStatusType)}
                  </>
              </div>
            <h6 className="text-gray mb-2">
              <i className="fas fa-briefcase mr-2" /> Filtreeri lepinguid sisu järgi:
            </h6>

            <div className="mb-4 bg-light-gray-2 p-3">
              {this.renderTextFilter("Number", "number")}

                {this.props.contractType === ContractType.EnergySaleAgreement &&
                <>
                    <h6>Üürnik</h6>
                    <CompanyListFilter fieldKey={"leaserRegNo"}
                                       fieldValue={this.state.filters["leaserRegNo"]}
                                       freeTextFieldKey={"leaserName"}
                                       freeTextFieldValue={this.state.filters["leaserName"]}
                                       backingFieldKey={"leaserRegNo"}
                                       backingFieldValue={this.state.filters["leaserRegNo"]}
                                       valueKey={"regNo"}
                                       portalTarget={"modal"}
                                       baseURL={this.props.baseURL}
                                       onChange={this.handleMultiFilterChange}
                    />
                    <h6>EIC</h6>
                    <EICListFilter fieldKey={"eic"}
                                   fieldValue={this.state.filters["eic"]}
                                   portalTarget={"modal"}
                                   baseURL={this.props.baseURL}
                                   onChange={this.handleFilterChange}
                    />
                </>
                }

              {[ContractType.LeaseAgreement, ContractType.EnergySaleAgreement]
                  .indexOf(this.props.contractType) > -1 &&
              this.renderTextFilter("Üürilepingu kood", "rentalCode")
              }

              {(this.props.contractType !== ContractType.EnergySaleAgreement &&
               this.props.contractType !== ContractType.ConstructionContract) &&
              this.renderTypeFilter()
              }

              {this.props.contractType === ContractType.ConstructionContract &&
                this.renderTextFilter("Projekt", "projectName")
              }

              {this.renderTextFilter("Pealkiri", "generalDocumentTitle")}

              {this.props.contractType === ContractType.ConsumptionContract &&
              <>
                {this.renderClassifierSelectFilter("Näitude edastamise tüüp", "meterForwardType", ClassifierCode.MeterForwardType, false)}
                {this.renderTextFilter("Peamine e-mail", "emailAddress1")}
                {this.renderTextFilter("Lisa e-mail", "emailAddress2")}
              </>
              }

              {/*[
                      ContractType.ServiceContract,
                      ContractType.ConstructionContract,
                      ContractType.SupportServiceContract
                  ].indexOf(this.props.contractType) > -1 &&
                  <>
                    {this.renderTextFilter("Raamleping", "frameContractReference")}
                    {this.renderTextFilter("Hange", "procurementReference")}
                    {this.renderTextFilter("Väärtus", "value")}
                    {this.renderTextFilter("Lepingu reserv", "reserveValue")}
                  </>
                  */}

              {this.renderDateFilter("Alguskuupäev alates", "startTimeFrom")}
              {this.renderDateFilter("Lõpukuupäev alates", "endTimeFrom")}
              {this.renderDateFilter("Sõlmitud kuupäev alates", "createdOnFrom")}
              {this.renderTextFilter("Märkus", "comment")}
            </div>

            {this.props.contractType !== ContractType.ConsumptionContract &&
            <>
              <h6 className="text-gray mb-2 mt-4">
                <i className="fas fa-map-marker-alt mr-2"/> Filtreeri asukoha järgi:
              </h6>
              <div className="mb-4 bg-light-gray-2 p-3">
                {this.renderTextFilter("Kinnistu nimi", "propertyName")}
                {this.renderTextFilter("Kinnistu kood", "propertyCode")}
                {this.renderTextFilter("Kinnistu aadress", "propertyAddress")}
                  {this.props.contractType === ContractType.LeaseAgreement &&
                  <>
                      {this.renderClassifierSelectFilter("Portfell", "portfolioType", ClassifierCode.PortfolioType, false)}
                  </>
                  }
                {/*this.renderClassifierSelectFilter("Regioon", "propertyRegionType", ClassifierCode.RegionType)*/}
              </div>
            </>
            }

            <h6 className="text-gray mb-2">
              <i className="fas fa-user mr-2" /> Filtreeri ettevõtete ja isikute järgi:
            </h6>
            <div className="bg-light-gray-2 p-3">
              {this.renderCompanyFilters()}
            </div>
          </>
      )
    }

    private renderMainLeaseFilters() {
      return (
          <div className="mb-4 bg-light-gray-2 p-3">
            {this.renderTextFilter("Peaüür", "code")}
            {this.renderTextFilter("Lepingu number", "contractNumber")}
            {this.renderTextFilter("Ettevõte", "companyName")}
            {this.renderTextFilter("Haldur", "managerName")}
          </div>
      );
    }

    private renderReportFilters() {
        const { filters } = this.state as { filters: ReportFilters };
        return (
            <>
                <h6 className="text-gray mb-2">
                    <i className="fas fa-briefcase mr-2" /> Filtreeri sisu järgi:
                </h6>

                <div className="mb-4 bg-light-gray-2 p-3">
                    {this.props.reportType === ReportType.ServiceContractReport &&
                        <>
                            {this.renderClassifierSelectFilter("Üürniku valitsemisala", "leaserCompanyGovernanceArea", ClassifierCode.GovernanceAreaType, true)}
                            {this.renderClassifierRadioFilter("Tüüp", "serviceContractType", ClassifierCode.ServiceContractType)}
                            {this.renderDateFilter("Lepingu algus alates", "serviceContractStartTimeFrom")}
                            {this.renderDateFilter("Lepingu algus kuni", "serviceContractStartTimeTo")}
                            {this.renderDateFilter("Lepingu lõpp alates", "serviceContractEndTimeFrom")}
                            {this.renderDateFilter("Lepingu lõpp kuni", "serviceContractEndTimeTo")}
                        </>
                    }
                </div>

                <>
                    <h6 className="text-gray mb-2 mt-4">
                        <i className="fas fa-map-marker-alt mr-2"/> Filtreeri asukoha järgi:
                    </h6>
                    <div className="mb-4 bg-light-gray-2 p-3">
                        {this.props.reportType === ReportType.ServiceContractReport &&
                            <>
                                {this.renderTextFilter("Kinnistu nimi", "contractPropertyName")}
                                {this.renderTextFilter("Kinnistu kood", "contractPropertyCode")}
                                {this.renderTextFilter("Kinnistu aadress", "contractPropertyAddress")}
                            </>
                        }
                    </div>
                </>


                <h6 className="text-gray mb-2">
                    <i className="fas fa-user mr-2" /> Filtreeri ettevõtete ja isikute järgi:
                </h6>
                <div className="bg-light-gray-2 p-3">
                    {this.props.reportType === ReportType.ServiceContractReport &&
                    <>
                        <h6>Üürnik</h6>
                        <CompanyListFilter fieldKey={"leaserCompanyId"}
                                           fieldValue={this.state.filters["leaserCompanyId"]}
                                           freeTextFieldKey={"leaserCompanyName"}
                                           freeTextFieldValue={this.state.filters["leaserCompanyName"]}
                                           backingFieldKey={"leaserCompanyRegNo"}
                                           backingFieldValue={this.state.filters["leaserCompanyRegNo"]}
                                           valueKey={"id"}
                                           portalTarget={"modal"}
                                           baseURL={this.props.baseURL}
                                           onChange={this.handleMultiFilterChange}
                        />
                        <h6>Haldur</h6>
                        <PersonListFilter  fieldKey={"managerCode"}
                                           fieldValue={this.state.filters["managerCode"]}
                                           freeTextFieldKey={"managerName"}
                                           freeTextFieldValue={this.state.filters["managerName"]}
                                           portalTarget={"modal"}
                                           baseURL={this.props.baseURL}
                                           companyRegNo={this.props.rkasCompanyRegNo}
                                           onChange={this.handleMultiFilterChange}
                        />
                    </>
                    }
                </div>
            </>
        )
    }

    render() {
        return (
            <aside className={"filter " + (this.props.show ? "show" : "")}>
                <nav>

                <div className="d-flex justify-content-between">
                  <h4>Filtreeri</h4>
                  <button onClick={this.clearAllFilters} className="btn btn-link btn-sm p-0">Tühista kõik filtrid</button>
                </div>
                <hr />

                {this.props.type === "contract" && this.renderContractFilters()}
                {this.props.type === "mainLease" && this.renderMainLeaseFilters()}
                {this.props.type === "report" && this.renderReportFilters()}

                <div className="filter-buttons">
                  <button className="btn btn-secondary"
                          onClick={this.saveFilters}>
                      Rakenda filtrid
                  </button>
                  <button className="btn btn-light"
                          onClick={this.props.toggleFilters}>
                      Sulge
                  </button>
                </div>
                </nav>
             </aside>
        );
    }

    private renderTextFilter(label: string, field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters) {
      return (
          <>
            <h6>{label}</h6>
            <input type="text"
                   name="number"
                   className="form-control"
                   value={this.state.filters[field] as string || ''}
                   onChange={this.handleInputChange(field)} />
          </>
      )
    }

    private renderDateFilter(label: string, field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters) {
      const selected = this.state.filters[field]
          ? moment(this.state.filters[field] as unknown as string, 'YYYY-MM-DD').toDate()
          : null;

      return (
          <>
            <h6>{label}</h6>
            <DatePicker
                className="form-control calendar-input"
                selected={selected}
                dateFormat="dd.MM.yyyy"
                onChange={this.handleDateChange(field)}
            />
          </>
      );
    }

    private renderClassifierRadioFilter(label: string, field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters,
                                        classifierCode: ClassifierCode) {

      return (
          <>
            <h6>{label}</h6>
            <div className="dateFilter-btn-group btn-group d-flex">
              {getClassifierValues(this.props.classifiers, classifierCode)
                  .map((classifierValue, index) => (
                      <Button
                          key={`${field}-${index}`}
                          size="sm"
                          color="outline-primary"
                          onClick={() => this.handleRadioButtonChange(field, classifierValue.code)}
                          className={this.state.filters[field] === classifierValue.code ? "active" : ""}
                          role="button">
                        {classifierValue.name}
                      </Button>
                  ))}
            </div>
          </>
      )
    }

    private renderClassifierMultiRadioFilter(label: string, field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters,
                                        classifierCode: ClassifierCode) {

        return (
            <>
                <h6>{label}</h6>
                <div className="dateFilter-btn-group btn-group d-flex">
                    {getClassifierValues(this.props.classifiers, classifierCode)
                        .map((classifierValue, index) => (
                            <Button
                                key={`${field}-${index}`}
                                size="sm"
                                color="outline-primary"
                                onClick={() => this.handleMultiRadioButtonChange(field, classifierValue.code)}
                                className={this.isMultiRadioButtonChecked(field, classifierValue.code) ? "active" : ""}
                                role="button">
                                {classifierValue.name}
                            </Button>
                        ))}
                </div>
            </>
        )
    }

    private renderClassifierSelectFilter(label: string, field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters,
                                         classifierCode: ClassifierCode, isMulti: boolean) {
      const classifierValues = getClassifierValues(this.props.classifiers, classifierCode);
      const options = classifierValues.map((classifierValue: ClassifierValue) => ({
        label: classifierValue.name,
        value: classifierValue.code
      }));

      let valueOption;
      if (this.state.filters[field]) {
         valueOption = isMulti ? options.filter(option => this.state.filters[field].includes(option.value)) : options.find(option => option.value == this.state.filters[field]);
      }

      return (
          <>
            <h6>{label}</h6>
              {isMulti &&
              <Select className="react-select"
                      classNamePrefix="react-select"
                      menuPlacement={"auto"}
                      isClearable={true}
                      placeholder="Vali"
                      isMulti={true}
                      options={options}
                      value={valueOption}
                      onChange={(options) => this.handleFilterChange(field, options ? options.flatMap(option => option.value) : undefined)}
              />
              }
              {!isMulti &&
              <Select className="react-select"
                      classNamePrefix="react-select"
                      menuPlacement={"auto"}
                      isClearable={true}
                      placeholder="Vali"
                      isMulti={false}
                      options={options}
                      value={valueOption}
                      onChange={(option) => this.handleFilterChange(field, option ? option.value : undefined)}
              />
              }
          </>
      );
    }

    private renderTypeFilter() {
      const label = "Tüüp";

      if (this.props.contractType === ContractType.LeaseAgreement) {
        return this.renderClassifierSelectFilter(label, "leaseContractType", ClassifierCode.LeaseContractType, true);
      } else if (this.props.contractType === ContractType.ConsumptionContract) {
        return this.renderClassifierRadioFilter(label, "consumptionContractType", ClassifierCode.ConsumptionContractType);
      } else if (this.props.contractType === ContractType.SupportServiceContract) {
          return this.renderClassifierRadioFilter(label, "supportServiceContractType", ClassifierCode.SupportServiceContractType);
      } else {
        return this.renderClassifierRadioFilter(label, "serviceContractType", ClassifierCode.ServiceContractType);
      }
    }

    private renderCompanyFilters() {
        if (this.props.contractType === ContractType.LeaseAgreement
            || this.props.contractType == ContractType.EnergySaleAgreement) {
          return (
              <>
                  { this.props.contractType === ContractType.LeaseAgreement &&
                      <>
                      <h6>Üürnik</h6>
                      <CompanyListFilter
                      fieldKey={"leaserRegNo"}
                      fieldValue={this.state.filters["leaserRegNo"]}
                      freeTextFieldKey={"leaserName"}
                      freeTextFieldValue={this.state.filters["leaserName"]}
                      backingFieldKey={"leaserRegNo"}
                      backingFieldValue={this.state.filters["leaserRegNo"]}
                      valueKey={"regNo"}
                      portalTarget={"modal"}
                      baseURL={this.props.baseURL}
                      onChange={this.handleMultiFilterChange}
                      />
                      </>
                  }
                  <>
                      <h6>Haldur</h6>
                      <PersonListFilter  fieldKey={"managerPersonalCode"}
                                         fieldValue={this.state.filters["managerPersonalCode"]}
                                         freeTextFieldKey={"managerName"}
                                         freeTextFieldValue={this.state.filters["managerName"]}
                                         portalTarget={"modal"}
                                         baseURL={this.props.baseURL}
                                         companyRegNo={this.props.rkasCompanyRegNo}
                                         onChange={this.handleMultiFilterChange}
                      />
                  </>
                  { this.props.contractType === ContractType.LeaseAgreement &&
                    <>{this.renderClassifierSelectFilter("Valitsemisala", "governanceAreaType", ClassifierCode.GovernanceAreaType, false)}</>
                  }
              </>
          );
        } else {
          return (
              <>
                {this.props.contractType !== ContractType.ConsumptionContract &&
                this.renderTextFilter("Tellija", "customerName")
                }

                {this.renderTextFilter("Peatöövõtja", "contractorName")}

                {this.props.contractType !== ContractType.ConsumptionContract &&
                this.renderTextFilter(this.props.contractType == ContractType.ServiceContract ? "Töövõtja projektijuht" : "Peatöövõtja kontaktisik", "contractorRepresentativeName")
                }
              </>
          );
        }
    }

    private clearAllFilters = () => {
        this.setState(
            {
              filters: {} as ContractFilters|MainLeaseFilters|ReportFilters
            },
            this.saveFilters
        );
    };

    private handleFilterChange = (field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters, value: any) => {
        this.setState({
            filters: {
                ... this.state.filters,
                [field]: value !== '' ? value : undefined
            }
        });
    };

    private handleMultiFilterChange = (fields: [keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters], values: any) => {
        const filters = this.state.filters;
        fields.map((field,index) => filters[field] = values[index]);
        this.setState({
            filters: filters
        });
    };

    private handleInputChange = (field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters) =>
        (event: ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
            const value = event.target.value;
            this.handleFilterChange(field, value);
        };

    private handleDateChange = (field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters) => (value: Date) => {
        this.handleFilterChange(field, value ? moment(value).format('YYYY-MM-DD') : null);
    };

    private handleRadioButtonChange = (field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters, value?: any) => {
        this.setState({
            filters: {
                ... this.state.filters,
                [field]: value != undefined && this.state.filters[field] !== value ? value : undefined
            }
        })
    };

    private handleMultiRadioButtonChange = (field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters, value?: any) => {
        let fieldValue = (!Array.isArray(this.state.filters[field]) ? [] : this.state.filters[field]) as string[];
        if(fieldValue.some(f => f === value)){
            fieldValue.splice(fieldValue.findIndex(f => f === value),1);
        } else{
            fieldValue.push(value);
        }

        this.setState({
            filters: {
                ... this.state.filters,
                [field]: fieldValue
            }
        })
    };

    private isMultiRadioButtonChecked(field: keyof ContractFilters|keyof MainLeaseFilters|keyof ReportFilters, value?: any) : boolean {
        if(!this.state.filters[field] || !Array.isArray(this.state.filters[field])) return false;
        return (this.state.filters[field] as string[]).some(f => f === value);
    }

    private saveFilters = () => {
        this.props.onFiltersChange(this.state.filters);
        this.props.toggleFilters();
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    rkasCompanyRegNo: getRkasCompanyRegNo(state),
});

export default connect(mapStateToProps)(ListFilters);
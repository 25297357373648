import {
    ContractRowState,
    ContractRowStateMap, EnergyContractRow,
    LeaseContractRow,
    RowEditState,
    RowGroupState,
    RowValidityState,
    ServiceContractRow
} from "../Contracts/ContractDetails/ContractRow";
import {
    ContractType,
    ContractValidationErrorType,
    FullConstructionContract, FullEnergyContract,
    FullLeaseContract,
    FullServiceContract
} from "../Contracts/Contract";
import equal from "fast-deep-equal/es6/react";

export class ContractTableUtils {

    /**
     * Divide rows into groups based on expiry dates
     * Detect row change types by diffing between saved and editing contracts
     */
    static ProcessRows(editingContract: FullLeaseContract | FullServiceContract | FullConstructionContract | FullEnergyContract, savedContract: FullLeaseContract | FullServiceContract | FullConstructionContract | FullEnergyContract) : ContractRowStateMap {
        const now = new Date();
        const editRowStates : ContractRowStateMap =  new Map();
        const rowErrors = editingContract.errors?.filter(error => error.type == ContractValidationErrorType.RowException);
        const hasStartDate = editingContract.type == ContractType.LeaseAgreement || editingContract.type == ContractType.EnergySaleAgreement;

        editingContract.rows.forEach(row => {
            const editRowState = new ContractRowState();
            editRowStates.set(row.id || row.tempId, editRowState);

            let savedRowState = null;
            const rowStartDate = hasStartDate ? row.startDate : row.serviceStartDate;
            const rowEndDate = hasStartDate ? row.endDate : row.serviceEndDate;
            if(new Date(rowEndDate || savedContract.endTime) < now)
                editRowState.groupState = RowGroupState.Expired;
            if(new Date(rowStartDate || savedContract.startTime) > now)
                editRowState.groupState = RowGroupState.Future;
            if(new Date(rowEndDate || savedContract.endTime) > now && new Date(rowStartDate || savedContract.startTime) < now)
                editRowState.groupState = RowGroupState.Current;

            // @ts-ignore
            const savedRow = savedContract.rows.find(savedRow => row.id && savedRow.id == row.id);
            if(savedRow) {
                savedRowState = new ContractRowState();

                const rowStartDate = hasStartDate ? savedRow.startDate : savedRow.serviceStartDate;
                const rowEndDate = hasStartDate ? savedRow.endDate : savedRow.serviceEndDate;
                if(new Date(rowEndDate || savedContract.endTime) < now)
                    savedRowState.groupState = RowGroupState.Expired;
                if(new Date(rowStartDate || savedContract.startTime) > now)
                    savedRowState.groupState = RowGroupState.Future;
                if(new Date(rowEndDate || savedContract.endTime) > now && new Date(rowStartDate || savedContract.startTime) < now)
                    savedRowState.groupState = RowGroupState.Current;
            }

            if(!savedRowState || (row.deletedOn == null && savedRow.deletedOn != null)) {
                editRowState.editState = row.deletedOn == null ? RowEditState.JustAdded : RowEditState.JustDeleted;
            } else if(row.deletedOn != null) {
                editRowState.editState = savedRow.deletedOn == null ? RowEditState.JustDeleted : RowEditState.Deleted;
            } else if(savedRowState && editRowState.groupState != savedRowState.groupState) {
                editRowState.groupState = savedRowState.groupState;
                editRowState.editState = RowEditState.JustMoved;
            } else {
                editRowState.editState = equal(savedRow,row) ? RowEditState.Normal : RowEditState.JustChanged;
            }

            editRowState.validityState = RowValidityState.Valid;
            if(rowErrors) {
                const rowError = rowErrors.find(error => error.id && error.id === (row.id || row.tempId));
                if(rowError) {
                    editRowState.validityState = RowValidityState.Invalid;
                    editRowState.message = rowError.errorMessage;
                }
            }
        })

        return editRowStates;
    }

    static renderRowStatus(row: LeaseContractRow | ServiceContractRow | EnergyContractRow, rowStates: ContractRowStateMap) {
        let classNames = "";
        const rowState =  rowStates.get(row.id || row.tempId);

        const editState = rowState?.editState;
        switch(editState) {
            case RowEditState.JustMoved:
                classNames += " row-moved";
                break;
            case RowEditState.JustAdded:
                classNames += " row-added";
                break;
            case RowEditState.Deleted:
                classNames += " row-deleted";
                break;
            case RowEditState.JustDeleted:
                classNames += " row-just-deleted";
                break;
            case RowEditState.Normal:
                classNames += " row-normal";
                break;
            case RowEditState.JustChanged:
                classNames += " row-just-changed";
                break;
            default:
                classNames += " row-normal";
        }

        const validityState =  rowState?.validityState;
        switch(validityState) {
            case RowValidityState.Valid:
                classNames += " row-valid";
                break;
            case RowValidityState.Invalid:
                classNames += " row-invalid";
                break;
            default:
                classNames += " row-valid";
        }

        return classNames;
    }

    static renderRowMessage(row: LeaseContractRow | ServiceContractRow | EnergyContractRow, rowStates: ContractRowStateMap) {
        const rowState =  rowStates.get(row.id || row.tempId);
        return rowState.message;
    }
}
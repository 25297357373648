import React from "react";
import {
    FullSupportServiceContract,
    ReserveCalculationBasedOn
} from "../../Contract";
import {Classifier, ClassifierCode} from "../../../Classifier/Classifier";
import { RootState } from "../../../rootReducer";
import { getClassifiers } from "../../../Classifier/classifierReducer";
import { connect } from "react-redux";
import TextField from "../ContractInfo/Fields/TextField";
import TextAreaField from "../ContractInfo/Fields/TextAreaField";
import DateField from "../ContractInfo/Fields/DateField";
import { getBaseURL, getRkasCompanyRegNo, getSapEnabled } from "../../../Config/configReducer";
import CompanyField from "../ContractInfo/Fields/CompanyField";
import NumberField from "../ContractInfo/Fields/NumberField";
import {modalActions} from "../../../Modal/modalActions";
import PersonField from "../ContractInfo/Fields/PersonField";
import {ContactInfoModalData, ModalType} from "../../../Modal/Modal";
import ReserveField from "../ContractInfo/Fields/ReserveField";
import ClassifierField from "../ContractInfo/Fields/ClassifierField";
import PatternedTextField from "../ContractInfo/Fields/PatternedTextField";

interface Props {
    classifiers: Classifier[]
    baseURL: string;
    contract: FullSupportServiceContract;
    editingContract?: FullSupportServiceContract;
    editMode: boolean;
    updateEditingContract: (contract: FullSupportServiceContract) => void;
    rkasCompanyRegNo: string;
    sapEnabled: boolean;

    toggleModal: typeof modalActions.toggleModal;
}

class SupportServiceInfo extends React.Component<Props, any> {

    private onChange = (fieldKey: keyof FullSupportServiceContract, value: any) => {
        if (this.props.editingContract) {
            this.props.updateEditingContract({
                ...this.props.editingContract,
                [fieldKey]: value
            });
        }
    };

    render() {
        const contract = this.props.editingContract || this.props.contract;

        return (
            <div className="row">
                <div className={this.props.editMode
                    ? "col-12 col-lg-5 col-xl-5"
                    : "col-12 col-md-6"}>
                    <>
                        <TextField contract={contract}
                                   label={"Pealkiri"}
                                   fieldKey={"generalDocumentTitle"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <ClassifierField contract={contract}
                                         label={"Tüüp"}
                                         fieldKey={"supportServiceContractType"}
                                         classifiers={this.props.classifiers}
                                         classifierCode={ClassifierCode.SupportServiceContractType}
                                         editMode={this.props.editMode}
                                         editable={false}
                                         onChange={this.onChange}
                        />
                        <PatternedTextField contract={contract}
                                            label={"RHR number"}
                                            fieldKey={"rhrReferenceNumber"}
                                            replacementPattern={/\s/g}
                                            validationPattern={/^\s*(?:[\w\.]\s*){0,15}$/}
                                            editMode={this.props.editMode}
                                            editable={this.props.editMode}
                                            onChange={this.onChange}
                        />
                        <TextField contract={contract}
                                   label={"EDHS kood"}
                                   fieldKey={"edhsCode"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        {this.props.sapEnabled &&
                            <TextField contract={contract}
                                       label={"SAP kood"}
                                       fieldKey={"sapCode"}
                                       editMode={this.props.editMode}
                                       editable={false}
                                       onChange={this.onChange}
                            />
                        }
                        <CompanyField contract={contract}
                                      label={"Tellija"}
                                      fieldKey={"customer"}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      required={true}
                                      onChange={this.onChange}
                        />
                        <PersonField contract={contract}
                                     label={"Tellija kontaktisik"}
                                     fieldKey={"customerRepresentative"}
                                     company={contract.customer}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     isClearable={true}
                                     required={true}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <CompanyField contract={contract}
                                      label={"Teine osapool"}
                                      fieldKey={"contractor"}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      required={true}
                                      onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Algus"}
                                   fieldKey={"startTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Lõpp"}
                                   fieldKey={"endTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                    </>
                </div>
                <div className={this.props.editMode
                    ? "col-12 col-lg-6 col-xl-5 offset-md-0 offset-lg-1 offset-xl-2 mt-3 mt-lg-0"
                    : "col-12 col-md-6"}>
                    <>
                        {this.renderInvoiceDueDays()}
                        <NumberField contract={contract}
                                     label={"Maksumus"}
                                     fieldKey={"sum"}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     onChange={this.onChange}
                                     minimumFractionDigits={2}
                                     required={true}
                                     suffix={" €"}
                        />
                        {this.renderReserveField(contract)}
                        <TextAreaField contract={contract}
                                       label={"Kommentaarid"}
                                       fieldKey={"note"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                    </>
                </div>
            </div>
        );
    }


    private renderInvoiceDueDays(): JSX.Element {
        return (
            <>
                {this.props.editMode &&
                <NumberField contract={this.props.editingContract || this.props.contract}
                             label={"Maksetähtaeg (päevades)"}
                             fieldKey={"paymentTerm"}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
                />
                }

                {!this.props.editMode &&
                <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Maksetähtaeg"}</label>
                        {this.props.contract.paymentTerm &&
                        <>
                            <span className="font-weight-bold">{this.props.contract.paymentTerm}</span>
                            <span className="font-weight-bold">&nbsp;{this.props.contract.paymentTerm == "1" ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!this.props.contract.paymentTerm &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private renderReserveField(contract: FullSupportServiceContract) : JSX.Element {
        if(this.props.editMode){
            return (
                <div className={"form-row custom-form-row"}>
                    <div className={"col-12 align-items-start"}>
                        <label className="w-200">Reserv<span className="input-required" /></label>
                        {contract.reserveCalculationBasedOn == ReserveCalculationBasedOn.Value &&
                        <ReserveField contract={contract}
                                      label={""}
                                      reserveCalculationBasedOn={contract.reserveCalculationBasedOn}
                                      reservePercentage={contract.reservePercentage}
                                      fieldKey={"reserveValue"}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      onChange={this.onChange}
                                      skipLabel={true}
                                      skipFormRowClasses={true}
                        />
                        }
                        {contract.reserveCalculationBasedOn == ReserveCalculationBasedOn.Percent &&
                        <ReserveField contract={contract}
                                      label={""}
                                      reserveCalculationBasedOn={contract.reserveCalculationBasedOn}
                                      reservePercentage={contract.reservePercentage}
                                      fieldKey={"reservePercentage"}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      onChange={this.onChange}
                                      skipLabel={true}
                                      skipFormRowClasses={true}
                        />
                        }
                        <div style={{flexGrow:1}}>
                            <ClassifierField contract={contract}
                                             label={""}
                                             fieldKey={"reserveCalculationBasedOn"}
                                             classifiers={this.props.classifiers}
                                             classifierCode={ClassifierCode.ReserveCalculationBasedOn}
                                             editMode={this.props.editMode}
                                             editable={this.props.editMode}
                                             onChange={this.onChange}
                                             skipLabel={true}
                                             skipFormRowClasses={true}
                            />
                        </div>
                    </div>
                </div>);
        }
        else {
            return (
                <ReserveField contract={contract}
                              label={"Reserv"}
                              reserveCalculationBasedOn={contract.reserveCalculationBasedOn}
                              reservePercentage={contract.reservePercentage}
                              fieldKey={"reserveValue"}
                              editMode={this.props.editMode}
                              editable={this.props.editMode}
                              onChange={this.onChange}
                />
            );
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    rkasCompanyRegNo: getRkasCompanyRegNo(state),
    sapEnabled: getSapEnabled(state)
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps,mapDispatchToProps)(SupportServiceInfo);
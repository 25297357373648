import React from "react";
import { RootState } from "../../rootReducer";
import { getBaseURL, getSapEnabled } from "../../Config/configReducer";
import { connect } from "react-redux";
import { ContractType, Property, SupportServiceContract } from "../Contract";
import axios from "axios";
import { Permission } from "../../Auth/User";
import { alertActions } from '../../Alerts/alertActions';
import { Alert, AlertContentType, AlertType } from '../../Alerts/Alert';
import { AbstractContractList } from "../AbstractContractList";
import { ColumnDescription } from "react-bootstrap-table-next";
import {
  currencyFormatter,
  dateFormatter,
  dateTimeFormatter,
  percentFormatter
} from "../../BootstrapTable/customFormatters";
import { NavLink } from "react-router-dom";
import {
  ClassifierCode,
  ClassifierValue,
  getClassifierValueName,
  getClassifierValues
} from "../../Classifier/Classifier";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {getClassifiers} from "../../Classifier/classifierReducer";

class SupportServiceContractList extends AbstractContractList<SupportServiceContract> {

  static readonly TYPE = ContractType.SupportServiceContract;

  protected getType(): ContractType {
    return SupportServiceContractList.TYPE;
  }

  protected getManagePermission(): Permission | Permission[] {
    return Permission.ManageSupportServiceContract;
  }

  protected getColumns(): ColumnDescription<SupportServiceContract>[] {
    return [
      {
        dataField: "number",
        text: "Lepingu number",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/support-service/" + row.id}>{cell}</NavLink>
      },
      {
        dataField: "generalDocumentTitle",
        text: "Pealkiri",
        sort: true
      },
      {
        dataField: "supportServiceContractType",
        text: "Tüüp",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.SupportServiceContractType, cell)
      },
      {
        dataField: "rhrReferenceNumber",
        text: "RHR number",
        sort: true
      },
      {
        dataField: "edhsCode",
        text: "EDHS kood",
        sort: true
      },
      ...(this.props.sapEnabled ? [{
        dataField: "sapCode",
        text: "SAP kood",
        sort: true
      }] : []),
      {
        dataField: "customer.name",
        text: "Tellija",
        sort: true
      },
      {
        dataField: "contractor.name",
        text: "Teine osapool",
        sort: true
      },
      {
        dataField: "properties",
        text: "Kinnistu",
        formatter: (value) => value && value.length ? value.map((p: Property) => p.name).join(", ") : null
      },
      {
        dataField: "publishedOn",
        text: "Avaldatud",
        sort: true,
        formatter: dateTimeFormatter
      },
      {
        dataField: "startTime",
        text: "Algus",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "endTime",
        text: "Lõpp",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "sum",
        text: "Maksumus",
        sort: true,
        formatter: currencyFormatter
      },
      {
        dataField: "reserveValue",
        text: "Lepingu reserv",
        sort: true,
        formatter: (cell, row) => {
          if (row.reserveValue != undefined && row.reservePercentage != undefined) {
            const reserveValue = currencyFormatter(row.reserveValue || 0);
            const reservePercentage = percentFormatter(row.reservePercentage || 0);

            return `${reserveValue} (${reservePercentage})`;
          }
          return null;
        }
      }
    ];
  }

  protected renderAddButton() {
    return (
        <>
          <UncontrolledDropdown className="ml-4">
            <DropdownToggle color="primary" caret>
              + Lisa tugiteenusleping
            </DropdownToggle>
            <DropdownMenu>
              {getClassifierValues(this.props.classifiers, ClassifierCode.SupportServiceContractType)
                  .map((classifierValue, index) => (
                      <DropdownItem
                          key={`${classifierValue.name}-${index}`}
                          tag="a"
                          className="p-3 pointer"
                          disabled={this.state.addButtonLoading}
                          onClick={(e) => this.handleAddMultiButton(e, classifierValue)}
                          role="button">
                        {classifierValue.name}
                      </DropdownItem>
                  ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
    );
  }


  protected handleAddMultiButton = (e: React.MouseEvent<HTMLElement>, classifierValue :  ClassifierValue) => {
    e.preventDefault();

    this.setState({
      addButtonLoading: true
    }, () => {
      axios.post(this.props.baseURL + "/contracts/support-service?type=" + classifierValue.code)
          .then(res => {
            this.props.history.push(this.props.match!.url + "/" + res.data.id, { action: "new" });
          })
          .catch(error => {
            let alertType = AlertContentType.ContractAddError;
            if (error.response) {
              const apiErrorType = Alert.fromException(error.response.data.error);
              if(apiErrorType)
                alertType = apiErrorType;
            }

            const alert: Alert = new Alert();
            alert.type = AlertType.Danger;
            alert.content = alertType;
            this.props.addAlert(alert);

            this.setState({
              addButtonLoading: false
            });
          });
    });
  };

  protected handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {};
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state),
  sapEnabled: getSapEnabled(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportServiceContractList);

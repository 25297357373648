import { isNullOrUndefined } from "util";
import { NumberFormatOptions } from "./FormatOptions";

export class NumberUtils {

  static format(
      value?: string|number,
      minimumFractionDigits: number = NumberFormatOptions.minimumFractionDigits,
      maximumFractionDigits: number = NumberFormatOptions.maximumFractionDigits
  ) {
    let stringValue: string = !isNullOrUndefined(value) ? value.toString() : "";
    stringValue = stringValue
        .replace(".", ",")
        .replace(/(^,*|[^0-9\s,])/g, "");

    if (stringValue) {
      value = this.parse(stringValue);
    }

    if (typeof value === "number") {
      let formattedValue: string = Intl.NumberFormat("et-EE", {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
      }).format(value);

      if (stringValue.indexOf(",") === stringValue.length - 1) {
        formattedValue += ",";
      }
      if (stringValue.match(/,[0-9]*0+/) && stringValue.split(",")[1].length <= maximumFractionDigits) {
        formattedValue = formattedValue.split(",")[0] + "," + stringValue.split(",")[1];
      }

      return formattedValue;
    }
    return "";
  }

  static parse(value: string): number|undefined {
    if (value) {
      value = value.replace(/\s/g, "")
                   .replace(",", ".");

      if (value) {
        return parseFloat(value);
      }
    }

    return undefined;
  }
}
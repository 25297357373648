/* eslint react/prop-types: 0 */
import React, { Component } from "react";

import pageResolver from "react-bootstrap-table2-paginator/lib/src/page-resolver";
import PaginationList from "react-bootstrap-table2-paginator/lib/src/pagination-list";

const paginationListAdapter = WrappedComponent =>
    class PaginationListAdapter extends pageResolver(Component) {
      render() {
        const {
          lastPage,
          totalPages,
          pageButtonRenderer,
          onPageChange,
          disablePageTitle,
          hidePageListOnlyOnePage,
          pageListRenderer
        } = this.props;
        const pages = this.calculatePageStatus(
            this.calculatePages(totalPages, lastPage),
            lastPage,
            disablePageTitle
        );
        if (totalPages === 1 && hidePageListOnlyOnePage) {
          return null;
        }
        if (pageListRenderer) {
          return pageListRenderer({
            pages: pages,
            onPageChange: onPageChange
          });
        }
        return (
            <WrappedComponent
                pageButtonRenderer={ pageButtonRenderer }
                onPageChange={ onPageChange }
                pages={ pages }
            />
        );
      }
    };


export const PaginationListWithAdapter = paginationListAdapter(PaginationList);
export default paginationListAdapter;
import React from "react";
import { FullEnergyContract } from "../../Contract";
import {connect} from "react-redux";
import {contractDetailsActions} from "../contractDetailsActions";
import EnergyContractTable from "./EnergyContractTable";

interface Props {
    contract: FullEnergyContract;
    infoEditMode: boolean;
    editingContract?: FullEnergyContract;
    updateEditingContract: (contract: FullEnergyContract, callback?: () => void) => void;
    onSaveContract: () => void;
}



export class EnergyContractDetails extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!this.props.infoEditMode) {
            return (
                <>
                    <EnergyContractTable  contract={this.props.contract}
                                          editingContract={this.props.editingContract}
                                          updateEditingContract={this.props.updateEditingContract}
                                          onSaveContract={this.props.onSaveContract}
                    />
                </>
            );
        }
        return <></>;
    }
}

const mapDispatchToProps = {
    toggleAddRowModal: contractDetailsActions.toggleAddRowModal
};

export default connect(null,mapDispatchToProps)(EnergyContractDetails);
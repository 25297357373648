import React from "react";
import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import { FullContract } from "../../../Contract";
import { NumberInput } from "../../../../Common/NumberInput";
import { NumberUtils } from "../../../../Common/NumberUtils";
import {MainLease} from "../../../../MainLease/MainLease";

interface Props<T extends FullContract | MainLease> extends ContractInfoFieldProps<T> {
  minimumFractionDigits? : number;
  maximumFractionDigits? : number;
  suffix? : string;
  separator? : string;
}

export default class NumberField<T extends FullContract | MainLease> extends ContractInfoField<T, Props<T>> {

  private onChange = (value: number) => {
    this.props.onChange(this.props.fieldKey, value);
  };

  protected renderReadOnlyField(): JSX.Element {
    let number = undefined;
    if(this.getFieldValue()) {
      number = NumberUtils.format(this.getFieldValue(), this.props.minimumFractionDigits || 0, this.props.maximumFractionDigits || 4);
      if(this.props.separator)
        number = number.replace(",", this.props.separator);
      if(this.props.suffix)
        number = number + this.props.suffix;
    }

    return (
        <span className="font-weight-bold">
          {number != undefined ? number : "-"}
        </span>
    );
  }

  protected renderEditableField(): JSX.Element {
    return (
        <NumberInput value={this.getFieldValue()}
                     onNumberChange={this.onChange}
        />
    );
  }
}
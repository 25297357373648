import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import {ConstructionContractImportRowsModalData} from "../Modal";
import {CostItem, ServiceContractRow} from "../../Contracts/ContractDetails/ContractRow";
import {Classifier, ClassifierCode, getClassifierValueName} from "../../Classifier/Classifier";
import {ExceptionRowDefaults, TimeLimits} from "../../Config/Config";
import {ContractType} from "../../Contracts/Contract";
import {RootState} from "../../rootReducer";
import {getBaseURL, getExceptionRowsDefaultValues, getRegularRowsDefaultValues} from "../../Config/configReducer";
import {getClassifiers} from "../../Classifier/classifierReducer";
import {getCostItems} from "../../Contracts/costItemReducer";
import {connect} from "react-redux";
import axios from "axios";
import {NumberInput} from "../../Common/NumberInput";
import Dropzone from "react-dropzone";
import {Alert, AlertContentType, AlertType} from "../../Alerts/Alert";
import {alertActions} from "../../Alerts/alertActions";
import {
    ConstructionContractRowImportRow,
    ContractRowImportResult
} from "../../Contracts/ContractDetails/ContractRowImport";
import {klona} from "klona";


interface Props {
    modalOpen: boolean;
    modalData: ConstructionContractImportRowsModalData;
    onToggleModal: () => void;
    addAlert: typeof alertActions.addAlert;

    allCostItems: CostItem[];
    baseURL: string;
    classifiers: Classifier[];
    exceptionRowsDefaults: ExceptionRowDefaults[];
    regularRowsDefaults: TimeLimits;
}

interface State {
    invalidImportRows: ConstructionContractRowImportRow[];
    validImportRowCount: number;
    nextId: number;
}

class ConstructionContractImportRowsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            invalidImportRows: null,
            validImportRowCount: 0,
            nextId: -1
        }
    }


    componentWillMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            this.resetState();
        }
    }

    private cancel = () => {
        this.resetState();
        this.props.onToggleModal();
    };

    private resetState() {
        this.setState({
            invalidImportRows: null,
            validImportRowCount: 0,
            //nextId: -1
        });
    }

    private onSave = () => {
        const contractId = this.props.modalData.editingContract.id;
        let cleanRows = klona(this.state.invalidImportRows);
        cleanRows.forEach(row => row.errorCodes = []);

        axios.post(`${this.props.baseURL}/contracts/${contractId}/rows/import`, cleanRows)
            .then((res) => {
                this.handleImportResult(res.data);
            }).catch(error => {
            this.handleImportError(error);
        });
    };

    private handleFileUpload = ([file]: File[]) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("contractType", ContractType.ConstructionContract);
        const contractId = this.props.modalData.editingContract.id;

        axios.post(`${this.props.baseURL}/contracts/${contractId}/rows/import-file`, formData)
            .then((res) => {
                this.handleImportResult(res.data);
            }).catch(error => {
            this.handleImportError(error);
        });
    };

    private handleImportResult(importResult: ContractRowImportResult) {
        if (importResult.validRows.length > 0) {
            const newRows = this.mergeValidRows(importResult.validRows as ServiceContractRow[]);
            this.props.modalData.updateEditingContract({
                ...this.props.modalData.editingContract,
                rows: newRows
            });
            const prevCount = this.state.validImportRowCount;
            this.setState({validImportRowCount: prevCount + importResult.validRows.length});
        }

        if (importResult.invalidRows.length > 0) {
            this.setState({
                invalidImportRows: importResult.invalidRows as ConstructionContractRowImportRow[]
            }, () => {this.forceUpdate()});
        } else {
            this.props.onToggleModal();
        }
    }

    private handleImportError(error: any) {
        let alertType = AlertContentType.ContractRowImportFail;
        if (error.response) {
            const apiErrorType = Alert.fromException(error.response.data.error);
            if (apiErrorType)
                alertType = apiErrorType;
        }

        const alert: Alert = new Alert();
        alert.type = AlertType.Danger;
        alert.content = alertType;
        this.props.addAlert(alert);
    }

    private mergeValidRows(validRows: ServiceContractRow[]): ServiceContractRow[] {
        let nextId = this.state.nextId;
        let rows = this.props.modalData.editingContract.rows;
        let newRows = validRows as ServiceContractRow[];

        for (let newRow of newRows) {
            if (newRow.id != null && newRow.id >= 0)
                rows[rows.findIndex(r => r.id == newRow.id)] = newRow;
            else
                nextId = this.addRow(nextId, newRow, rows);
        }

        this.setState({nextId: nextId});
        return rows;
    }

    private addRow(nextId: number, newRow: ServiceContractRow, rows: ServiceContractRow[]): number {

        newRow.slaReactionTimeLimit = this.props.regularRowsDefaults.slaReactionTimeLimit;
        newRow.slaCompletionTimeLimit = this.props.regularRowsDefaults.slaCompletionTimeLimit;
        newRow.tempId = nextId;

        rows.push(newRow);
        nextId -= 1;

        return nextId;
    }

    render() {
        const inEditMode: boolean = this.state.invalidImportRows && this.state.invalidImportRows.length > 0;
        const rows = this.state.invalidImportRows;

        return (
            <Modal isOpen={this.props.modalOpen} size="xl">
                <ModalHeader toggle={this.cancel}>
                    Ridade import
                </ModalHeader>

                <ModalBody className="p-2 p-lg-3">
                    {!inEditMode &&
                    <>
                        {this.renderUpload()}
                    </>
                    }
                    {inEditMode &&
                    <>
                        <h6 className="text-dark font-weight-normal mt-0 mb-3">{this.state.validImportRowCount} rida
                            imporditud, {this.state.invalidImportRows.length}-l real esineb vigasid</h6>
                        <div className="datatable">
                            <div className="table-responsive-lg">
                                <div>
                                    <table
                                        className="table table-bordered datatable-style table-service-contract-details table-hover w-100 overflow-hidden"
                                        style={{minWidth: "1000px"}}>
                                        {rows.length > 0 &&
                                        <>
                                            <thead>
                                            {this.renderTableHeaderRow(rows)}
                                            </thead>
                                            <tbody>
                                            {this.renderTableBodyRows(rows)}
                                            </tbody>
                                        </>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary"
                    >
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        color="primary"
                        hidden={!inEditMode}
                    >
                        Impordi read
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private renderTableHeaderRow(data: ConstructionContractRowImportRow[]) {
        return (
            <tr>
                <th>Kulukoht</th>
                <th>Märkus</th>
                <th>Summa</th>
                <th>Projekti kood</th>
                <th>Kinnistu kood</th>
            </tr>
        );
    }

    private renderTableBodyRows(rows: ConstructionContractRowImportRow[]) {
        return (
            rows.map((row, index) => {
                return <React.Fragment key={"row-" + index}>
                    <tr id={"row-" + index} className="row-normal">
                        <td>
                            <input className="form-control"
                                   value={row.costItemCode || ""}
                                   onChange={e => this.changeRow(index, "costItemCode", e.target.value)}
                            />
                        </td>
                        <td>
                            <input className="form-control"
                                   value={row.comment || ""}
                                   onChange={e => this.changeRow(index, "comment", e.target.value)}
                            />
                        </td>
                        <td>
                            <NumberInput value={row.sum}
                                         onNumberChange={value => row.sum = value}/>
                        </td>
                        <td>
                            <input className="form-control"
                                   value={row.projectCode || ""}
                                   onChange={e => this.changeRow(index, "projectCode", e.target.value)}
                            />
                        </td>
                        <td>
                            <input className="form-control"
                                   value={row.propertyCode || ""}
                                   onChange={e => this.changeRow(index, "propertyCode", e.target.value)}
                            />
                        </td>
                        <UncontrolledTooltip target={"row-" + index} fade={false}
                                             innerClassName="tooltip-row-error" style={{maxWidth: "800px"}}
                                             placement={"top"} hideArrow={true}>
                            <div>
                                {row.errorCodes.map((errorCode, errorIndex) => {
                                    return <span key={`error-${index}-${errorIndex}`}
                                        className="no-wrap d-block">{getClassifierValueName(this.props.classifiers, ClassifierCode.RowImportErrorCode, errorCode)}</span>;
                                })}
                            </div>

                        </UncontrolledTooltip>
                    </tr>
                </React.Fragment>
            })
        );
    }

    private renderUpload(): JSX.Element {
        return (
            <div className="form-row custom-form-row">
                <div className="col-12">
                    <Dropzone onDrop={this.handleFileUpload}>
                        {({getRootProps, getInputProps}) => (
                            <div className="file-input w-100" {...getRootProps()}>
                                <input {...getInputProps()}/>
                                <span className="icon icon_upload icon-lg"/>
                                <h6>Lohista fail siia või</h6>
                                <label htmlFor="upload_file" className="btn btn-link pointer">vali seadmest</label>
                            </div>
                        )}
                    </Dropzone>
                </div>
            </div>
        )
    }

    private changeRow(rowIndex, propertyName: string, value: any) {
        let updatedRows = this.state.invalidImportRows;
        updatedRows[rowIndex][propertyName] = value;

        this.setState({
            invalidImportRows: updatedRows
        });
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    allCostItems: getCostItems(state),
    exceptionRowsDefaults: getExceptionRowsDefaultValues(state),
    regularRowsDefaults: getRegularRowsDefaultValues(state),
});

const mapDispatchToProps = {
    addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionContractImportRowsModal);
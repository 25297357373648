import React from "react";
import {ContractType, FullConstructionContract, FullContract, Property} from "../../Contract";
import {PagingMetadata} from "../../../Paging/Paging";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import {connect} from "react-redux";
import {RootState} from "../../../rootReducer";
import {getBaseURL, getRkasCompanyRegNo} from "../../../Config/configReducer";
import {PagingUtils} from "../../../Paging/PagingUtils";
import {UncontrolledTooltip} from "reactstrap";
import PersonField from "../ContractInfo/Fields/PersonField";
import {ContactInfoModalData, ModalType} from "../../../Modal/Modal";
import {MainLease} from "../../../MainLease/MainLease";

interface Props {
  baseURL: string;
  rkasCompanyRegNo: string;
  editingContract?: FullContract;
  updateEditingContract: (editingContract: FullContract) => void;
}

interface State {
  open: boolean;
  paging: PagingMetadata;
  properties: Property[];
  searchTimeout?: number;
}

class ContractProperties extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      open: true,
      paging: new PagingMetadata({
        page: -1, // allow increase when loading first time
        limit: 20,
        search: ""
      }),
      properties: []
    }
  }

  private toggle = () => {
    this.setState({
      open: !this.state.open
    })
  };

  private getNextPropertyPage = () => {
    this.setState({
      paging: {
        ...this.state.paging,
        page: this.state.paging.page + 1
      }
    }, () => {
      axios.get(`${this.props.baseURL}/contract-properties`, {
        params: PagingUtils.toHttpParams(this.state.paging)
      }).then(res => {
        this.setState({
          properties: [
            ...this.state.properties,
            ...res.data.data
          ],
          paging: res.data.metadata
        })
      });
    });
  };

  private hasMore() {
    return this.state.paging.count > ((this.state.paging.page + 1) * this.state.paging.limit);
  };

  private onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    const timeout = window.setTimeout(() => {
      this.setState({
        paging: {
          ...this.state.paging,
          page: -1
        },
        properties: []
      }, this.getNextPropertyPage);
    }, 500);

    this.setState({
      paging: {
        ...this.state.paging,
        search: search
      },
      searchTimeout: timeout
    });
  };

  private selectProperty = (property: Property) => () => {
    this.props.updateEditingContract({
      ...this.props.editingContract,
      properties: [
          ...this.props.editingContract.properties,
          property
      ]
    });
  };

  private removeProperty = (property: Property) => () => {
    this.props.updateEditingContract({
      ...this.props.editingContract,
      properties: this.props.editingContract.properties.filter(p => p.id !== property.id)
    });
  };

  private onPropertyProjectManagerChange = (fieldKey: keyof Property, value: any, property: Property) => {
    if(property) {
      property.projectManager = value;
      this.props.updateEditingContract({
        ...this.props.editingContract
      });
    }
  };

  componentDidMount(): void {
    this.getNextPropertyPage();
  }

  render() {
    const isConstructionContract = this.props.editingContract.type == ContractType.ConstructionContract;

    return (
        <div className="block block-white width-100 block-collapsible">
          <button className={"btn btn-link block-title title-border-bottom block-title-btn position-relative " +
                    (this.state.open ? "arrow-up" : "arrow-down")}
                  onClick={this.toggle}
          >
            Kinnistud
            <span className="icon icon_arrow_dark ml-2" />
          </button>

          <div className={"block-collapsible-content" + (this.state.open ? " open" : "")}>
            <div className="p-4 p-md-4 p-lg-5">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="d-flex flex-row">
                    <input className="form-control"
                           type="text"
                           placeholder="Otsi kinnistut nime, kinnistu koodi või aadressi järgi"
                           value={this.state.paging.search}
                           onChange={this.onSearchChange}
                    />
                  </div>

                  <div className="mt-3"
                       style={{maxHeight: "500px", overflowY: "auto"}}
                       id="infinite-scroll"
                  >
                    <InfiniteScroll
                        dataLength={this.state.properties.length}
                        next={this.getNextPropertyPage}
                        hasMore={this.hasMore()}
                        loader={<h4>Laen...</h4>}
                        scrollableTarget="infinite-scroll"
                    >
                      {this.state.properties
                           .filter(property => !this.props.editingContract.properties.some(p => p.id === property.id))
                           .map((property, index) => (
                              <div className="property-result"
                                   onClick={this.selectProperty(property)}
                                   key={"property-result-" + index}
                              >
                                <h6>{property.name} ({property.code})</h6>
                                <span className="font-weight-light text-gray">{property.address}</span>
                              </div>
                            ))}
                    </InfiniteScroll>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-5 offset-md-0 offset-lg-2">
                  <h6 className="d-flex align-items-center w-100 p-2 mb-3 border-bottom-light-gray">
                    <span className="icon building mr-2" /> Lepingusse valitud kinnistud
                  </h6>

                  {!this.props.editingContract.properties.length && (
                  <>
                    <span className="font-weight-light font-italic text-center text-gray d-block">
                      Ühtegi kinnistut pole lisatud
                    </span>
                    <span className="font-weight-light font-italic text-center text-gray d-block text-sm">
                      Vali vasakult poolt kinnistu
                    </span>
                  </>
                  )}

                  {this.props.editingContract.properties.map((property, index) => (
                    <div className="property-result d-flex align-items-center justify-content-between"
                         style={{cursor: "auto"}}
                         key={"property-" + index}
                    >
                      <div>
                        <h6>{property.name} ({property.code})</h6>
                        <span className="font-weight-light text-gray">{property.address}</span>
                        <br/>
                        {isConstructionContract &&
                          <PersonField contract={property}
                                       label={"Projektijuht"}
                                       fieldKey={"projectManager"}
                                       companyRegNo={this.props.rkasCompanyRegNo}
                                       roleCode={"rkas-construction-project-manager"}
                                       baseURL={this.props.baseURL}
                                       editMode={true}
                                       editable={true}
                                       isClearable={false}
                                       required={false}
                                       skipFormRowClasses={false}
                                       labelClass={"project-manager-label"}
                                       onChange={((fk, v) => this.onPropertyProjectManagerChange(fk, v, property))}
                          />
                        }
                      </div>

                      {property.hasPublished != true &&
                          <>
                            <button type="button"
                                    className="close"
                                    id={"selected-property-" + property.id}
                                    onClick={this.removeProperty(property)}
                            >
                              <span className="submenu-hover submenu-hover-position" />
                              <span className="icon icon_close" />
                            </button>
                            <UncontrolledTooltip target={"selected-property-" + property.id} fade={false}>
                              Eemalda
                            </UncontrolledTooltip>
                          </>
                      }
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  rkasCompanyRegNo: getRkasCompanyRegNo(state)
});

export default connect(mapStateToProps)(ContractProperties);
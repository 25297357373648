import React from "react";
import {FullSupportServiceContract, Property} from "../../Contract";
import {connect} from "react-redux";
import {RootState} from "../../../rootReducer";
import {getClassifiers} from "../../../Classifier/classifierReducer";
import {getPropregUrl} from "../../../Config/configReducer";


interface Props {
    contract: FullSupportServiceContract;
    editingContract?: FullSupportServiceContract;
}

interface State {

}



class SupportServiceContractTable extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const editMode = this.props.contract.publishedOn === null;
        let contract = editMode ? this.props.editingContract : this.props.contract;
        let contractProperties = contract.properties;
        return (
            <>
                {contractProperties.map(property => this.renderPropertyTable(property))}
            </>
        );
    }

    private renderPropertyTable(property: Property) {
        return (
            <React.Fragment key={property.id} >
                <div className="block block-white block-collapsible width-100">
                    <div className="d-flex align-items-center justify-content-between title-border-bottom">
                        <span className={"block-title title-border-bottom position-relative"}>
                            {property.name}, {property.address}, {property.code}
                        </span>
                        <div className="d-flex align-items-center">
                            {/*                            <a id={"redirect-to-expenses"}
                               href="https://proto2.rkas.bitweb.ee/expenses" target="_blank"
                               className="btn btn-link btn-sm no-wrap d-flex align-items-center hover-icon-secondary">
                                Ava arved <span className="icon external-link ml-1"/>
                            </a>
                            <UncontrolledTooltip target={"redirect-to-expenses"} fade={false}>
                                Suunab Tuumikusse
                            </UncontrolledTooltip>*/}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    classifiers: getClassifiers(state),
    propregUrl: getPropregUrl(state)
});

export default connect(mapStateToProps)(SupportServiceContractTable);
import React from "react";
import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import {Company, Eic, FullContract, Property} from "../../../Contract";
import axios from "axios";
import Select from "react-select";

type EicFieldKey<T extends FullContract> = { [C in keyof T]:  C  }[keyof T];

type EICOption = { label: string, value: string, eic: Eic };

interface Props<T extends FullContract> extends ContractInfoFieldProps<T> {
    baseURL: string;
    fieldKey: EicFieldKey<T>;
    isClearable?: boolean;
    fieldValue: any;
    property: Property;
    placeholder?: string;
}

interface State {
    options: EICOption[];
}

export default class EICField<T extends FullContract> extends ContractInfoField<T, Props<T>, State> {

    constructor(props: Props<T>) {
        super(props);

        this.state = {
            options: this.getDefaultOptions()
        };
    }

    componentDidMount(): void {
        this.loadOptions();
    }

    componentDidUpdate(prevProps: Readonly<Props<T>>): void {
        if (prevProps.property !== this.props.property) {
            this.loadOptions();
        }
    }

    private formatLabel = (eic: Eic) => `${eic.eic} - ${eic.contractNumber}`;

    private formatOptionLabel = (option: EICOption, meta : any) => {
        const disabledOption = (option.eic.contractNumber && option.eic.contractNumber !== this.props.contract.number)
        return <div style={{ display: "flex", flexDirection: "column", alignItems: "start", opacity: disabledOption ? "0.5" : "1.0" }}>
            <p>{option.eic.eic}</p>
            {(disabledOption && meta.context == "menu") &&
                <p style={{fontSize:"12px"}}>Lisatud kehtivale lepingule {option.eic.contractNumber}</p>
            }
        </div>
    }

    private getOption = (eic?: Eic): EICOption => {
        if (eic) {
            return {
                label: this.formatLabel(eic),
                value: eic.eic,
                eic
            };
        }
        return undefined;
    };

    private findOption = (eic?: string): EICOption => {
        if (eic && this.state.options) {
            return this.state.options.find(option => option?.eic?.eic == eic)
        }
        return undefined;
    };

    private getValue(): EICOption[] {
        let eics: string[] = this.props.fieldValue;
        if (eics) {
            return eics.map(this.findOption);
        }
        return;
    }

    private getDefaultOptions(): EICOption[]{
        let eic: Eic = this.props.fieldValue;
        if (eic) {
            return [this.getOption(eic)];
        }
    }

    private loadOptions = () => {
        const property = this.props.property;
        if(property){
            return axios.get(this.props.baseURL + "/contract-properties/" + property.id + "/eics").then(res => {
                let options = res.data.data.map(this.getOption);
                this.setState({ options });
            });
        }
    };

    private onChange = (options: EICOption[]) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.fieldKey, options ? options.map(option => option.eic.eic) : undefined);
        }
    };

    public renderEditableField(): JSX.Element {
        return (
            <Select className="react-select"
                    menuPlacement={"auto"}
                    classNamePrefix="react-select"
                    isClearable={this.props.isClearable}
                    isMulti={true}
                    isDisabled={!this.props.editable}
                    placeholder="Vali"
                    options={this.state.options}
                    onChange={this.onChange}
                    menuPortalTarget={document.querySelector('body')}
                    formatOptionLabel={this.formatOptionLabel}
                    isOptionDisabled={option => (option.eic.contractNumber && option.eic.contractNumber !== this.props.contract.number)}
                    value={this.getValue()}
            />
        );
    }

    protected renderReadOnlyField(): JSX.Element {
        const eics: string[] = this.getFieldValue();

        return (
            <div className={"flex flex-row"}>
                {(eics && eics.length > 0) && eics.map(eic=>
                    <span key={eic} className="d-block font-italic">
                                {eic || "-"}
                            </span>
                )}
                {(!eics|| eics.length == 0) &&
                    <span className="font-weight-bold">-</span>
                }
            </div>
        );
    }
}

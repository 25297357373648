import React from "react";
import { ContractType, ServiceContract } from "../Contract";
import { RootState } from "../../rootReducer";
import { getBaseURL } from "../../Config/configReducer";
import { connect } from "react-redux";
import axios from "axios";
import { getClassifiers } from "../../Classifier/classifierReducer";
import { ClassifierCode, getClassifierValueName } from "../../Classifier/Classifier";
import { Permission } from "../../Auth/User";
import { alertActions } from '../../Alerts/alertActions';
import { Alert, AlertContentType, AlertType } from '../../Alerts/Alert';
import { AbstractContractList } from "../AbstractContractList";
import { ColumnDescription } from "react-bootstrap-table-next";
import { NavLink } from "react-router-dom";
import {
  dateFormatter,
  dateTimeFormatter,
  percentFormatter, decimalFormatter, personNameFormatter
} from "../../BootstrapTable/customFormatters";

class ServiceContractList extends AbstractContractList<ServiceContract> {

  static readonly TYPE = ContractType.ServiceContract;

  protected getType(): ContractType {
    return ServiceContractList.TYPE;
  }

  protected getManagePermission(): Permission | Permission[] {
    return Permission.ManageServiceContract;
  }

  protected getColumns(): ColumnDescription<ServiceContract>[] {
    return [
      {
        dataField: "number",
        text: "Lepingu number",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/service/" + row.id}>{cell}</NavLink>
      },
      {
        dataField: "serviceContractType",
        text: "Tüüp",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.ServiceContractType, cell)
      },
      {
        dataField: "generalDocumentTitle",
        text: "Pealkiri",
        sort: true
      },
      {
        dataField: "rhrReferenceNumber",
        text: "RHR number",
        sort: true
      },
      {
        dataField: "customer.name",
        text: "Tellija",
        sort: true
      },
      {
        dataField: "customerRepresentative",
        text: "Tellija kontaktisik",
        sort: true,
        formatter: personNameFormatter
      },
      {
        dataField: "contractor.name",
        text: "Peatöövõtja",
        sort: true
      },
      {
        dataField: "contractorRepresentative",
        text: "Töövõtja projektijuht",
        sort: true,
        formatter: personNameFormatter
      },
      {
        dataField: "frameContractReference",
        text: "Raamleping",
        sort: true
      },
      {
        dataField: "procurementReference",
        text: "Hange",
        sort: true
      },
      {
        dataField: "enterTime",
        text: "Sõlmitud",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "publishedOn",
        text: "Avaldatud",
        sort: true,
        formatter: dateTimeFormatter
      },
      {
        dataField: "startTime",
        text: "Algus",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "endTime",
        text: "Lõpp",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "sum",
        text: "Väärtus",
        formatter: (cell, row) => {
          let sum = (row.sum || 0) + (row.reserveValue || 0);
          if (sum === 0) {
            return null;
          }
          return decimalFormatter(sum);
        }
      },
      {
        dataField: "reservePercentage",
        text: "Reserv",
        sort: true,
        formatter: percentFormatter
      },
    ];
  }

  protected renderAddButton() {
    return (
        <button type="button"
                className="btn btn-primary ml-4"
                onClick={this.handleAddButton}
                disabled={this.state.addButtonLoading}>
          + Lisa korrashoiuteenuste leping
        </button>
    );
  }

  protected handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState({
      addButtonLoading: true
    }, () => {
      axios.post(this.props.baseURL + "/contracts")
          .then(res => {
            this.props.history.push(this.props.match!.url + "/" + res.data.id, { action: "new" });
          })
          .catch(error => {
            let alertType = AlertContentType.ContractAddError;
            if (error.response) {
              const apiErrorType = Alert.fromException(error.response.data.error);
              if(apiErrorType)
                alertType = apiErrorType;
            }
            const alert: Alert = new Alert();
            alert.type = AlertType.Danger;
            alert.content = alertType;
            this.props.addAlert(alert);

            this.setState({
              addButtonLoading: false
            });
          });
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContractList);
import React from 'react';
import PaginationList from "react-bootstrap-table2-paginator/lib/src/pagination-list";
import standaloneAdapter from "react-bootstrap-table2-paginator/lib/src/standalone-adapter";
import PaginationHandler from "react-bootstrap-table2-paginator/lib/src/pagination-handler";
import paginationListAdapter from "./PaginationListAdapter";

const PaginationListStandalone = props => (
    <PaginationList { ...props } />
);

export default standaloneAdapter(PaginationHandler(paginationListAdapter(PaginationListStandalone)));
import { Alert } from "./Alert";
import { createAction } from '@reduxjs/toolkit';

export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

const addAlert = createAction<Alert>('ADD_ALERT');
const removeAlert = createAction<number>('REMOVE_ALERT');

export const alertActions = {
    addAlert,
    removeAlert
};

export type AlertActionReturnTypes = ReturnType<typeof addAlert>|ReturnType<typeof removeAlert>
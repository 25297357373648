export const DateFormatOptions: any = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};

export const DateTimeFormatOptions: any = Object.assign({}, DateFormatOptions, {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
});

export const NumberFormatOptions: any = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export const PercentFormatOptions: any = Object.assign({}, NumberFormatOptions, {
  style: "percent"
});

export const CurrencyFormatOptions: any = Object.assign({}, NumberFormatOptions, {
  style: "currency",
  currency: "EUR"
});
import React from "react";
import {ContractRowField, ContractRowFieldProps} from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import {Popover, PopoverBody, PopoverHeader} from "reactstrap";

interface State {
    popoverOpen : boolean;
    popoverValue : string;
}

export default class ServiceTitleRowField<T extends ContractRow> extends ContractRowField<T, ContractRowFieldProps<T>, State> {

    constructor(props: ContractRowFieldProps<T>) {
        super(props);

        this.state = {
            popoverOpen: false,
            popoverValue: this.getFieldValue()
        };
    }

    private onChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.props.row, this.props.fieldKey, this.state.popoverValue);
            this.togglePopover();
        }
    };

    protected renderReadOnlyField(): JSX.Element {
        return (
            <span>{this.getFieldValue()}</span>
        )
    }

    protected renderEditableField(): JSX.Element {
        return (
            <>
                <span id={"open-title-popover-" + this.props.row.id} style={{textDecoration: "underline", cursor : "pointer"}}
                        onClick={() => this.togglePopover()}>
                    {this.getFieldValue() || "-"}
                </span>
                <Popover isOpen={this.state.popoverOpen}
                         placement="top"
                         target={"open-title-popover-" + this.props.row.id}
                         popperClassName={"popover-xl"}>
                    <PopoverHeader>Muuda teenuse nimetust</PopoverHeader>
                    <PopoverBody>
                        <textarea className="form-control"
                                  style={{width:"450px"}}
                                  value={this.state.popoverValue}
                                  onChange={event => this.setState({popoverValue : event.target.value})}
                                  disabled={!this.props.editable}
                        />
                        <div className="d-lg-flex mt-2">
                            <div className="btn-group">
                                <button onClick={() => this.togglePopover()} className="btn no-wrap btn-outline-primary">
                                    Katkesta
                                </button>
                                <button onClick={() => this.onChange()} className="btn no-wrap btn-outline-primary ">
                                    Salvesta
                                </button>
                            </div>
                        </div>
                    </PopoverBody>
                </Popover>
            </>
        );
    }

    private togglePopover() {

        this.setState({popoverOpen: !this.state.popoverOpen, popoverValue: this.getFieldValue()})
    }
}
import React from "react";
import { FullConstructionContract } from "../../Contract";
import ConstructionContractTable from "./ConstructionContractTable";

interface Props {
    contract: FullConstructionContract;
    infoEditMode: boolean;
    editingContract?: FullConstructionContract;
    updateEditingContract: (contract: FullConstructionContract, callback?: () => void) => void;
    onSaveContract: () => void;
}


export default class ConstructionContractDetails extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!this.props.infoEditMode) {
            return (
                <>
                    <ConstructionContractTable contract={this.props.contract}
                                               editingContract={this.props.editingContract}
                                               updateEditingContract={this.props.updateEditingContract}
                                               onSaveContract={this.props.onSaveContract}
                    />
                </>
            );
        }
        return <></>;
    }
}
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from "reactstrap";
import {DefaultPricesModalData} from "../Modal";
import {DefaultPrice, ServiceContractType} from "../../Contracts/Contract";
import {NumberInput} from "../../Common/NumberInput";
import {CostItem} from "../../Contracts/ContractDetails/ContractRow";
import {RootState} from "../../rootReducer";
import {getCostItems} from "../../Contracts/costItemReducer";
import {connect} from "react-redux";
import {getClassifiers} from "../../Classifier/classifierReducer";
import {Classifier} from "../../Classifier/Classifier";
import {getBaseURL} from "../../Config/configReducer";

interface Props {
    baseURL: string;
    allCostItems: CostItem[];
    classifiers: Classifier[];

    modalOpen: boolean;
    modalData: DefaultPricesModalData;
    onToggleModal: () => void;
}

interface State {
    prices: DefaultPrice[]
}

class DefaultPricesModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            prices: []
        }
    }

    componentWillMount() {
        this.resetState();
    }

    componentDidUpdate(prevProps:Props) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            this.resetState();
        }
    }

    render() {
        return (
            <Modal centered={true} size="xl" isOpen={this.props.modalOpen} toggle={this.props.onToggleModal}>
                <ModalHeader toggle={this.props.onToggleModal}>Muuda ühikuhindu</ModalHeader>
                <ModalBody className="p-4">
                    <>
                        <div className="d-flex flex-column">
                            {this.renderDefaultPriceGroups()}
                            <div>
                                <Button
                                    size={"sm"}
                                    onClick={() => this.addPrice()}
                                    color="secondary">
                                    Lisa uus ühikuhind
                                </Button>
                            </div>
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary">
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        color="primary">
                        Salvesta ühikuhinnad
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    private renderDefaultPriceGroups() {
        return this.state.prices.map(
            defaultPriceGroup => (
                <React.Fragment key={defaultPriceGroup.id}>
                    <div className="form-row custom-form-row pt-2 pl-3 pr-2 pb-3" style={{backgroundColor: "#fff",boxShadow: "0 0 14px 0 #e7e7e7"}}>
                        <div className="col-1 align-items-start">
                            <label className="w-200 mb-2">Ühikuhind:</label>
                            <NumberInput value={defaultPriceGroup.price}
                                         onNumberChange={value => this.changeGroupPrice(defaultPriceGroup,value)} />
                        </div>
                        <div className="col-5 align-items-stretch">
                            <label className="w-200">Valitud kulukohad:</label>
                            <div className="mt-2">
                                {this.renderCostItemTree(defaultPriceGroup, new CostItem({children: defaultPriceGroup.costItems}), true, 0)}
                            </div>
                        </div>
                        <div className="col-5 flex-column align-items-stretch">
                            <label className="w-200 mb-2">Kulukohtade lisamine:</label>
                            <NumberInput value={defaultPriceGroup.searchValue}
                                         placeholder="Lisamiseks sisesta kulukoha number"
                                         onNumberChange={value => this.changeGroupSearchValue(defaultPriceGroup,value)} />
                                <div className="mt-2">
                                    {this.renderCostItemTree(defaultPriceGroup, this.findCostItem(defaultPriceGroup.searchValue, this.props.allCostItems), false,0)}
                                </div>
                        </div>
                        <div className="col-1">
                            <button id={"delete-price-" + defaultPriceGroup.id} onClick={() => this.deletePrice(defaultPriceGroup)} className="close mr-2 mt-2 mb-2" type="button">
                                <span className="submenu-hover submenu-hover-position" />
                                <span className="icon trash icon-bg-danger" />
                            </button>
                            <UncontrolledTooltip target={"delete-price-" + defaultPriceGroup.id} fade={false}>
                                Kustuta ühikuhind
                            </UncontrolledTooltip>
                        </div>
                    </div>

                </React.Fragment>
            )
        )
    }

    private cancel = () => {
        this.resetState();
        this.props.onToggleModal();
    };

    private onSave = () => {
        this.props.modalData.onSubmit(this.state.prices);
        this.resetState();
        this.props.onToggleModal();
    };

    private resetState() {
        let prices = this.props.modalData.contract.defaultPrices ? this.props.modalData.contract.defaultPrices : [new DefaultPrice()];

        if(this.props.modalData.contract.serviceContractType == ServiceContractType.ServHk){
            prices.forEach(defaultPrice => defaultPrice.searchValue = 3000);
        }
        if(this.props.modalData.contract.serviceContractType == ServiceContractType.ServTT){
            prices.forEach(defaultPrice => defaultPrice.searchValue = 7100);
        }

        this.setState({
            prices: prices
        });
    }

    private changeGroupPrice(item: DefaultPrice, value: number) {
        const prices = this.state.prices;
        prices[prices.indexOf(item)].price = value;
        this.setState({prices: prices});
    }

    private changeGroupSearchValue(item: DefaultPrice, value: number) {
        const prices = this.state.prices;
        prices[prices.indexOf(item)].searchValue = value;
        this.setState({prices: prices});
    }

    private addPrice() {
        let prices = this.state.prices;
        const newPrice = new DefaultPrice();
        newPrice.costItems = [];
        newPrice.price = 0;
        newPrice.id = prices.length > 0 ? Math.max(...prices.map(o => o.id), 0) + 1 : 0;

        if(this.props.modalData.contract.serviceContractType == ServiceContractType.ServHk)
            newPrice.searchValue = 3000;

        prices.push(newPrice);
        this.setState({prices: prices});
    }

    private deletePrice(item: DefaultPrice) {
        let prices = this.state.prices;
        prices = prices.filter(price => price !== item);
        this.setState({prices: prices});
    }

    private toggleCostItemSelection(priceGroup: DefaultPrice, item: CostItem) {
        const prices = this.state.prices;
        const group = prices[prices.indexOf(priceGroup)];
        if(!group.costItems) {
            group.costItems = [];
        }

        const groupItem = group.costItems.find(costItem => costItem.code === item.code);
        if(!groupItem)
            group.costItems.push(item);
        else
            group.costItems = group.costItems.filter(costItem => costItem !== groupItem);

        group.costItems.sort((a,b) => a.id > b.id ? 1 : -1);
        this.setState({prices: prices});
    }

    private renderCostItemTree(priceGroup: DefaultPrice, costItem : CostItem, showSelectedOnly : boolean, depth: number) {
        if(!costItem || !costItem.children) return null;

        const newDepth = depth + 1;
        let items = costItem.children;
        if(showSelectedOnly)
            items = items.filter(costItem => priceGroup.costItems.some(selectedItem => selectedItem.code == costItem.code) == true)

        return (items.map(
                item => (
                    <React.Fragment key={item.id}>
                        <div className={"selectable-baserow flex-row " + (!!priceGroup.costItems.some(costItem => costItem.code == item.code) && "selected")}
                             style={{marginLeft: depth * 20, padding: 0}}
                             onClick={(e) => {e.preventDefault(); this.toggleCostItemSelection(priceGroup, item)}}>
                            <div className="col-3 selectable-baserow-number" style={{padding: "0px"}}>
                                <div className="custom-control custom-checkbox" style={{width: "30px"}}>
                                    <input type="checkbox"
                                           className="custom-control-input"
                                           id={"select-costitem-"+item.code}
                                           readOnly={true}
                                           checked={!!priceGroup.costItems.some(costItem => costItem.code == item.code)}
                                    />
                                    <label className="custom-control-label no-wrap" htmlFor={"select-costitem-"+item.code}/>
                                </div>
                                <h6 className="pl-2">{item.code}</h6>
                            </div>
                            <div className="col-9 selectable-baserow-text" style={{padding: "0px"}}>
                                <h6 className="pl-2">{item.label}</h6>
                            </div>
                        </div>
                        {this.renderCostItemTree(priceGroup,item, showSelectedOnly, newDepth)}
                    </React.Fragment>
                )
            )
        );
    }

    private findCostItem(searchValue: number, costItems: CostItem[]) {
        if(!searchValue)
            return null;

        const findArray = searchValue.toString().split("");
        const allCostItems = new CostItem();
        allCostItems.children = costItems;

        let matchingItem : CostItem = null;
        for (let i = 0; i < findArray.length; i++) {
            const searchSet = matchingItem ? matchingItem.children : allCostItems.children;
            const searchResult = searchSet.find(costItem => costItem.code[i] == findArray[i]);
            if(searchResult)
                matchingItem = searchResult;
            else
                break;
        }

        return matchingItem ? new CostItem( {children : [matchingItem]}) : null;
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    allCostItems: getCostItems(state),
    classifiers: getClassifiers(state)
});

export default connect(mapStateToProps, null)(DefaultPricesModal);
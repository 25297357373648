import React from "react";
import { RouteComponentProps } from "react-router";
import { Classifier } from "../Classifier/Classifier";
import { alertActions } from "../Alerts/alertActions";
import { PagingMetadata } from "../Paging/Paging";
import * as qs from "qs";
import {Permission, User} from "../Auth/User";
import { PagingUtils } from "../Paging/PagingUtils";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import axios from "axios";
import BootstrapTable, {
    ColumnDescription, SortOrder,
    TableChangeState,
    TableChangeType
} from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import PaginationListStandalone from "../BootstrapTable/PaginationListStandalone";
import {
    pageListRenderer,
    sizePerPageRenderer,
    sortCaretRenderer
} from "../BootstrapTable/customRenderers";
import { headerFormatter } from "../BootstrapTable/customFormatters";
import ToolkitProvider, { ToolkitContextType } from "react-bootstrap-table2-toolkit";
import * as querystring from "querystring";
import {ContractFilters} from "../Contracts/ContractFilters";
import {Report, ReportType} from "./Report";
import RegionTypeQuickFilter from "../Contracts/QuickFilters/RegionTypeQuickFilter";
import ListFilters from "../ListFilters/ListFilters";

export interface ReportListProps extends RouteComponentProps<{}> {
    baseURL: string;
    sapEnabled: boolean;
    classifiers: Classifier[];
    addAlert: typeof alertActions.addAlert;
    displayNavHeaderCallback: (val: boolean) => void;
    currentUser: User;
}

export interface ReportListState<T extends Report> {
    storedPagingLoaded?: boolean;
    storedFiltersLoaded?: boolean;
    storedHiddenColumnsLoaded?: boolean;
    filters: ContractFilters;
    params: any;
    paging: PagingMetadata;
    columns: ColumnDescription[];
    data: T[];
    filtersToggled: boolean;
    searchTimeout?: number;
    searchInput: string;
    queryPrepared: boolean;
    queryLoading: boolean;
}

export abstract class AbstractReportList<T extends Report, P extends ReportListProps = ReportListProps,
    S extends ReportListState<T> = ReportListState<T>> extends React.Component<P, S> {

    constructor(props: P) {
        super(props);

        let params: any = {};
        if (props.location.search) {
            params = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        }

        const columns = this.getColumns();
        columns.forEach(column => {
            column.sortCaret = sortCaretRenderer;
            column.headerFormatter = headerFormatter;
        });

        this.state = {
            filters: {},
            params: params,
            paging: new PagingMetadata({
                page: 0,
                limit: 20,
                count: 0,
                sort: "id"
            }),
            columns: columns,
            data: [],
            filtersToggled: false,
            searchInput: '',
            queryPrepared: false,
            queryLoading: true,
        } as Readonly<S>;
    }

    componentDidMount(): void {
        this.loadPaging();
        this.loadFilters();
        this.loadHiddenColumns();
        this.loadFirstQuery();
    }

    protected abstract getType(): ReportType;

    protected abstract getEndPoint(): string;

    protected abstract getManagePermission(): Permission|Permission[];

    protected abstract getColumns(): ColumnDescription<T>[];

    protected getPagingLocalStorageKey = () => {
        return this.getType().toLowerCase() + "_list" + ".paging";
    };

    protected getFiltersLocalStorageKey = () => {
        return this.getType().toLowerCase() + "_list" + ".filters";
    };

    protected getHiddenColumnsLocalStorageKey = () => {
        return this.getType().toLowerCase() + "_list" + ".hiddenColumns";
    };

    protected toggleFilters = () => {
        const dom: any = document.querySelector('body');
        dom.classList.toggle("filters-open");

        this.setState({
            filtersToggled: !this.state.filtersToggled
        });
    };

    protected onPagingChange = (paging: PagingMetadata) => {
        const { page, limit, sort, sortDirection, search} = paging;

        localStorage.setItem(this.getPagingLocalStorageKey(), JSON.stringify({
            page,
            limit,
            sort,
            sortDirection,
            search
        }));

        this.setState({
            paging: paging
        }, () => {
            this.getReports();
        });
    };

    protected onFiltersChange = (filters: ContractFilters) => {
        localStorage.setItem(this.getFiltersLocalStorageKey(), JSON.stringify(filters));
        const params = {...this.state.params as any};

        // remove filters that are no longer applied
        Object.keys(this.state.filters).forEach(key => {
            const filterKey = key as keyof ContractFilters;
            if (filters[filterKey] == undefined || filters[filterKey] === '') {
                delete params[filterKey];
                delete filters[filterKey];
            }
        });

        // set currently applied filters
        Object.keys(filters).forEach(key => {
            const filterKey = key as keyof ContractFilters;
            if (filters[filterKey] != undefined && filters[filterKey] !== '') {
                params[filterKey] = filters[filterKey];
            }
        });

        this.setState({
            filters: filters,
            params: params
        }, () => {
            this.getReports();
        });
    };

    protected toggleColumn = (column: ColumnDescription) => () => {

        this.setState({
            columns: this.state.columns.map(col => {
                if (col.dataField == column.dataField) {
                    col.hidden = !col.hidden;
                }
                return col;
            })
        }, () => {
            const hiddenColumns = this.state.columns.filter(col => (col as any).reverseVisibility ? col.hidden == false : col.hidden).map(col => col.dataField);
            localStorage.setItem(this.getHiddenColumnsLocalStorageKey(), JSON.stringify(hiddenColumns));
        });
    };

    protected handleQuickFilter = (key: string, value: any) => (event: any) => {
        if (this.state.params[key] != undefined && this.state.params[key] === value) {
            value = undefined;
        }

        this.setState({
            params: {
                ... this.state.params as any,
                [key]: value
            },
            filters: {
                ... this.state.filters,
                [key]: value
            }
        }, () => {
            this.onFiltersChange(this.state.filters);
        });
    };

    protected quickFilterSelected(key: string, value?: any): boolean {
        if (this.state.params) {
            if (value != undefined) {
                return this.state.params[key] === value;
            } else {
                return this.state.params[key] != undefined;
            }
        }
        return false;
    }

    protected onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value;

        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout!);
        }

        let timeout = window.setTimeout(() => {
            this.onPagingChange(Object.assign(this.state.paging, {
                search,
                page: 0
            }));
        }, 500);

        this.setState({
            searchTimeout: timeout,
            searchInput: search
        });
    };

    protected export = (exportType: string) => {
        const paging = this.state.paging;
        paging.params = {
            ... this.state.params as any,
            type: this.state.params && this.state.params.type ? this.state.params.type : this.getType()
        };

        const urlParams: URLSearchParams = new URLSearchParams();
        let columnsAsString: string = "";
        if (paging) {
            let appliedParams = PagingUtils.toHttpParams(paging);
            for (const [key, value] of Object.entries(appliedParams)) {
                urlParams.set(key, value);
            }
            const visibleColumns : string[] = this.state.columns.filter(col => !col.hidden).map(col => col.dataField);
            if (visibleColumns) {
                columnsAsString = visibleColumns.join(",");
            }
        }
        window.open(`${this.props.baseURL}/reports/${this.getEndPoint()}/export/${exportType}?fields=${columnsAsString}&${urlParams}`);
    };

    protected getReports() {

        if(!this.state.queryPrepared) return;
        const paging = this.state.paging;
        paging.params = {
            ... this.state.params as any
        };

        this.setState({queryLoading : true});
        axios.get(this.props.baseURL + "/reports/" + this.getEndPoint(), {
            params: PagingUtils.toHttpParams(paging),
            paramsSerializer: querystring.stringify
        })
            .then(res => {
                this.setState({
                    paging: res.data.metadata,
                    data: res.data.data,
                    queryLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    queryLoading: false
                });
            })
    }

    protected renderToolbar(context: ToolkitContextType) {
        return (
            <div className="title-container justify-content-between d-none d-md-flex">
                <RegionTypeQuickFilter filters={this.state.filters}
                                       onFiltersChange={this.onFiltersChange}
                                       count={this.state.paging.count}
                />

                {!Object.values(this.state.filters as any).length &&
                <div className="works-link align-items-center flex-row" onClick={this.toggleFilters}>
                    <span className="icon icon_filter mr-2" />
                    <span className="text-sm text-primary text-nowrap">Filtreeri</span>
                </div>
                }
                {!!Object.values(this.state.filters as any).length &&
                <div className="works-link active" onClick={this.toggleFilters}>
                    <div className="quick-filter-label">
                        <span className="text-sm text-gray text-nowrap">Filtreeritud</span>
                        <i className="fas fa-pencil-alt text-sm text-gray" />
                    </div>
                    <h4 className="works-filtered-number text-primary">
                        {this.state.paging ? this.state.paging.count || 0 : 0}
                    </h4>
                </div>
                }

                <div className="d-flex" style={{flex: "1"}}>
                    <input type="text"
                           className="form-control"
                           placeholder="Otsi lepingu numbri või pealkirja järgi"
                           value={this.state.searchInput || ''}
                           onChange={this.onSearch}
                    />
                </div>
                <div className="d-flex" style={{flex: "1"}}/>
                <div className="d-flex align-items-center">
                    <UncontrolledDropdown className="submenu ml-3 mr-3">
                        <div className="submenu-hover" />
                        <DropdownToggle color="link" className="btn d-flex">
                            <span className="icon download d-flex" />
                        </DropdownToggle>
                        <DropdownMenu tag="ul" className="dropdown-menu dropdown-menu-right" style={{marginRight: "20px"}}>
                            <li role="menuitem">
                                <DropdownItem tag="a" className="p-3 pointer"
                                              onClick={() => this.export("CSV")}>
                                    Ekspordi CSV
                                </DropdownItem>
                            </li>
                            <li role="menuitem">
                                <DropdownItem tag="a" className="p-3 pointer"
                                              onClick={() => this.export("XLSX")}>
                                    Ekspordi XLSX
                                </DropdownItem>
                            </li>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown className="submenu" style={{right: 0, position: "relative"}}>
                        <div className="submenu-hover" />
                        <DropdownToggle color="link" className="btn d-flex">
                            <span className="icon icon_settings d-flex" />
                        </DropdownToggle>
                        <DropdownMenu tag="ul" className="dropdown-menu dropdown-menu-right" style={{marginRight: "20px", zIndex: 10000}}>
                            {this.state.columns.map((column, index, columns) => (
                                <React.Fragment key={"column-toggle-" + index}>
                                    <li role="menuitem">
                                        <DropdownItem tag="div" className="custom-control custom-checkbox"
                                                      style={{paddingLeft: "3rem", paddingBottom: "0.5rem", paddingTop: "0.5rem"}}
                                                      toggle={false}
                                        >
                                            <input type="checkbox"
                                                   className="custom-control-input"
                                                   id={"toggle-column-" + index}
                                                   checked={!column.hidden}
                                                   onChange={this.toggleColumn(column)}
                                            />
                                            <label className="custom-control-label" htmlFor={"toggle-column-" + index}>
                                                {column.text}
                                            </label>
                                        </DropdownItem>
                                    </li>
                                    {index < (columns.length -1) &&
                                    <DropdownItem divider style={{ margin: 0 }} />
                                    }
                                </React.Fragment>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        );
    }

    protected onTableChange = (type: TableChangeType, state: TableChangeState<T>) => {
        this.onPagingChange(Object.assign(this.state.paging, {
            page: (state.page || 1) - 1,
            limit: state.sizePerPage,
            sort: state.sortField,
            sortDirection: (state.sortOrder || "asc").toUpperCase()
        }));
    };

    protected renderTable(context: ToolkitContextType) {
        if (!(this.state.storedFiltersLoaded && this.state.storedPagingLoaded && this.state.storedHiddenColumnsLoaded)) {
            return <></>
        }

        return (
            <>
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        sizePerPageRenderer: sizePerPageRenderer,
                        pageListRenderer: pageListRenderer(this.state.paging),
                        page: (this.state.paging.page || 0) + 1,
                        sizePerPage: this.state.paging.limit || 20,
                        totalSize: this.state.paging.count || 0,
                        sizePerPageList: [20, 50, 100]
                    })}>
                    {({ paginationProps, paginationTableProps }) => (
                        <div className="datatable" style={{position: "relative"}}>
                            <div className="table-responsive">
                                <BootstrapTable
                                    {...context.baseProps}
                                    {...paginationTableProps}
                                    remote={true}
                                    headerWrapperClasses="datatable-header"
                                    onTableChange={this.onTableChange}
                                    noDataIndication={this.state.queryLoading ? "Päring käib, palun oota..." : "Kirjeid ei leitud"}
                                    sort={{
                                        dataField: this.state.paging.sort,
                                        order: (this.state.paging.sortDirection ?
                                            this.state.paging.sortDirection.toLowerCase() : undefined) as SortOrder
                                    }}
                                />
                            </div>
                            <div className="datatable-footer">
                                <div className="datatable-footer-inner">
                                    <div className="d-flex align-items-center" style={{padding: "5px 10px"}}>
                                        <SizePerPageDropdownStandalone {...paginationProps} />
                                        <PaginationListStandalone {...paginationProps} />
                                    </div>
                                </div>
                            </div>
                            {this.state.queryLoading &&
                            <div style={{backgroundColor: "rgba(255,255,255,0.4)", position: "absolute", width: "100%", height: "100%", top: 0, left: 0, pointerEvents: "none"}}>
                                <span style={{position: "fixed", top: "35%", left: "50%"}}><i className="details-spinner fa fa-spinner fa-spin fa-2x"/></span>
                            </div>
                            }
                        </div>
                    )}
                </PaginationProvider>
            </>
        );
    }

    render() {
        return (
            <div className="contract-list">
                <ListFilters type="report"
                             reportType={this.getType()}
                             filters={this.state.filters}
                             show={this.state.filtersToggled}
                             toggleFilters={this.toggleFilters}
                             onFiltersChange={this.onFiltersChange}
                />
                <div className="filters-background" />
                <div className="container-fluid">
                    <ToolkitProvider
                        keyField="id"
                        data={this.state.data}
                        columns={this.state.columns}
                        columnToggle
                    >
                        {(context: ToolkitContextType) => (
                            <>
                                {this.renderToolbar(context)}

                                <div className="white-container mt-0">
                                    {this.renderTable(context)}
                                </div>
                            </>
                        )}
                    </ToolkitProvider>
                </div>
            </div>
        );
    }

    private loadPaging(): void {
        const localStorageKey = this.getPagingLocalStorageKey();
        let paging: PagingMetadata = this.state.paging;

        if (localStorage.getItem(localStorageKey)) {
            let pagingString: string = localStorage.getItem(localStorageKey);
            let pagingJson: any = JSON.parse(pagingString) as PagingMetadata;

            paging = Object.assign(paging, pagingJson);
        }

        this.setState({
            paging,
            storedPagingLoaded: true,
            searchInput: paging.search
        });
    }

    private loadFilters(): void {
        const localStorageKey = this.getFiltersLocalStorageKey();
        if (localStorage.getItem(localStorageKey)) {
            let filterString: string = localStorage.getItem(localStorageKey);
            let filters: ContractFilters = JSON.parse(filterString) as ContractFilters;

            this.onFiltersChange(filters);
        }

        this.setState({
            storedFiltersLoaded: true
        });
    }

    private loadHiddenColumns(): void {
        const localStorageKey = this.getHiddenColumnsLocalStorageKey();
        let { columns } = this.state;

        if (localStorage.getItem(localStorageKey)) {
            let columnsString: string = localStorage.getItem(localStorageKey);
            let hiddenColumns: string[] = JSON.parse(columnsString) as string[];

            columns.forEach(column => {
                column.hidden = hiddenColumns.some(hiddenField => hiddenField === column.dataField);
                if((column as any).reverseVisibility)
                    column.hidden = !column.hidden;
            });
        }

        this.setState({
            columns,
            storedHiddenColumnsLoaded: true
        });
    }

    private loadFirstQuery() {
        setTimeout(() => {this.setState({queryPrepared: true}, () => this.getReports())}, 10);
    }
}
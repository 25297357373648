import React from "react";
import { ContractRowField, ContractRowFieldProps } from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import { Button } from "reactstrap";

interface Props<T extends ContractRow> extends ContractRowFieldProps<T> {
  checkboxLabel?: string;
}

export default class BooleanSelectRowField<T extends ContractRow> extends ContractRowField<T, Props<T>> {

  protected renderReadOnlyField(): JSX.Element {
    return (
        <span>
          {this.getFieldValue() ? "Jah" : "Ei"}
        </span>
    );
  }

  protected renderEditableField(): JSX.Element {
    return (
        <div className="btn-group d-flex">
            <Button
                size="sm"
                color="outline-primary"
                onClick={() => this.props.onChange(this.props.row, this.props.fieldKey, true)}
                className={this.getFieldValue() ? "active" : ""}
                disabled={!this.props.editable}
                role="button">
                Jah
            </Button>
            <Button
                size="sm"
                color="outline-primary"
                onClick={() => this.props.onChange(this.props.row, this.props.fieldKey, false)}
                className={this.getFieldValue() == false ? "active" : ""}
                disabled={!this.props.editable}
                role="button">
                Ei
            </Button>
        </div>
    );
  }
}
import React from "react";
import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import {FullContract, Project} from "../../../Contract";
import axios from "axios";
import Select from "react-select";
import _ from "lodash";
import {UncontrolledTooltip} from "reactstrap";

type ProjectFieldKey<T extends FullContract> = { [C in keyof T]: T[C] extends Project[] ? C : never }[keyof T];

type ProjectOption = { label: string, value: number, project: Project };

interface Props<T extends FullContract> extends ContractInfoFieldProps<T> {
    baseURL: string;
    fieldKey: ProjectFieldKey<T>;
    isClearable?: boolean;
}

interface State {
    options: ProjectOption[];
    query: string;
}

export default class ProjectField<T extends FullContract> extends ContractInfoField<T, Props<T>, State> {
    private readonly debouncedLoadOptions: any;

    constructor(props: Props<T>) {
        super(props);

        this.state = {
            options: [],
            query: ""
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
    }

    private formatLabel = (project: Project) => `${project.name}${project.projectNumber ? (" - " + project.projectNumber) : ""}`;

    private getOption = (project: Project): ProjectOption => {
        if (project) {
            return {
                label: this.formatLabel(project),
                value: project.id,
                project
            };
        }
        return undefined;
    };

    private getValue(): ProjectOption[] {
        let projects: Project[] = this.getFieldValue();
        if (projects) {
            return  projects.map(this.getOption);
        }
        return;
    }

    private onChange = (options: ProjectOption[]) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.fieldKey, options ? options.flatMap(option => option.project) : undefined);
        }
    };

    private loadOptions() {
        axios.get(`${this.props.baseURL}/projects?limit=10&search=${this.state.query}`)
            .then(res => {
                const projects = res.data.data.map(externalProject => new Project(externalProject, this.props.contract?.externalId));
                const options = projects.map(this.getOption);
                this.setState({options: options});
            });
    }

    private onInputChange (newTextValue: string)
    {
        if(newTextValue) {
            this.setState({query: newTextValue}, () => {
                this.debouncedLoadOptions();
                return this.state.query;
            });
        }
    }

    protected renderReadOnlyField(): JSX.Element {
        const projects: Project[] = this.getFieldValue();

        return (
            <>
                {projects && projects.length > 0 &&
                    <div className={"flex flex-row"}>
                        {projects.map(project =>
                            <span className="d-block font-italic" key={project.id}>{project.name}{project.projectNumber ? (" - " + project.projectNumber) : ""}</span>
                        )}
                    </div>
                }
                {!projects || projects.length == 0 &&
                    <span className="font-weight-bold">-</span>
                }
            </>
        );
    }

    protected renderEditableField(): JSX.Element {
        return (
            <Select className="react-select"
                    classNamePrefix="react-select"
                    isMulti={true}
                    isClearable={false}
                    placeholder="Vali"
                    menuPortalTarget={document.querySelector('body')}
                    options={this.state.options}
                    value={this.getValue()}
                    isDisabled={!this.props.editable}
                    onChange={this.onChange}
                    onInputChange={this.onInputChange}
            />
        );
    }
}
import {Company, Property, ServiceContract} from "../Contracts/Contract";

export enum ReportType {
    ServiceContractReport = "SERVICE_CONTRACT_REPORT"
}

export class Report {
    id: number;
}

export class ServiceContractReport extends Report {
    rentalCode: string;
    leaserCompany: Company;
    contractorCompany: Company;
    contractProperty: Property;
    serviceContract: ServiceContract;
    manager: { code: string, name: string };
}
import * as React from "react";
import { Input, InputProps } from "reactstrap";
import { isNullOrUndefined } from "util";
import { NumberUtils } from "./NumberUtils";

interface Props extends InputProps {
  onNumberChange?: (value?: number) => void;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  maxValue?: number;
}

interface State {
  formattedValue: string;
}

export class NumberInput extends React.Component<Props, State> {

  static defaultProps: Props = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  };

  private input: Input;

  constructor(props: Props) {
    super(props);

    this.state = {
      formattedValue: !isNullOrUndefined(props.value) ?
          NumberUtils.format(props.value as string, props.minimumFractionDigits, props.maximumFractionDigits) : ""
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.formattedValue) { // input's value was updated externally
      const isNumber = NumberUtils.parse(this.props.value.toString());
      let formattedValue =
          NumberUtils.format(isNumber ? this.props.value.toString() : 0, this.props.minimumFractionDigits, this.props.maximumFractionDigits);

      this.setState({
        formattedValue: formattedValue
      });
    }
  }

  render() {
    let props = {...this.props};
    delete(props.onNumberChange);
    delete(props.minimumFractionDigits);
    delete(props.maximumFractionDigits);

    return (
        <Input
            {...props}
            onChange={this.onChange}
            value={this.state.formattedValue}
            ref={input => this.input = input!} />
    );
  }

  private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let target: HTMLInputElement = e.target as HTMLInputElement;
    const isNumber = NumberUtils.parse(target.value);
    let formattedValue = NumberUtils.format(isNaN(isNumber) ? 0 : target.value, this.props.minimumFractionDigits, this.props.maximumFractionDigits);
    let numberValue = NumberUtils.parse(formattedValue);
    if(this.props.maxValue) {
      numberValue = Math.min(numberValue, this.props.maxValue);
      formattedValue = numberValue.toString();
    }

    this.setState({
      formattedValue: formattedValue
    });

    if (this.props.onNumberChange) {
      this.props.onNumberChange(numberValue);
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };
}

import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {ServiceContractAddRowsModalData} from "../Modal";
import {CostItem, RowType, ServiceContractRow} from "../../Contracts/ContractDetails/ContractRow";
import {Classifier, ClassifierCode} from "../../Classifier/Classifier";
import {ExceptionRowDefaults, TimeLimits} from "../../Config/Config";
import {
    ContractType,
    FullConstructionContract,
    FullServiceContract,
    Project,
    Property,
    ServiceContractType
} from "../../Contracts/Contract";
import {RootState} from "../../rootReducer";
import {getBaseURL, getExceptionRowsDefaultValues, getRegularRowsDefaultValues} from "../../Config/configReducer";
import {getClassifiers} from "../../Classifier/classifierReducer";
import {getCostItems} from "../../Contracts/costItemReducer";
import {connect} from "react-redux";
import axios from "axios";
import ClassifierField from "../../Contracts/ContractDetails/ContractInfo/Fields/ClassifierField";
import DatePicker from "react-datepicker";
import {NumberInput} from "../../Common/NumberInput";


interface Props {
    modalOpen: boolean;
    modalData: ServiceContractAddRowsModalData;
    onToggleModal: () => void;

    allCostItems: CostItem[];
    baseURL: string;
    classifiers: Classifier[];

    exceptionRowsDefaults: ExceptionRowDefaults[];
    regularRowsDefaults: TimeLimits;
}

interface State {
    selectedCostItems: CostItem[];
    selectedCostItemCategory: CostItem;
    startDate: Date;
    endDate: Date;
    selectedProperties: Property[];
    selectedProjects: Project[];
    rowType: RowType;
    unitName: string;
    serviceDescription: string;
    multiplier: number;
    exceptionRowsRequired: boolean;
    leaseItemCodes?: string[],
    nextId: number
}

class ServiceContractAddRowsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedCostItems: [],
            selectedCostItemCategory: null,
            startDate: new Date(),
            endDate: new Date(),
            selectedProperties: [],
            selectedProjects: [],
            rowType: null,
            unitName: "",
            serviceDescription: "",
            multiplier: 1,
            exceptionRowsRequired: false,
            nextId: -1
        }
    }


    componentWillMount() {
        this.resetState();
        axios.get(this.props.baseURL + "/parameters/LEASE_SERVICE")
            .then(res => {
                axios.get(this.props.baseURL + "/parameters/SIDE_SERVICE")
                    .then(res2 => {
                        this.setState({
                            leaseItemCodes: [
                                ...res.data.value.split(","),
                                ...res2.data.value.split(",")
                            ]
                        });
                    });
            })
            .catch(() => {});
    }

    componentDidUpdate(prevProps:Props) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            this.resetState();
        }
    }

    private cancel = () => {
        this.resetState();
        this.props.onToggleModal();
    };

    private onSave = () => {

        let nextId = this.state.nextId;
        let newRows = this.props.modalData.editingContract.rows;
        for (let i = 0; i < this.state.multiplier; i++) {

            this.state.selectedProperties.map(property => {
                nextId = this.saveRow(property, false, nextId, newRows);
            })
            this.state.selectedProjects.map(project => {
                nextId = this.saveRow(project,true, nextId, newRows);
            })
        }

        this.props.modalData.updateEditingContract({
            ...this.props.modalData.editingContract,
            rows: newRows
        });

        this.resetState();
        this.props.onToggleModal();
    };

    private saveRow(object: Property | Project, isProject: boolean, nextId: number, newRows: ServiceContractRow[]) : number {

        this.state.selectedCostItems.map(costItem => {
            let serviceName: string;
            if(this.state.selectedCostItems.length == 1 && this.state.serviceDescription?.length > 0)
                serviceName = this.state.serviceDescription;
            else
                serviceName = costItem.label;
            const startTime = this.state.startDate?.getTime() || this.props.modalData.editingContract.startTime;
            const endTime = this.state.endDate?.getTime() || this.props.modalData.editingContract.endTime;

            if(this.state.rowType) {
                let row = new ServiceContractRow();
                row.costItem = costItem;
                row.serviceName = serviceName;
                row.serviceStartDate = startTime;
                row.serviceEndDate = endTime;
                row.type = this.state.rowType;
                if(isProject)
                    row.project = object as Project;
                else
                    row.propertyId = object.id;
                row.slaReactionTimeLimit = this.props.regularRowsDefaults.slaReactionTimeLimit;
                row.slaCompletionTimeLimit = this.props.regularRowsDefaults.slaCompletionTimeLimit;
                row.tempId = nextId;
                if(row.type == RowType.Unit)
                    row.unitName = this.state.unitName;
                if(row.type == RowType.MonthlyReserve)
                    row.rowPrice = 0;
                if(row.type == RowType.IndexableSum)
                    row.rowPrice = 0;

                newRows.push(row);
                nextId -= 1;
            }

            if (this.state.exceptionRowsRequired) {
                const exRows = this.handleAddingExceptionRows(
                    startTime,
                    endTime,
                    object.id,
                    object,
                    isProject,
                    costItem,
                    nextId,
                    serviceName
                );

                exRows.forEach(row => {
                    newRows.push(row);
                });
                nextId -= 3;
            }

            // special case for SERV_HK
            if(this.props.modalData.editingContract.serviceContractType == ServiceContractType.ServHk) {
                // add an extra unit row
                if(this.state.rowType && this.state.rowType == RowType.MonthlyReserve) {
                    let row = new ServiceContractRow();
                    row.costItem = costItem;
                    row.serviceName = serviceName;
                    row.serviceStartDate = startTime;
                    row.serviceEndDate = endTime;
                    row.type = RowType.Unit;
                    row.unitName = "€/m2";
                    row.unitPrice = 0;
                    row.rowPrice = 0;
                    if(isProject)
                        row.project = object as Project;
                    else
                        row.propertyId = object.id;
                    row.slaReactionTimeLimit = this.props.regularRowsDefaults.slaReactionTimeLimit;
                    row.slaCompletionTimeLimit = this.props.regularRowsDefaults.slaCompletionTimeLimit;
                    row.tempId = nextId;

                    newRows.push(row);
                    nextId -= 1;
                }
            }
        });
        return nextId;
    }

    private handleAddingExceptionRows = (
        startTime: number,
        endTime: number,
        propertyId: number,
        object:  Property| Project,
        isProject: boolean,
        costItem: CostItem,
        nextId: number,
        serviceName?: string
    ) => {
        const exceptions = this.props.exceptionRowsDefaults;
        let rowsArray: ServiceContractRow[] = [];

        for (const ex of exceptions) {
            let newRow = new ServiceContractRow();
            newRow.type = RowType.Unit;
            if(isProject)
                newRow.project = object as Project;
            else
                newRow.propertyId = propertyId;
            newRow.tempId = nextId;
            newRow.costItem = costItem;
            newRow.serviceStartDate = startTime;
            newRow.serviceEndDate = endTime;
            newRow.serviceName = ex.name + " - " + (serviceName || costItem.label);
            newRow.slaReactionTimeLimit = ex.timeLimits.slaReactionTimeLimit;
            newRow.slaCompletionTimeLimit = ex.timeLimits.slaCompletionTimeLimit;
            newRow.unitName = "€/h";
            nextId -= 1;

            rowsArray.push(newRow);
        }

        return rowsArray;
    };

    private selectCostItemCategory(costItem: CostItem) {
        this.setState({
            selectedCostItemCategory : costItem
        })
    }

    private toggleCostItemSelection(item: CostItem) {
        if(!this.isCostItemAvailable(item)) return;

        let selectedCostItems = this.state.selectedCostItems;
        if(selectedCostItems.includes(item))
            selectedCostItems = selectedCostItems.filter(costItem => costItem !== item)
        else
            selectedCostItems.push(item);

        this.setState({ selectedCostItems: selectedCostItems})
    }

    private togglePropertySelection(item: Property) {
        let selectedProperties = this.state.selectedProperties;
        if(selectedProperties.includes(item))
            selectedProperties = selectedProperties.filter(property => property !== item)
        else
            selectedProperties.push(item);

        this.setState({ selectedProperties: selectedProperties})
    }

    private toggleProjectSelection(item: Project) {
        let selectedProjects = this.state.selectedProjects;
        if(selectedProjects.includes(item))
            selectedProjects = selectedProjects.filter(project => project !== item)
        else
            selectedProjects.push(item);

        this.setState({ selectedProjects: selectedProjects})
    }

    private onRowTypeChange = (fieldKey: keyof FullServiceContract, value: any) => {
        let newCostItems = this.state.selectedCostItems;
        if(value == RowType.IndexableSum) {
            newCostItems = newCostItems.filter(i => ["4820","8820"].some(c => c == i.code));
        } else {
            newCostItems = newCostItems.filter(i => !["4820","8820"].some(c => c == i.code));
        }
        this.setState({rowType: value, selectedCostItems: newCostItems});
    };

    private onUnitNameChange = (fieldKey: keyof FullServiceContract, value: any) => {
        this.setState({unitName: value});
    };

    private calculateRowCount = () => {
        return (this.state.exceptionRowsRequired ? (this.state.rowType ? 4 : 3) : (this.state.rowType ? 1 : 0))
            * (this.state.multiplier ?? 0)
            * ((this.state.selectedProperties?.length || 0) + (this.state.selectedProjects?.length || 0))
            * this.state.selectedCostItems.length;
    }

    private resetState() {
        const selectedProperties = this.props.modalData.selectedProperty ?
            [this.props.modalData.editingContract.properties.find(property => property.id == this.props.modalData.selectedProperty.id)]
            : (this.props.modalData.selectedProject ? [] : this.props.modalData.editingContract.properties);

        const selectedProjects = this.props.modalData.selectedProject ?
            [(this.props.modalData.editingContract as FullConstructionContract).projects.find(project => project.id == this.props.modalData.selectedProject.id)]
            : (this.props.modalData.selectedProperty ? [] : (this.props.modalData.editingContract as FullConstructionContract).projects || []);

        this.setState({
            selectedCostItems: [],
            selectedCostItemCategory: this.props.allCostItems[0],
            startDate: this.props.modalData.editingContract.startTime ? new Date(this.props.modalData.editingContract.startTime) : null,
            endDate: this.props.modalData.editingContract.endTime ? new Date(this.props.modalData.editingContract.endTime) : null,
            selectedProperties: selectedProperties,
            selectedProjects: selectedProjects,
            rowType: null,
            serviceDescription: "",
            unitName: this.props.modalData.editingContract.serviceContractType == ServiceContractType.ServTh ? "€/h" : "",
            multiplier: 1,
            exceptionRowsRequired: false,
            nextId: -1
        });
    }

    render() {
        console.log(this.state);
        return (
            <Modal isOpen={this.props.modalOpen} toggle={this.cancel} size="xl">
                <ModalHeader toggle={this.cancel} >
                    Baasridade lisamine
                </ModalHeader>

                <ModalBody className="p-2 p-lg-3">
                    {this.props.modalData.editingContract.properties.length > 0 &&
                    <>
                        <h6 className="text-dark font-weight-normal mt-3 mb-3 border-bottom-light-gray">Lisa kulukohad valitud kinnistutele</h6>
                        <div className="d-flex flex-column">
                            {this.renderPropertySelection()}
                        </div>
                    </>
                    }
                    {(this.props.modalData.editingContract as FullConstructionContract).projects &&
                        <>
                            <h6 className="text-dark font-weight-normal mt-3 mb-3 border-bottom-light-gray">Lisa kulukohad valitud projektidele</h6>
                            <div className="d-flex flex-column">
                                {this.renderProjectSelection()}
                            </div>
                        </>
                    }

                    <h6 className="text-dark font-weight-normal mt-3 mb-3 border-bottom-light-gray">Vali kulukohad</h6>
                    <div className="d-flex">
                        {this.renderCostItemCategories()}
                        <div>
                            <div className={"selectable-baserow " + (!!this.state.selectedCostItems.includes(this.state.selectedCostItemCategory) && "selected")}
                                 style={{marginLeft: 0}}
                                 onClick={(e) => {e.preventDefault(); this.toggleCostItemSelection(this.state.selectedCostItemCategory)}}>
                                <div className="selectable-baserow-number">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               className="custom-control-input"
                                               id={"select-costitem-"+this.state.selectedCostItemCategory.code}
                                               readOnly={true}
                                               disabled={!this.isCostItemAvailable(this.state.selectedCostItemCategory)}
                                               checked={!!this.state.selectedCostItems.includes(this.state.selectedCostItemCategory)}
                                        />
                                        <label className="custom-control-label no-wrap" htmlFor={"select-costitem-"+this.state.selectedCostItemCategory.code}/>
                                    </div>
                                    <h6 className="pl-2">{this.state.selectedCostItemCategory.code}</h6>
                                </div>
                                <div className="selectable-baserow-text">
                                    <h6 className="pl-2">{this.state.selectedCostItemCategory.label}</h6>
                                </div>
                            </div>
                            {this.renderCostItemTree(this.state.selectedCostItemCategory,1)}
                        </div>
                    </div>
                    <h6 className="text-dark font-weight-normal mt-3 mb-3 border-bottom-light-gray">Muu info</h6>
                    <div className="d-flex flex-column">
                        {this.renderRowMetadata()}
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary"
                    >
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        disabled={this.calculateRowCount() <= 0}
                        color="primary"
                    >
                        Salvesta read
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private renderCostItemCategories() {
        return (
            <div className="col-12 d-flex flex-column justify-content-start" style={{maxWidth: "200px"}}>
                {this.props.allCostItems.map(costItem => (
                    <div key={costItem.id} className={"baserow " + (costItem === this.state.selectedCostItemCategory && "selected")}
                         onClick={() => this.selectCostItemCategory(costItem)}>
                        <h5 className="font-weight-bold mb-0">{costItem.code}</h5>
                        <span className="baserow-title">{costItem.label}</span>
                    </div>
                ))
                }
            </div>
        );
    }

    private renderCostItemTree(costItem : CostItem, depth: number) {
        const newDepth = depth + 1;
        return (costItem.children.map(
                item => (
                    <React.Fragment key={item.id}>
                        <div className={"selectable-baserow " + (!!this.state.selectedCostItems.includes(item) && "selected")}
                             style={{marginLeft: depth * 20}}
                             onClick={(e) => {e.preventDefault(); this.toggleCostItemSelection(item)}}>
                            <div className="selectable-baserow-number">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           className="custom-control-input"
                                           id={"select-costitem-"+item.code}
                                           readOnly={true}
                                           disabled={!this.isCostItemAvailable(item)}
                                           checked={!!this.state.selectedCostItems.includes(item)}
                                    />
                                    <label className="custom-control-label no-wrap" htmlFor={"select-costitem-"+item.code}/>
                                </div>
                                <h6 className="pl-2">{item.code}</h6>
                            </div>
                            <div className="selectable-baserow-text">
                                <h6 className="pl-2">{item.label}</h6>
                            </div>
                        </div>
                        {this.renderCostItemTree(item, newDepth)}
                    </React.Fragment>
                )
            )
        );
    }

    private renderPropertySelection() {
        return this.props.modalData.editingContract.properties.map(
            item => (
                <React.Fragment key={item.id}>
                    <div className={"" + (!!this.state.selectedProperties.includes(item) && "selected")}
                         onClick={(e) => {e.preventDefault(); this.togglePropertySelection(item)}}
                         style={{width: "max-content", cursor: "pointer"}}>
                        <div className="selectable-baserow-number d-inline-block" style={{ minWidth: "25px", maxWidth: "25px"}}>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                       className="custom-control-input"
                                       id={"select-property-"+item.code}
                                       readOnly={true}
                                       checked={!!this.state.selectedProperties.includes(item)}
                                />
                                <label className="custom-control-label no-wrap" htmlFor={"select-property-"+item.code}/>
                            </div>

                        </div>
                        <div className="selectable-baserow-text d-inline-block">
                            <h6 className="pl-2">{`${item.name} (${item.code}), ${item.address}`}</h6>
                        </div>
                    </div>
                </React.Fragment>
            )
        )
    }

    private renderProjectSelection() {
        return (this.props.modalData.editingContract as FullConstructionContract).projects.map(
            item => (
                <React.Fragment key={item.id}>
                    <div className={"" + (!!this.state.selectedProjects.includes(item) && "selected")}
                         onClick={(e) => {e.preventDefault(); this.toggleProjectSelection(item)}}
                         style={{width: "max-content", cursor: "pointer"}}>
                        <div className="selectable-baserow-number d-inline-block" style={{ minWidth: "25px", maxWidth: "25px"}}>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                       className="custom-control-input"
                                       id={"select-project-"+item.id}
                                       readOnly={true}
                                       checked={!!this.state.selectedProjects.includes(item)}
                                />
                                <label className="custom-control-label no-wrap" htmlFor={"select-project-"+item.id}/>
                            </div>

                        </div>
                        <div className="selectable-baserow-text d-inline-block">
                            <h6 className="pl-2">{`${item.name} (${item.projectNumber})`}</h6>
                        </div>
                    </div>
                </React.Fragment>
            )
        )
    }

    private renderRowMetadata() {
        return (
            <>
                {(this.state.selectedCostItems.length == 1) &&
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Teenuse kirjeldus:</label>
                        <input className="form-control"
                               placeholder={this.state.selectedCostItems[0].label}
                               defaultValue={this.state.serviceDescription}
                               onChange={e => this.setState({serviceDescription: e.target.value})}
                        />

                    </div>
                </div>
                }
                <ClassifierField contract={null}
                                 label={"Rea liik:"}
                                 fieldKey={null}
                                 fieldValue={this.state.rowType}
                                 classifiers={this.props.classifiers}
                                 classifierCode={ClassifierCode.ContractRowType}
                                 portalTarget={"#modal"}
                                 customClass={"select-addrows-modal"}
                                 excludedValueCodes={this.props.modalData.editingContract.type == ContractType.ServiceContract ? ['QUANTITY'] : null}
                                 required={true}
                                 editMode={true}
                                 editable={true}
                                 isClearable={true}
                                 onChange={this.onRowTypeChange}/>
                {(this.state.rowType == RowType.Unit) &&
                    <ClassifierField contract={null}
                                     label={"Ühik:"}
                                     fieldKey={null}
                                     fieldValue={this.state.unitName}
                                     classifiers={this.props.classifiers}
                                     classifierCode={ClassifierCode.UnitType}
                                     portalTarget={"#modal"}
                                     customClass={"select-addrows-modal"}
                                     editMode={true}
                                     editable={true}
                                     isClearable={true}
                                     onChange={this.onUnitNameChange}/>
                }
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Algus:</label>
                        <DatePicker
                            className="form-control calendar-input"
                            selected={this.state.startDate}
                            placeholderText={"Vali"}
                            dateFormat="dd.MM.yyyy"
                            onChange={(value: Date) => this.setState({startDate: value })}
                        />
                    </div>
                </div>
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Lõpp:</label>
                        <DatePicker
                            className="form-control calendar-input"
                            selected={this.state.endDate}
                            placeholderText={"Vali"}
                            dateFormat="dd.MM.yyyy"
                            onChange={(value: Date) => this.setState({endDate: value })}
                        />
                    </div>
                </div>
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Erindiread:</label>
                        <div className="btn-group d-flex" style={{zIndex:0}}>
                            <Button
                                size="sm"
                                color="outline-primary"
                                onClick={() => this.setState({exceptionRowsRequired: true})}
                                className={this.state.exceptionRowsRequired ? "active" : ""}
                                role="button">
                                Jah
                            </Button>
                            <Button
                                size="sm"
                                color="outline-primary"
                                onClick={() => this.setState({exceptionRowsRequired: false})}
                                className={this.state.exceptionRowsRequired == false ? "active" : ""}
                                role="button">
                                Ei
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Kordaja:</label>
                        <NumberInput value={this.state.multiplier} maxValue={15} style={{width: "160px"}}
                                     onNumberChange={value => this.setState({multiplier: value})} />
                    </div>
                </div>
                <div className="form-row custom-form-row">
                    <div className="col-12 align-items-start">
                        <label className="w-200">Ridu kokku:</label>
                        <span>{this.calculateRowCount()}</span>
                    </div>
                </div>
            </>
        );
    }

    private isCostItemAvailable(item: CostItem) {

        if(item.disabled) return false;
        // incompatible with SAP
        if(this.props.modalData.editingContract.type == ContractType.ConstructionContract && item.code == "8000")
            return false;

        if(!this.state.rowType) return true;
        if(["4820","8820"].some(value => item.code === value)) {
            return this.state.rowType == RowType.IndexableSum;
        } else {
            return this.state.rowType !== RowType.IndexableSum;
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    allCostItems: getCostItems(state),
    exceptionRowsDefaults: getExceptionRowsDefaultValues(state),
    regularRowsDefaults: getRegularRowsDefaultValues(state),
});

export default connect(mapStateToProps,null)(ServiceContractAddRowsModal);

import { Alert } from "./Alert";
import { RootState } from "../rootReducer";
import { alertActions } from "./alertActions";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export class AlertState {
  alerts: Map<number, Alert>;
  nextId: number;

  constructor(state?: AlertState) {
    if (state) {
      this.alerts = new Map(state.alerts);
      this.nextId = state.nextId;
    } else {
      this.alerts = new Map();
      this.nextId = 0;
    }
  }
}

export const alertReducer = createReducer(new AlertState(), {
    [alertActions.addAlert.type]: (state, action: PayloadAction<Alert>) => {
        let newState = new AlertState(state);
        if (state.alerts.size === 5) {
          const firstAlert = [...Array.from(newState.alerts.values())][0];
          newState.alerts.delete(firstAlert.id);
        }

        action.payload.id = state.nextId;
        newState.alerts.set(action.payload.id, action.payload);
        newState.nextId = state.nextId + 1;

        return newState;
    },
    [alertActions.removeAlert.type]: (state, action: PayloadAction<number>) => {
        let newState = new AlertState(state);
        newState.alerts.delete(action.payload);
        return newState;
    }
});

export function getAlerts(state: RootState): Alert[] {
  return [...Array.from(state.alert.alerts.values())];
}
export class Classifier {

  code: ClassifierCode;
  name: string;
  description: string;
  values: ClassifierValue[];
}

export class ClassifierValue {

  code: string;
  name: string;
}

export enum ClassifierCode {
  ActFrequency = "ACT_FREQUENCY",
  ConsumptionService = "CONSUMPTION_SERVICE",
  ContractEventType = "CONTRACT_EVENT_TYPE",
  ContractRowType = "CONTRACT_ROW_TYPE",
  ContractType = "CONTRACT_TYPE",
  EndingPredication = "ENDING_PREDICATION",
  HeatingType = "HEATING_TYPE",
  NoticeDate = "NOTICE_DATE",
  IncomeType = "INCOME_TYPE",
  LeaseContractRowSource = "LEASE_CONTRACT_ROW_SOURCE",
  LeaseContractType = "LEASE_CONTRACT_TYPE",
  LeaseType = "LEASE_TYPE",
  ReserveCalculationBasedOn = "RESERVE_CALCULATION_BASED_ON",
  ServiceContractType = "SERVICE_CONTRACT_TYPE",
  SupportServiceContractType = "SUPPORT_SERVICE_CONTRACT_TYPE",
  InvoiceChannelType = "INVOICE_CHANNEL_TYPE",
  RegionType = "REGION_TYPE",
  ValidationType = "VALIDATION_TYPE",
  ContractFileType = "CONTRACT_FILE_TYPE",
  MeterForwardType = "METER_FORWARD_TYPE",
  ConsumptionContractType = "CONSUMPTION_CONTRACT_TYPE",
  UnitType = "UNIT_TYPE",
  EquipmentType = "EQUIPMENT_TYPE",
  ThCostItemGroupType = "TH_COST_ITEM_GROUP_TYPE",
  PortfolioType = "PORTFOLIO_TYPE",
  GovernanceAreaType = "GOVERNANCE_AREA_TYPE",
  RowImportErrorCode = "ROW_IMPORT_ERROR_CODE",
  ContractPublishedStatusType = "CONTRACT_PUBLISHED_STATUS_TYPE",
  ContractExpirationStatusType = "CONTRACT_EXPIRATION_STATUS_TYPE",
}

export function getClassifierValues(classifiers: Classifier[], classifierCode: ClassifierCode): ClassifierValue[] {
  if (classifiers) {
    let classifier = classifiers.find(c => c.code === classifierCode);
    if (classifier) {
      return classifier.values;
    }
  }
  return [];
}

export function getClassifierValueName(classifiers: Classifier[], classifierCode: ClassifierCode, valueCode: string):
    string {

  if (classifiers) {
    let classifier = classifiers.find(c => c.code === classifierCode);
    if (classifier && classifier.values) {
      let value = classifier.values.find(v => v.code === valueCode);
      if (value) {
        return value.name;
      }
    }
  }
  return valueCode;
}
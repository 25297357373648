import React from "react";
import {ContractInfoField, ContractInfoFieldProps} from "./ContractInfoField";
import {FullContract} from "../../../Contract";
import DatePicker from "react-datepicker";
import {BodyPortal} from "../../../../Common/BodyPortal";
import moment from "moment";
import {PagingMetadata} from "../../../../Paging/Paging";

interface Props<T extends FullContract> extends ContractInfoFieldProps<T> {
    unlimited?: boolean;
}

interface State {
    unlimitedChecked: boolean;
}

export default class DateField<T extends FullContract> extends ContractInfoField<T, Props<T>, State> {

    constructor(props: Props<T>) {
        super(props);

        this.state = {
            unlimitedChecked: false
        };
    }

    componentDidMount(): void {
        this.setState({
            unlimitedChecked: this.props.unlimited && (this.getValue()?.valueOf() == new Date(253370757600000).valueOf())
        });
    }

    private getValue(): Date {
        return (this.getFieldValue() && !this.state.unlimitedChecked) ? new Date(this.getFieldValue()) : undefined;
    }

    private onChange = (date: Date) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.fieldKey, date?.getTime());
        }
    };

    private onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({unlimitedChecked: event.target.checked});
        this.onChange(event.target.checked ? new Date(253370757600000) : this.getValue());
    };

    protected renderReadOnlyField(): JSX.Element {
        const value: number = this.getFieldValue();
        const unlimited = value == new Date(253370757600000).valueOf();

        return (
            <span className="font-weight-bold">
              {(value && !unlimited) && moment(value).format("DD.MM.YYYY")}
              {!value && "-"}
              {unlimited && "Tähtajatu"}
            </span>
        )
    }

    protected renderEditableField(): JSX.Element {

        return (
            <div className="d-flex">
                <div className="w-140" style={{minWidth: "140px"}}>
                    <DatePicker
                        className="form-control calendar-input"
                        selected={this.getValue()}
                        placeholderText={this.state.unlimitedChecked ? "Tähtajatu" : "Vali"}
                        dateFormat="dd.MM.yyyy"
                        onChange={this.onChange}
                        popperContainer={BodyPortal}
                        popperPlacement={"top"}
                        popperModifiers={{
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                                padding: 25
                            }
                        }}
                        disabled={!this.props.editable || (this.props.unlimited && this.state.unlimitedChecked)}
                    />
                </div>
                {this.props.unlimited &&
                <div className="custom-control custom-checkbox form-control" style={{paddingTop: "6px"}}>
                    <input type="checkbox"
                           className="custom-control-input"
                           id={"check-" + this.props.fieldKey}
                           checked={this.state.unlimitedChecked}
                           disabled={!this.props.editable}
                           onChange={this.onCheckBoxChange}
                    />
                    <label className="custom-control-label no-wrap" htmlFor={"check-" + this.props.fieldKey}>
                        Tähtajatu/10 aastat
                    </label>
                </div>
                }
            </div>
        );
    }
}
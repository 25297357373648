import { CostItem } from "./ContractDetails/ContractRow";
import { RootState } from "../rootReducer";
import { costItemActions } from "./costItemActions";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export class CostItemState {
  costItems: CostItem[];

  constructor(state?: CostItemState) {
    if (state) {
      this.costItems = state.costItems;
    } else {
      this.costItems = [];
    }
  }
}

export const costItemsReducer = createReducer(new CostItemState(), {
    [costItemActions.addCostItems.type]: (state, action: PayloadAction<CostItem[]>) => {
        let newState = new CostItemState();
        newState.costItems = action.payload;
        return newState;
    }
});

export function getCostItems(state: RootState): CostItem[] {
  return state.costItem.costItems;
}
import * as React from 'react';
import {
    ContractType,
    FullConsumptionContract,
    FullLeaseContract,
    FullContract,
    FullServiceContract,
    FullConstructionContract, FullEnergyContract, FullSupportServiceContract
} from "../../Contract";
import ContractFiles from './Files/ContractFiles';
import './ContractInfo.css';
import ConsumptionInfo from "../ConsumptionContract/ConsumptionInfo";
import { Button } from "reactstrap";
import equal from "fast-deep-equal/es6/react";
import LeaseInfo from "../LeaseContract/LeaseInfo";
import ServiceInfo from "../ServiceContract/ServiceInfo";
import ConstructionInfo from "../ConstructionContract/ConstructionInfo";
import axios from "axios";
import {RootState} from "../../../rootReducer";
import {getBaseURL} from "../../../Config/configReducer";
import {connect} from "react-redux";
import EnergyInfo from "../EnergyContract/EnergyInfo";
import SupportServiceInfo from "../SupportServiceContract/SupportServiceInfo";

interface Props {
    baseURL: string;

    contract: FullContract;
    editingContract?: FullContract;
    infoEditMode: boolean;

    toggleInfoEditMode?: () => void;
    onShowUnsavedRowsConfirmationModal?: () => void;
    updateEditingContract: (contract: FullContract, callback?: () => void) => void;
    onSaveContract: () => void;
    onUnpublishContract: (toggleModal: boolean) => void;
    onPublishContract: (toggleModal: boolean) => void;
}

interface State {
    open: boolean;
    tabs: TabConfiguration[];
    openedTab: TabConfiguration;
    fileCount: number;
}

interface TabConfiguration {
    name: string;
    label: string;
    showEdit: boolean;
}

class ContractInfo extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const tabs = this.getDefaultTabConfiguration();
        this.state = {
            open: true,
            tabs: tabs,
            openedTab: tabs[0],
            fileCount: 0
        };
    }

    componentDidMount(): void {
        this.getFileCount();
    }

    private getDefaultTabConfiguration(): TabConfiguration[] {

        switch (this.props.contract.type) {
            case ContractType.LeaseAgreement:
                return [
                    { name:"overview", label: 'Lepingu põhiandmed', showEdit: true },
                    { name:"lease", label: 'Üüri andmed', showEdit: true },
                    { name:"files", label: 'Failid', showEdit: false },
                ]
            default:
                return [
                    { name:"overview", label: 'Lepingu põhiandmed', showEdit: true },
                    { name:"files", label:  'Failid', showEdit: false },
                ]
        }
    }

    private renderTabs() {
        return (
            this.state.tabs.map((tab) => (
                <React.Fragment key={tab.name}>
                    <button
                        onClick={() => {this.openTab(tab)}}
                        className={`btn btn-link complex-block-title block-title-btn position-relative no-wrap
                        ${this.state.open && this.state.openedTab === tab && 'tab-open'}
                        ${this.state.openedTab === tab && 'block-active'}`}
                    >
                        {tab.label + (tab.name == "files" ? ` (${this.state.fileCount})` : "")}
                    </button>
                    <span className="complex-block-title-separator"/>
                </React.Fragment>
            ))
        );
    }

    private renderContent() {
        switch(this.state.openedTab.name) {
            case "overview":
            case "lease":
                return this.renderInfo(this.state.openedTab.name);
            case "files":
                return this.renderFiles();
        }
    }

    private renderInfo(filterType: string) {
        switch (this.props.contract.type) {
            case ContractType.ConsumptionContract:
                return (
                    <ConsumptionInfo contract={this.props.contract as FullConsumptionContract}
                                     editingContract={this.props.editingContract as FullConsumptionContract}
                                     editMode={this.props.infoEditMode}
                                     updateEditingContract={this.props.updateEditingContract}
                    />
                );
            case ContractType.LeaseAgreement:
                return (
                    <LeaseInfo contract={this.props.contract as FullLeaseContract}
                               filterType={filterType}
                               editingContract={this.props.editingContract as FullLeaseContract}
                               editMode={this.props.infoEditMode}
                               updateEditingContract={this.props.updateEditingContract}
                    />
                );
            case ContractType.EnergySaleAgreement:
                return (
                    <EnergyInfo contract={this.props.contract as FullEnergyContract}
                               editingContract={this.props.editingContract as FullEnergyContract}
                               editMode={this.props.infoEditMode}
                               updateEditingContract={this.props.updateEditingContract}
                    />
                );
            case ContractType.ServiceContract:
                return (
                    <ServiceInfo contract={this.props.contract as FullServiceContract}
                                 filterType={filterType}
                                 editingContract={this.props.editingContract as FullServiceContract}
                                 editMode={this.props.infoEditMode}
                                 updateEditingContract={this.props.updateEditingContract}
                    />
                );
            case ContractType.SupportServiceContract:
                return (
                    <SupportServiceInfo contract={this.props.contract as FullSupportServiceContract}
                                        editingContract={this.props.editingContract as FullSupportServiceContract}
                                        editMode={this.props.infoEditMode}
                                        updateEditingContract={this.props.updateEditingContract}
                    />
                );
            case ContractType.ConstructionContract:
                return (
                    <ConstructionInfo contract={this.props.contract as FullConstructionContract}
                                      filterType={filterType}
                                      editingContract={this.props.editingContract as FullConstructionContract}
                                      editMode={this.props.infoEditMode}
                                      updateEditingContract={this.props.updateEditingContract}
                    />
                );
            default:
                return <></>;
        }
    }

    private renderFiles() {
        return (
            <ContractFiles
                open={this.state.open}
                contract={this.props.contract}
                editingContract={this.props.editingContract}
                onFileCountChanged={this.onFileCountChanged}
                updateEditingContract={this.props.updateEditingContract}
                onSaveContract={this.props.onSaveContract}
                onUnpublishContract={this.props.onUnpublishContract}
                onPublishContract={this.props.onPublishContract}
            />
        );
    }

    private toggleOpen = () => {
        this.setState({
            open: !this.state.open
        });
    };

    private openTab = (tab: TabConfiguration) => {
        this.setState({
            openedTab : tab
        });
    }

    private editInfo = () => {
        this.setState({
            open: true,
        }, () => {
            this.props.toggleInfoEditMode();
        })
    };

    private getFileCount() {
        axios.get(`${this.props.baseURL}/contracts/${this.props.contract.id}/files`)
            .then(res => {
                this.setState({
                    fileCount: res.data.length
                })
            });
    }

    private onFileCountChanged = (newCount : number) : void => {
        this.setState({fileCount: newCount});
    }

    private enterInfoEditMode = () => {
        switch (this.props.contract.type) {
            case ContractType.LeaseAgreement:
                if(!equal(
                    (this.props.editingContract as FullLeaseContract).rows,
                    (this.props.contract as FullLeaseContract).rows)) {
                    return this.props.onShowUnsavedRowsConfirmationModal();
                }
                return this.editInfo();
            default:
                return this.editInfo();
        }

    }

    render(): React.ReactNode {
        return (
            <div className="block block-white width-100 block-collapsible contract-info">
                <div className="d-flex title-border-bottom align-items-center justify-content-between">
                    {!this.props.infoEditMode &&
                        <div className="d-flex align-items-center">
                            {this.renderTabs()}
                        </div>
                    }

                    {this.props.infoEditMode &&
                        <span className="block-title w-auto no-wrap">Lepingu põhiandmed</span>
                    }
                    <button
                        onClick={this.toggleOpen}
                        className={`btn btn-link block-title block-title-btn position-relative
                                                ${this.state.open ? 'arrow-up' : 'arrow-down'}`}
                    >
                        <span className="icon icon_arrow_dark"/>
                    </button>


                    {!this.props.contract.publishedOn && this.state.openedTab.showEdit
                    && !this.props.infoEditMode &&
                    <Button color="outline-dark"
                            className="no-wrap d-flex align-items-center justify-content-center mr-3 border-0"
                            onClick={this.enterInfoEditMode}
                    >
                        Muuda
                    </Button>
                    }
                </div>
                <div className={`block-collapsible-content ${this.state.open && 'open'}`}>
                    <div className="p-4 p-md-4 p-lg-5">
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state)
});

export default connect(mapStateToProps)(ContractInfo);
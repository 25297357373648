import React from "react";
import {ContractType, LeaseContract} from "../Contract";
import {hasPermission, Permission} from "../../Auth/User";
import {AbstractContractList} from "../AbstractContractList";
import {RootState} from "../../rootReducer";
import {getBaseURL} from "../../Config/configReducer";
import {alertActions} from '../../Alerts/alertActions';
import {connect} from "react-redux";
import {ColumnDescription} from "react-bootstrap-table-next";
import {
  ClassifierCode,
  ClassifierValue,
  getClassifierValueName,
  getClassifierValues
} from "../../Classifier/Classifier";
import {getClassifiers} from "../../Classifier/classifierReducer";
import axios from "axios";
import {Alert, AlertContentType, AlertType} from '../../Alerts/Alert';
import {NavLink} from "react-router-dom";
import {checkMarkFormatter, dateFormatter, personNameFormatter} from "../../BootstrapTable/customFormatters";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {getCurrentUser} from "../../Auth/authReducer";

class LeaseContractList extends AbstractContractList<LeaseContract> {

  static readonly TYPE: ContractType = ContractType.LeaseAgreement;

  protected getType(): ContractType {
    return LeaseContractList.TYPE;
  }

  protected getManagePermission(): Permission | Permission[] {
    return Permission.ManageLeaseAgreement;
  }

  protected getColumns(): ColumnDescription<LeaseContract>[] {
    const columns = [
      {
        dataField: "number",
        text: "Lepingu number",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/lease/" + row.id}>{cell}</NavLink>
      },
      {
        dataField: "generalDocumentTitle",
        text: "Pealkiri",
        sort: true
      },
      {
        dataField: "leaseContractType",
        text: "Tüüp",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.LeaseContractType, cell)
      },
      {
        dataField: "rentalCode",
        text: "Üürilepingu kood",
        sort: true
      },
      {
        dataField: "properties[0].code",
        text: "Kinnistu kood",
        sort: false
      },
      {
        dataField: "properties[0].address",
        text: "Kinnistu aadress",
        sort: false
      },
      {
        dataField: "properties[0].regionType",
        text: "Kinnistu piirkond",
        sort: false,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.RegionType, cell)
      },
      {
        dataField: "leaser.name",
        text: "Üürnik",
        sort: true
      },
      {
        dataField: "area",
        text: "Üüripind",
        sort: true
      },
      {
        dataField: "manager",
        text: "Haldur",
        sort: true,
        formatter: personNameFormatter
      },
      {
        dataField: "properties[0].accountant",
        text: "Raamatupidaja",
        sort: false,
        formatter: personNameFormatter
      },
      {
        dataField: "leaseType",
        text: "Üüri liik",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.LeaseType, cell)
      },
      {
        dataField: "isSettleable",
        text: "Tasaarveldatav",
        sort: true,
        formatter: checkMarkFormatter
      },
      {
        dataField: "frameContractReference",
        text: "Raamleping",
        sort: true
      },
      {
        dataField: "publishedOn",
        text: "Avaldatud",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "startTime",
        text: "Algus",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "endTime",
        text: "Lõpp",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "leaser.governanceArea",
        text: "Valitsemisala",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.GovernanceAreaType, cell)
      },
      {
        dataField: "properties[0].portfolioType",
        text: "Portfell",
        sort: false,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.PortfolioType, cell)
      },
      {
        dataField: "properties[0].portfolioManager",
        text: "Portfellihaldur",
        sort: false,
        formatter: personNameFormatter
      },
      {
        dataField: "comment",
        text: "Märkused",
        sort: false,
      },
      {
        dataField: "limit",
        text: "Piirmäär",
        sort: true,
      },
    ];

    if (hasPermission(this.props.currentUser, Permission.ViewLeaseAgreementAdditional)) {
      columns.splice(3,0, {
        dataField: "mainLease",
        text: "Peaüür",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(18,0, {
        dataField: "isOrdinaryCancellation",
        text: "Korraline ülesütlemine",
        sort: true,
        formatter: checkMarkFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(19,0, {
        dataField: "cancellationPeriod",
        text: "Etteteatamise aeg (kuud)",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(20,0, {
        dataField: "cancellationContractReference",
        text: "Lepingu punkt",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(21,0, {
        dataField: "endingPredication",
        text: "Lõpetamise alus",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.EndingPredication, cell),
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(22,0, {
        dataField: "cancellationCompensation",
        text: "Ülesütlemise hüvitis",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(23,0, {
        dataField: "subsequentMonthInvoicing",
        text: "Arvelda kuu hiljem",
        sort: true,
        formatter: checkMarkFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(24,0, {
        dataField: "invoiceChannelType",
        text: "Arve saatmise kanal",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.InvoiceChannelType, cell),
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(25,0, {
        dataField: "invoiceDueDays",
        text: "Maksetähtaeg",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(30,0, {
        dataField: "transitionPeriodEnd",
        text: "Ülemineku perioodi lõpp",
        sort: true,
        formatter: dateFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(31,0, {
        dataField: "indexingStart",
        text: "Indekseerimise algus",
        sort: true,
        formatter: dateFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(32,0, {
        dataField: "cpiDate",
        text: "THI kuupäev",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(33,0, {
        dataField: "coefficient",
        text: "Koefitsient",
        sort: true,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(34,0, {
        dataField: "consumptionService",
        text: "Tarbimisteenuste tüüp",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.ConsumptionService, cell),
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(35,0, {
        dataField: "sendNotice",
        text: "Saada üüriteatis",
        sort: true,
        formatter: checkMarkFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(36,0, {
        dataField: "noticeDate",
        text: "Üüriteatise saatmise aeg",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.NoticeDate, cell),
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(37,0, {
        dataField: "isBondCollateral",
        text: "Laenu tagatis",
        sort: true,
        formatter: checkMarkFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
      columns.splice(38,0, {
        dataField: "isInternalBilling",
        text: "Kontorikulud",
        sort: true,
        formatter: checkMarkFormatter,
        hidden: true,
        reverseVisibility : true
      } as any);
    }

    return columns;
  }

  protected renderAddButton() {
    return (
        <>
          <UncontrolledDropdown className="ml-4">
            <DropdownToggle color="primary" caret>
              + Lisa kasutusleping
            </DropdownToggle>
            <DropdownMenu>
              {getClassifierValues(this.props.classifiers, ClassifierCode.LeaseContractType)
                  .map((classifierValue, index) => (
                      <DropdownItem
                          key={`${classifierValue.name}-${index}`}
                          tag="a"
                          className="p-3 pointer"
                          disabled={this.state.addButtonLoading}
                          onClick={(e) => this.handleAddMultiButton(e, classifierValue)}
                          role="button">
                        {classifierValue.name}
                      </DropdownItem>
                  ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
    );
  }

  protected handleAddMultiButton = (e: React.MouseEvent<HTMLElement>, classifierValue :  ClassifierValue) => {
    e.preventDefault();

    this.setState({
      addButtonLoading: true
    }, () => {
      axios.post(this.props.baseURL + "/contracts/lease?type=" + classifierValue.code)
          .then(res => {
            this.props.history.push(this.props.match!.url + "/" + res.data.id, { action: "new" });
          })
          .catch(error => {
            let alertType = AlertContentType.ContractAddError;
            if (error.response) {
              const apiErrorType = Alert.fromException(error.response.data.error);
              if(apiErrorType)
                alertType = apiErrorType;
            }

            const alert: Alert = new Alert();
            alert.type = AlertType.Danger;
            alert.content = alertType;
            this.props.addAlert(alert);

            this.setState({
              addButtonLoading: false
            });
          });
    });
  };

  protected handleAddButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
  };
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state),
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaseContractList);
import jQuery from "jquery";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: Function;
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

window.$ = window.jQuery = jQuery;
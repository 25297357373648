import * as React from "react";
import {User, UserCompany} from "./User";
import {connect} from "react-redux";
import {RootState} from "../rootReducer";
import {getCurrentUser} from "./authReducer";
import axios from "axios";
import store from "../store";
import {authActions} from "./authActions";
import './RepresentativeModal.css';
const userIcon = require("../../assets/gfx/icons/person-24px-white.svg") as string;

interface Props {
    currentUser: User;
    onDismiss: () => void;
}

class RepresentativeModal extends React.Component<Props> {

    constructor(props: any) {
        super(props);
    };

    render() {
        const { currentUser } = this.props;
        return (
            <div className="modal-body">
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-info alert-flex">
                            <h6>Siin saad valida, mis ettevõtte nimel soovid siseneda. Seda valikut saad pärast sisse logimist ka muuta.</h6>
                        </div>
                    </div>
                </div>
                {currentUser && currentUser.companies.map((company, index) => (
                    <div key={index} className={company.id && currentUser.userCompany && company.id == currentUser.userCompany.id? "role row current" : "role row"}>
                        <div className="col-12 d-flex align-items-center justify-content-start">
                            <img className="role-img" height="auto" src={userIcon} width="auto" />
                            <button onClick={e => this.selectUserCompany(company)}
                                    className="btn btn-role-selection">{ company.name }</button>
                        </div>
                    </div>
                ))}
                {currentUser && currentUser.substitutions
                    .flatMap(substitution => (substitution.actingAsUser.companies))
                    .map((company, index) => (
                    <div key={index} className={company.id && currentUser.userCompany && company.id == currentUser.userCompany.id? "role row current" : "role row"}>
                        <div className="col-12 d-flex align-items-center justify-content-start">
                            <img className="role-img" height="auto" src={userIcon} width="auto" />
                            <button onClick={e => this.selectUserCompany(company)}
                                    className="btn btn-role-selection">{`${company.name} (${company.user.name})`}</button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    private selectUserCompany = (company: UserCompany) => {
        sessionStorage.setItem('UserCompanySelection', company.id.toString());

        axios.post(store.getState().config.baseURL + "/auth/login", {userCompanyId: company.id})
            .then(
                res => {
                    if (res.headers["content-type"] !== "application/json") {
                        this.redirectToAuth();
                    }
                    store.dispatch(authActions.setCurrentUser(res.data));
                },
                err => {
                    if (err.response === undefined) {
                        this.redirectToAuth();
                    }
                }
            )
            .then(() => {
                this.props.onDismiss();
            });
    };

    private redirectToAuth(): void {
        window.location.replace(`${store.getState().config.baseURL}/auth`);
    }

}

const mapStateToProps = (state: RootState) => ({
    currentUser: getCurrentUser(state)
});

export default connect(mapStateToProps)(RepresentativeModal);
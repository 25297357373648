import React from "react";
import { RouteComponentProps } from "react-router";
import { Classifier } from "../Classifier/Classifier";
import { alertActions } from "../Alerts/alertActions";
import { PagingMetadata } from "../Paging/Paging";
import { ContractFilters } from "./ContractFilters";
import { Contract, ContractType } from "./Contract";
import * as qs from "qs";
import ListFilters from "../ListFilters/ListFilters";
import {Permission, User} from "../Auth/User";
import Secured from "../Auth/Secured";
import { PagingUtils } from "../Paging/PagingUtils";
import { Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import axios from "axios";
import BootstrapTable, {
  ColumnDescription, SortOrder,
  TableChangeState,
  TableChangeType
} from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import PaginationListStandalone from "../BootstrapTable/PaginationListStandalone";
import {
  pageListRenderer,
  sizePerPageRenderer,
  sortCaretRenderer
} from "../BootstrapTable/customRenderers";
import { headerFormatter } from "../BootstrapTable/customFormatters";
import ToolkitProvider, { ToolkitContextType } from "react-bootstrap-table2-toolkit";
import RegionTypeQuickFilter from "./QuickFilters/RegionTypeQuickFilter";
import * as querystring from "querystring";

export interface ContractListProps extends RouteComponentProps<{}> {
  baseURL: string;
  sapEnabled: boolean;
  classifiers: Classifier[];
  addAlert: typeof alertActions.addAlert;
  displayNavHeaderCallback: (val: boolean) => void;
  currentUser: User;
}

export interface ContractListState<T extends Contract> {
  count: number;
  publishedCount: number;
  unpublishedCount: number;
  currentCount: number;
  expiredCount: number;
  storedPagingLoaded?: boolean;
  storedFiltersLoaded?: boolean;
  storedHiddenColumnsLoaded?: boolean;
  filters: ContractFilters;
  params: any;
  paging: PagingMetadata;
  columns: ColumnDescription[];
  data: T[];
  filtersToggled: boolean;
  searchTimeout?: number;
  addButtonLoading: boolean;
  searchInput: string;
  queryPrepared: boolean;
  queryLoading: boolean;
}

export abstract class AbstractContractList<T extends Contract, P extends ContractListProps = ContractListProps,
    S extends ContractListState<T> = ContractListState<T>> extends React.Component<P, S> {

  constructor(props: P) {
    super(props);

    let params: any = {};
    if (props.location.search) {
      params = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    }

    const columns = this.getColumns();
    columns.forEach(column => {
      column.sortCaret = sortCaretRenderer;
      column.headerFormatter = headerFormatter;
    });

    this.state = {
      count: 0,
      publishedCount: 0,
      unpublishedCount: 0,
      currentCount: 0,
      expiredCount: 0,
      filters: {},
      params: params,
      paging: new PagingMetadata({
        page: 0,
        limit: 20,
        count: 0,
        sort: "number"
      }),
      columns: columns,
      data: [],
      filtersToggled: false,
      addButtonLoading: false,
      searchInput: '',
      queryPrepared: false,
      queryLoading: true,
    } as Readonly<S>;
  }

  componentDidMount(): void {
    axios.get(this.props.baseURL + "/contracts/stats", {
      params: {
        type: this.getType()
      }
    }).then(res => {
      this.setState({
        count: res.data.count,
        publishedCount: res.data.publishedCount,
        unpublishedCount: res.data.unpublishedCount,
        currentCount: res.data.currentCount,
        expiredCount: res.data.expiredCount
      })
    }).catch(() => void(0));

    this.loadPaging();
    this.loadFilters();
    this.loadHiddenColumns();
    this.loadFirstQuery();
  }

  protected abstract getType(): ContractType;

  protected abstract getManagePermission(): Permission|Permission[];

  protected abstract getColumns(): ColumnDescription<T>[];

  protected abstract handleAddButton: (e: React.MouseEvent<HTMLButtonElement>) => void;

  protected renderAddButton() {
    return (
        <button type="button" className="btn btn-primary ml-4" onClick={this.handleAddButton}>+ Lisa leping</button>
    );
  }

  protected getPagingLocalStorageKey = () => {
    return this.getType().toLowerCase() + "_list" + ".paging";
  };

  protected getFiltersLocalStorageKey = () => {
    return this.getType().toLowerCase() + "_list" + ".filters";
  };

  protected getHiddenColumnsLocalStorageKey = () => {
    return this.getType().toLowerCase() + "_list" + ".hiddenColumns";
  };

  protected toggleFilters = () => {
    const dom: any = document.querySelector('body');
    dom.classList.toggle("filters-open");

    this.setState({
      filtersToggled: !this.state.filtersToggled
    });
  };

  protected onPagingChange = (paging: PagingMetadata) => {
    const { page, limit, sort, sortDirection, search} = paging;

    localStorage.setItem(this.getPagingLocalStorageKey(), JSON.stringify({
      page,
      limit,
      sort,
      sortDirection,
      search
    }));

    this.setState({
      paging: paging
    }, () => {
      this.getContracts();
    });
  };

  protected onFiltersChange = (filters: ContractFilters) => {
    localStorage.setItem(this.getFiltersLocalStorageKey(), JSON.stringify(filters));
    const params = {...this.state.params as any};

    // remove filters that are no longer applied
    Object.keys(this.state.filters).forEach(key => {
      const filterKey = key as keyof ContractFilters;
      if (filters[filterKey] == undefined || filters[filterKey] === '') {
        delete params[filterKey];
        delete filters[filterKey];
      }
    });

    // set currently applied filters
    Object.keys(filters).forEach(key => {
      const filterKey = key as keyof ContractFilters;
      if (filters[filterKey] != undefined && filters[filterKey] !== '') {
        params[filterKey] = filters[filterKey];
      }
    });

    this.setState({
      filters: filters,
      params: params
    }, () => {
      this.getContracts();
    });
  };

  protected toggleColumn = (column: ColumnDescription) => () => {
    if(column.dataField == "properties[0].portfolioType")
      column.dataField = "portfolioType";
    if(column.dataField == "properties[0].accountant")
      column.dataField = "accountantName";
    if(column.dataField == "properties[0].address")
      column.dataField = "propertyAddress";
    if(column.dataField == "properties[0].code")
      column.dataField = "propertyCode";
    if(column.dataField == "properties[0].regionType")
      column.dataField = "propertyRegionType";

    this.setState({
      columns: this.state.columns.map(col => {
        if (col.dataField == column.dataField) {
          col.hidden = !col.hidden;
        }
        return col;
      })
    }, () => {
      const hiddenColumns = this.state.columns.filter(col => (col as any).reverseVisibility ? col.hidden == false : col.hidden).map(col => col.dataField);
      localStorage.setItem(this.getHiddenColumnsLocalStorageKey(), JSON.stringify(hiddenColumns));
    });
  };

  protected handleQuickFilter = (key: string, value: any) => (event: any) => {
    if (this.state.params[key] != undefined && this.state.params[key] === value) {
      value = undefined;
    }

    this.setState({
      params: {
        ... this.state.params as any,
        [key]: value
      },
      filters: {
        ... this.state.filters,
        [key]: value
      }
    }, () => {
      this.onFiltersChange(this.state.filters);
    });
  };

  protected quickFilterSelected(key: string, value?: any): boolean {
    if (this.state.params) {
      if (value != undefined) {
        return this.state.params[key] === value;
      } else {
        return this.state.params[key] != undefined;
      }
    }
    return false;
  }

  protected handleMultiQuickFilter = (key: string, toggleValue: any, fullValue: string[]) => (event: any) => {

    let fieldValue = (!Array.isArray(this.state.params[key]) ? [] : this.state.params[key]) as string[];

    if(fieldValue.some(f => f === toggleValue)){
      fieldValue.splice(fieldValue.findIndex(f => f === toggleValue),1);
    } else{
      fieldValue.push(toggleValue);
    }

    if(Array.isArray(fullValue)) {
      fieldValue = fullValue;
    }

    this.setState({
      params: {
        ... this.state.params as any,
        [key]: fieldValue
      },
      filters: {
        ... this.state.filters,
        [key]: fieldValue
      }
    }, () => {
      this.onFiltersChange(this.state.filters);
    });
  };

  protected multiQuickFilterSelected(key: string, value?: any): boolean {
    if(!this.state.params[key] || !Array.isArray(this.state.params[key])) return false;

    if(value) {
      if(Array.isArray(value)) {
        let allPresent = true;
        (value as string[]).forEach(v => {
          if(!(this.state.params[key] as string[]).some(f => f === v)) allPresent = false;
        });
        return allPresent;
      } else {
        return (this.state.params[key] as string[]).some(f => f === value);
      }
    }
    return false;
  }

  protected hasMultiQuickFilter(key: string): boolean {
    return !(!this.state.params[key] || !Array.isArray(this.state.params[key]) || (this.state.params[key] as string[]).length <= 0);
  }

  protected onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout!);
    }

    let timeout = window.setTimeout(() => {
      this.onPagingChange(Object.assign(this.state.paging, {
        search,
        page: 0
      }));
    }, 500);

    this.setState({
      searchTimeout: timeout,
      searchInput: search
    });
  };

  protected export = (exportType: string) => {
    const paging = this.state.paging;
    paging.params = {
      ... this.state.params as any,
      type: this.state.params && this.state.params.type ? this.state.params.type : this.getType()
    };

    const urlParams: URLSearchParams = new URLSearchParams();
    let columnsAsString: string = "";
    if (paging) {
      let appliedParams = PagingUtils.toHttpParams(paging);
      for (const [key, value] of Object.entries(appliedParams)) {
        urlParams.set(key, value);
      }
      const visibleColumns : string[] = this.state.columns.filter(col => !col.hidden).map(col => col.dataField);
      if (visibleColumns) {
        columnsAsString = visibleColumns.join(",");
        columnsAsString = columnsAsString.replace("properties[0].portfolioType", "portfolioType");
        columnsAsString = columnsAsString.replace("properties[0].accountant", "accountantName");
        columnsAsString = columnsAsString.replace("properties[0].portfolioManager", "portfolioManagerName");
        columnsAsString = columnsAsString.replace("properties[0].address", "propertyAddress");
        columnsAsString = columnsAsString.replace("properties[0].code", "propertyCode");
        columnsAsString = columnsAsString.replace("properties[0].regionType", "propertyRegionType");
      }
    }
    window.open(`${this.props.baseURL}/contracts/export/${exportType}?fields=${columnsAsString}&${urlParams}`);
  };

  protected getContracts() {

    if(!this.state.queryPrepared) return;
    const paging = this.state.paging;
    paging.params = {
      ... this.state.params as any,
      type: this.state.params && this.state.params.type ? this.state.params.type : this.getType()
    };

    this.setState({queryLoading : true});
    axios.get(this.props.baseURL + "/contracts", {
           params: PagingUtils.toHttpParams(paging),
           paramsSerializer: querystring.stringify
         })
         .then(res => {
           this.setState({
             paging: res.data.metadata,
             data: res.data.data,
             queryLoading: false
           });
         })
         .catch(error => {
           this.setState({
             queryLoading: false
           });
         })
  }

  protected renderToolbar(context: ToolkitContextType) {
    const allSelected = !this.hasMultiQuickFilter('contractExpirationStatusType') || this.multiQuickFilterSelected('contractExpirationStatusType', ['ACTIVE', 'EXPIRED', 'FUTURE'] );
    const activeSelected = this.multiQuickFilterSelected('contractExpirationStatusType', 'ACTIVE');
    const futureSelected = this.multiQuickFilterSelected('contractExpirationStatusType', 'FUTURE');
    const expiredSelected = this.multiQuickFilterSelected('contractExpirationStatusType', 'EXPIRED');
    return (
        <div className="title-container justify-content-between d-none d-md-flex">
          <div
              className={"works-link" +
              (allSelected ? " active" : "")}
              onClick={this.handleMultiQuickFilter('contractExpirationStatusType', null, [])}
          >
            <span className="text-sm text-gray text-nowrap">Kõik</span>
            { allSelected &&
              <h4 className="works-filtered-number text-primary">{this.state.paging.count || 0}</h4>
            }
          </div>
          <div
              className={"works-link" +
              (activeSelected ? " active" : "")}
              onClick={this.handleMultiQuickFilter('contractExpirationStatusType', 'ACTIVE', null)}
          >
            <span className="text-sm text-gray text-nowrap">Kehtivad</span>
            { activeSelected &&
              <h4 className="works-filtered-number text-primary">{this.state.paging.active || 0}</h4>
            }
          </div>
          <div
              className={"works-link" +
              (expiredSelected ? " active" : "")}
              onClick={this.handleMultiQuickFilter('contractExpirationStatusType', 'EXPIRED', null)}
          >
            <span className="text-sm text-gray text-nowrap">Kehtetud</span>
            { expiredSelected &&
            <h4 className="works-filtered-number text-primary">{this.state.paging.expired || 0}</h4>
            }
          </div>
          <div
              className={"works-link mr-2" +
              (futureSelected ? " active" : "")}
              onClick={this.handleMultiQuickFilter('contractExpirationStatusType', 'FUTURE', null)}
          >
            <span className="text-sm text-gray text-nowrap">Tulevikus kehtivad</span>
            { futureSelected &&
              <h4 className="works-filtered-number text-primary">{this.state.paging.future || 0}</h4>
            }
          </div>
          <div
              className={"works-link align-items-center flex-row" +
              (this.quickFilterSelected('isEmpty', true) ? " active" : "")}
              onClick={this.handleQuickFilter('isEmpty', true)}
          >
            <span className="icon icon_filter mr-2" />
            <h6 className="text-sm text-gray text-nowrap">Tühjad lepingud</h6>
          </div>
          <RegionTypeQuickFilter filters={this.state.filters}
                                 onFiltersChange={this.onFiltersChange}
                                 count={this.state.paging.count}
          />

          {!Object.values(this.state.filters as any).length &&
          <div className="works-link align-items-center flex-row" onClick={this.toggleFilters}>
            <span className="icon icon_filter mr-2" />
            <span className="text-sm text-primary text-nowrap">Filtreeri</span>
          </div>
          }
          {!!Object.values(this.state.filters as any).length &&
          <div className="works-link active" onClick={this.toggleFilters}>
            <div className="quick-filter-label">
              <span className="text-sm text-gray text-nowrap">Filtreeritud</span>
              <i className="fas fa-pencil-alt text-sm text-gray" />
            </div>
            <h4 className="works-filtered-number text-primary">
              {this.state.paging ? this.state.paging.count || 0 : 0}
            </h4>
          </div>
          }

          <div className="d-flex" style={{flex: "1"}}>
            <input type="text"
                   className="form-control"
                   placeholder="Otsi lepingu numbri, pealkirja, aadressi järgi"
                   value={this.state.searchInput || ''}
                   onChange={this.onSearch}
            />
          </div>

          <div className="d-flex align-items-center">
            <Secured permission={this.getManagePermission()}>
              {this.renderAddButton()}
            </Secured>
            <UncontrolledDropdown className="submenu ml-3 mr-3">
              <div className="submenu-hover" />
              <DropdownToggle color="link" className="btn d-flex">
                <span className="icon download d-flex" />
              </DropdownToggle>
              <DropdownMenu tag="ul" className="dropdown-menu dropdown-menu-right" style={{marginRight: "20px"}}>
                      <li role="menuitem">
                        <DropdownItem tag="a" className="p-3 pointer"
                                      onClick={() => this.export("CSV")}>
                          Ekspordi CSV
                        </DropdownItem>
                      </li>
                      <li role="menuitem">
                        <DropdownItem tag="a" className="p-3 pointer"
                                      onClick={() => this.export("XLSX")}>
                          Ekspordi XLSX
                        </DropdownItem>
                      </li>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown className="submenu" style={{right: 0, position: "relative"}}>
              <div className="submenu-hover" />
              <DropdownToggle color="link" className="btn d-flex">
                <span className="icon icon_settings d-flex" />
              </DropdownToggle>
              <DropdownMenu tag="ul" className="dropdown-menu dropdown-menu-right" style={{marginRight: "50px", zIndex: 10000}}>
                {this.state.columns.map((column, index, columns) => (
                    <React.Fragment key={"column-toggle-" + index}>
                      <li role="menuitem">
                        <DropdownItem tag="div" className="custom-control custom-checkbox"
                                      style={{paddingLeft: "3rem", paddingBottom: "0.5rem", paddingTop: "0.5rem"}}
                                      toggle={false}
                        >
                          <input type="checkbox"
                                 className="custom-control-input"
                                 id={"toggle-column-" + index}
                                 checked={!column.hidden}
                                 onChange={this.toggleColumn(column)}
                          />
                          <label className="custom-control-label" htmlFor={"toggle-column-" + index}>
                            {column.text}
                          </label>
                        </DropdownItem>
                      </li>
                      {index < (columns.length -1) &&
                      <DropdownItem divider style={{ margin: 0 }} />
                      }
                    </React.Fragment>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
    );
  }

  protected onTableChange = (type: TableChangeType, state: TableChangeState<T>) => {
    this.onPagingChange(Object.assign(this.state.paging, {
      page: (state.page || 1) - 1,
      limit: state.sizePerPage,
      sort: state.sortField,
      sortDirection: (state.sortOrder || "asc").toUpperCase()
    }));
  };

  protected renderTable(context: ToolkitContextType) {
    if (!(this.state.storedFiltersLoaded && this.state.storedPagingLoaded && this.state.storedHiddenColumnsLoaded)) {
      return <></>
    }

    return (
        <>
          <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                sizePerPageRenderer: sizePerPageRenderer,
                pageListRenderer: pageListRenderer(this.state.paging),
                page: (this.state.paging.page || 0) + 1,
                sizePerPage: this.state.paging.limit || 20,
                totalSize: this.state.paging.count || 0,
                sizePerPageList: [20, 50, 100]
              })}>
            {({ paginationProps, paginationTableProps }) => (
                <div className="datatable" style={{position: "relative"}}>
                  <div className="table-responsive">
                    <BootstrapTable
                        {...context.baseProps}
                        {...paginationTableProps}
                        remote={true}
                        headerWrapperClasses="datatable-header"
                        onTableChange={this.onTableChange}
                        noDataIndication={this.state.queryLoading ? "Päring käib, palun oota..." : "Kirjeid ei leitud"}
                        sort={{
                          dataField: this.state.paging.sort,
                          order: (this.state.paging.sortDirection ?
                              this.state.paging.sortDirection.toLowerCase() : undefined) as SortOrder
                        }}
                    />
                  </div>
                  <div className="datatable-footer">
                    <div className="datatable-footer-inner">
                      <div className="d-flex align-items-center" style={{padding: "5px 10px"}}>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                  {this.state.queryLoading &&
                  <div style={{backgroundColor: "rgba(255,255,255,0.4)", position: "absolute", width: "100%", height: "100%", top: 0, left: 0, pointerEvents: "none"}}>
                    <span style={{position: "fixed", top: "35%", left: "50%"}}><i className="details-spinner fa fa-spinner fa-spin fa-2x"/></span>
                  </div>
                  }
                </div>
            )}
          </PaginationProvider>
        </>
    );
  }

  render() {
    return (
        <div className="contract-list">
          <ListFilters type="contract"
                       contractType={this.getType()}
                       filters={this.state.filters}
                       show={this.state.filtersToggled}
                       toggleFilters={this.toggleFilters}
                       onFiltersChange={this.onFiltersChange}
          />
          <div className="filters-background" />
          <div className="container-fluid">
            <ToolkitProvider
                keyField="id"
                data={this.state.data}
                columns={this.state.columns}
                columnToggle
            >
              {(context: ToolkitContextType) => (
                <>
                  {this.renderToolbar(context)}

                  <div className="white-container mt-0">
                    {this.renderTable(context)}
                  </div>
                </>
              )}
            </ToolkitProvider>
          </div>
        </div>
    );
  }

  private loadPaging(): void {
    const localStorageKey = this.getPagingLocalStorageKey();
    let paging: PagingMetadata = this.state.paging;

    if (localStorage.getItem(localStorageKey)) {
      let pagingString: string = localStorage.getItem(localStorageKey);
      let pagingJson: any = JSON.parse(pagingString) as PagingMetadata;

      paging = Object.assign(paging, pagingJson);
    }

    this.setState({
      paging,
      storedPagingLoaded: true,
      searchInput: paging.search
    });
  }

  private loadFilters(): void {
    const localStorageKey = this.getFiltersLocalStorageKey();
    if (localStorage.getItem(localStorageKey)) {
      let filterString: string = localStorage.getItem(localStorageKey);
      let filters: ContractFilters = JSON.parse(filterString) as ContractFilters;

      this.onFiltersChange(filters);
    }

    this.setState({
      storedFiltersLoaded: true
    });
  }

  private loadHiddenColumns(): void {
    const localStorageKey = this.getHiddenColumnsLocalStorageKey();
    let { columns } = this.state;

    if (localStorage.getItem(localStorageKey)) {
      let columnsString: string = localStorage.getItem(localStorageKey);
      let hiddenColumns: string[] = JSON.parse(columnsString) as string[];

      columns.forEach(column => {
          column.hidden = hiddenColumns.some(hiddenField => hiddenField === column.dataField);
          if((column as any).reverseVisibility)
            column.hidden = !column.hidden;
      });
    }

    this.setState({
      columns,
      storedHiddenColumnsLoaded: true
    });
  }

  private loadFirstQuery() {
    setTimeout(() => {this.setState({queryPrepared: true}, () => this.getContracts())}, 10);
  }
}
import {RootState } from "../rootReducer";
import {Contract} from "./Contract";
import {contractActions} from "./contractActions";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export class ContractState {
    contract: Contract;

    constructor(state?: ContractState) {
        if (state) {
            this.contract = state.contract;
        } else {
            this.contract = new Contract();
        }
    }
}

export const contractReducer = createReducer(new ContractState(), {
    [contractActions.setContract.type]: (state, action: PayloadAction<Contract>) => {
        let newState = new ContractState();
        newState.contract = action.payload;
        return newState;
    }
});

export function getContract(state: RootState): Contract {
    return state.contract.contract;
}
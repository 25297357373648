import React from "react";
import { Classifier, ClassifierCode, getClassifierValueName } from "../../Classifier/Classifier";
import { ContractFilters } from "../ContractFilters";
import { RootState } from "../../rootReducer";
import { getClassifiers } from "../../Classifier/classifierReducer";
import { connect } from "react-redux";

interface Props {
  classifiers: Classifier[];
  filters: ContractFilters;
  count?: number;
  onFiltersChange: (filters: ContractFilters) => void;
}

interface State {
  open: boolean;
  value: string[];
}

class RegionTypeQuickFilter extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      value: []
    };
  }

  private toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  private onChange = (regionType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      this.setState({
        value: [
          ... this.state.value,
          regionType
        ]
      });
    } else {
      this.setState({
        value: this.state.value.filter(value => value !== regionType)
      });
    }
  };

  private onClear = () => {
    this.setState(
        {
          value: []
        },
        this.onSave
    );
  };

  private onSave = () => {
    this.props.onFiltersChange({
      ...this.props.filters,
      propertyRegionType: this.state.value.length ? this.state.value : undefined
    });
    this.toggleOpen();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.filters !== this.props.filters && this.props.filters) {
      this.setState({
        value: this.props.filters.propertyRegionType || []
      });
    }
  }

  render() {
    return (
        <div className="position-relative">
          {!(this.props.filters.propertyRegionType && this.props.filters.propertyRegionType.length) &&
          <div className="works-link align-items-center flex-row" onClick={this.toggleOpen}>
            <span className="icon icon_filter mr-2" />
            <span className="text-sm text-primary text-nowrap">Vali piirkond</span>
          </div>
          }
          {this.props.filters.propertyRegionType && !!this.props.filters.propertyRegionType.length &&
          <div className="works-link active" onClick={this.toggleOpen}>
            <div className="quick-filter-label">
              <span className="text-sm text-gray text-nowrap">
                {
                  this.props.filters.propertyRegionType.length > 1
                      ? "Piirkond: " + this.props.filters.propertyRegionType.length + " valitud"
                      : getClassifierValueName(this.props.classifiers, ClassifierCode.RegionType,
                          this.props.filters.propertyRegionType[0])
                }
              </span>
              <i className="fas fa-pencil-alt text-sm text-gray" />
            </div>
            <h4 className="works-filtered-number text-primary">
              {this.props.count || 0}
            </h4>
          </div>
          }
          {this.state.open &&
          <div className="quick-subfilter-bg">
            <button className="btn btn-sm btn-block btn-outline-secondary mb-2" type="button" onClick={this.onClear}>
              Tühista filter
            </button>
            <h6 className="mb-1">Piirkonnad:</h6>
            {this.props.classifiers.find(c => c.code == "REGION_TYPE")
                 .values
                 .sort((o1, o2) => o1.name.localeCompare(o2.name))
                 .map(regionType => (
                     <div className="custom-control custom-checkbox" key={"regionType-" + regionType.code}>
                       <input className="custom-control-input"
                              id={"check-" + regionType.code}
                              type="checkbox"
                              onChange={this.onChange(regionType.code)}
                              checked={this.state.value.some(v => v === regionType.code)}
                       />
                       <label className="custom-control-label" htmlFor={"check-" + regionType.code}>{regionType.name}</label>
                     </div>
                  ))
            }

            <button className="btn btn-sm btn-secondary btn-block mt-3" onClick={this.onSave}>
              Rakenda filter
            </button>
          </div>
          }
        </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  classifiers: getClassifiers(state)
});

export default connect(mapStateToProps)(RegionTypeQuickFilter);
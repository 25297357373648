import React from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "reactstrap";
import {ContactInfoModalData, ModalType} from "../Modal";

interface Props {
    modalOpen: boolean;
    modalData: ContactInfoModalData;
    onToggleModal: () => void;
}

export default class ContactInfoModal extends React.Component<Props, any> {
    render() {
        const person = this.props.modalData.person;
        return (
            <Modal isOpen={this.props.modalOpen} toggle={this.props.onToggleModal}>
                <ModalHeader toggle={this.props.onToggleModal}>Kontaktinfo</ModalHeader>
                <ModalBody className="p-4">
                    <>
                        <div className="row">
                            <div className="col-12 d-flex flex-row">
                                <label className="w-140">Nimi</label>
                                <span className="font-weight-bold">
                                    {person.forename} {person.surname}
                                </span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 d-flex flex-row">
                                <label className="w-140">Telefon</label>
                                <span className="font-weight-bold">
                                    {person.phoneNumber}
                                </span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 d-flex flex-row">
                                <label className="w-140">Email</label>
                                <span className="font-weight-bold">
                                    {person.email}
                                </span>
                            </div>
                        </div>
                    </>
                </ModalBody>
            </Modal>
        )
    }
}
import { FullLeaseContract } from "../../Contract";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../rootReducer";
import { getBaseURL } from "../../../Config/configReducer";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Classifier } from "../../../Classifier/Classifier";
import { getClassifiers } from "../../../Classifier/classifierReducer";
import { getCostItems } from "../../costItemReducer";
import {
    CostItem,
    IncomeSource,
    LeaseContractRow,
    LeaseRowIncomeType,
    RowType
} from "../ContractRow";
import DatePicker from "react-datepicker";
import axios from "axios";
import { contractDetailsActions } from "../contractDetailsActions";
import { getAddRowModalToggle } from "../contractDetailsReducer";

interface Props {
    allCostItems: CostItem[];
    baseURL: string;
    classifiers: Classifier[];
    editingContract: FullLeaseContract;
    updateEditingContract: (contract: FullLeaseContract) => void;
    onSaveContract: () => void;

    isOpen: boolean,
    toggleAddRowModal: typeof contractDetailsActions.toggleAddRowModal;
}

interface State {
    selectedCostItems: CostItem[];
    selectedCostItemCategory: CostItem;
    startDate: Date;
    endDate: Date;
    leaseItemCodes?: string[]
}

class LeaseContractAddRowsModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedCostItems: [],
            selectedCostItemCategory: null,
            startDate: new Date(),
            endDate: new Date()
        }
    }

    componentWillMount() {
        this.resetState();
        axios.get(this.props.baseURL + "/parameters/LEASE_SERVICE")
            .then(res => {
                axios.get(this.props.baseURL + "/parameters/SIDE_SERVICE")
                    .then(res2 => {
                        this.setState({
                            leaseItemCodes: [
                                ...res.data.value.split(","),
                                ...res2.data.value.split(",")
                            ]
                        });
                    });
            })
            .catch(() => {});
    }

    private cancel = () => {
        this.resetState();
        this.props.toggleAddRowModal(false);
    };

    private onSave = () => {

        let newRows = this.props.editingContract.rows;
        let tempId = -1;
        this.state.selectedCostItems.map(costItem => {
            let row = new LeaseContractRow();
            row.tempId = tempId;
            row.costItem = costItem;
            row.serviceName = costItem.label;
            row.startDate = this.state.startDate.getTime();
            row.endDate = this.state.endDate.getTime();

            row.isSettleable = false;
            row.incomeType = LeaseRowIncomeType.LeaseService;
            row.incomeSource = IncomeSource.Budget;
            row.changedIncomeBasedOnExpense = false;
            row.minVendorInvoices = 0;

            row.type = RowType.Lease;
            row.propertyId = this.props.editingContract.properties[0].id;

            newRows.push(row);
            tempId--;
        });

        this.props.updateEditingContract({
            ...this.props.editingContract,
            rows: newRows
        });

        this.resetState();
        this.props.toggleAddRowModal(false);
    };

    private selectCostItemCategory(costItem: CostItem) {
        this.setState({
            selectedCostItemCategory : costItem
        })
    }

    private toggleCostItemSelection(item: CostItem) {
        if(!this.isCostItemAvailable(item)) return;

        let selectedCostItems = this.state.selectedCostItems;
        if(selectedCostItems.includes(item))
            selectedCostItems = selectedCostItems.filter(costItem => costItem !== item)
        else
            selectedCostItems.push(item);

        this.setState({ selectedCostItems: selectedCostItems})
    }

    private isCostItemAvailable(item: CostItem) {
        return !item.disabled;
    }


    private resetState() {
        this.setState({
            selectedCostItems: [],
            selectedCostItemCategory: this.props.allCostItems[0],
            startDate: new Date(this.props.editingContract.startTime || new Date()),
            endDate: new Date(this.props.editingContract.endTime || new Date()),
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.cancel} size="xl">
                <ModalHeader toggle={this.cancel} >
                    Baasridade lisamine
                </ModalHeader>

                <ModalBody className="p-2 p-lg-3">
                    <h6 className="text-dark font-weight-normal mt-3 mb-3 border-bottom-light-gray">Vali kulukohad</h6>
                    <div className="d-flex">
                        {this.renderCostItemCategories()}
                        <div>
                            <div className={"selectable-baserow " + (!!this.state.selectedCostItems.includes(this.state.selectedCostItemCategory) && "selected")}
                                 style={{marginLeft: 0}}
                                 onClick={(e) => {e.preventDefault(); this.toggleCostItemSelection(this.state.selectedCostItemCategory)}}>
                                <div className="selectable-baserow-number">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox"
                                               className="custom-control-input"
                                               id={"select-costitem-"+this.state.selectedCostItemCategory.code}
                                               readOnly={true}
                                               disabled={!this.isCostItemAvailable(this.state.selectedCostItemCategory)}
                                               checked={!!this.state.selectedCostItems.includes(this.state.selectedCostItemCategory)}
                                        />
                                        <label className="custom-control-label no-wrap" htmlFor={"select-costitem-"+this.state.selectedCostItemCategory.code}/>
                                    </div>
                                    <h6 className="pl-2">{this.state.selectedCostItemCategory.code}</h6>
                                </div>
                                <div className="selectable-baserow-text">
                                    <h6 className="pl-2">{this.state.selectedCostItemCategory.label}</h6>
                                </div>
                            </div>
                            {this.renderCostItemTree(this.state.selectedCostItemCategory,1)}
                        </div>
                    </div>
                    <h6 className="text-dark font-weight-normal mt-3 mb-3 border-bottom-light-gray">Muu info</h6>
                    <div>
                        <div className="form-row custom-form-row">
                            <div className="col-12 align-items-start">
                                <label className="w-200">Algus:</label>
                                <DatePicker
                                    className="form-control calendar-input"
                                    selected={this.state.startDate}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(value: Date) => this.setState({startDate: value })}
                                />
                            </div>
                        </div>
                        <div className="form-row custom-form-row">
                            <div className="col-12 align-items-start">
                                <label className="w-200">Lõpp:</label>
                                <DatePicker
                                    className="form-control calendar-input"
                                    selected={this.state.endDate}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(value: Date) => this.setState({endDate: value })}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={this.cancel}
                        color="outline-primary"
                    >
                        Katkesta
                    </Button>
                    <Button
                        onClick={this.onSave}
                        color="primary"
                    >
                        Salvesta read
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    private renderCostItemCategories() {
        return (
            <div className="col-12 d-flex flex-column justify-content-start" style={{maxWidth: "200px"}}>
                {this.props.allCostItems.map(costItem => (
                    <div key={costItem.id} className={"baserow " + (costItem === this.state.selectedCostItemCategory && "selected")}
                         onClick={() => this.selectCostItemCategory(costItem)}>
                        <h5 className="font-weight-bold mb-0">{costItem.code}</h5>
                        <span className="baserow-title">{costItem.label}</span>
                    </div>
                ))
                }
            </div>
        );
    }

    private renderCostItemTree(costItem : CostItem, depth: number) {
        const newDepth = depth + 1;
        return (costItem.children.map(
                item => (
                    <React.Fragment key={item.id}>
                        <div className={"selectable-baserow " + (!!this.state.selectedCostItems.includes(item) && "selected")}
                             style={{marginLeft: depth * 20}}
                             onClick={(e) => {e.preventDefault(); this.toggleCostItemSelection(item)}}>
                            <div className="selectable-baserow-number">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                           className="custom-control-input"
                                           id={"select-costitem-"+item.code}
                                           readOnly={true}
                                           disabled={!this.isCostItemAvailable(item)}
                                           checked={!!this.state.selectedCostItems.includes(item)}
                                    />
                                    <label className="custom-control-label no-wrap" htmlFor={"select-costitem-"+item.code}/>
                                </div>
                                <h6 className="pl-2">{item.code}</h6>
                            </div>
                            <div className="selectable-baserow-text">
                                <h6 className="pl-2">{item.label}</h6>
                            </div>
                        </div>
                        {this.renderCostItemTree(item, newDepth)}
                    </React.Fragment>
                )
            )
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    allCostItems: getCostItems(state),
    isOpen: getAddRowModalToggle(state)
});

const mapDispatchToProps = {
    toggleAddRowModal: contractDetailsActions.toggleAddRowModal
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaseContractAddRowsModal);
import React from 'react';
import {modalActions} from "./modalActions";
import {RootState} from "../rootReducer";
import {connect} from "react-redux";
import {getModalData, getModalToggle} from "./modalReducer";
import {ModalData, ModalType} from "./Modal";
import ContactInfoModal from "./ModalTypes/ContactInfoModal";
import SimpleTextModal from "./ModalTypes/SimpleTextModal";
import ThiMultiplicationModal from "./ModalTypes/ThiMultiplicationModal";
import DefaultPricesModal from "./ModalTypes/DefaultPricesModal";
import ThDefaultPricesModal from "./ModalTypes/ThDefaultPricesModal";
import ServiceContractAddRowsModal from "./ModalTypes/ServiceContractAddRowsModal";
import ConstructionContractImportRowsModal from "./ModalTypes/ConstructionContractImportRowsModal";
import ConstructionContractEditWarrantyModal from "./ModalTypes/ConstructionContractEditWarrantyModal";


interface Props {
    modalOpen: boolean;
    modalData: ModalData;
    toggleModal: typeof modalActions.toggleModal;
}

interface State {
}

class ModalContainer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const ModalType = this.getModalType();
        if(!ModalType) return null;

        return (
            <ModalType
                modalOpen={this.props.modalOpen}
                modalData={this.props.modalData}
                onToggleModal={this.onToggleModal}/>
        )
    }

    private onToggleModal = () => {
        this.props.toggleModal(this.props.modalData);
    }

    private getModalType(): any {
        if(!this.props.modalData) return null;

        switch(this.props.modalData.type){
            case ModalType.SimpleText:
                return SimpleTextModal;
            case ModalType.ContactInfo:
                return ContactInfoModal;
            case ModalType.ThiMultiplication:
                return ThiMultiplicationModal;
            case ModalType.DefaultPrices:
                return DefaultPricesModal;
            case ModalType.ThDefaultPrices:
                return ThDefaultPricesModal;
            case ModalType.ServiceContractAddRows:
                return ServiceContractAddRowsModal;
            case ModalType.ConstructionContractImportRows:
                return ConstructionContractImportRowsModal;
            case ModalType.ConstructionContractEditWarranty:
                return ConstructionContractEditWarrantyModal;
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    modalOpen: getModalToggle(state),
    modalData: getModalData(state)
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);

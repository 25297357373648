import React from "react";
import {ContractType, FullConsumptionContract, Property} from "../../Contract";
import {Classifier, ClassifierCode} from "../../../Classifier/Classifier";
import {RootState} from "../../../rootReducer";
import {getClassifiers} from "../../../Classifier/classifierReducer";
import {connect} from "react-redux";
import TextField from "../ContractInfo/Fields/TextField";
import CompanyField from "../ContractInfo/Fields/CompanyField";
import ClassifierField from "../ContractInfo/Fields/ClassifierField";
import TextAreaField from "../ContractInfo/Fields/TextAreaField";
import {getBaseURL, getPropregUrl, getSapEnabled} from "../../../Config/configReducer";
import PersonField from "../ContractInfo/Fields/PersonField";
import DateField from "../ContractInfo/Fields/DateField";
import CheckboxField from "../ContractInfo/Fields/CheckboxField";
import {UncontrolledTooltip} from "reactstrap";
import NumberField from "../ContractInfo/Fields/NumberField";
import FrameContractField from "../ContractInfo/Fields/FrameContractField";
import {modalActions} from "../../../Modal/modalActions";
import {ContactInfoModalData, ModalType} from "../../../Modal/Modal";
import PatternedTextField from "../ContractInfo/Fields/PatternedTextField";

interface Props {
  classifiers: Classifier[]
  baseURL: string;
  propregUrl: string;
  contract: FullConsumptionContract;
  editingContract?: FullConsumptionContract;
  editMode: boolean;
  updateEditingContract: (contract: FullConsumptionContract) => void;
  sapEnabled: boolean;

  toggleModal: typeof modalActions.toggleModal;
}

class ConsumptionInfo extends React.Component<Props, any> {

  private onChange = (fieldKey: keyof FullConsumptionContract, value: any) => {
    if (this.props.editingContract) {
      this.props.updateEditingContract({
        ...this.props.editingContract,
        [fieldKey]: value
      });
    }
  };

  private getPropertyRegistryUrl = (property: Property) => {
    return `${this.props.propregUrl}/#/properties/${property.id}`;
  };

  private renderProperties = () => {
    return (
        <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
          <div className="col-12 align-items-start">
            <label className="w-200">Kinnistud</label>
            <span className="font-weight-bold">
              {this.props.contract.properties.sort((o1, o2) => o1.code.localeCompare(o2.code))
                   .map((property, index, properties) => (
                       <React.Fragment key={"property-" + index}>
                         <a className="text-nowrap pt-0 pb-0 d-flex align-items-center hover-icon-secondary"
                            href={this.getPropertyRegistryUrl(property)}
                            target="_blank"
                            id={"property-" + property.id}
                         >
                           {property.code} ({property.address})
                           <span className="icon external-link ml-1" />
                         </a>
                         <UncontrolledTooltip target={"property-" + property.id} fade={false}>
                           Suunab kinnisturegistrisse
                         </UncontrolledTooltip>
                        </React.Fragment>
                    ))}
              {!this.props.contract.properties.length && "-"}
            </span>
          </div>
        </div>
    );
  };

  render() {
    const contract = this.props.editingContract || this.props.contract;
    
    return (
        <div className="row">
          <div className="col-12 col-md-6">
            <TextField contract={contract}
                       label={"Lepingu number"}
                       fieldKey={"number"}
                       editMode={this.props.editMode}
                       editable={false}
                       required={true}
                       onChange={this.onChange}
            />
            <TextField contract={contract}
                       label={"Pealkiri"}
                       fieldKey={"generalDocumentTitle"}
                       editMode={this.props.editMode}
                       editable={this.props.editMode}
                       onChange={this.onChange}
            />
            <PatternedTextField contract={contract}
                                label={"RHR number"}
                                fieldKey={"rhrReferenceNumber"}
                                replacementPattern={/\s/g}
                                validationPattern={/^\s*(?:[\w\.]\s*){0,15}$/}
                                editMode={this.props.editMode}
                                editable={this.props.editMode}
                                onChange={this.onChange}
            />
            {this.props.sapEnabled &&
              <TextField contract={contract}
                         label={"SAP kood"}
                         fieldKey={"sapCode"}
                         editMode={this.props.editMode}
                         editable={false}
                         onChange={this.onChange}
              />
            }
            <ClassifierField contract={contract}
                             label={"Näitude edastamise tüüp"}
                             fieldKey={"meterForwardType"}
                             classifiers={this.props.classifiers}
                             classifierCode={ClassifierCode.MeterForwardType}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
            />
            <ClassifierField contract={contract}
                             label={"Tarbimisteenuste tüüp"}
                             fieldKey={"consumptionContractType"}
                             classifiers={this.props.classifiers}
                             classifierCode={ClassifierCode.ConsumptionContractType}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             required={true}
                             onChange={this.onChange}
            />
            <DateField contract={contract}
                       label={"Algus"}
                       fieldKey={"startTime"}
                       editMode={this.props.editMode}
                       editable={this.props.editMode}
                       onChange={this.onChange}
            />
            <DateField contract={contract}
                       label={"Lõpp"}
                       fieldKey={"endTime"}
                       editMode={this.props.editMode}
                       editable={this.props.editMode}
                       onChange={this.onChange}
            />
            <DateField contract={contract}
                       label={"Allkirjastatud"}
                       fieldKey={"dateSigned"}
                       editMode={this.props.editMode}
                       editable={this.props.editMode}
                       onChange={this.onChange}
            />
          </div>

          <div className="col-12 col-md-6">

            {this.props.editMode &&
              <div className={"form-row custom-form-row"}>
                <div className={"col-12 align-items-start"}>
                  <label className="w-200">Raamleping</label>
                  <CheckboxField contract={contract}
                                 label={""}
                                 checkboxLabel={"on raamleping"}
                                 fieldKey={"isFrameAgreement"}
                                 editMode={this.props.editMode}
                                 editable={this.props.editMode}
                                 onChange={this.onChange}
                                 skipFormRowClasses={true}
                                 skipLabel={true}
                  />
                  {!contract.isFrameAgreement &&
                    <div style={{flexGrow:1}}>
                      <FrameContractField contract={contract}
                                          label={""}
                                          fieldKey={"frameContractReference"}
                                          contractType={ContractType.ConsumptionContract}
                                          baseURL={this.props.baseURL}
                                          editMode={this.props.editMode}
                                          editable={this.props.editMode}
                                          isClearable={true}
                                          onChange={this.onChange}
                                          skipFormRowClasses={true}
                                          skipLabel={true}
                      />
                    </div>
                  }
                </div>
              </div>
            }

            {!this.props.editMode &&
            <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
              <div className="col-12 align-items-start">
                <label className="w-200">{"Raamleping"}</label>
                {contract.isFrameAgreement &&
                  <span className="font-weight-bold">Jah</span>
                }
                {(!contract.isFrameAgreement && !contract.frameContractReference)  &&
                  <span className="font-weight-bold">Ei</span>
                }
                {(!contract.isFrameAgreement && contract.frameContractReference)  &&
                  <span className="font-weight-bold">Ei ; {contract.frameContractReference}</span>
                }
              </div>
            </div>
            }


            <CompanyField contract={contract}
                          label={"Tellija"}
                          fieldKey={"customer"}
                          baseURL={this.props.baseURL}
                          editMode={this.props.editMode}
                          editable={this.props.editMode}
                          onChange={this.onChange}
            />
            <PersonField contract={contract}
                         company={contract.customer}
                         label={"Tellija kontaktisik"}
                         fieldKey={"customerRepresentative"}
                         baseURL={this.props.baseURL}
                         editMode={this.props.editMode}
                         editable={this.props.editMode}
                         isClearable={true}
                         onChange={this.onChange}
                         onLinkClick={(person) => this.props.toggleModal(
                             new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                         )}
            />
            <CompanyField contract={contract}
                          label={"Peatöövõtja"}
                          fieldKey={"contractor"}
                          baseURL={this.props.baseURL}
                          editMode={this.props.editMode}
                          editable={this.props.editMode}
                          required={true}
                          onChange={this.onChange}
            />
            <PersonField contract={contract}
                         company={contract.contractor}
                         label={"Peatöövõtja kontaktisik"}
                         fieldKey={"contractorRepresentative"}
                         baseURL={this.props.baseURL}
                         editMode={this.props.editMode}
                         editable={this.props.editMode}
                         isClearable={true}
                         onChange={this.onChange}
                         onLinkClick={(person) => this.props.toggleModal(
                             new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                         )}
            />
            <TextField contract={contract}
                       label={"Peamine e-mail"}
                       fieldKey={"emailAddress1"}
                       editMode={this.props.editMode}
                       editable={this.props.editMode}
                       onChange={this.onChange}
            />
            <TextField contract={contract}
                       label={"Lisa e-mail"}
                       fieldKey={"emailAddress2"}
                       editMode={this.props.editMode}
                       editable={this.props.editMode}
                       onChange={this.onChange}
            />
            <NumberField contract={contract}
                         label={"Maksumus"}
                         fieldKey={"sum"}
                         editMode={this.props.editMode}
                         editable={this.props.editMode}
                         onChange={this.onChange}
            />
          </div>
          <div className="col-12">
            <TextAreaField contract={contract}
                           label={"Kommentaarid"}
                           fieldKey={"comment"}
                           editMode={this.props.editMode}
                           editable={this.props.editMode}
                           onChange={this.onChange}
            />
          </div>
          {!this.props.editMode &&
          <div className="col-12">
            {this.renderProperties()}
          </div>
          }
        </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  propregUrl: getPropregUrl(state),
  classifiers: getClassifiers(state),
  sapEnabled: getSapEnabled(state)
});

const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps,mapDispatchToProps)(ConsumptionInfo);
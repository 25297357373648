import React from "react";
import {FullEnergyContract} from "../../Contract";
import { Classifier } from "../../../Classifier/Classifier";
import { RootState } from "../../../rootReducer";
import { getClassifiers } from "../../../Classifier/classifierReducer";
import { connect } from "react-redux";
import TextField from "../ContractInfo/Fields/TextField";
import TextAreaField from "../ContractInfo/Fields/TextAreaField";
import DateField from "../ContractInfo/Fields/DateField";
import { getBaseURL, getRkasCompanyRegNo } from "../../../Config/configReducer";
import CompanyField from "../ContractInfo/Fields/CompanyField";
import NumberField from "../ContractInfo/Fields/NumberField";
import MainLeaseField from "../ContractInfo/Fields/MainLeaseField";
import {modalActions} from "../../../Modal/modalActions";
import EICField from "../ContractInfo/Fields/EICField";
import CheckboxField from "../ContractInfo/Fields/CheckboxField";

interface Props {
    classifiers: Classifier[]
    baseURL: string;
    contract: FullEnergyContract;
    editingContract?: FullEnergyContract;
    editMode: boolean;
    updateEditingContract: (contract: FullEnergyContract) => void;
    rkasCompanyRegNo: string;

    toggleModal: typeof modalActions.toggleModal;
}

class EnergyInfo extends React.Component<Props, any> {

    private onChange = (fieldKey: keyof FullEnergyContract, value: any) => {
        if (this.props.editingContract) {
            this.props.updateEditingContract({
                ...this.props.editingContract,
                [fieldKey]: value
            });
        }
    };

    render() {
        const contract = this.props.editingContract || this.props.contract;
        const hasPublished = this.props.contract.hasPublished;

        return (
            <div className="row">
                <div className={this.props.editMode
                    ? "col-12 col-lg-5 col-xl-5"
                    : "col-12 col-md-6"}>
                    <>
                        <TextField contract={contract}
                                   label={"Pealkiri"}
                                   fieldKey={"generalDocumentTitle"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <CompanyField contract={contract}
                                      label={"Üürnik"}
                                      fieldKey={"leaser"}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      required={true}
                                      onChange={this.onChange}
                        />
                        <TextField contract={contract}
                                   label={"Üürikood"}
                                   fieldKey={"rentalCode"}
                                   placeholder={this.getRentalCodePlaceholder()}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode && this.props.editingContract?.rentalCode !== null}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <TextField contract={contract}
                                   label={"Kontakti email"}
                                   fieldKey={"contactEmail"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <TextField contract={contract}
                                   label={"Kontakti telefon"}
                                   fieldKey={"contactPhone"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <EICField contract={contract}
                                   label={"EIC"}
                                   fieldKey={"eic"}
                                   property={contract.properties && contract.properties.length ? contract.properties[0] : undefined}
                                   fieldValue={contract.eic}
                                   baseURL={this.props.baseURL}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}/>
                    </>
                </div>
                <div className={this.props.editMode
                    ? "col-12 col-lg-6 col-xl-5 offset-md-0 offset-lg-1 offset-xl-2 mt-3 mt-lg-0"
                    : "col-12 col-md-6"}>
                    <>
                        {this.renderInvoiceDueDays()}
                        <MainLeaseField contract={contract}
                                        label={"Peaüür"}
                                        fieldKey={"mainLease"}
                                        baseURL={this.props.baseURL}
                                        editMode={this.props.editMode}
                                        editable={this.props.editMode}
                                        isClearable={true}
                                        onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Algus"}
                                   fieldKey={"startTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <DateField contract={contract}
                                   label={"Lõpp"}
                                   fieldKey={"endTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   unlimited={true}
                                   onChange={this.onChange}
                        />
                        <TextAreaField contract={contract}
                                       label={"Märkused"}
                                       fieldKey={"comment"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                        {!hasPublished &&
                        <CheckboxField contract={this.props.editingContract || this.props.contract}
                                       label={"Ekspordi avaldamata lepingu andmed"}
                                       fieldKey={"isAvailableUnpublished"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                        }
                    </>
                </div>
            </div>
        );
    }


    private renderInvoiceDueDays(): JSX.Element {
        return (
            <>
                {this.props.editMode &&
                <NumberField contract={this.props.editingContract || this.props.contract}
                             label={"Maksetähtaeg (päevades)"}
                             fieldKey={"invoiceDueDays"}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
                />
                }

                {!this.props.editMode &&
                <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Maksetähtaeg"}</label>
                        {this.props.contract.invoiceDueDays &&
                        <>
                            <span className="font-weight-bold">{this.props.contract.invoiceDueDays}</span>
                            <span className="font-weight-bold">&nbsp;{this.props.contract.invoiceDueDays == 1 ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!this.props.contract.invoiceDueDays &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private getRentalCodePlaceholder() {
        if (this.props.editMode && this.props.contract && this.props.editingContract) {
            const mainPropertyRentalCode = this.props.editingContract.properties[0]?.code;
            const contractRentalCode = this.props.contract.rentalCode;
            const editingContractRentalCode = this.props.editingContract.rentalCode;

            if(!contractRentalCode && !editingContractRentalCode) {
                if(mainPropertyRentalCode) {
                    return mainPropertyRentalCode;
                }
                return "Kinnistu valimata";
            }
        }
        return "";
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    rkasCompanyRegNo: getRkasCompanyRegNo(state),
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps,mapDispatchToProps)(EnergyInfo);
import React from "react";
import { ConsumptionContract, ContractType } from "../Contract";
import { Permission } from "../../Auth/User";
import { AbstractContractList } from "../AbstractContractList";
import { RootState } from "../../rootReducer";
import { getBaseURL } from "../../Config/configReducer";
import { alertActions } from "../../Alerts/alertActions";
import { connect } from "react-redux";
import { ColumnDescription } from "react-bootstrap-table-next";
import { ClassifierCode, getClassifierValueName } from "../../Classifier/Classifier";
import { getClassifiers } from "../../Classifier/classifierReducer";
import axios from "axios";
import { Alert, AlertContentType, AlertType } from "../../Alerts/Alert";
import { NavLink } from "react-router-dom";
import { dateFormatter, dateTimeFormatter } from "../../BootstrapTable/customFormatters";

class ConsumptionContractList extends AbstractContractList<ConsumptionContract> {

  static readonly TYPE: ContractType = ContractType.ConsumptionContract;

  protected getType(): ContractType {
    return ConsumptionContractList.TYPE;
  }

  protected getManagePermission(): Permission | Permission[] {
    return Permission.ManageConsumptionContract;
  }

  protected getColumns(): ColumnDescription<ConsumptionContract>[] {
    return [
      {
        dataField: "number",
        text: "Lepingu number",
        sort: true,
        formatter: (cell, row) => <NavLink to={"/consumption/" + row.id}>{cell}</NavLink>
      },
      {
        dataField: "generalDocumentTitle",
        text: "Pealkiri",
        sort: true
      },
      {
        dataField: "rhrReferenceNumber",
        text: "RHR number",
        sort: true
      },
      {
        dataField: "contractor.name",
        text: "Ettevõte",
        sort: true
      },
      {
        dataField: "meterForwardType",
        text: "Näitude edastamise tüüp",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.MeterForwardType, cell)
      },
      {
        dataField: "consumptionContractType",
        text: "Tarbimisteenuste tüüp",
        sort: true,
        formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.ConsumptionContractType, cell)
      },
      {
        dataField: "emailAddress1",
        text: "Peamine e-mail",
        sort: true
      },
      {
        dataField: "emailAddress2",
        text: "Lisa e-mail",
        sort: true
      },
      {
        dataField: "dateSigned",
        text: "Allkirjastatud",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "publishedOn",
        text: "Avaldatud",
        sort: true,
        formatter: dateTimeFormatter
      },
      {
        dataField: "startTime",
        text: "Algus",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "endTime",
        text: "Lõpp",
        sort: true,
        formatter: dateFormatter
      },
      {
        dataField: "comment",
        text: "Kommentaar",
        sort: true
      }
    ]
  }

  protected renderAddButton() {
    return (
        <button type="button"
                className="btn btn-primary ml-4"
                onClick={this.handleAddButton}
                disabled={this.state.addButtonLoading}>
          + Lisa tarbimisleping
        </button>
    );
  }

  protected handleAddButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    this.setState({
      addButtonLoading: true
    }, () => {
      axios.post(this.props.baseURL + "/contracts/consumption")
           .then(res => {
             this.props.history.push(this.props.match!.url + "/" + res.data.id);
           })
           .catch(error => {
             let alertType = AlertContentType.ContractAddError;
             if (error.response) {
               const apiErrorType = Alert.fromException(error.response.data.error);
               if(apiErrorType)
                 alertType = apiErrorType;
             }

             const alert: Alert = new Alert();
             alert.type = AlertType.Danger;
             alert.content = alertType;
             this.props.addAlert(alert);

             this.setState({
               addButtonLoading: false
             });
           });
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumptionContractList);
import { User } from "./User";
import { RootState } from "../rootReducer";
import { authActions } from "./authActions";
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export class AuthState {
  user: User;

  constructor(state?: AuthState) {
    if (state) {
      this.user = state.user;
    }
  }
}

export const authReducer = createReducer(new AuthState(), {
    [authActions.setCurrentUser.type]: (state, action: PayloadAction<User>) => {
        let newState = new AuthState(state);
        newState.user = action.payload;

        return newState;
    }
});

export function getCurrentUser(state: RootState): User {
  return state.auth.user;
}
import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import 'animate.css';
import 'react-notifications-component/dist/theme.css';
import {
    Contract,
    ContractType, CostItemWarranty,
    DefaultPrice, FullConstructionContract, FullContract, FullEnergyContract,
    FullLeaseContract,
    FullServiceContract, FullSupportServiceContract,
    getManagePermission,
    ServiceContractType,
    ThiMultiplication
} from "../Contract";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import Secured from "../../Auth/Secured";
import equal from "fast-deep-equal/es6/react";
import {connect} from "react-redux";
import {contractDetailsActions} from "./contractDetailsActions";
import {modalActions} from "../../Modal/modalActions";
import {
    ConstructionContractEditWarrantyModalData,
    ConstructionContractImportRowsModalData,
    DefaultPricesModalData,
    ModalType,
    ServiceContractAddRowsModalData,
    ThDefaultPricesModalData,
    ThiMultiplicationModalData
} from "../../Modal/Modal";
import {MainLease} from "../../MainLease/MainLease";
import {Permission} from "../../Auth/User";

interface Props extends RouteComponentProps<{}> {
    contract?: Contract | MainLease;
    isContract: boolean;
    editingContract?: Contract | MainLease;
    infoEditMode: boolean;

    onValidate: () => void;
    onSaveContract: () => void;
    onUpdateEditingContract: (contract: FullContract | MainLease, callback?: () => void) => void;
    onSaveContractRows?: () => void;
    onShowPublishConfirmationModal: () => void;
    onShowDeleteConfirmationModal: () => void;
    onShowUnsavedRowsConfirmationModal?: () => void;
    onShowContractHistory: () => void;
    onShowContractorNotificationPreviewModal: () => void;
    onSaveThiMultiplication: (data: ThiMultiplication) => void;
    onCleanProperties: () => void;
    onSaveDefaultPrices: (data: DefaultPrice[]) => void;
    onSaveWarrantyEndDates: (data: CostItemWarranty[]) => void;
    onExportToEDHS: () => void;

    toggleAddRowModal: typeof contractDetailsActions.toggleAddRowModal;
    toggleModal: typeof modalActions.toggleModal;
}

class DetailsHeader extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props);
    };

    private handlePathClick = () => {
        this.props.history.push("/" + this.props.location.pathname.split("/")[1]);
    };

    private translatePath(path: string): string | undefined {
        switch (path) {
            case "service":
                return "Korrashoiuteenuste lepingud";
            case "construction":
                return "Ehituslepingud";
            case "support-service":
                return "Tugiteenuslepingud";
            case "energy":
                return "Energiavahenduslepingud";
            case "lease":
                return "Kasutuslepingud";
            case "consumption":
                return "Tarbimislepingud";
            case "main-leases":
                return "Peaüürid";
            default:
                return undefined;
        }
    }

    render() {
        const isContract = this.props.isContract;
        const contract = this.props.contract as Contract;
        const mainLease = this.props.contract as MainLease;
        return (
            <nav className="navbar navbar-details-page">
                <ul>
                    <li className="nav-item">
                        <a onClick={this.props.history.goBack} className="nav-link-back">
                            <span className="icon icon_arrow_dark" />
                            <span className="nav-link-back-label">Tagasi</span>
                        </a>
                    </li>

                    {(contract || mainLease) &&
                    <>
                        <li className="nav-breadcrumb">
                            <button type="button" className="btn btn-link" onClick={this.handlePathClick}>
                                {this.translatePath(this.props.location.pathname.split("/")[1])}
                            </button>
                            <span className="icon icon_arrow_gray"/>
                        </li>

                        <li className="nav-breadcrumb">
                            <span
                                className="nav-link-current">{isContract ? contract.number : mainLease.contractNumber}</span>
                        </li>
                    </>
                    }
                </ul>

                {(contract || mainLease) && (
                <>
                    {/* MD and up START */}
                    <ul className="navbar-action-buttons">
                        {(!this.props.infoEditMode && isContract) &&
                        <>
                            <li className="nav-item">
                                <UncontrolledDropdown>
                                    <DropdownToggle color="outline-primary" caret>
                                        Vali tegevus
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {contract.publishedOn === null &&
                                            <>{this.renderCustomUnpublishedDropdownItems(contract)}</>
                                        }
                                        {contract.publishedOn !== null &&
                                        <>{this.renderCustomPublishedDropdownItems(contract)}</>
                                        }
                                        {contract.hasPublished !== true &&
                                            <Secured permission={Permission.DeleteContract}>
                                                <DropdownItem tag="a" className="p-3 pointer"
                                                              onClick={this.props.onShowDeleteConfirmationModal}>
                                                    <i className="fas fa-trash mr-2" /> Kustuta leping
                                                </DropdownItem>
                                            </Secured>
                                        }
                                        <DropdownItem tag="a" className="p-3 pointer"
                                                      onClick={this.props.onShowContractHistory}>
                                            <i className="fas fa-history mr-2" />Vaata ajalugu
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                            {this.renderNavButtons(contract)}
                        </>
                        }
                        {(!isContract || contract.publishedOn === null) && this.props.infoEditMode &&
                        <li className="nav-item">
                            <Button color="primary" onClick={this.props.onSaveContract}>
                                Salvesta
                            </Button>
                        </li>
                        }
                    </ul>
                </>
                )}
            </nav>
        );
    }

    private renderNavButtons(contract: Contract) {
        let validRows = false;
        let rowsEqual, propertiesEqual, projectsEqual;
        switch (contract.type) {
            case ContractType.LeaseAgreement:
                validRows = equal(
                    (this.props.editingContract as FullLeaseContract).rows,
                    (this.props.contract as FullLeaseContract).rows);
                break;
            case ContractType.ServiceContract:
                rowsEqual = equal(
                    (this.props.editingContract as FullServiceContract).rows,
                    (this.props.contract as FullServiceContract).rows);
                propertiesEqual = equal(
                    (this.props.editingContract as FullServiceContract).properties,
                    (this.props.contract as FullServiceContract).properties);
                validRows = rowsEqual && propertiesEqual;
                break;
            case ContractType.SupportServiceContract:
                propertiesEqual = equal(
                    (this.props.editingContract as FullSupportServiceContract).properties,
                    (this.props.contract as FullSupportServiceContract).properties);
                validRows = propertiesEqual;
                break;
            case ContractType.EnergySaleAgreement:
                rowsEqual = equal(
                    (this.props.editingContract as FullEnergyContract).rows,
                    (this.props.contract as FullEnergyContract).rows);
                propertiesEqual = equal(
                    (this.props.editingContract as FullEnergyContract).properties,
                    (this.props.contract as FullEnergyContract).properties);
                validRows = rowsEqual && propertiesEqual;
                break;
            case ContractType.ConstructionContract:
                rowsEqual = equal(
                    (this.props.editingContract as FullConstructionContract).rows,
                    (this.props.contract as FullConstructionContract).rows);
                propertiesEqual = equal(
                    (this.props.editingContract as FullConstructionContract).properties,
                    (this.props.contract as FullConstructionContract).properties);
                projectsEqual = equal(
                    (this.props.editingContract as FullConstructionContract).projects,
                    (this.props.contract as FullConstructionContract).projects);
                validRows = rowsEqual && propertiesEqual && projectsEqual;
                break;
        }

        return (
            <>
                {contract.publishedOn === null &&
                <li className="nav-item">
                    <Button color="outline-danger" disabled={!validRows} onClick={this.props.onValidate}>
                        <i className="fas fa-calculator mr-2" /> Kontrolli andmeid
                    </Button>
                </li>
                }
                <li className="nav-item">
                    <Button color="outline-primary" disabled={!validRows} onClick={this.props.onExportToEDHS}>
                        <i className="fas fa-share mr-2" /> Ekspordi EDHS'i
                    </Button>
                </li>
                <li className="nav-item">
                    <Secured permission={getManagePermission(contract.type)}>
                        {contract.publishedOn !== null &&
                            <Button color="secondary" onClick={this.props.onShowPublishConfirmationModal}>
                                Võta muutmisse
                            </Button>
                        }
                        {contract.publishedOn === null &&
                            <>{this.renderEditModePublishButton(contract, validRows)}</>
                        }
                    </Secured>
                </li>
            </>
        );
    }

    private renderEditModePublishButton(contract: Contract, validRows: boolean) {
        switch (contract.type) {
            case ContractType.LeaseAgreement:
            case ContractType.ServiceContract:
            case ContractType.ConstructionContract:
            case ContractType.EnergySaleAgreement:
                if (!validRows) {
                    return (<Button color="primary" onClick={this.props.onSaveContractRows}>Salvesta read</Button>);
                }
                return (<Button color="secondary" onClick={this.props.onShowPublishConfirmationModal}>Avalda</Button>)
            default:
                return (<Button color="secondary" onClick={this.props.onShowPublishConfirmationModal}>Avalda</Button>)
        }
    }

    private renderCustomUnpublishedDropdownItems(contract: Contract) {
        switch (contract.type) {
            case ContractType.LeaseAgreement:
                return <>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={() => this.toggleAddRowsModal(contract)} tag="a" className="p-3 pointer">
                        <i className="fas fa-plus mr-2"/>Lisa ridu
                    </DropdownItem>
                </>
            case ContractType.ServiceContract:
                return <>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={() => this.toggleAddRowsModal(contract)} tag="a" className="p-3 pointer">
                        <i className="fas fa-plus mr-2"/>Lisa ridu
                    </DropdownItem>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={() => this.props.toggleModal(
                                      (this.props.editingContract as FullServiceContract).serviceContractType == ServiceContractType.ServTh
                                          ? new ThDefaultPricesModalData({
                                              type: ModalType.ThDefaultPrices,
                                              onSubmit: this.props.onSaveDefaultPrices,
                                              contract: this.props.editingContract as FullServiceContract})
                                          : new DefaultPricesModalData({
                                              type: ModalType.DefaultPrices,
                                              onSubmit: this.props.onSaveDefaultPrices,
                                              contract: this.props.editingContract as FullServiceContract})

                                  )} tag="a" className="p-3 pointer">
                        <i className="fas fa-pencil-alt mr-2"/>
                        {
                            (this.props.editingContract as FullServiceContract).serviceContractType == ServiceContractType.ServTh
                            ? "Muuda tunnihindu"
                            : "Muuda ühikuhindu"
                        }
                    </DropdownItem>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={() => this.props.toggleModal(
                                      new ThiMultiplicationModalData({
                                          type: ModalType.ThiMultiplication,
                                          onSubmit: this.props.onSaveThiMultiplication,
                                          contract: this.props.editingContract as FullServiceContract})
                                  )} tag="a" className="p-3 pointer">
                        <i className="fas fa-times mr-2"/>Korruta THI'ga
                    </DropdownItem>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={this.props.onCleanProperties} tag="a" className="p-3 pointer">
                        <i className="fas fa-broom mr-2"/>Puhasta
                    </DropdownItem>
                </>
            case ContractType.ConstructionContract:
                return <>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={() => this.toggleAddRowsModal(contract)} tag="a" className="p-3 pointer">
                        <i className="fas fa-plus mr-2"/>Lisa ridu
                    </DropdownItem>
                    <DropdownItem disabled={!!contract.publishedOn}
                                  onClick={() => this.toggleImportRowsModal(contract)} tag="a" className="p-3 pointer">
                        <i className="fas fa-upload mr-2"/>Impordi ridu
                    </DropdownItem>
                    <DropdownItem disabled={!!contract.publishedOn || !(contract as FullConstructionContract).warrantyEndDate}
                                  onClick={() => this.toggleWarrantyEditModal(contract)} tag="a" className="p-3 pointer">
                        <i className="fas fa-calendar-check mr-2"/>Määra garantii lõppkuupäevad
                    </DropdownItem>
                </>
        }
    }

    private renderCustomPublishedDropdownItems(contract: Contract) {
        switch (contract.type) {
            case ContractType.ServiceContract:
                return <>
                    <DropdownItem disabled={!contract.publishedOn || !!(contract as FullServiceContract).contractorNotificationTimestamp}
                                  onClick={this.props.onShowContractorNotificationPreviewModal} tag="a" className="p-3 pointer">
                        <i className="fas fa-envelope mr-2"/>Saada projektijuhile e-mail
                    </DropdownItem>
                </>
        }
    }

    private toggleAddRowsModal(contract: Contract) {
        let modalData;
        switch(contract.type){
            case ContractType.ServiceContract:
                modalData = new ServiceContractAddRowsModalData({
                    type: ModalType.ServiceContractAddRows,
                    editingContract: this.props.editingContract as FullServiceContract,
                    selectedProperty: null,
                    selectedProject: null,
                    onSaveContract: this.props.onSaveContract,
                    updateEditingContract: this.props.onUpdateEditingContract});
                this.props.toggleModal(modalData);
                break;
            case ContractType.ConstructionContract:
                modalData = new ServiceContractAddRowsModalData({
                    type: ModalType.ServiceContractAddRows,
                    editingContract: this.props.editingContract as FullServiceContract,
                    selectedProperty: null,
                    selectedProject: null,
                    onSaveContract: this.props.onSaveContract,
                    updateEditingContract: this.props.onUpdateEditingContract});
                this.props.toggleModal(modalData);
                break;
            default:
                this.props.toggleAddRowModal(true);
        }
    }

    private toggleImportRowsModal(contract: Contract) {
        let modalData;
        switch(contract.type) {
            case ContractType.ConstructionContract:
                modalData = new ConstructionContractImportRowsModalData({
                    type: ModalType.ConstructionContractImportRows,
                    editingContract: this.props.editingContract as FullConstructionContract,
                    onSaveContract: this.props.onSaveContract,
                    updateEditingContract: this.props.onUpdateEditingContract});
                this.props.toggleModal(modalData);
                break;
        }
    }

    private toggleWarrantyEditModal(contract: Contract) {
        let modalData;
        switch(contract.type) {
            case ContractType.ConstructionContract:
                modalData = new ConstructionContractEditWarrantyModalData({
                    type: ModalType.ConstructionContractEditWarranty,
                    contract: this.props.contract as FullConstructionContract,
                    onSubmit: this.props.onSaveWarrantyEndDates,});
                this.props.toggleModal(modalData);
                break;
        }
    }
}

const mapDispatchToProps = {
    toggleAddRowModal: contractDetailsActions.toggleAddRowModal,
    toggleModal: modalActions.toggleModal
};

export default connect(null, mapDispatchToProps)(DetailsHeader);

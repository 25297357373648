import React, {ChangeEvent} from "react";
import { ContractRowField, ContractRowFieldProps } from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import TextareaAutosize from 'react-textarea-autosize';

interface Props<T extends ContractRow> extends ContractRowFieldProps<T> {
    placeholder?: string;
}

export default class TextAreaRowField<T extends ContractRow> extends ContractRowField<T, Props<T>> {

    private onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.row, this.props.fieldKey, event.target.value);
        }
    };

    protected renderEditableField(): JSX.Element {
        const charWidth = Math.min(((this.getFieldValue()) as string || "").length || 0, 100);

        return (
            <TextareaAutosize className="form-control expanding-textarea"
                              placeholder={this.props.placeholder}
                              cols={charWidth}
                              value={this.getFieldValue() || ""}
                              onChange={this.onChange}
                              disabled={!this.props.editable}/>
        );
    }
}
import { createStore } from "redux";
import { rootReducer, RootState } from "./rootReducer";

// tslint:disable:no-var-requires
const reduxModule = require("redux");
reduxModule.__DO_NOT_USE__ActionTypes.INIT = "@@redux/INIT";
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/REPLACE";

function configureStore(initialState?: RootState) {
  return createStore(
      rootReducer,
      initialState!,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}

const store = configureStore();

export default store;
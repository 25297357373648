import * as React from "react";
import {RootState} from "../../rootReducer";
import {connect} from "react-redux";
import {
    ColumnDescription,
} from "react-bootstrap-table-next";
import {ClassifierCode, getClassifierValueName} from "../../Classifier/Classifier";
import {
    dateFormatter
} from "../../BootstrapTable/customFormatters";
import {getClassifiers} from "../../Classifier/classifierReducer";

import {Permission} from "../../Auth/User";
import {getBaseURL} from "../../Config/configReducer";
import {NavLink} from "react-router-dom";
import {alertActions} from "../../Alerts/alertActions";
import {AbstractReportList} from "../AbstractReportList";
import {ReportType, ServiceContractReport} from "../Report";


class ServiceContractsReportList extends AbstractReportList<ServiceContractReport> {

    static readonly TYPE = ReportType.ServiceContractReport

    protected getType(): ReportType {
        return ServiceContractsReportList.TYPE;
    }

    protected getEndPoint(): string {
        return "service-contract-report";
    }

    protected getManagePermission(): Permission | Permission[] {
        return Permission.ManageServiceContract;
    }

    protected getColumns(): ColumnDescription<ServiceContractReport>[] {
        return [
            {
                dataField: "contractProperty.code",
                text: "Kinnistu kood",
                sort: true
            },
            {
                dataField: "contractProperty.name",
                text: "Kinnistu nimetus",
                sort: true
            },
            {
                dataField: "contractProperty.address",
                text: "Aadress",
                sort: true
            },
            {
                dataField: "manager.name",
                text: "Haldur",
                sort: true
            },
            {
                dataField: "leaserCompany.name",
                text: "Üürnik",
                sort: true
            },
            {
                dataField: "rentalCode",
                text: "Üürikood",
                sort: true
            },
            {
                dataField: "serviceContract.number",
                text: "Lepingu number",
                sort: true,
                formatter: (cell, row) => <NavLink to={"/service/" + row.serviceContract.id}>{cell}</NavLink>
            },
            {
                dataField: "contractorCompany.name",
                text: "Tarnija ettevõte",
                sort: true
            },
            {
                dataField: "serviceContract.type",
                text: "Lepingu tüüp",
                sort: true,
                formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.ServiceContractType, cell)
            },
            {
                dataField: "serviceContract.minServiceStartTime",
                text: "Ridade alguskuupäev",
                sort: true,
                formatter: dateFormatter
            },
            {
                dataField: "serviceContract.maxServiceEndTime",
                text: "Ridade lõppkuupäev",
                sort: true,
                formatter: dateFormatter
            },
            {
                dataField: "serviceContract.startTime",
                text: "Algus",
                sort: true,
                formatter: dateFormatter
            },
            {
                dataField: "serviceContract.endTime",
                text: "Lõpp",
                sort: true,
                formatter: dateFormatter
            },
        ];
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state)
});

const mapDispatchToProps = {
    addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContractsReportList);
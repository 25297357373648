import { InvoiceChannelType, Company, Person } from "../Contracts/Contract";

export class MainLease {

  id: number;
  code: string;
  contractNumber: string;
  isBondCollateral: boolean;
  invoiceDueDays: number;
  company: Company;
  contact: Person;
  manager: Person;
  accountant: Person;
  invoiceChannelType: InvoiceChannelType;
}
import React from "react";
import {FullLeaseContract, LeaseContractType} from "../../Contract";
import {Classifier, ClassifierCode} from "../../../Classifier/Classifier";
import {RootState} from "../../../rootReducer";
import {getClassifiers} from "../../../Classifier/classifierReducer";
import {connect} from "react-redux";
import TextField from "../ContractInfo/Fields/TextField";
import ClassifierField from "../ContractInfo/Fields/ClassifierField";
import TextAreaField from "../ContractInfo/Fields/TextAreaField";
import PersonField from "../ContractInfo/Fields/PersonField";
import DateField from "../ContractInfo/Fields/DateField";
import {getBaseURL, getRkasCompanyRegNo, getSapEnabled} from "../../../Config/configReducer";
import CompanyField from "../ContractInfo/Fields/CompanyField";
import CheckboxField from "../ContractInfo/Fields/CheckboxField";
import {Button} from "reactstrap";
import NumberField from "../ContractInfo/Fields/NumberField";
import MainLeaseField from "../ContractInfo/Fields/MainLeaseField";
import {ContactInfoModalData, ModalType} from "../../../Modal/Modal";
import {modalActions} from "../../../Modal/modalActions";
import moment from "moment";

interface Props {
    classifiers: Classifier[]
    baseURL: string;
    contract: FullLeaseContract;
    editingContract?: FullLeaseContract;
    editMode: boolean;
    updateEditingContract: (contract: FullLeaseContract) => void;
    filterType: string;
    rkasCompanyRegNo: string;
    sapEnabled: boolean;

    toggleModal: typeof modalActions.toggleModal;
}

class LeaseInfo extends React.Component<Props, any> {

    private onChange = (fieldKey: keyof FullLeaseContract, value: any) => {
        if (this.props.editingContract) {
            this.props.updateEditingContract({
                ...this.props.editingContract,
                [fieldKey]: value
            });
        }
    };

    render() {
        const isServitude = this.props.contract.leaseContractType == LeaseContractType.Servitude;
        const hasPublished = this.props.contract.hasPublished;
        const isLeaseLike = this.props.contract.leaseContractType == LeaseContractType.Lease || this.props.contract.leaseContractType == LeaseContractType.Invoicing;
        return (
            <div className="row">
                <div className={this.props.editMode
                    ? "col-12 col-lg-5 col-xl-5"
                    : "col-12 col-md-6"}>
                    {(this.props.filterType === "overview" || this.props.editMode) &&
                    <>
                        <TextField contract={this.props.editingContract || this.props.contract}
                                   label={"Lepingu number"}
                                   fieldKey={"number"}
                                   editMode={this.props.editMode}
                                   editable={false}
                                   onChange={this.onChange}
                        />
                        <TextField contract={this.props.editingContract || this.props.contract}
                                   label={"Pealkiri"}
                                   fieldKey={"generalDocumentTitle"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <ClassifierField contract={this.props.editingContract || this.props.contract}
                                         label={"Tüüp"}
                                         fieldKey={"leaseContractType"}
                                         classifiers={this.props.classifiers}
                                         classifierCode={ClassifierCode.LeaseContractType}
                                         excludedValueCodes={isLeaseLike ? ['SERVITUDE', 'FMLEASE'] : null}
                                         required={true}
                                         editMode={this.props.editMode}
                                         editable={!(!!this.props.contract.leaseContractType) || isLeaseLike}
                                         onChange={this.onChange}
                        />
                        <MainLeaseField contract={this.props.editingContract || this.props.contract}
                                        label={"Peaüür"}
                                        fieldKey={"mainLease"}
                                        baseURL={this.props.baseURL}
                                        editMode={this.props.editMode}
                                        editable={this.props.editMode}
                                        isClearable={true}
                                        onChange={this.onChange}
                        />
                        <PersonField contract={null}
                                     label={"Haldur"}
                                     fieldKey={null}
                                     fieldValue={(this.props.editingContract || this.props.contract).properties[0]?.manager || null}
                                     placeholder={"Kinnistu valimata"}
                                     companyRegNo={this.props.rkasCompanyRegNo}
                                     baseURL={this.props.baseURL}
                                     editMode={this.props.editMode}
                                     editable={false}
                                     required={true}
                                     onChange={this.onChange}
                                     onLinkClick={(person) => this.props.toggleModal(
                                         new ContactInfoModalData({ type: ModalType.ContactInfo, person: person} )
                                     )}
                        />
                        <TextField contract={this.props.editingContract || this.props.contract}
                                   label={"Üürikood"}
                                   fieldKey={"rentalCode"}
                                   placeholder={this.getRentalCodePlaceholder()}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode && this.props.editingContract?.rentalCode !== null}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <CompanyField contract={this.props.editingContract || this.props.contract}
                                      label={"Üürnik"}
                                      fieldKey={"leaser"}
                                      baseURL={this.props.baseURL}
                                      editMode={this.props.editMode}
                                      editable={this.props.editMode}
                                      required={true}
                                      onChange={this.onChange}
                        />
                        <NumberField contract={this.props.editingContract || this.props.contract}
                                     label={"Üüripind"}
                                     fieldKey={"area"}
                                     editMode={this.props.editMode}
                                     editable={this.props.editMode}
                                     separator={"."}
                                     required={!isServitude}
                                     onChange={this.onChange}
                        />
                        <TextField contract={this.props.editingContract || this.props.contract}
                                   label={"Raamleping"}
                                   fieldKey={"frameContractReference"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        <DateField contract={this.props.editingContract || this.props.contract}
                                   label={"Algus"}
                                   fieldKey={"startTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   required={true}
                                   onChange={this.onChange}
                        />
                        <DateField contract={this.props.editingContract || this.props.contract}
                                   label={"Lõpp"}
                                   fieldKey={"endTime"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                    </>
                    }

                    {(this.props.filterType === "lease" || this.props.editMode) &&
                    <>
                        <DateField contract={this.props.editingContract || this.props.contract}
                                   label={"Üleminekuperioodi lõpp"}
                                   fieldKey={"transitionPeriodEnd"}
                                   editMode={this.props.editMode}
                                   editable={this.props.editMode}
                                   onChange={this.onChange}
                        />
                        {!isServitude &&
                            <>
                                <DateField contract={this.props.editingContract || this.props.contract}
                                           label={"Indekseerimise algus"}
                                           fieldKey={"indexingStart"}
                                           editMode={this.props.editMode}
                                           editable={this.props.editMode}
                                           onChange={this.onChange}
                                />
                                <TextField contract={this.props.editingContract || this.props.contract}
                                            label={"THI kuupäev"}
                                            fieldKey={"cpiDate"}
                                            editMode={this.props.editMode}
                                            editable={this.props.editMode}
                                            onChange={this.onChange}
                                />
                                <NumberField contract={this.props.editingContract || this.props.contract}
                                            label={"Koefitsent"}
                                            fieldKey={"coefficient"}
                                            editMode={this.props.editMode}
                                            editable={this.props.editMode}
                                            onChange={this.onChange}
                                />
                                <NumberField contract={this.props.editingContract || this.props.contract}
                                           label={"Piirmäär"}
                                           fieldKey={"limit"}
                                           minimumFractionDigits={0}
                                           maximumFractionDigits={0}
                                           editMode={this.props.editMode}
                                           editable={this.props.editMode}
                                           onChange={this.onChange}
                                />
                                <ClassifierField contract={this.props.editingContract || this.props.contract}
                                                label={"Tarbimisteenuste tüüp"}
                                                fieldKey={"consumptionService"}
                                                classifiers={this.props.classifiers}
                                                classifierCode={ClassifierCode.ConsumptionService}
                                                required={true}
                                                editMode={this.props.editMode}
                                                editable={this.props.editMode}
                                                onChange={this.onChange}
                                />
                            </>
                        }
                    </>
                    }
                </div>
                <div className={this.props.editMode
                    ? "col-12 col-lg-6 col-xl-5 offset-md-0 offset-lg-1 offset-xl-2 mt-3 mt-lg-0"
                    : "col-12 col-md-6"}>
                    {(this.props.filterType === "overview" || this.props.editMode) &&
                    <>
                        {(this.props.contract.leaseContractType == LeaseContractType.Lease || !this.props.editMode) &&
                            <CheckboxField contract={this.props.editingContract || this.props.contract}
                                           label={"Arvelda kuu hiljem"}
                                           fieldKey={"subsequentMonthInvoicing"}
                                           editMode={this.props.editMode}
                                           editable={this.props.editMode}
                                           onChange={this.onChange}
                            />
                        }
                        <ClassifierField contract={this.props.editingContract || this.props.contract}
                                         label={"Arve saatmise kanal"}
                                         fieldKey={"invoiceChannelType"}
                                         classifiers={this.props.classifiers}
                                         classifierCode={ClassifierCode.InvoiceChannelType}
                                         isClearable={true}
                                         editMode={this.props.editMode}
                                         editable={this.props.editMode}
                                         onChange={this.onChange}
                        />
                        {this.renderInvoiceDueDays()}
                        {!isServitude &&
                            <ClassifierField contract={this.props.editingContract || this.props.contract}
                                             label={"Üüri liik"}
                                             fieldKey={"leaseType"}
                                             classifiers={this.props.classifiers}
                                             classifierCode={ClassifierCode.LeaseType}
                                             required={true}
                                             editMode={this.props.editMode}
                                             editable={this.props.editMode}
                                             onChange={this.onChange}
                            />
                        }
                        {this.props.sapEnabled &&
                            <TextField contract={this.props.editingContract || this.props.contract}
                                       label={"SAP kood"}
                                       fieldKey={"sapCode"}
                                       editMode={this.props.editMode}
                                       editable={false}
                                       onChange={this.onChange}
                            />
                        }
                        {!this.props.editMode &&
                            <>
                                <ClassifierField contract={null}
                                                 label={"Valitsemisala"}
                                                 fieldKey={null}
                                                 fieldValue={(this.props.editingContract || this.props.contract).leaser?.governanceArea || "-"}
                                                 classifiers={this.props.classifiers}
                                                 classifierCode={ClassifierCode.GovernanceAreaType}
                                                 editMode={false}
                                                 editable={false}
                                                 onChange={null}
                                />
                                <ClassifierField contract={null}
                                                 label={"Portfell"}
                                                 fieldKey={null}
                                                 fieldValue={(this.props.editingContract || this.props.contract).properties[0]?.portfolioType || "-"}
                                                 classifiers={this.props.classifiers}
                                                 classifierCode={ClassifierCode.PortfolioType}
                                                 editMode={false}
                                                 editable={false}
                                                 onChange={null}
                                />
                            </>
                        }
                        {!isServitude &&
                        <>
                            {this.renderOrdinaryCancellation()}
                            <ClassifierField contract={this.props.editingContract || this.props.contract}
                                             label={"Lõpetamise alus"}
                                             fieldKey={"endingPredication"}
                                             classifiers={this.props.classifiers}
                                             classifierCode={ClassifierCode.EndingPredication}
                                             isClearable={true}
                                             editMode={this.props.editMode}
                                             editable={this.props.editMode}
                                             onChange={this.onChange}
                            />
                        </>
                        }
                        <TextField contract={this.props.editingContract || this.props.contract}
                            label={"Ülesütlemise hüvitis"}
                            fieldKey={"cancellationCompensation"}
                            editMode={this.props.editMode}
                            editable={this.props.editMode}
                            onChange={this.onChange}
                        />
                        <TextAreaField contract={this.props.editingContract || this.props.contract}
                                       label={"Märkused"}
                                       fieldKey={"comment"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                    </>
                    }

                    {(this.props.filterType === "lease" || this.props.editMode) &&
                    <>
                        {!isServitude &&
                            <CheckboxField contract={this.props.editingContract || this.props.contract}
                                           label={"Tasaarveldatav"}
                                           fieldKey={"isSettleable"}
                                           editMode={this.props.editMode}
                                           editable={this.props.editMode}
                                           onChange={this.onChange}
                            />
                        }
                        {this.renderSendNotice()}
                        <CheckboxField contract={this.props.editingContract || this.props.contract}
                                       label={"Laenu tagatis"}
                                       fieldKey={"isBondCollateral"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                        {!hasPublished &&
                            <CheckboxField contract={this.props.editingContract || this.props.contract}
                                           label={"Ekspordi avaldamata lepingu andmed"}
                                           fieldKey={"isAvailableUnpublished"}
                                           editMode={this.props.editMode}
                                           editable={this.props.editMode}
                                           onChange={this.onChange}
                            />
                        }
                        <CheckboxField contract={this.props.editingContract || this.props.contract}
                                       label={"Kontorikulud"}
                                       fieldKey={"isInternalBilling"}
                                       editMode={this.props.editMode}
                                       editable={this.props.editMode}
                                       onChange={this.onChange}
                        />
                    </>
                    }
                </div>
            </div>
        );
    }

    private renderSendNotice(): JSX.Element {
        return (
            <>
                <CheckboxField contract={this.props.editingContract || this.props.contract}
                               label={"Saada üüriteatis"}
                               fieldKey={"sendNotice"}
                               editMode={this.props.editMode}
                               editable={this.props.editMode}
                               onChange={this.onChange}
                />
                {((this.props.editMode && this.props.editingContract.sendNotice)
                    || (!this.props.editMode && this.props.contract.sendNotice)) &&
                <ClassifierField contract={this.props.editingContract || this.props.contract}
                                 label={"Üüriteatise saatmise aeg"}
                                 fieldKey={"noticeDate"}
                                 classifiers={this.props.classifiers}
                                 classifierCode={ClassifierCode.NoticeDate}
                                 editMode={this.props.editMode}
                                 editable={this.props.editMode}
                                 onChange={this.onChange}
                />
                }
            </>
        );
    }

    private renderInvoiceDueDays(): JSX.Element {
        return (
            <>
                {this.props.editMode &&
                <NumberField contract={this.props.editingContract || this.props.contract}
                             label={"Maksetähtaeg (päevades)"}
                             fieldKey={"invoiceDueDays"}
                             editMode={this.props.editMode}
                             editable={this.props.editMode}
                             onChange={this.onChange}
                />
                }

                {!this.props.editMode &&
                <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                    <div className="col-12 align-items-start">
                        <label className="w-200">{"Maksetähtaeg"}</label>
                        {this.props.contract.invoiceDueDays &&
                        <>
                            <span className="font-weight-bold">{this.props.contract.invoiceDueDays}</span>
                            <span className="font-weight-bold">&nbsp;{this.props.contract.invoiceDueDays == 1 ? "päev" : "päeva"}</span>
                        </>
                        }
                        {!this.props.contract.invoiceDueDays &&
                        <span className="font-weight-bold">-</span>
                        }
                    </div>
                </div>
                }
            </>
        );
    }

    private renderOrdinaryCancellation(): JSX.Element {
        const unlimited = this.props.editingContract.endTime != null && moment(this.props.editingContract.endTime).year() == 9999;
        return (
            <>
                {!this.props.editMode &&
                    <div className={"form-row custom-form-row" + (!this.props.editMode ? " mt-md-0 mb-md-2 mb-lg-0" : "")}>
                        <div className="col-12 align-items-start">
                            <label className="w-200">{"Korraline ülesütlemine"}</label>
                            <span className="font-weight-bold">{
                                    `${this.props.editingContract.isOrdinaryCancellation ? 'Jah' : 'Ei'}`
                            }</span>
                        </div>
                    </div>
                }
                {this.props.editMode &&
                    <div className="form-row custom-form-row">
                        <div className="col-12 align-items-start">
                            <label className="w-200">Korraline ülesütlemine</label>
                            <div className="btn-group d-flex">
                                <Button
                                    size="sm"
                                    color="outline-primary"
                                    onClick={() => this.onChange("isOrdinaryCancellation", true)}
                                    className={this.props.editingContract.isOrdinaryCancellation ? "active" : ""}
                                    role="button">
                                    Jah
                                </Button>
                                <Button
                                    size="sm"
                                    color="outline-primary"
                                    onClick={() => this.onChange("isOrdinaryCancellation", false)}
                                    className={this.props.editingContract.isOrdinaryCancellation == false ? "active" : ""}
                                    role="button">
                                    Ei
                                </Button>
                            </div>
                        </div>
                    </div>
                }

                {this.props.editingContract.isOrdinaryCancellation &&
                <>
                    <NumberField contract={this.props.editingContract || this.props.contract}
                                label={"Etteteatamise aeg (Kuud)"}
                                fieldKey={"cancellationPeriod"}
                                editMode={this.props.editMode}
                                editable={this.props.editMode}
                                onChange={this.onChange}
                                suffix={this.props.editingContract.cancellationPeriod == 1 ? " kuu" : " kuud"}
                    />
                    <TextField contract={this.props.editingContract || this.props.contract}
                               label={"Lepingu punkt"}
                               fieldKey={"cancellationContractReference"}
                               editMode={this.props.editMode}
                               editable={this.props.editMode}
                               onChange={this.onChange}
                               placeholder={this.props.editingContract.isOrdinaryCancellation && unlimited ? "Kehtib võlaõigusseaduse punkt 3 kuud – paragrahv 312 lõige 1" : ""}
                    />
                </>
                }
            </>
        );
    }

    private getRentalCodePlaceholder() {
        if (this.props.editMode && this.props.contract && this.props.editingContract) {
            const mainPropertyRentalCode = this.props.editingContract.properties[0]?.code;
            const contractRentalCode = this.props.contract.rentalCode;
            const editingContractRentalCode = this.props.editingContract.rentalCode;

            if(!contractRentalCode && !editingContractRentalCode) {
                if(mainPropertyRentalCode) {
                    //const rentalCodeCount = Number(this.props.editingContract.properties[0].rentalCodeCount) + 1;
                    //return mainPropertyRentalCode + (rentalCodeCount < 10 ? `_0${rentalCodeCount}` :`_${rentalCodeCount}`);
                    return mainPropertyRentalCode;
                }
                return "Kinnistu valimata";
            }
        }
        return "";
    }
}

const mapStateToProps = (state: RootState) => ({
    baseURL: getBaseURL(state),
    classifiers: getClassifiers(state),
    rkasCompanyRegNo: getRkasCompanyRegNo(state),
    sapEnabled: getSapEnabled(state)
});

const mapDispatchToProps = {
    toggleModal: modalActions.toggleModal
};

export default connect(mapStateToProps,mapDispatchToProps)(LeaseInfo);

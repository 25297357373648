export interface User {

  countryCode: string;
  personalCode: string;
  forename: string;
  surname: string;
  phone: string;
  email: string;
  companies: UserCompany[];
  userCompany: UserCompany;
  roles: Role[];
  substitutions: UserSubstitution[];
}

export interface UserSubstitution {
  id: number;
  actingAsUser: User;
}

export interface UserCompany {
  id: number;
  companyId: number;
  name: string;
  user: UserCompanyUser;
  phone: string;
  email: string;
}

export interface UserCompanyUser {
  id: number;
  name: string;
  email: string;
  phone: string;
  personalCode: string;
}

export interface Role {
  id: number;
  code: string;
  name: string;
  permissions: AvailablePermission[];
}

export enum Permission {
  AccessServiceContract = "service_contract.access.all",
  AccessConstructionContract = "construction_contract.access.all",
  AccessSupportServiceContract = "support_service_contract.access.all",
  AccessLeaseAgreement = "lease_agreement.access.all",
  AccessEnergySaleAgreement = "energy_sale_agreement.access.all",
  AccessConsumptionContract = "consumption_contract.access.all",
  AccessMainLease = "main_lease.access.all",

  ManageServiceContract = "service_contract.manage.all",
  ManageConstructionContract = "construction_contract.manage.all",
  ManageSupportServiceContract = "support_service_contract.manage.all",
  ManageLeaseAgreement = "lease_agreement.manage.all",
  ManageEnergySaleAgreement = "energy_sale_agreement.manage.all",
  ManageConsumptionContract = "consumption_contract.manage.all",
  ManageMainLease = "main_lease.manage.all",

  ViewLeaseAgreementAdditional = "lease_agreement_additional.view",
  DeleteContract = "contract.delete"
}

export interface AvailablePermission {
  id: number;
  code: string;
  name: string;
}

export function hasPermission(user: User, permission: Permission): boolean {
  if(user && user.roles) {
    for (let role of user.roles) {
      if(role.permissions.some(rolePermission => rolePermission.code === permission))
        return true;
    }
  }
  return false;
}
import React from "react";
import { ContractRowField } from "./ContractRowField";
import { ContractRow } from "../../ContractRow";
import { NumberUtils } from "../../../../Common/NumberUtils";
import { NumberInput } from "../../../../Common/NumberInput";

export default class NumberRowField<T extends ContractRow> extends ContractRowField<T> {
    private onChange = (value: number) => {
        this.props.onChange(this.props.row, this.props.fieldKey, value);
    };

    protected renderReadOnlyField(): JSX.Element {
        return (
            <span>
                {!this.getFieldValue() != undefined && NumberUtils.format(this.getFieldValue(), 0, 4)}
            </span>
        );
    }

    protected renderEditableField(): JSX.Element {
        return (
            <NumberInput value={this.getFieldValue()}
                         disabled={!this.props.editable}
                         onNumberChange={this.onChange} />
        );
    }
}
import { Equipment, FullConsumptionContract } from "../../Contract";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../rootReducer";
import { getBaseURL } from "../../../Config/configReducer";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import BootstrapTable, {
  ColumnDescription,
  SortOrder,
  TableChangeState,
  TableChangeType
} from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import PaginationListStandalone from "../../../BootstrapTable/PaginationListStandalone";
import {
  pageListRenderer,
  sizePerPageRenderer,
  sortCaretRenderer
} from "../../../BootstrapTable/customRenderers";
import { headerFormatter } from "../../../BootstrapTable/customFormatters";
import { PagingMetadata } from "../../../Paging/Paging";
import axios from "axios";
import { PagingUtils } from "../../../Paging/PagingUtils";
import { Classifier, ClassifierCode, getClassifierValueName } from "../../../Classifier/Classifier";
import { getClassifiers } from "../../../Classifier/classifierReducer";

interface Props {
  baseURL: string;
  classifiers: Classifier[];
  editingContract: FullConsumptionContract;
  isOpen: boolean,
  toggle: () => void,
  updateEditingContract: (contract: FullConsumptionContract) => void;
  onSaveContract: () => void;
}

interface State {
  contractEquipment?: Equipment[];
  paging: PagingMetadata;
  equipment: Equipment[];
  searchText: string;
  searchTimeout?: number;
}

class ConsumptionEquipmentModal extends React.Component<Props, State> {

  private _columns: ColumnDescription[] = ([
    {
      dataField: "code",
      text: "Kood",
      sort: true
    },
    {
      dataField: "eic",
      text: "EIC",
      sort: true
    },
    {
      dataField: "equipmentType",
      text: "Tüüp",
      sort: true,
      formatter: cell => getClassifierValueName(this.props.classifiers, ClassifierCode.EquipmentType, cell)
    },
    {
      dataField: "propertyCode",
      text: "Kinnistu kood",
      sort: true
    },
    {
      dataField: "buildingCode",
      text: "Hoone kood",
      sort: true
    },
    {
      dataField: "comment",
      text: "Kommentaar",
      sort: true
    },
    {
      dataField: "consumptionContract.number",
      text: "Seotud leping"
    },
    {
      dataField: "selected",
      text: "",
      formatter: (cell, row) => {
        const { equipment } = this.props.editingContract;
        const selectable = !equipment || (!equipment.some(e => e.id == row.id) &&
            (!row.consumptionContract || row.consumptionContract.id === this.props.editingContract.id));

        return (
            <>
              {equipment && equipment.some(e => e.id == row.id) && (
                  <button type="button"
                          onClick={() => this.removeEquipment(row)}
                          className="btn btn-sm btn-link">
                    Eemalda valik
                  </button>
              )}
              {selectable && (
                  <button onClick={() => this.selectEquipment(row)}
                          className="btn btn-sm btn-primary"
                          type="button">
                    Vali arvesti
                  </button>
              )}
            </>
        );
      }
    },
  ] as ColumnDescription[]).map(column => {
    column.sortCaret = sortCaretRenderer;
    column.headerFormatter = headerFormatter;
    return column;
  });

  constructor(props: Props) {
    super(props);

    this.state = {
      paging: new PagingMetadata(),
      equipment: [],
      searchText: ""
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        contractEquipment: this.props.editingContract.equipment
      });
      this.getEquipment();
    }
  }

  private cancel = () => {
    this.props.updateEditingContract({
      ...this.props.editingContract,
      equipment: this.state.contractEquipment
    });
    this.props.toggle();

    this.setState({
      contractEquipment: undefined
    });
  };

  private onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout!);
    }

    let timeout = window.setTimeout(() => {
      this.onPagingChange(Object.assign(this.state.paging, {
        search,
        page: 0
      }));
    }, 500);

    this.setState({
      searchTimeout: timeout,
      searchText: search
    });
  };

  private hackRefreshTable() {
    // FIXME BootstrapTable not refreshing when data changed, find better solution
    const { equipment } = this.state;

    this.setState({
      equipment: []
    }, () => {
      this.setState({
        equipment
      })
    });
  }

  private selectEquipment = (equipment: Equipment) => {
    this.props.updateEditingContract({
      ...this.props.editingContract,
      equipment: [
          ...this.props.editingContract.equipment,
          equipment
      ]
    });
    this.hackRefreshTable();
  };

  private removeEquipment = (equipment: Equipment) => {
    this.props.updateEditingContract({
      ...this.props.editingContract,
      equipment: this.props.editingContract.equipment.filter(e => e.id !== equipment.id)
    });
    this.hackRefreshTable();
  };

  private onSave = () => {
    this.props.onSaveContract();
    this.props.toggle();
  };

  private getEquipment() {
    const { paging } = this.state;

    axios.get(this.props.baseURL + "/equipment", {
           params: PagingUtils.toHttpParams(paging)
         })
         .then(res => {
           this.setState({
             paging: res.data.metadata,
             equipment: res.data.data
           });
         })
         .catch(error => {
           //params.error(error); todo
         })
  }

  private onPagingChange = (paging: PagingMetadata) => {
    this.setState({
      paging: paging
    }, () => {
      this.getEquipment();
    });
  };

  private onTableChange = (type: TableChangeType, state: TableChangeState<Equipment>) => {
    this.onPagingChange(Object.assign(this.state.paging, {
      page: (state.page || 1) - 1,
      limit: state.sizePerPage,
      sort: state.sortField,
      sortDirection: (state.sortOrder || "asc").toUpperCase()
    }));
  };

  private renderTable() {
    return(
        <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              sizePerPageRenderer: sizePerPageRenderer,
              pageListRenderer: pageListRenderer(this.state.paging),
              page: (this.state.paging.page || 0) + 1,
              sizePerPage: this.state.paging.limit || 20,
              totalSize: this.state.paging.count || 0,
              sizePerPageList: [20, 50, 100]
            })}>
          {({ paginationProps, paginationTableProps }) => (
              <div className="datatable">
                <div className="table-responsive">
                  <BootstrapTable
                      {...paginationTableProps}
                      remote={true}
                      keyField="id"
                      columns={this._columns}
                      data={this.state.equipment}
                      headerWrapperClasses="datatable-header"
                      onTableChange={this.onTableChange}
                      noDataIndication="Kirjeid ei leitud"
                      sort={{
                        dataField: this.state.paging.sort,
                        order: (this.state.paging.sortDirection ?
                            this.state.paging.sortDirection.toLowerCase() : undefined) as SortOrder
                      }}
                  />
                </div>
                <div className="datatable-footer">
                  <div className="datatable-footer-inner">
                    <div className="d-flex align-items-center" style={{padding: "5px 10px"}}>
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              </div>
          )}
        </PaginationProvider>
    );
  }


  render() {
    return (
        <Modal isOpen={this.props.isOpen} toggle={this.cancel} size="xl">
          <ModalHeader>
            Vali lepingu arvestid
          </ModalHeader>

          <ModalBody className="p-2 p-lg-3">
            <div className="form-control-with-search-icon">
              <Input defaultValue={this.state.paging.search}
                     type="text"
                     placeholder="Otsi arvestit koodi, EIC järgi"
                     onChange={this.onSearch}
              />
              <span className={"icon icon_search icon-sm" + (!this.state.searchText ? " mask-gray" : "")} />
              {this.state.searchText &&
              <button className="btn p-0">
                <span className="icon icon_close icon-sm" />
              </button>
              }
            </div>

            <div className="table-responsive">
              {this.renderTable()}
            </div>
          </ModalBody>

          <ModalFooter>
            <h6 className="mr-3">Valitud arvesteid: {this.props.editingContract.equipment.length}</h6>

            <Button
              onClick={this.cancel}
              color="outline-primary"
            >
              Katkesta
            </Button>
            <Button
              onClick={this.onSave}
              color="primary"
            >
              Salvesta arvestid
            </Button>
          </ModalFooter>
        </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state)
});

export default connect(mapStateToProps)(ConsumptionEquipmentModal);
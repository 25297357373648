import {ColumnDescription} from "react-bootstrap-table-next";
import React from "react";
import {Person} from "../Contracts/Contract";
import {
    CurrencyFormatOptions,
    DateFormatOptions,
    DateTimeFormatOptions,
    NumberFormatOptions,
    PercentFormatOptions
} from "../Common/FormatOptions";
import {isNullOrUndefined} from "util";

export function headerFormatter(column: ColumnDescription, colIndex: number,
                                components: { sortElement: JSX.Element; filterElement: JSX.Element; }) {
    return (
        <div className={"datatable-header-cell" + (column.sort ? " sortable" : "")}>
            <div className="datatable-header-cell-template-wrap">
          <span className="datatable-header-cell-wrapper">
            <span className="datatable-header-cell-label draggable">
              {column.text}
            </span>
          </span>
                {components.sortElement}
            </div>
        </div>
    );
}

export function personNameFormatter(value: Person) {
    return value && value.forename && value.surname ? value.forename + " " + value.surname : null;
}

export function checkMarkFormatter(value: boolean) {
    return value ? <i className='fa fa-check'/> : null;
}

export function dateFormatter(value: number): string|null {
    if (value) {
        return new Intl.DateTimeFormat("et-EE", DateFormatOptions).format(new Date(value));
    }
    return null;
}

export function dateTimeFormatter(value: number): string|null {
    if (value) {
        return new Intl.DateTimeFormat("et-EE", DateTimeFormatOptions).format(new Date(value));
    }
    return null;
}

export function decimalFormatter(value: number): string|null {
    if (!isNullOrUndefined(value)) {
        return new Intl.NumberFormat("et-EE", NumberFormatOptions).format(value);
    }
    return null;
}

export function percentFormatter(value: number): string|null {
    if (!isNullOrUndefined(value)) {
        return new Intl.NumberFormat("et-EE", PercentFormatOptions).format(value);
    }
    return null;
}

export function currencyFormatter(value: number,): string|null {
    if (!isNullOrUndefined(value)) {
        return new Intl.NumberFormat("et-EE", CurrencyFormatOptions).format(value);
    }
    return null;
}
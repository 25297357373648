import * as React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import LeaseContractList from "./LeaseContractList";
import ContractDetails2 from "../ContractDetails/ContractDetails";

export class LeaseContracts extends React.Component<RouteComponentProps<{}>> {

  render() {
    return (
        <>
          <Route path={this.props.match!.url} exact={true} component={LeaseContractList} />
          <Route path={this.props.match!.url + "/:id"} exact={true} component={ContractDetails2} />
        </>
    );
  }
}
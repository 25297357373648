import React from "react";
import {alertActions} from "../../Alerts/alertActions";
import {Classifier, ClassifierCode, getClassifierValueName} from "../../Classifier/Classifier";
import {connect} from "react-redux";
import {RootState} from "../../rootReducer";
import {getBaseURL} from "../../Config/configReducer";
import {getClassifiers} from "../../Classifier/classifierReducer";
import {
  ConstructionContract,
  ContractType, CostItemWarranty,
  DefaultPrice,
  FullConstructionContract,
  FullConsumptionContract,
  FullContract,
  FullEnergyContract,
  FullLeaseContract,
  FullServiceContract,
  FullSupportServiceContract,
  ReserveCalculationBasedOn,
  ServiceContract,
  ThiMultiplication
} from "../Contract";
import axios from "axios";
import store from "../../store";
import {contractActions} from "../contractActions";
import {RouteComponentProps} from "react-router";
import ContractInfo from "./ContractInfo/ContractInfo";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {Alert, AlertContentType, AlertType} from "../../Alerts/Alert";
import {ConsumptionContractDetails} from "./ConsumptionContract/ConsumptionContractDetails";
import LeaseContractDetails from "./LeaseContract/LeaseContractDetails";
import ServiceContractDetails from "./ServiceContract/ServiceContractDetails";
import {ContractHistory} from "./ContractHistory/ContractHistory";
import ContractProperties from "./ContractProperties/ContractProperties";
import {klona} from "klona";
import DetailsHeader from "./DetailsHeader";
import equal from "fast-deep-equal/es6/react";
import ConstructionContractDetails from "./ConstructionContract/ConstructionContractDetails";
import {NumberUtils} from "../../Common/NumberUtils";
import {EnergyContractDetails} from "./EnergyContract/EnergyContractDetails";
import {SupportServiceContractDetails} from "./SupportServiceContract/SupportServiceContractDetails";

interface Params {
  id?: string;
  action?: string;
}

interface Props extends RouteComponentProps<Params> {
  baseURL: string;
  addAlert: typeof alertActions.addAlert;
  classifiers: Classifier[];
  displayNavHeaderCallback: (val: boolean) => void;
}

export type ModalType = "publish" | "unpublish";

interface State {
  contract?: FullContract;
  editingContract?: FullContract;
  contractLoading: boolean;
  infoEditMode: boolean;
  publishConfirmationModalOpen: boolean;
  deleteConfirmationModalOpen: boolean;
  unsavedRowsConfirmationModalOpen: boolean;
  contractorNotificationPreviewModalOpen: boolean;
  contractHistoryOpen: boolean;
  contractDeletionReason: string;
  notificationPreviewContent: string;
}

class ContractDetails extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      contractLoading: true,
      infoEditMode: false,
      deleteConfirmationModalOpen: false,
      publishConfirmationModalOpen: false,
      unsavedRowsConfirmationModalOpen: false,
      contractorNotificationPreviewModalOpen: false,
      contractHistoryOpen: false,
      contractDeletionReason: "",
      notificationPreviewContent: null
    };
  }

  private updateContract = (contract: FullContract): void => {
    this.setState({
      contract: contract,
      contractLoading: false
    });
  };

  private updateEditingContract = (editingContract: FullContract, callback: () => void = () => {}): void => {
    this.setState({ editingContract }, callback);
  };

  private onSaveContract = () => {
    if (this.state.editingContract) {
      axios.put(`${this.props.baseURL}/contracts/${this.props.match!.params.id}`, this.state.editingContract)
           .then(res => {
             this.updateEditingContract(klona(res.data));
             this.updateContract(res.data);
           })
           .then(() => {
             this.setState({
               infoEditMode: false
             });
           });
    } else {
      this.setState({
        infoEditMode: false
      });
    }
  };

  private onSaveLeaseContractRows = () => {
    if (!this.state.editingContract) return;

    axios.post(`${this.props.baseURL}/contracts/${this.props.match!.params.id}/rows`, (this.state.editingContract as FullLeaseContract).rows)
         .then((res) => {
            const editingContract = this.state.editingContract as FullLeaseContract;
            editingContract.rows = res.data;

           this.updateEditingContract(editingContract);
           this.onSaveContract();
        });
  };

  private renderContractPublishedAlert = () => {
    let alert = new Alert();
    alert.type = AlertType.Success;
    alert.content = AlertContentType.Published;
    this.props.addAlert(alert);
  };

  private translateValidationErrorCode = (errorCode: string) => {

    return getClassifierValueName(this.props.classifiers, ClassifierCode.ValidationType, errorCode);
  };

  private handleValidationErrors(errors: any[]): void {
    const errorTypes = errors.map((error: any) => error.type)
                             .filter((value: any, index: number, self: any[]) => self.indexOf(value) === index);

    errorTypes.forEach((error: any) => {
      let alert = new Alert();
      alert.type = AlertType.Warning;
      alert.content = error;
      if(error == 'CONTRACT_EXCEPTION') {
        alert.additionalContent = errors.filter((e: any) => e.type == error)
            .map((e: any) => this.translateValidationErrorCode(e.errorCode))
            .sort((e1: string, e2: string) => e1.localeCompare(e2))
            .join(", ");
      }
      else if(error == 'ROW_EXCEPTION') {
        const rowErrors = errors.filter(error => error.type == 'ROW_EXCEPTION');
        alert.additionalContent = `Lepingul on ${rowErrors.length} ${rowErrors.length == 1 ? 'vigane': 'vigast'} rida`;
      }

      this.props.addAlert(alert);
    });
  }

  private renderValidationSuccessAlert = () => {
    let alert = new Alert();
    alert.type = AlertType.Success;
    alert.content = AlertContentType.Success;
    this.props.addAlert(alert);
  };

  private onValidate = () => {
    axios.post(`${this.props.baseURL}/contracts/${this.props.match!.params.id}/validations`)
         .then(res => {
           const errors = res.data.errors;

           const editingContract = klona(res.data.contract);
           const contract = res.data.contract;

           if (errors.length === 0) {
             this.renderValidationSuccessAlert();
           } else {
             this.handleValidationErrors(errors);

             errors.map((e: any) => e.errorMessage = this.translateValidationErrorCode(e.errorCode));
             editingContract.errors = errors;
             contract.errors = errors;
           }

           this.updateEditingContract(editingContract);
           this.updateContract(contract);
         });
  };

  private onPublishContract = (toggleModal: boolean = true) => {
    axios.put(`${this.props.baseURL}/contracts/published-contracts/${this.props.match!.params.id}`)
        .then(res => {
          const errors = res.data.errors;
          const editingContract = klona(res.data.contract);
          const contract = res.data.contract;

          if (errors.length === 0) {
            this.renderContractPublishedAlert();
          } else {
            this.handleValidationErrors(errors);

            errors.map((e: any) => e.errorMessage = this.translateValidationErrorCode(e.errorCode));
            editingContract.errors = errors;
            contract.errors = errors;
          }

          this.updateEditingContract(editingContract);
          this.updateContract(contract);
        })
        .catch(error => {
          let alertType = AlertContentType.ContractPublishError;
          if (error.response) {
            const apiErrorType = Alert.fromException(error.response.data.error);
            if (apiErrorType)
              alertType = apiErrorType;
          }
          const alert: Alert = new Alert();
          alert.type = AlertType.Danger;
          alert.content = alertType;

          this.props.addAlert(alert);
        })
        .then(() => {
          if (toggleModal)
            this.toggleConfirmationModal();
        });
  };

  private onUnpublishContract = (toggleModal: boolean = true) => {
    axios.put(`${this.props.baseURL}/contracts/${this.props.match!.params.id}/unpublish`)
         .then(res => {
           this.updateEditingContract(klona(res.data.contract as FullContract));
           this.updateContract(res.data.contract);
         })
         .then(() => {
           if(toggleModal)
            this.toggleConfirmationModal();
         });
  };

  private onDeleteContract = (deletionComment: string) => {

    axios.delete(`${this.props.baseURL}/contracts/${this.props.match!.params.id}`, {data: deletionComment})
        .then(res => {
          this.toggleContractDeletionModal();
        })
        .then(() => {
          const alert: Alert = new Alert();
          alert.type = AlertType.Success;
          alert.content = AlertContentType.ContractDeleteSuccess;
          this.props.addAlert(alert);

          this.props.history.goBack();
        }).catch(error => {
          let alertType = AlertContentType.ContractDeleteError;
          if (error.response) {
            const apiErrorType = Alert.fromException(error.response.data.error);
            if (apiErrorType)
              alertType = apiErrorType;
          }
          const alert: Alert = new Alert();
          alert.type = AlertType.Danger;
          alert.content = alertType;

          this.props.addAlert(alert);
          this.toggleContractDeletionModal();
        });
  };

  private onPublishConfirm = () => {
    if (this.state.contract.publishedOn) {
      this.onUnpublishContract();
    } else {
      this.onPublishContract();
    }
  };

  private onDeleteConfirm = (deletionReason : string) => {
    this.onDeleteContract(deletionReason);
  };

  private toggleInfoEditMode = () => {
    this.setState({
      infoEditMode: !this.state.infoEditMode
    });
  };

  private toggleConfirmationModal = () => {
    this.setState({
      publishConfirmationModalOpen: !this.state.publishConfirmationModalOpen
    });
  };

  private toggleContractDeletionModal = () => {
    this.setState({
      deleteConfirmationModalOpen: !this.state.deleteConfirmationModalOpen
    });
  };

  private toggleUnsavedRowsConfirmationModal = () => {
    this.setState({
      unsavedRowsConfirmationModalOpen: !this.state.unsavedRowsConfirmationModalOpen
    });
  };

    private sendContractorNotificationEmail = () => {
    axios.put(this.props.baseURL + "/contracts/" + this.props.match!.params.id + "/send-contractor-notification-email"
    ).then(res => {
      let alert = new Alert();
      alert.type = AlertType.Success;
      alert.content = AlertContentType.ContractorNotificationSuccess;
      this.props.addAlert(alert);
      this.setState({ contractorNotificationPreviewModalOpen: false }, () => {
        this.updateContract(res.data);
      });
    }).catch(() => {
      let alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.ContractorNotificationFailed;
      this.props.addAlert(alert);
    });
  };

  private openContractorNotificationPreviewModal = () => {
    axios.get(this.props.baseURL + "/mail/notification-preview", {
      params: {
        contractId: this.props.match!.params.id,
        notificationType: "SERVICE_CONTRACT_CONTRACTOR_REPRESENTATIVE_NOTIFICATION"
      }
    }).then(res => {
      this.setState({
        contractorNotificationPreviewModalOpen: true,
        notificationPreviewContent: res.data
      });
    }).catch(() => {
      let alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.ContractorNotificationFailed;
      this.props.addAlert(alert);
    });
  };

  private closeContractorNotificationPreviewModal = () => {
    this.setState({
      contractorNotificationPreviewModalOpen: false
    });
  };



  private onSaveRowsConfirm = () => {
    this.setState({
      infoEditMode: true
    }, () => {
      this.onSaveLeaseContractRows();
      this.toggleUnsavedRowsConfirmationModal();
    });
  }

  private toggleContractHistory = () => {
    this.setState({
      contractHistoryOpen: !this.state.contractHistoryOpen
    })
  };

  private onSaveThiMultiplication = (thiMultiplication: ThiMultiplication) => {
    axios.post(
        this.props.baseURL + "/contracts/" + this.props.match!.params.id + "/thi-multiplication",
        thiMultiplication
    ).then(res => {
      this.updateEditingContract(klona(res.data));
      this.updateContract(res.data);
    }).catch(() => {
      let alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.ThiMultiplicationFailed;
      this.props.addAlert(alert);
    });
  };

  private onSaveWarrantyEndDates = (data : CostItemWarranty[]) => {
    // make sure contract and rows are saved before we update anything
    const rowsEqual = equal(
        (this.state.editingContract as FullConstructionContract).rows,
        (this.state.contract as FullConstructionContract).rows);
    const propertiesEqual = equal(
        (this.state.editingContract as FullConstructionContract).properties,
        (this.state.contract as FullConstructionContract).properties);
    const validRows = rowsEqual && propertiesEqual;

    if(!validRows) {
      axios.post(`${this.props.baseURL}/contracts/${this.props.match!.params.id}/rows`, (this.state.editingContract as FullConstructionContract).rows)
          .then((res) => {
            const contract = this.state.contract as FullConstructionContract;
            contract.rows = res.data;
            const editingContract = this.state.editingContract as FullConstructionContract;
            editingContract.rows = klona(res.data);

            this.updateEditingContract(editingContract);
            this.updateContract(contract);
            this.saveWarrantyEndDates(data);
          });
    } else {
      this.saveWarrantyEndDates(data);
    }
  }

  private saveWarrantyEndDates = (data : CostItemWarranty[]) => {
    axios.put(
        this.props.baseURL + "/contracts/" + this.props.match!.params.id + "/cost-item-warranty", data
    ).then(() => {
      this.onSaveContract();
    }).catch(() => {
      let alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.WarrantyEndDateSaveFailed;
      this.props.addAlert(alert);
    });
  };

  onSaveDefaultPrices = (defaultPrices : DefaultPrice[]) => {
    // make sure contract and rows are saved before we update anything
    const rowsEqual = equal(
        (this.state.editingContract as FullServiceContract).rows,
        (this.state.contract as FullServiceContract).rows);
    const propertiesEqual = equal(
        (this.state.editingContract as FullServiceContract).properties,
        (this.state.contract as FullServiceContract).properties);
    const validRows = rowsEqual && propertiesEqual;

    if(!validRows) {
      axios.post(`${this.props.baseURL}/contracts/${this.props.match!.params.id}/rows`, (this.state.editingContract as FullServiceContract).rows)
          .then((res) => {
            const contract = this.state.contract as FullServiceContract;
            contract.rows = res.data;
            const editingContract = this.state.editingContract as FullServiceContract;
            editingContract.rows = klona(res.data);

            this.updateEditingContract(editingContract);
            this.updateContract(contract);
            this.saveDefaultPrices(defaultPrices);
          });
    } else {
      this.saveDefaultPrices(defaultPrices);
    }

  }

  private saveDefaultPrices = (prices: DefaultPrice[]) => {
    axios.post(
        this.props.baseURL + "/contracts/" + this.props.match!.params.id + "/prices", prices
    ).then(res => {
      this.updateEditingContract(klona(res.data));
      this.updateContract(res.data);
    }).catch(() => {
      let alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.DefaultPricesSaveFailed;
      this.props.addAlert(alert);
    });
  };

  private onCleanProperties = () => {
    const contract = this.state.editingContract;

    if (contract && contract.type === ContractType.ServiceContract) {
      const serviceContract = contract as FullServiceContract;

      if (serviceContract.properties) {
        let promises = [];

        serviceContract.properties!
        .filter(property => serviceContract.rows
            && !serviceContract.rows.some(row => row.propertyId === property.id && !row.deletedOn))
        .forEach(property => {
          promises.push(axios.delete(this.props.baseURL + "/contracts/" + contract.id + "/properties/" + property.id + "/")
              .then(res => {
                this.updateEditingContract(klona(res.data));
                this.updateContract(res.data);
              })
              .catch(() => {
                let alert = new Alert();
                alert.type = AlertType.Danger;
                alert.content = AlertContentType.PropertyCleanFailed;
                this.props.addAlert(alert);
              }));
        });

        Promise.all(promises).then(() => {
        });
      }
    }
  }

  private onExportToEDHS = () => {
    axios.get(this.props.baseURL + "/contracts/" + this.props.match!.params.id + "/export-to-edhs"
    ).then(res => {
      let alert = new Alert();
      alert.type = AlertType.Success;
      alert.content = AlertContentType.EDHSContractUpdateSuccess;
      this.props.addAlert(alert);
    }).catch(() => {
      let alert = new Alert();
      alert.type = AlertType.Danger;
      alert.content = AlertContentType.EDHSContractUpdateFailed;
      this.props.addAlert(alert);
    });
  };

  private renderContract() {
    switch (this.state.contract.type) {
      case ContractType.ConsumptionContract:
        return <ConsumptionContractDetails contract={this.state.contract! as FullConsumptionContract}
                                           infoEditMode={this.state.infoEditMode}
                                           editingContract={this.state.editingContract as FullConsumptionContract}
                                           updateEditingContract={this.updateEditingContract}
                                           onSaveContract={this.onSaveContract}/>;
      case ContractType.LeaseAgreement:
        return <LeaseContractDetails       contract={this.state.contract! as FullLeaseContract}
                                           infoEditMode={this.state.infoEditMode}
                                           editingContract={this.state.editingContract as FullLeaseContract}
                                           updateEditingContract={this.updateEditingContract}
                                           onSaveContract={this.onSaveContract}/>;
      case ContractType.EnergySaleAgreement:
        return <EnergyContractDetails      contract={this.state.contract! as FullEnergyContract}
                                           infoEditMode={this.state.infoEditMode}
                                           editingContract={this.state.editingContract as FullEnergyContract}
                                           updateEditingContract={this.updateEditingContract}
                                           onSaveContract={this.onSaveContract}/>;
      case ContractType.SupportServiceContract:
        return <SupportServiceContractDetails contract={this.state.contract! as FullSupportServiceContract}
                                              infoEditMode={this.state.infoEditMode}
                                              editingContract={this.state.editingContract as FullSupportServiceContract}
                                              updateEditingContract={this.updateEditingContract}
                                              onSaveContract={this.onSaveContract}/>;
      case ContractType.ServiceContract:
        return <ServiceContractDetails     contract={this.state.contract! as FullServiceContract}
                                           infoEditMode={this.state.infoEditMode}
                                           editingContract={this.state.editingContract as FullServiceContract}
                                           updateEditingContract={this.updateEditingContract}
                                           onSaveContract={this.onSaveContract}/>;
      case ContractType.ConstructionContract:
        return <ConstructionContractDetails contract={this.state.contract! as FullConstructionContract}
                                            infoEditMode={this.state.infoEditMode}
                                            editingContract={this.state.editingContract as FullConstructionContract}
                                            updateEditingContract={this.updateEditingContract}
                                            onSaveContract={this.onSaveContract}/>;

    }

    return <></>;
  }

  private renderContent() {
    if (this.state.contractLoading) {
      return <span><i className="details-spinner fa fa-spinner fa-spin fa-2x"/></span>;
    } else {
      if (this.state.contract) {
        return <>
          <ContractInfo
              contract={this.state.contract!}
              editingContract={this.state.editingContract}
              infoEditMode={this.state.infoEditMode}
              toggleInfoEditMode={this.toggleInfoEditMode}
              updateEditingContract={this.updateEditingContract}
              onSaveContract={this.onSaveContract}
              onUnpublishContract={this.onUnpublishContract}
              onPublishContract={this.onPublishContract}
              onShowUnsavedRowsConfirmationModal={this.toggleUnsavedRowsConfirmationModal}
          />
          {this.state.infoEditMode && (
            <ContractProperties
                editingContract={this.state.editingContract!}
                updateEditingContract={this.updateEditingContract}
            />
          )}

          {this.renderContract()}
        </>
      } else {
        return <span className="not-found">Paha lugu, seda lepingut meie andmebaasist ei leitud!</span>;
      }
    }
  }

  componentDidMount() {
    axios.get(this.props.baseURL + "/contracts/" + this.props.match!.params.id)
         .then(res => {
           this.updateEditingContract(klona(res.data));
           this.updateContract(res.data);
           store.dispatch(contractActions.setContract(res.data));
         })
         .finally(() => {
           this.setState({
             contractLoading: false
           });

           switch(this.props.location.state?.action) {
             case "new":
               this.toggleInfoEditMode();
               this.props.history.replace({...this.props.location, state: {...this.props.location.state, action: undefined}});
               break;
           }

         });
  }

  render() {
    return (
        <>
          {this.state.contract &&
          <>
            {this.renderPublishConfirmationModal()}
            {this.renderContractDeleteConfirmationModal()}
            {this.renderUnsavedRowsConfirmationModal()}
            {this.renderContractorNotificationPreviewModal()}

            <ContractHistory contract={this.state.contract}
                             isOpen={this.state.contractHistoryOpen}
                             toggle={this.toggleContractHistory}
            />
          </>
          }

          <div className="sticky">
            <DetailsHeader
                {...this.props as RouteComponentProps<Params>}
                contract={this.state.contract}
                isContract={true}
                editingContract={this.state.editingContract}
                infoEditMode={this.state.infoEditMode}
                onValidate={this.onValidate}
                onSaveContract={this.onSaveContract}
                onUpdateEditingContract={this.updateEditingContract}
                onSaveContractRows={this.onSaveLeaseContractRows}
                onShowPublishConfirmationModal={this.toggleConfirmationModal}
                onShowDeleteConfirmationModal={this.toggleContractDeletionModal}
                onShowUnsavedRowsConfirmationModal={this.toggleUnsavedRowsConfirmationModal}
                onShowContractorNotificationPreviewModal={this.openContractorNotificationPreviewModal}
                onShowContractHistory={this.toggleContractHistory}
                onSaveThiMultiplication={this.onSaveThiMultiplication}
                onCleanProperties={this.onCleanProperties}
                onSaveDefaultPrices={this.onSaveDefaultPrices}
                onSaveWarrantyEndDates={this.onSaveWarrantyEndDates}
                onExportToEDHS={this.onExportToEDHS}
            />
          </div>
          {this.state.infoEditMode &&
            <div className="container-fluid d-flex align-items-center flex-column mt-4 container-xl-max-width">
              {this.renderContent()}
            </div>
          }
          {!this.state.infoEditMode &&
          <div className="container-fluid pt-4">
            {this.renderContent()}
          </div>
          }
        </>
    );
  }

  private renderContractDeleteConfirmationModal() {
    return (
        <ConfirmationModal isOpen={this.state.deleteConfirmationModalOpen}
                           canConfirm={this.state.contractDeletionReason.length > 0}
                           confirmButtonColor="danger"
                           confirmButtonContent={<><i className="far fa-trash-alt mr-1" />Kustuta</>}
                           onCancel={this.toggleContractDeletionModal}
                           onConfirm={() => this.onDeleteConfirm(this.state.contractDeletionReason)}
        >
          <h4>
            {"Oled kindel, et soovid lepingu kustutada?"}
          </h4>
          <div className="form-row custom-form-row mt-5">
            <div className="col-12">
              <h6 className={"w-200"}>{"Lepingu kustutamise põhjus"}<span className="input-required"/></h6>
              <textarea className="form-control"
                        style={{width:"450px"}}
                        onChange={event => this.setState({contractDeletionReason : event.target.value})}
                        value={this.state.contractDeletionReason}
              />
            </div>
          </div>
        </ConfirmationModal>
    );
  }

  private renderPublishConfirmationModal() {
    return (
        <ConfirmationModal isOpen={this.state.publishConfirmationModalOpen}
                           canConfirm={true}
                           confirmButtonContent={this.state.contract.publishedOn ? "Võta muutmisse" : "Avalda"}
                           onCancel={this.toggleConfirmationModal}
                           onConfirm={this.onPublishConfirm}
        >
          <h4>
            {!this.state.contract.publishedOn
                ? "Oled kindel, et soovid lepingu avalikustada?"
                : "Oled kindel, et soovid lepingu muutmisse võtta?"
            }
          </h4>
          <div className="form-row custom-form-row mt-4">
            <div className="col-12">
              <div className="alert alert-warning">
                {!this.state.contract.publishedOn
                    ? "Pärast avaldamist pole lepingu päiseandmeid ja ridu võimalik muuta, " +
                    "olete avaldamissoovis kindlad?"
                    : "Kuni järgmise avaldamiseni on lepingu andmed nähtavad ainult veebiliidesest, " +
                    "olete muutmissoovis kindlad?"
                }
              </div>
            </div>
          </div>
        </ConfirmationModal>
    );
  }

  private renderUnsavedRowsConfirmationModal() {
    return (
        <ConfirmationModal isOpen={this.state.unsavedRowsConfirmationModalOpen}
                           canConfirm={true}
                           confirmButtonContent={"Salvesta read ja jätka"}
                           onCancel={this.toggleUnsavedRowsConfirmationModal}
                           onConfirm={this.onSaveRowsConfirm}
        >
          <h4>Oled kindel, et soovid jätkata?</h4>
          <div className="form-row custom-form-row mt-4">
            <div className="col-12">
              <div className="alert alert-warning">
                Sul on salvestamata ridasid
              </div>
            </div>
          </div>
        </ConfirmationModal>
    );
  }

  private renderContractorNotificationPreviewModal() {
    return (
        <ConfirmationModal isOpen={this.state.contractorNotificationPreviewModalOpen}
                           canConfirm={true}
                           confirmButtonContent={"Saada kiri"}
                           onCancel={this.closeContractorNotificationPreviewModal}
                           onConfirm={this.sendContractorNotificationEmail}
        >
          <h4>Saada kiri projektijuhile?</h4>
          <div className="form-row custom-form-row mt-4">
            <div className="col-12">
                <iframe className="notification-preview-iframe" frameBorder="0" src={"data:text/html;charset=utf-8,"+"<base target=\"_blank\">"+encodeURIComponent(this.state.notificationPreviewContent)}/>
            </div>
          </div>
        </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  baseURL: getBaseURL(state),
  classifiers: getClassifiers(state)
});

const mapDispatchToProps = {
  addAlert: alertActions.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails);

export function calculateValueOfContract(contract: ServiceContract | ConstructionContract) {
  let sum = 0;
  if (contract.reserveCalculationBasedOn === ReserveCalculationBasedOn.Value) {
    sum = contract.sum + contract.reserveValue;
  } else {
    sum = contract.sum + contract.sum * contract.reservePercentage;
  }
  if (sum > 0) {
    const formattedSum = NumberUtils.format(sum,2,2).replace(".", ",");
    return formattedSum + " €";
  } else {
    return "";
  }
}

export function calculateSumOfObjectReserves(contract: FullServiceContract | FullConstructionContract) {

  let sum = 0;
  contract.properties.forEach(property => sum += property.reserveValue || 0);
  if((contract as FullConstructionContract).projects)
    (contract as FullConstructionContract).projects.forEach(project => sum += project.reserveValue || 0);

  const formattedSum = NumberUtils.format(sum,2,2).replace(".", ",");
  return formattedSum + " €";
}

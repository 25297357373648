import React from "react";
import {FullContract, ReserveCalculationBasedOn} from "../../../Contract";
import { ContractInfoField, ContractInfoFieldProps } from "./ContractInfoField";
import {NumberUtils} from "../../../../Common/NumberUtils";
import {isNullOrUndefined} from "util";
import {NumberInput} from "../../../../Common/NumberInput";

interface Props<T extends FullContract> extends ContractInfoFieldProps<T> {
    reserveCalculationBasedOn: ReserveCalculationBasedOn;
    reservePercentage: number;
    placeholder?: string;
}

export default class ReserveField<T extends FullContract> extends ContractInfoField<T, Props<T>> {

    private onChange = (value: number) => {
        if (this.props.reserveCalculationBasedOn === ReserveCalculationBasedOn.Value) {
            this.props.onChange(this.props.fieldKey, value);
        } else {
            this.props.onChange(this.props.fieldKey, value / 100);
        }
    };

    protected renderReadOnlyField(): JSX.Element {
        return (
            <span className="font-weight-bold">
          {this.formatReadOnlyReserveValue()}
        </span>
        )
    }



    protected renderEditableField(): JSX.Element {
        return (
            <NumberInput value={this.getValue()}
                         onNumberChange={this.onChange} />
        );
    }

    private formatReadOnlyReserveValue= () => {

        if (this.props.reserveCalculationBasedOn === ReserveCalculationBasedOn.Value) {
            return isNullOrUndefined(this.getFieldValue()) ? "" :
                NumberUtils.format(this.getFieldValue() || 0) + " € ("
                + NumberUtils.format((this.props.reservePercentage || 0) * 100) + "%)";
        } else {
            return isNullOrUndefined(this.props.reservePercentage) ? "" :
                NumberUtils.format((this.props.reservePercentage || 0) * 100) + "% ("
                + NumberUtils.format(this.getFieldValue() || 0) + " €)";
        }
    }

    private getValue() {
        if (this.props.reserveCalculationBasedOn === ReserveCalculationBasedOn.Value) {
            return this.getFieldValue();
        } else {
            return this.getFieldValue() * 100;
        }

    }
}
